import THREE from "three";

import leaflet_vert from './shaders/leaflet_vert.glsl';
import leaflet_frag from './shaders/leaflet_frag.glsl';

import { ShaderChunks as chunks } from "./ShaderChunks";

export let LeafletShader = {
    uniforms: THREE.UniformsUtils.merge([
        THREE.UniformsLib["common"],
        chunks.CutPlanesUniforms,
        chunks.IdUniforms,
        chunks.ThemingUniform,
        {
            selectionColor: { type: "v4", value : new THREE.Vector4(0, 0, 1, 0) },
            viewportBounds: { type: "v4", value: new THREE.Vector4(0, 0, 1, 1) },
            modelLocalMatrix: { type: "m4", value: new THREE.Matrix4() },
        },
    ]),
    vertexShader: leaflet_vert,
    fragmentShader: leaflet_frag,
};

export const LeafletShaderWebGPU = {
    uniforms: {
        // Used for viewport clipping. Must be set to the inverse of the leaflet's model transform. See shader struct for details.
        worldToModelMatrix: { type: "m4", value: new THREE.Matrix4() },

        offsetRepeat: { type: "v4", value: new THREE.Vector4(0, 0, 1, 1) },
        viewportBounds: { type: "v4", value: new THREE.Vector4(0, 0, 1, 1) },
        selectionColor: { type: "v4", value : new THREE.Vector4(0, 0, 1, 0) },
        hasViewportClipping: { type: "i", value: 1 },

        map: { type: "t", value: null },
    },
    // Not needed for WebGPU, just set to avoid THREE warnings
    vertexShader: "",
    fragmentShader: "",
};
