
const SIZE_VB = 64 * 1024 * 1024;
const SIZE_IB = 16 * 1024 * 1024;
const SIZE_MB = 32 * 1024 * 1024;
const SIZE_UB = 128 * 1024 * 1024;

let nextId = 1;

//Maintains a list of free memory block within a GPU side buffer. Basically a minimal heap implementation.
//Used when we load/unload geometry repeatedly and dynamically when switching views
//This is a naive implementation that needs optimization after we do some benchmarking.
function FreeList(heapSize) {
    this.starts = new Map();
    this.ends = new Map();
    /** @type {number} Total size of all free blocks, not necessarily contiguous. */
    this.freeSize = 0;

    //Add the initial free block
    this.addFreeBlock(0, heapSize);
}

FreeList.prototype.addFreeBlock = function(offset, size) {
    let start = offset;
    let end = offset + size;
    this.freeSize += size;

    //See if the newly freed block is to the left of (before) an already free block, and combine them
    let endNext = this.starts.get(end);
    if (endNext !== undefined) {
        this.starts.delete(end);
        end = endNext;
    }

    //See if the newly freed block is to the right of (after) an already free block, and combine them
    //That this can be true even if there was a free block on the right (case above)
    let startBefore = this.ends.get(start);
    if (startBefore !== undefined) {
        this.ends.delete(start);
        start = startBefore;
    }

    this.starts.set(start, end);
    this.ends.set(end, start);
};

FreeList.prototype.findBlock = function(size) {
    //Look for a free block that's big enough to hold the requested size
    //This is just a naive spin through all free blocks
    let foundStart, foundEnd;
    for (const [start, end] of this.starts) {
        if (end - start >= size) {
            foundStart = start;
            foundEnd = end;
            break;
        }
    }

    if (foundStart === undefined) {
        return undefined;
    }

    this.freeSize -= size;
    let remainingStart = foundStart + size;

    this.starts.delete(foundStart);
    if (remainingStart === foundEnd) {
        //No space left in this chunk, remove it from the free list completely
        this.ends.delete(foundEnd);
    } else {
        //Create a smaller new chunk
        this.starts.set(remainingStart, foundEnd);
        this.ends.set(foundEnd, remainingStart);
    }

    return foundStart;
};

/** @returns {number} The total size of all free memory blocks. */
FreeList.prototype.getFreeSize = function() {
    return this.freeSize;
};


export class BumpAllocator {

    /**
     * @typedef {Object} BufferEntry
     * @property {GPUBuffer} buffer
     * @property {FreeList} heap
     * @property {number} id
     */

    /**
     * Describes an allocation with a GPUBuffer
     * @typedef {Object} BufferAlloc
     * @property {BufferEntry} bufferEntry
     * @property {number} offset
     * @property {number} size
     */

    /** @type {Object.<number, BufferEntry[]>} Vertex Buffer lists keyed by stride size. */
    #vbListPerStride = {};
    /** @type {BufferEntry[]} Index Buffer list */
    #ibList = [];
    /** @type {BufferEntry[]} */
    #materialList = [];
    /** @type {BufferEntry[]} */
    #objectUniformList = [];
    /** @type {GPUDevice} */
    #device;
    /**
     * @type {boolean} Whether frees are batched or happen immediately.
     * Batching frees can help prevent reusing the same portion of a buffer
     * multiple times in one frame, which can cause performance stalls.
     */
    #freesAreBatched;
    /**
     * @typedef {Object} FreeBlock
     * @property {FreeList} heap
     * @property {number} offset
     * @property {number} size
     */
    /** @type {FreeBlock[]} */
    #blocksToBeFreed = [];

    constructor(device, freesAreBatched = false) {
        this.#device = device;
        this.#freesAreBatched = freesAreBatched;
    }

    /**
     * Allocate memory in one of the buffers in bufferList.
     * @param {BufferEntry[]} bufferList
     * @param {GPUBufferUsage} usage Usage to create new buffers with.
     *   COPY_DST will also be added.
     * @param {number} bufferSize The size of new buffers.
     * @param {number} allocSize The amount of memory to allocate, in bytes.
     * @returns {BufferAlloc}
     */
    #alloc(bufferList, usage, bufferSize, allocSize) {
        let curBuf;
        let resOffset;

        for (let i = bufferList.length - 1; i >= 0; i--) {
            resOffset = bufferList[i].heap.findBlock(allocSize);
            if (resOffset !== undefined) {
                curBuf = bufferList[i];
                break;
            }
        }

        if (resOffset === undefined) {
            const buffer = this.#device.createBuffer({
                size: bufferSize,
                usage: usage | GPUBufferUsage.COPY_DST
            });

            curBuf = {
                buffer,
                heap: new FreeList(bufferSize),
                id: nextId++
            };

            bufferList.push(curBuf);

            resOffset = curBuf.heap.findBlock(allocSize);
        }

        return {
            bufferEntry: curBuf,
            offset: resOffset,
            size: allocSize,
        };
    }

    /**
     * Allocate memory for geometry vertex buffer.
     * @param {number} size The amount of memory to allocate, in bytes.
     * @param {number} strideBytes The stride of the vertex buffer, in bytes.
     * @returns {BufferAlloc}
     */
    vAlloc(size, strideBytes) {
        let vbList = this.#vbListPerStride[strideBytes];
        if (!vbList) {
            vbList = [];
            this.#vbListPerStride[strideBytes] = vbList;
        }

        return this.#alloc(vbList, GPUBufferUsage.VERTEX, SIZE_VB, size);
    }

    /**
     * Allocate memory for geometry index buffer.
     * @param {number} size The amount of memory to allocate, in bytes.
     * @returns {BufferAlloc}
     */
    iAlloc(size) {
        return this.#alloc(this.#ibList, GPUBufferUsage.INDEX, SIZE_IB, size);
    }

    /**
     * Allocate memory for a material.
     * @param {number} size The amount of memory to allocate, in bytes.
     * @returns {BufferAlloc}
     */
    mAlloc(size) {
        return this.#alloc(
            this.#materialList, GPUBufferUsage.STORAGE, SIZE_MB, size);
    }

    /**
     * Allocate memory for ranges of batched ObjectUniforms.
     * @param {number} size The amount of memory to allocate, in bytes.
     * @returns {BufferAlloc} An array where the first entry (type BufferEntry) is an object that represents the underlying buffer, and the
     * 	second entry is the offset of the newly allocated memory chunk in the underlying buffer.
     */
    uAlloc(size) {
        return this.#alloc(
            this.#objectUniformList, GPUBufferUsage.STORAGE, SIZE_UB, size);
    }

    /**
     * @param {FreeList} heap
     * @param {number} offset
     * @param {number} size
     */
    #freeBlock(heap, offset, size) {
        if (this.#freesAreBatched) {
            this.#blocksToBeFreed.push({
                heap,
                offset,
                size,
            });
        } else {
            heap.addFreeBlock(offset, size);
        }
    }

    gFree(geometry) {
        if (geometry.__gpuvb) {
            this.#freeBlock(
                geometry.__gpuvb.heap,
                geometry.__gpuvbBaseVertex * geometry.vbstride * 4,
                geometry.__gpuvbSize);
            geometry.__gpuvb = undefined;
        }

        if (geometry.__gpuib) {
            this.#freeBlock(
                geometry.__gpuib.heap,
                geometry.__gpuibBaseIndex << geometry.__gpuibShift,
                geometry.__gpuibSize);
            geometry.__gpuib = undefined;
        }

        geometry.__gpu = undefined;
    }

    /**
     * Frees the allocation in a GPUBuffer described by bufferAlloc.
     * @param {BufferAlloc} bufferAlloc
     */
    freeAlloc(bufferAlloc) {
        this.#freeBlock(
            bufferAlloc.bufferEntry.heap, bufferAlloc.offset, bufferAlloc.size);
    }

    /**
     * Free memory in the given BufferEntry.
     * @param {BufferEntry} bufferEntry
     * @param {number} byteOffset
     * @param {number} byteSize
     */
    free(bufferEntry, byteOffset, byteSize) {
        this.#freeBlock(bufferEntry.heap, byteOffset, byteSize);
    }

    flushFreeBlocks() {
        if (!this.#freesAreBatched) {
            return;
        }

        for (const block of this.#blocksToBeFreed) {
            block.heap.addFreeBlock(block.offset, block.size);
        }
        this.#blocksToBeFreed = [];
    }

    stats() {
        console.log(this.#vbListPerStride, this.#ibList);
    }

    computeUsedBytes() {
        let totalBufferSize = 0;
        let vbBytes = 0;
        for (const vbList of Object.values(this.#vbListPerStride)) {
            for (const buffer of vbList) {
                vbBytes += buffer.buffer.size - buffer.heap.getFreeSize();
                totalBufferSize += buffer.buffer.size;
            }
        }
        let ibBytes = 0;
        for (const buffer of this.#ibList) {
            ibBytes += buffer.buffer.size - buffer.heap.getFreeSize();
            totalBufferSize += buffer.buffer.size;
        }
        let mbBytes = 0;
        for (const buffer of this.#materialList) {
            mbBytes += buffer.buffer.size - buffer.heap.getFreeSize();
            totalBufferSize += buffer.buffer.size;
        }
        let ubBytes = 0;
        for (const buffer of this.#objectUniformList) {
            ubBytes += buffer.buffer.size - buffer.heap.getFreeSize();
            totalBufferSize += buffer.buffer.size;
        }

        return {
            vbBytes,
            ibBytes,
            mbBytes,
            ubBytes,
            totalBytes: vbBytes + ibBytes + mbBytes,
            totalBufferSize,
        };
    }

    // Maximum size in bytes that a single uniform buffer can have
    static maxUniformBufferSize() {
        return SIZE_UB;
    }
}
