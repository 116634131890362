import {FlatStringStorage} from "../../../wgs/scene/InstanceTreeStorage";
import {PropertyDatabase} from "../../lmvtk/common/Propdb";
import {AttributeType} from "../../lmvtk/common/PropdbEnums";
import {_AttrDefInternal, TypedArrayLike, isStringType, fail} from "./common";

/**
 * Generic interface for storing typed array data in column form
 */
export interface Column<T extends TypedArrayLike, V> {
    readonly idCount: number
    readonly isSparse?: boolean,
    readonly isStringType?: boolean,
    getValueAt(index: number): V|undefined
    setValueAt(index: number, valueId: number|undefined, pdb: PropertyDatabase): void

    index(pdb: PropertyDatabase, storage: FlatStringStorage, valIdToStorage: Map<number, number>): [FlatStringStorage, Map<number, number>]
    toWireType(): [T, Uint8Array, boolean] // Needs to be pairs of Entries as well as nullable column, Boolean is for flagging if this column is sparse
    byteCount(): number
}


/** Union type of all columns */
export type AnyColumn = Column<TypedArrayLike, unknown>;


export const Column = {

    fromWireType(idCount: number, attr: _AttrDefInternal, descriptor: [TypedArrayLike, Uint8Array, boolean], storage: FlatStringStorage): AnyColumn {

        const isSparse = descriptor[2];

        if (isSparse) {
            return new SparseColumn(attr, <Uint8Array>descriptor[0], storage, descriptor[1]);
        }
        const nullMarkerCol = descriptor ? new NullableColumnMarker(0, <Uint8Array>descriptor[1]) : undefined;
        const colData: unknown = descriptor[0];
        const size = idCount;
        const type = attr.dataType;
        const isStringType = type >= 20;

        if (isStringType) {
            return StringColumn.fromWireType(attr, <Uint32Array>colData, storage);
        }

        switch(type) {
            case AttributeType.Boolean: return new BoolColumn(attr, size, <Uint8Array>colData, nullMarkerCol);
            case AttributeType.Integer: return new Int32Col(attr, size, <Int32Array>colData, nullMarkerCol);
            case AttributeType.Float: return new Float32Col(attr, size, <Float32Array>colData, nullMarkerCol);
            case AttributeType.DbKey: return new Uint32Col(attr, size, <Uint32Array>colData, nullMarkerCol);
            case AttributeType.Double: return new DoubleCol(attr, size, <Float64Array>colData, nullMarkerCol);
            default: throw new Error(`Attribute not supported: [ ${attr.category}/${attr.name} ]`);
        }
    },

    fromAttribute(attr: _AttrDefInternal, size: number, isSparse?: boolean) {
        if (isSparse) {
            return new SparseColumn(attr);
        }
        const type = attr.dataType;
        if (isStringType(attr)) {
            return new StringColumn(size, attr);
        }

        switch(type) {
            case AttributeType.Boolean: return new BoolColumn(attr, size);
            case AttributeType.Integer: return new Int32Col(attr, size);
            case AttributeType.Float: return new Float32Col(attr, size);
            case AttributeType.DbKey: return new Uint32Col(attr, size);
            case AttributeType.Double: return new DoubleCol(attr, size);
            default: throw new Error(`Attribute not supported: [ ${attr.category}/${attr.name} ]`);
        }
    }
};

export class BoolColumn implements Column<Uint8Array, boolean> {

    readonly #col: Uint8Array;
    readonly #view: DataView;
    readonly #attr: _AttrDefInternal;

    readonly nullTable: NullableColumnMarker;

    constructor(attr: _AttrDefInternal, public readonly idCount: number, storage?: Uint8Array, flags?: NullableColumnMarker) {
        this.#attr = attr;
        this.#col = storage ?? new Uint8Array(Math.ceil(idCount / 8));
        this.#view = new DataView(this.#col.buffer);
        this.nullTable = flags ?? new NullableColumnMarker(idCount);
    }

    getValueAt(index: number): boolean|undefined {
        if (this.nullTable.isNull(index)) return undefined;
        return getMask(index, this.#view);
    }

    setValueAt(index: number, valueId: number, pdb: PropertyDatabase): void {
        const value = pdb.getAttrValue(this.#attr.attrId, valueId);
        if (valueId === undefined || value === undefined) {
            return;
        }

        this.nullTable.setNotNull(index);
        if (index >= this.idCount) return;
        setMask(index, value, this.#view);
    }

    toWireType(): [Uint8Array, Uint8Array, boolean] {
        return [this.#col, this.nullTable.flags, false];
    }

    byteCount(): number {
        return this.#col.byteLength + this.nullTable.flags.byteLength;
    }

    index(pdb: PropertyDatabase, storage: FlatStringStorage, valIdToStorage: Map<number, number>): [FlatStringStorage, Map<number, number>] {
        return [storage, valIdToStorage];
    }
}

// Only supports flagging indices as not null
class NullableColumnMarker {
    readonly flags: Uint8Array;
    readonly #view: DataView;
    constructor(idCount: number, storage?: Uint8Array) {
        this.flags = storage ?? new Uint8Array(Math.ceil(idCount / 8));
        this.#view = new DataView(this.flags.buffer);
    }
    setNotNull(index: number) {
        setMask(index, true, this.#view);
    }
    isNull(index: number) {
        return !getMask(index, this.#view);
    }
}

// Made to skip 3 separate implementations for 32-bit typed arrays
type NumberArray = Int32Array|Uint32Array|Float32Array|Float64Array;
type TypedNumberGetter = (v: DataView, index: number) => number;
type TypedNumberSetter = (v: DataView, index: number, value: number) => void;

export abstract class Base32BitCol implements Column<NumberArray, number> {

    readonly idCount: number;
    readonly #col: NumberArray;
    readonly #view: DataView;
    readonly #getter: TypedNumberGetter;
    readonly #setter: TypedNumberSetter;

    readonly #attr: _AttrDefInternal;

    readonly #nullTable;

    protected constructor(attr: _AttrDefInternal, buf: NumberArray, getter: TypedNumberGetter, setter: TypedNumberSetter, flags?: NullableColumnMarker) {
        this.#attr = attr;
        this.#nullTable = flags ?? new NullableColumnMarker(buf.length);
        this.#getter = getter;
        this.#setter = setter;
        this.#col = buf;
        this.#view = new DataView(buf.buffer);
        this.idCount = buf.length;
    }

    getValueAt(index: number): number|undefined {
        if (this.#nullTable.isNull(index)) return undefined;
        return this.#getter(this.#view, index);
    }
    setValueAt(index: number, valueId: number, pdb: PropertyDatabase): void {
        const value = pdb.getAttrValue(this.#attr.attrId, valueId);
        if (valueId === undefined || value === undefined) {
            return;
        }
        this.#nullTable.setNotNull(index);
        this.#setter(this.#view, index, value);
    }

    toWireType(): [NumberArray, Uint8Array, boolean] {
        return [this.#col, this.#nullTable.flags, false];
    }

    byteCount(): number {
        return this.#col.byteLength + this.#nullTable.flags.byteLength;
    }
    index(pdb: PropertyDatabase, storage: FlatStringStorage, valIdToStorage: Map<number, number>): [FlatStringStorage, Map<number, number>] {
        return [storage, valIdToStorage];
    }
}

export class Int32Col extends Base32BitCol {
    constructor(attr: _AttrDefInternal, idCount: number, storage?: Int32Array, flags?: NullableColumnMarker) {
        super(attr, storage ?? new Int32Array(idCount), (v, i) => v.getInt32(i * 4, true), (view, i, v) => view.setInt32(i * 4, v, true), flags);
    }
}
export class Uint32Col extends Base32BitCol {
    constructor(attr: _AttrDefInternal, idCount: number, storage?: Uint32Array, flags?: NullableColumnMarker) {
        super(attr, storage ?? new Uint32Array(idCount), (v, i) => v.getUint32(i * 4, true), (view, i, v) => view.setUint32(i * 4, v, true), flags);
    }
}
// Float type exists in LMVTK PDB but not in the C++ one... I've never seen this in the wild
export class Float32Col extends Base32BitCol {
    constructor(attr: _AttrDefInternal, idCount: number, storage?: Float32Array, flags?: NullableColumnMarker) {
        super(attr, storage ?? new Float32Array(idCount), (v, i) => v.getFloat32(i * 4, true), (view, i, v) => view.setFloat32(i * 4, v, true), flags);
    }
}
export class DoubleCol extends Base32BitCol {
    constructor(attr: _AttrDefInternal, idCount: number, storage?: Float64Array, flags?: NullableColumnMarker) {
        // somewhat a hack casting here, either that or coming up with a name that spans these number types
        super(attr, (storage ?? new Float64Array(idCount)), (v, i) => v.getFloat64(i * 8, true), (view, i, v) => view.setFloat64(i * 8, v, true), flags);
    }
}


// String column works the same as the Uint column, except it manages a FlatStringStorage instance for de-duplication
export class StringColumn implements Column<Uint32Array, string> {

    readonly isStringType = true;

    readonly #col: Uint32Array;
    readonly #view: DataView;
    #stringStorage?: FlatStringStorage;

    get isIndexed() { return Boolean(this.#stringStorage); }


    constructor(public readonly idCount: number, private readonly attrDef: _AttrDefInternal, data?: Uint32Array) {
        this.#col = data ?? new Uint32Array(idCount);
        this.#view = new DataView(this.#col.buffer);
    }

    index(pdb: PropertyDatabase, storage?: FlatStringStorage, valIdToStorage?: Map<number, number>): [FlatStringStorage, Map<number, number>] {
        if (this.isIndexed) throw new Error(`Cannot index twice`);

        this.#stringStorage = storage ?? new FlatStringStorage();
        const valIdToSs = valIdToStorage ?? new Map<number, number>();

        for (let i = 0; i < this.idCount; i++) {
            const valId = this.#view.getUint32(i * 4, true);
            if (valId === 0) continue;
            const existing = valIdToSs.get(valId);
            if (existing) {
                this.#view.setUint32(i * 4, existing, true);
                continue;
            }
            const str = pdb.getAttrValue(this.attrDef.attrId, valId);
            const ssIdx = this.#stringStorage.add(str);
            valIdToSs.set(valId, ssIdx);
            this.#view.setUint32(i * 4, ssIdx, true);
        }

        return [this.#stringStorage, valIdToSs];
    }

    getValueAt(index: number): string|undefined {
        if (!this.isIndexed) return '';
        return (<FlatStringStorage>this.#stringStorage).get(this.#view.getUint32(index * 4, true));
    }

    setValueAt(index: number, valueId: number): void {
        if (!valueId) return; // 0 will just be the null string
        // should this class be a uint32 return type with an additional function specifically for getting the string or?
        if (this.isIndexed) throw new Error(`Write after indexing`);

        this.#view.setUint32(index * 4, valueId, true);
    }

    toWireType(): [Uint32Array, Uint8Array, boolean] {
        return [this.#col, new Uint8Array(0), false]; // Strings wired separately
    }

    byteCount(): number {
        return this.#col.byteLength;
    }

    public static fromWireType(attrDef: _AttrDefInternal, buf: Uint32Array, storage?: FlatStringStorage) {
        const col = new StringColumn(buf.length, attrDef, buf);
        col.#stringStorage = storage;
        return col;
    }

}

/**
 * Sparse column should be used when the cost of contiguous arraybuffers
 * is higher than using a simple number->number map. This is not determined
 * here, but is done in a query indexing step, or dynamically predicted during the filter query
 */
class SparseColumn<T> implements Column<Uint8Array, T> {

    isSparse = true;

    #values = new Map<number, T>();
    #stringStorage?: FlatStringStorage;

    #descriptor: [Uint8Array, Uint8Array, boolean];

    get idCount() {
        return this.#values.size;
    }
    readonly #byteCount: number;
    readonly #attr: _AttrDefInternal;
    readonly isStringType: boolean;

    constructor(attr: _AttrDefInternal, data?: Uint8Array, storage?: FlatStringStorage, indexTable?: Uint8Array) {
        if (attr.dataType === AttributeType.Boolean) throw new Error("Not supported: Boolean");
        if (data && indexTable) {
            let buf: TypedArrayLike = new Uint32Array(data.buffer);
            if (attr.dataType === AttributeType.Integer) {
                buf = new Int32Array(data.buffer);
            } else if (attr.dataType === AttributeType.Float) {
                buf = new Float32Array(data.buffer);
            } else if (attr.dataType === AttributeType.Double) {
                buf = new Float64Array(data.buffer);
            }
            const indexBuf = new Uint32Array(indexTable.buffer);
            this.#values = new Map();
            for (let i = 0; i < buf.length; i++) {
                this.#values.set(indexBuf[i], <T>buf[i]);
            }
        }
        this.#byteCount = data?.byteLength ?? 0;
        this.#stringStorage = storage;
        this.#attr = attr;
        this.isStringType = attr.dataType >= 20;
    }

    index(pdb: PropertyDatabase, storage: FlatStringStorage, valIdToStorage: Map<number, number>): [FlatStringStorage, Map<number, number>] {

        if (isStringType(this.#attr)) {
            this.#stringStorage = storage;
        }

        const attr = this.#attr;
        const dummyPdb = {
            getAttrValue(aid: number, vid: number) {
                // For strings, it's just a dummy PDB that just passes back the vid just like direct storage does
                return isStringType(attr) ? vid : pdb.getAttrValue(attr.attrId, vid);
            }
        };
        const dummyIndexPdb = {
            getAttrValue: (aid: number, vid: number) => vid
        };

        // Convert to optimized buffer form by using existing columns:
        //    [ Specialized_DataType, Index ]
        const specializedColumn = Column.fromAttribute(this.#attr, this.#values.size);
        const indexColumn = Column.fromAttribute(<_AttrDefInternal>{ dataType: AttributeType.DbKey }, this.#values.size);

        let i = 0;
        for (const [index, value] of this.#values) {
            specializedColumn.setValueAt(i, <number>value, <PropertyDatabase>dummyPdb);
            indexColumn.setValueAt(i, index, <PropertyDatabase>dummyIndexPdb);
            i++;
        }

        specializedColumn.index(pdb, storage, valIdToStorage);

        const specializedDescriptor = specializedColumn.toWireType();
        const indexDescriptor = indexColumn.toWireType();

        this.#descriptor = [
            new Uint8Array(specializedDescriptor[0].buffer),
            new Uint8Array(indexDescriptor[0].buffer),
            true
        ];

        return [storage, valIdToStorage];
    }

    getValueAt(index: number): T | undefined {
        if (isStringType(this.#attr)) {
            return <T>this.#stringStorage?.get(this.#values.get(index));
        } else {
            return this.#values.get(index);
        }
    }
    setValueAt(index: number, valueId: number): void {
        if (typeof valueId === 'undefined' || valueId === null) return;
        this.#values.set(index, <T>valueId);
    }

    toWireType(): [Uint8Array, Uint8Array, boolean] {
        if (!this.#descriptor) {
            throw new Error(`Attempted to transfer string column when not indexed: ${this.#attr.name}`);
        }
        return this.#descriptor;
    }

    byteCount(): number {
        return this.#byteCount;
    }
}


export class CustomPropertyWrapperColumn<T, A extends TypedArrayLike, C extends Column<A, T>> implements Column<A, T> {

    readonly #wrapped: C;

    get idCount() { return this.#wrapped.idCount; }
    get isSparse() { return this.#wrapped.isSparse; }

    /**
     * Custom properties are technically applied to all objects in the model.
     * There are no undefined values, and no matter what the data-type, will return an empty string in that scenario
     * To preserve the space saving of the original columns, this simply wraps another column and returns empty string when required
     * @param wrapped
     */
    constructor(wrapped: C) {
        this.#wrapped = wrapped;
    }

    getValueAt(index: number): T | undefined {
        const val = this.#wrapped.getValueAt(index);
        if (val === undefined || val === null) {
            return <T>(<unknown>"");
        }
        return val;
    }

    setValueAt() {
        fail("Not supported");
    }

    index(): [FlatStringStorage, Map<number, number>] {
        fail("Not supported");
    }

    toWireType(): [A, Uint8Array, boolean] {
        return this.#wrapped.toWireType();
    }

    byteCount(): number {
        return this.#wrapped.byteCount() + 16;
    }

    public static wrap<T, A extends TypedArrayLike, C extends Column<A, T>>(otherColumn: C): CustomPropertyWrapperColumn<T, A, C> {
        return new CustomPropertyWrapperColumn(otherColumn);
    }

}


function getMask(index: number, view: DataView) {
    const byte = view.getUint8(Math.floor(index / 8));
    const mask = 1 << index % 8;
    return Boolean(byte & mask);
}

function setMask(index: number, value: boolean, view: DataView) {
    const byteIdx = Math.floor(index / 8);
    const byte = view.getUint8(byteIdx);
    let mask = 1 << index % 8;
    if (!value) mask = ~mask;
    view.setUint8(byteIdx, value ? byte | mask : byte & mask);
}