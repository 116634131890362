import { OtgLoader } from "../../../../../file-loaders/main/OtgLoader";
import { CallbackTask } from "../CallbackTask";

/**
 * Creates a task that waits until the geometry has been loaded (once the fraglist has been loaded).
 *
 * @param {BvhNode} node - The ID of the BVH node
 * @param {Stage} stage - The stage of the task
 */
export function initializeGeometryLoadedTasks(node, stage) {
    if (node.model.loader instanceof OtgLoader) {
        node.addTask(new CallbackTask(node.outOfCoreTileManager, async () => {
            await node.getNodeGeometryLoadedPromise();
        }), stage);
    }
}
