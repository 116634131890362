import { UploadTaskBase } from './UploadTaskBase';

/** @import { FragmentList } from "../../../FragmentList" */

export class InstancedMeshUploadTask extends UploadTaskBase {

    /**
     * Creates a new task to upload the given instance geometry
     *
     * @param {OutOfCoreTileManager} outOfCoreTileManager - The OutOfCoreTileManager instance
     * @param {number} meshIndex - The index of the mesh
     * @param {FragmentList} fragList - The fragment list
     * @param {number} bvhNodeId - The BVH node ID
     */
    constructor(outOfCoreTileManager, meshIndex, fragList, bvhNodeId) {
        super(outOfCoreTileManager, fragList, bvhNodeId);

        const model = this.outOfCoreTileManager.model;
        const consolidation = model.getConsolidation(this.bvhNodeId);
        if (!consolidation) {
            return;
        }
        this.geometries = [consolidation.meshes[meshIndex].geometry];
    }

    /**
     * Returns the geometries to be uploaded
     * @returns {THREE.BufferGeometry[]} The geometries to be uploaded
     */
    getGeometries() {
        return this.geometries;
    }

    /**
     * Add a geometry to the list of geometries to be uploaded
     * @param {number} meshIndex - The index of the mesh
     * @returns
     */
    addGeometry(meshIndex) {
        const consolidation = this.outOfCoreTileManager.model.getConsolidation(this.bvhNodeId);
        if (!consolidation) {
            return;
        }

        const geometry = consolidation.meshes[meshIndex].geometry;
        if (this.geometries.indexOf(geometry) === -1) {
            this.geometries.push(geometry);
        }
    }
}
