import { logger } from "../../../logger/Logger";
import { getVertexCount } from "../VertexEnumerator";
import { createBufferGeometry } from "../BufferGeometry";
import { runMergeSingleThreaded, createRangeArray } from "./ParallelGeomMerge";
import { writeIdToBuffer } from "./GeomMergeTask";
import { USE_OUT_OF_CORE_TILE_MANAGER, USE_MULTI_MATERIAL_RENDER_CALLS } from "../../globals";
import { useMaterialUBOs } from "../../render/UniformBufferObjects";
import { STREAMING_DRAW_ONCE_DURING_UPLOAD } from "../../render/constants";

// Maximum vertex count that we allow for a consolidated mesh. For simplicity, we keep it within 16 bit scope, so that
// we can always use Uint16 indices. Allowing too large containers may backfire in several ways, e.g.,
// it would reduce granularity for progressive rendering and frustum culling too much.
export const MaxVertexCountPerMesh = 0xFFFF;

// We use a global shared array buffer which is used when uploading consolidated geometries to the GPU.
// Once the data has been uploaded, the buffer can be reused for the next consolidation. This way, we
// avoid allocating a new buffer for each consolidation, which would lead to a lot of garbage collection.
const globalArrayBufferSize = 20 * 1024 * 1024;
let globalArrayBuffer = undefined;
let globalArrayBufferOffset = 0;

/**
 * Allocates a typed array in the shared array buffer. If the buffer is full, a new typed array is allocated.
 *
 * @param {Float32ArrayConstructor|Float64ArrayConstructor|Uint16ArrayConstructor|Int16ArrayConstructor|Int32ArrayConstructor} constructor - The constructor of the typed array to allocate.
 * @param {number} size - The size of the typed array to allocate.
 * @returns {Float32Array|Float64Array|Uint16Array|Int16Array|Int32Array} The allocated typed array.
 */
function allocateInGlobalArrayBuffer(constructor, size) {
    if (!USE_OUT_OF_CORE_TILE_MANAGER ||
        (globalArrayBufferOffset + size * constructor.BYTES_PER_ELEMENT > globalArrayBufferSize)) {
        return new constructor(size);
    } else {
        let offset = size + ((size % 4) !== 0 ? 4 - (size % 4) : 0); // Align to 4 bytes

        if (globalArrayBuffer === undefined) {
            globalArrayBuffer = new ArrayBuffer(globalArrayBufferSize);
        }

        const result = new constructor(globalArrayBuffer, globalArrayBufferOffset, size);
        globalArrayBufferOffset += offset * constructor.BYTES_PER_ELEMENT;
        return result;
    }
}

export function resetGlobalArrayBuffer() {
    globalArrayBufferOffset = 0;
}

/**
 * Creates consolidated geometry by either calculating the world transform on the CPU or
 * by using transform feedback to calculate the world transform on the GPU. If transform feedback
 * is used, the renderer must be provided.
 *
 * @param {Consolidation} consolidation - The consolidation object.
 * @param {number} meshIndex - The index of the mesh to create the consolidated geometry for.
 * @param {FragmentList} fragList - The fragment list for the model.
 * @param {boolean} [useTransformFeedback=false] - Whether to use transform feedback to calculate the world transform on the GPU.
 * @param {WebGLRenderer} [renderer=null] - The renderer to use for transform feedback.
 */
export function createConsolidatedGeometry(consolidation, meshIndex, model, useTransformFeedback = false, renderer = null) {
    const mesh = consolidation.meshes[meshIndex];
    const consolidationMap = consolidation.consolidationMap;
    const fragIds = consolidationMap.fragOrder;
    const rangeBegin = mesh.rangeBegin;
    const rangeLength = mesh.rangeCount;
    const fragList = model.getFragmentList();

    const mat = fragList.getMaterial(fragIds[rangeBegin]);

    const useMultiMaterialCalls = !!useMaterialUBOs(mat);

    // CPU Consolidation
    if (!useTransformFeedback) {
        const deadline = undefined; // don't allow interruption
        const mesh = consolidation.createMeshGeometry(meshIndex, fragList, deadline);

        if (USE_MULTI_MATERIAL_RENDER_CALLS) {
            renderer.getUBOManager().prepareUBOs(model, consolidationMap.fragOrder, mesh, useMultiMaterialCalls);
        }

        return mesh.geometry;
    }

    // GPU Consolidation
    const geometry = mesh.geometry;

    if (geometry.attributes) {
        return geometry;
    }
    mesh.proxyGeometry = mesh.geometry;

    consolidationMap.tmpGeoms.length = rangeLength;
    const matrices = new Float32Array(rangeLength * 16);
    const dbIds = new Uint32Array(rangeLength);

    let fragId;
    for (let i = 0; i < rangeLength; i++) {
        fragId = fragIds[rangeBegin + i];
        consolidationMap.tmpGeoms[i] = fragList.getGeometry(fragId);
        fragList.getOriginalWorldMatrix(fragId, consolidationMap.tmpMatrix);
        matrices.set(consolidationMap.tmpMatrix.elements, i * 16);
        dbIds[i] = USE_MULTI_MATERIAL_RENDER_CALLS ? i : fragList.getDbIds(fragId);
    }

    const mergedGeom = createMergeGeom(consolidationMap.tmpGeoms);
    const box = consolidationMap.boxes[mesh.oldRangeIndex];

    if (mergedGeom.boundingBox) {
        mergedGeom.boundingBox.copy(box);
    } else {
        mergedGeom.boundingBox = box.clone();
    }
    copyVertexAndIndexBuffers(consolidationMap.tmpGeoms, mergedGeom);

    const IDBytesPerVertex = 3;
    const ranges = createRangeArray(consolidationMap.tmpGeoms);
    const dstIds = new Uint8Array(IDBytesPerVertex * mergedGeom.vb.length / mergedGeom.vbstride);
    for (let i = 0; i < rangeLength; i++) {
        const rangeBegin = ranges[i + 0];
        const rangeEnd = ranges[i + 1];
        const rangeLength = rangeEnd - rangeBegin;
        const dbId = dbIds[i];

        let dstIdsByteOffset = rangeBegin * IDBytesPerVertex;
        for (let j = 0; j < rangeLength; j++) {
            writeIdToBuffer(dbId, dstIds, dstIdsByteOffset);
            dstIdsByteOffset += IDBytesPerVertex;
        }
    }
    mergedGeom.attributes.id.array = dstIds;
    mergedGeom.streamingDraw = STREAMING_DRAW_ONCE_DURING_UPLOAD;
    mergedGeom.streamingIndex = false;

    const consolidatedBuffer = renderer.createConsolidatedBuffer(mergedGeom, matrices, consolidationMap.tmpGeoms);
    mergedGeom.vbbuffer = consolidatedBuffer;
    mergedGeom.needsUpdate = false;
    mergedGeom.ibNeedsUpdate = true;
    mergedGeom.vbNeedsUpdate = false;
    mergedGeom.vbLength = mergedGeom.vb.length;
    mergedGeom.vb = null;
    mergedGeom.discardAfterUpload = true;
    mergedGeom.__webglContextId = renderer.lostContextRecovery?.webglContextId;

    mesh.geometry = mergedGeom;
    mesh.geometry.streamingDraw = false;
    mesh.geometry.streamingIndex = false;

    if (USE_MULTI_MATERIAL_RENDER_CALLS) {
        renderer.getUBOManager().prepareUBOs(model, fragIds, mesh, useMultiMaterialCalls);
    }

    return mergedGeom;
}

/**
 *  Set primitive type and related params (lineWidth/pointSize) of dstGeom to the same values as srcGeom.
 *   @param {BufferGeometry} srcGeom
 *   @param {BufferGeometry} dstGeom
 */
export function copyPrimitiveProps(srcGeom, dstGeom) {

    var primType = getPrimitiveType(srcGeom);
    setPrimitiveType(dstGeom, primType);

    // pointSize/lineWidth
    dstGeom.lineWidth = srcGeom.lineWidth;
    dstGeom.pointSize = srcGeom.pointSize;
}

/**
 *  Set vertex attributes and vbstride of dstGeom to the same vertex format as srcGeom.
 *  Note that this can only be used for interleaved vertex buffers.
 *   @param {BufferGeometry} srcGeom
 *   @param {BufferGeometry} dstGeom
 */
export function copyVertexFormat(srcGeom, dstGeom) {

    if (!srcGeom.vb) {
        logger.warn("copyVertexFormat() supports only interleaved buffers");
    }

    dstGeom.vbstride = srcGeom.vbstride;

    let unusedAttributes = new Set(Object.keys(dstGeom.attributes));

    for (var attrib in srcGeom.attributes) {
        // VertexAttribute objects of WGS BufferGeometry do not contain actual vertex data.
        // Therefore, identical BufferAttribute objects are shared among different
        // BufferGeometries. (see findBufferAttribute in BufferGeometry.js)
        dstGeom.attributes[attrib] = srcGeom.attributes[attrib];
        unusedAttributes.delete(attrib);
    }

    unusedAttributes.delete('id'); // keep id attribute

    // remove unused attributes
    for (let attrib of unusedAttributes) {
        delete dstGeom.attributes[attrib];
    }

    // copy attribute keys
    dstGeom.attributesKeys = srcGeom.attributesKeys.slice(0); // shallow copy
}

var PRIMITIVE_TYPE = {
    UNKNOWN: 0,
    TRIANGLES: 1,
    LINES: 2,
    WIDE_LINES: 3,
    POINTS: 4
};

export function getPrimitiveType(geom) {
    if (geom.isLines) return PRIMITIVE_TYPE.LINES;
    if (geom.isPoints) return PRIMITIVE_TYPE.POINTS;
    if (geom.isWideLines) return PRIMITIVE_TYPE.WIDE_LINES;
    return PRIMITIVE_TYPE.TRIANGLES;
}

export function setPrimitiveType(geom, type) {

    // clear any previous flags
    if (geom.isLines === true) geom.isLines = undefined;
    if (geom.isWideLines === true) geom.isWideLines = undefined;
    if (geom.isPoints === true) geom.isPoints = undefined;

    switch (type) {
        case PRIMITIVE_TYPE.LINES: geom.isLines = true; break;
        case PRIMITIVE_TYPE.WIDE_LINES: geom.isWideLines = true; break;
        case PRIMITIVE_TYPE.POINTS: geom.isPoints = true; break;
    }
}

/**
 *  Returns true if geom1 and geom2 have compatible vertex format to allow merging.
 *  For this, vbstride and all vertex attributes must be equal.
 *
 * Requirement: This function is only called for geoms that...
 *  1. use interleaved vertex buffers
 *  2. do not use instancing
 *
 * @param {THREE.BufferGeometry} geom1
 * @param {THREE.BufferGeometry} geom2
 * @returns {boolean}
 */
export function canBeMerged(geom1, geom2) {

    if (geom1.vbstride != geom2.vbstride) {
        return false;
    }

    var primType1 = getPrimitiveType(geom1);
    var primType2 = getPrimitiveType(geom2);
    if (primType1 !== primType2) {
        return false;
    }

    // compare pointSize/lineWidth for points/wideLines
    if (geom1.isPoints && geom1.pointSize !== geom2.pointSize) return false;
    if (geom1.isWideLines && geom1.lineWidth !== geom2.lineWidth) return false;

    if (geom1.attributesKeys.length != geom2.attributesKeys.length) {
        return false;
    }

    // compare each attribute
    for (var i = 0, iEnd = geom1.attributesKeys.length; i < iEnd; i++) {
        var key = geom1.attributesKeys[i];

        // get BufferAttributes of both geoms
        var attrib1 = geom1.attributes[key];
        var attrib2 = geom2.attributes[key];

        // if geom2 does not have this, we are done
        if (!attrib2) {
            return false;
        }

        // Since attributes are cached in WGS BufferGeometry, we will mostly detect equality here already.
        if (attrib1 === attrib2) {
            continue;
        }

        // Compare values. Note that it's not enough to compare the THREE.BufferAttribute properties itemSize and normalized, but
        // also some WGS-specific values (see BufferGeometry.js).
        const differentBytesPerItem = attrib1.bytesPerItem !== attrib2.bytesPerItem;
        if (
            attrib1.offset !== attrib2.offset ||
            attrib1.normalized !== attrib2.normalized ||
            attrib1.itemSize !== attrib2.itemSize ||
            differentBytesPerItem ||
            attrib1.isPattern !== attrib2.isPattern
        ) {
            return false;
        }
    }
    return true;
}

/**
 * Create a single BufferGeometry that contains all geometries.
 * Requirements:
 *  - All geoms must have identical vertex format.
 *  - Geometries must have interleaved vertex buffers
 *  - Geometries must not have instance buffers. But the same geometry may be added with different matrices.
 *
 *  @param {THREE.BufferGeometry[]} geoms
 *  @param {Float32Array}           matrices - array of matrices per geometry. Each matrix is a range of 16 floats.
 *  @param {Int32Array}             dbIds    - db per input geometry. Used to create per-vertex ids.
 *  @param {THREE.Box3}             worldBox - summed worldBox of all transformed geometries
 *  @param {ParallelGeomMerge}      [parallelMerge] - Coordinates worker threads for parallel merge.
 *                                                    Not needed for single-threaded use.
 *  @returns {LmvBufferGeometry}
 */
export function mergeGeometries(geoms, matrices, dbIds, worldBox, parallelMerge) {

    var mergedGeom = createMergeGeom(geoms);

    if (mergedGeom.boundingBox) {
        mergedGeom.boundingBox.copy(worldBox);
    } else {
        mergedGeom.boundingBox = worldBox.clone();
    }

    // copy src vertex/index buffers into mergedGeom
    copyVertexAndIndexBuffers(geoms, mergedGeom);

    // The last steps are either done directly or delegated to a worker thread
    if (parallelMerge) {
        parallelMerge.addMergeTask(geoms, mergedGeom, matrices, dbIds);
    } else {
        runMergeSingleThreaded(geoms, mergedGeom, matrices, dbIds);
    }

    return mergedGeom;
}

/**
 * Copies the vertex/index buffers of geoms into mergedGeom. Indices are modified by an offset
 * so that they point to the correct position in mergedGeom's vertex buffer.
 *  @param {BufferGeometry[]} geoms
 *  @param {BufferGeometry}   mergedGeom
 */
export function copyVertexAndIndexBuffers(geoms, mergedGeom) {

    // write-offset in mergedGeom.vb (in floats)
    var dstOffset = 0;

    // create combined vertex and index buffer - including transforms
    var vertexOffset = 0;
    var indexOffset = 0;
    var indexOffsetLines = 0;

    for (var i = 0; i < geoms.length; i++) {
        var geom = geoms[i];
        var vertexCount = getVertexCount(geom);

        const vb = geom.vb;
        const ib = geom.ib;
        let indexCount = ib.length;
        const mergedVb = mergedGeom.vb;
        const mergedIb = mergedGeom.ib;
        const iblines = geom.iblines;
        let indexlinesCount = iblines?.length ?? 0;
        const mergedIblines = mergedGeom.iblines;

        // copy indices (+ offset)

        // Make sure that indexCount is a multiple of 3 for triangle meshes or 2 for lines.
        // This should always be the case, but if there would be any case, where this condition
        // is not met, this would break the consolidated buffer, because
        // all triangles would be shifted by one or two vertice.
        let numIndicesPerPrimitive = geom.isLines ? 2 : 3;
        indexCount = indexCount - (indexCount % numIndicesPerPrimitive);
        for (let j = 0; j < indexCount; j++) {
            mergedIb[indexOffset + j] = ib[j] + vertexOffset;
        }

        // copy line indices

        // Make sure that indexCount is a multiple of 2. This should always
        // be the case, but we encountered meshes where this condition was violated.
        // In cases, where this condition is not met, this would break the consolidated
        //  buffer, because all later lines would be shifted by one vertex.
        indexlinesCount = indexlinesCount - (indexlinesCount % 2);
        for (let j = 0; j < indexlinesCount; j++) {
            mergedIblines[indexOffsetLines + j] = iblines[j] + vertexOffset;
        }
        indexOffsetLines += indexlinesCount;

        // copy vertex buffer
        mergedVb.set(vb, dstOffset);
        dstOffset += vb.length;

        // set offsets for next geom
        vertexOffset += vertexCount;
        indexOffset += indexCount;
    }
}

/**
 * Creates target BufferGeometry used to merge several src BufferGeometries into one. (see mergeGeometries)
 *
 * Returns a new BufferGeometry for which...
 *  - vb/ib are large enough to fit in all src geometry vertices/indices (allocated, but not filled yet)
 *  - the vertex-format of the interleaved vb is the same as for the input geometries
 *  - primitive type is the same as for (including pointSize/lineWidth)
 *  - it has an additional attribute for per-vertex ids
 *
 *  @param {BufferGeometry[]} geoms - source geometry buffers.
 *  @returns {BufferGeometry}
 */
export function createMergeGeom(geoms) {

    // floats per vertex
    let stride = geoms[0].vbstride; // same for src and dst, because we add per-vertex ids as separate attribute

    // compute summed vertex and index count (and summed box if needed)
    var indexCount = 0;
    var vertexCount = 0;
    let indexlines;
    let indexlinesCount = 0;
    for (let i = 0; i < geoms.length; i++) {
        const geom = geoms[i];
        vertexCount += getVertexCount(geom);
        // Make sure that indexCount is a multiple of 3 for triangle meshes or 2 for lines.
        // This should always be the case, but if there would be any case, where this condition
        // is not met, this would break the consolidated buffer, because
        // all triangles/lines would be shifted by one or two vertices.
        let numIndices = geom.ib.length;
        let numIndicesPerPrimitive = geom.isLines ? 2 : 3;
        numIndices = numIndices - (numIndices % numIndicesPerPrimitive);
        indexCount += numIndices;
        indexlines = geom.iblines;
        if (indexlines) {
            // Make sure that indexCount is a multiple of 2. This should always
            // be the case, but we encountered meshes where this condition was violated.
            // In cases, where this condition is not met, this would break the consolidated
            // buffer, because all later lines would be shifted by one vertex.
            let numIndicesLines = indexlines.length;
            numIndicesLines = numIndicesLines - (numIndicesLines % 2);
            indexlinesCount += numIndicesLines;
        }
    }

    var mergedGeom = createBufferGeometry();
    mergedGeom.byteSize = 0;

    // allocate new geometry with vertex and index buffer
    mergedGeom.vb = allocateInGlobalArrayBuffer(Float32Array, vertexCount * stride);
    mergedGeom.ib = allocateInGlobalArrayBuffer(Uint16Array, indexCount);

    if (indexlinesCount > 0) {
        indexlines = allocateInGlobalArrayBuffer(Uint16Array, indexlinesCount);
        mergedGeom.byteSize += indexlines.byteLength;
        mergedGeom.iblines = indexlines;
    }

    // make sure that byteSize is set just like for input geometry. This is required for later memory tracking.
    mergedGeom.byteSize += mergedGeom.vb.byteLength + mergedGeom.ib.byteLength;

    // copy primitive type + params (pointSize/lineWidth)
    copyPrimitiveProps(geoms[0], mergedGeom);

    // copy common properties from geom[0]
    copyVertexFormat(geoms[0], mergedGeom);

    // In the shader, an id is a vec3 with components in [0,1].
    // In memory, each component has 8 Bits of the dbId.
    var IDItemSize = 3; // IDs are vec3 in the shader

    // create/add additional per-vertex id attribute
    //
    // Note: The actual array buffer is not created yet, but assigned later.
    //       (see mergeGeometries)
    var idAttrib = mergedGeom.attributes.id || new THREE.BufferAttribute(new Float32Array(), IDItemSize);
    idAttrib.normalized = true; // shader needs normalized components
    idAttrib.bytesPerItem = 1;
    mergedGeom.setAttribute('id', idAttrib);

    // set primitive type
    var firstGeom = geoms[0];
    var primType = getPrimitiveType(firstGeom);
    setPrimitiveType(mergedGeom, primType);

    // copy size/width for points/wide-lines
    if (firstGeom.isPoints) mergedGeom.pointSize = firstGeom.pointSize;
    if (firstGeom.isWideLines) mergedGeom.lineWidth = firstGeom.lineWidth;

    return mergedGeom;
}

/**
  *  Helper class to collect shapes with identical materials and merge them into a single large shape.
  *
  *  @constructor
  *    @param {number} materialId - Material must be the same for all added geometries.
  *    @param {number} bvhNodeId - BVH node id of the bucket
  */
export function MergeBucket(materialId, bvhNodeId) {
    this.geoms = [];
    this.matrices = [];
    this.vertexCount = 0;
    this.materialId = materialId;
    this.fragIds = [];
    this.worldBox = new THREE.Box3();
    this.cost = 0;
    this.bvhNodeId = bvhNodeId;
}

MergeBucket.prototype = {
    constructor: MergeBucket,

    /**
     * @param {THREE.BufferGeometry} geom
     * @param {THREE.Box3}           worldBox
     * @param {Number}               fragId
     * @returns {Number}             costs - memory cost increase caused by the new geometry
     */
    addGeom: function(geom, worldBox, fragId) {

        this.fragIds.push(fragId);

        this.worldBox.union(worldBox);

        if (geom === null) {
            return 0;
        }

        this.geoms.push(geom);
        this.vertexCount += getVertexCount(geom);

        // Track memory costs. As long as the bucket has only a single shape,
        // we have no costs at all.
        var numGeoms = this.geoms.length;
        if (numGeoms == 1) {
            return 0;
        }

        // Fragment geometries are usually BufferGeometry, which provide a byteSize for the
        // interleaved buffer. Anything else is currently unexpected and needs code change.
        if (geom.byteSize === undefined) {
            logger.warn("Error in consolidation: Geometry must contain byteSize.");
        }

        // For any bucket with >=2 geoms, all geometries must be considered for the costs.
        let costDelta = geom.byteSize + (numGeoms == 2 ? this.geoms[0].byteSize : 0);
        this.cost += costDelta;

        return costDelta;
    }
};
