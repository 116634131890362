import {filterParamThreeToGPU, paramTexThreeToGL} from "./compat";
import {computeMipmapLevelCount} from "./chunks/mipmap_shader";
import {MipmapPipeline} from "./main/MipmapPipeline";
import THREE from 'three';

function clampToMaxSize ( image, maxSize ) {

    if (image.width <= maxSize && image.height <= maxSize) {

        return image;

    }

    if ((typeof HTMLImageElement !== 'undefined' && image instanceof HTMLImageElement) ||
        (typeof HTMLCanvasElement !== 'undefined' && image instanceof HTMLCanvasElement) ||
        (typeof ImageBitmap !== 'undefined' && image instanceof ImageBitmap)) {


        // Warning: Scaling through the canvas will only work with images that use
        // premultiplied alpha.

        var maxDimension = Math.max(image.width, image.height);
        var scale = maxSize / maxDimension;

        var newWidth = Math.max(Math.floor(image.width * scale), 1);
        var newHeight = Math.max(Math.floor(image.height * scale), 1);

        var canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, newWidth, newHeight);

        return canvas;
    } else {
        if ('data' in image) {
            console.warn('THREE.WebGLRenderer: Image in DataTexture is too big (' + image.width + 'x' + image.height + ').');
        }

        return image;
    }

}

/**
 * Creates a descriptor for use with createSampler() for a THREE.Texture.
 * @param {THREE.Texture} texture - The texture to create the sampler descriptor for.
 * @returns {GPUSamplerDescriptor} The sampler descriptor.
 */
export function createSamplerDesc(texture) {
    /** @type {GPUSamplerDescriptor} */
    const samplerDesc = {
        addressModeU: /** @type {GPUAddressMode} */ (filterParamThreeToGPU(texture.wrapS)),
        addressModeV: /** @type {GPUAddressMode} */ (filterParamThreeToGPU(texture.wrapT)),
        magFilter: /** @type {GPUFilterMode} */ (filterParamThreeToGPU(texture.magFilter)),
        minFilter: /** @type {GPUFilterMode} */ (filterParamThreeToGPU(texture.minFilter)),
        mipmapFilter: /** @type {GPUFilterMode} */ (filterParamThreeToGPU(texture.minFilter, true)),
        maxAnisotropy: texture.anisotropy || 1,
    };

    return samplerDesc;
}

/**
 * Creates a sampler for a THREE.Texture.
 * @param {THREE.Texture} texture
 */
export function createSampler(device, texture) {
    return device.createSampler(createSamplerDesc(texture));
}


export function initCubeMap(device, texture) {

    if (texture?.image.length !== 6) {
        return;
    }

    if (texture.needsUpdate !== true) {
        return;
    }

    let isCompressed = texture instanceof THREE.CompressedTexture;
    let isDataTexture = texture.image[0] instanceof THREE.DataTexture;

    let cubeImage = [];
    for (let i = 0; i < 6; i++) {
        if (!isCompressed && !isDataTexture) {
            cubeImage[i] = clampToMaxSize(texture.image[i], device.limits.maxTextureDimension2D || 8192);
        } else {
            cubeImage[i] = isDataTexture ? texture.image[i].image : texture.image[i];
        }
    }

    if (!texture.__gpuTextureCube) {

        texture.addEventListener( 'dispose', () => {
            texture.__gpuTextureCube?.destroy();
            texture.__gpuTextureCube = null;
            texture.__gpuSampler = null;
            // The texture might be used in different places that don't know about each other.
            // We set needsUpdate here so that code that still uses a texture that got disposed elsewhere can
            // re-initialize it.
            texture.needsUpdate = true;
        });

        texture.__gpuTextureCube = device.createTexture({
            label: `cube map texture ${texture.uuid}`,
            dimension: "2d",
            format: paramTexThreeToGL(texture.format, texture.type),
            mipLevelCount: cubeImage[0].mipmaps?.length || 1,
            size: [cubeImage[0].width, cubeImage[0].height, 6],
            usage: GPUTextureUsage.TEXTURE_BINDING | GPUTextureUsage.COPY_DST
        });

        texture.__gpuSampler = createSampler(device, texture);

    }

    for (let i = 0; i < 6; i++) {

        if (!isCompressed) {

            if (isDataTexture) {

                device.queue.writeTexture(
                    { texture: texture.__gpuTextureCube, origin: [0, 0, i] },
                    cubeImage[i].data,
                    { offset: 0, bytesPerRow: cubeImage[i].data.byteLength / cubeImage[i].height },
                    [cubeImage[i].width, cubeImage[i].height]
                );

            } else {

                device.queue.copyExternalImageToTexture(
                    { source: cubeImage[i], flipY: !!texture.flipY },
                    { texture: texture.__gpuTextureCube, origin: [0, 0, i] },
                    [cubeImage[i].width, cubeImage[i].height]
                );

            }

            //TODO:
        /*if ( texture.generateMipmaps ) {

            _gl.generateMipmap( _gl.TEXTURE_CUBE_MAP );

        }
         */


        } else {

            let mipmaps = cubeImage[i].mipmaps;

            for (let j = 0, jl = mipmaps.length; j < jl; j++) {

                let mipmap = mipmaps[j];

                device.queue.writeTexture(
                    { texture: texture.__gpuTextureCube, origin: [0, 0, i], mipLevel: j },
                    mipmap.data,
                    { offset: 0, bytesPerRow: mipmap.data.byteLength / mipmap.height },
                    [mipmap.width, mipmap.height]
                );
            }

        }

    }

    texture.needsUpdate = false;

    if (texture.onUpdate) texture.onUpdate();

}

/**
 * @param {GPUDevice} device
 * @param {MipmapPipeline} mipmapPipeline
 * @param {THREE.Texture} texture
 */
export function initTexture(device, mipmapPipeline, texture) {

    if (texture.needsUpdate !== true) {
        return;
    }

    let isCompressed = texture instanceof THREE.CompressedTexture;
    let isDataTexture = texture instanceof THREE.DataTexture;

    if (!isCompressed && !isDataTexture) {
        texture.image = clampToMaxSize(texture.image, device.limits.maxTextureDimension2D || 8192);
    }

    const mipMapLevelCount = computeMipmapLevelCount(texture.image);
    let descriptor = {
        label: texture.name,
        dimension: "2d",
        format: paramTexThreeToGL(texture.format, texture.type),
        mipLevelCount: texture.mipmaps?.length || mipMapLevelCount,
        size: [texture.image.width, texture.image.height],
        //NOTE: RenderAttachment usage seems to be needed in order to call copyExternalImageToTexture
        usage: GPUTextureUsage.TEXTURE_BINDING | GPUTextureUsage.COPY_DST | GPUTextureUsage.RENDER_ATTACHMENT
    };

    if (!texture.__gpuTexture) {

        texture.addEventListener('dispose', () => {
            texture.__gpuTexture?.destroy();
            texture.__gpuTexture = null;
            texture.__gpuSampler = null;
        });

        texture.__gpuTexture = device.createTexture(descriptor);

        texture.__gpuSampler = createSampler(device, texture);

    }

    let image = texture.image;
    let mipmaps = texture.mipmaps;

    if (isDataTexture || isCompressed) {

        // use manually created mipmaps if available
        // if there are no manual mipmaps
        // set 0 level mipmap and then use GL to generate other mipmap levels

        if (mipmaps.length > 0) {

            for (let i = 0, il = mipmaps.length; i < il; i++) {

                let mipmap = mipmaps[i];

                device.queue.writeTexture(
                    { texture: texture.__gpuTexture, origin: [0, 0], mipLevel: i },
                    mipmap.data,
                    { offset: 0, bytesPerRow: mipmap.data.byteLength / mipmap.height },
                    [mipmap.width, mipmap.height]
                );

            }

            texture.generateMipmaps = false;

        } else {

            device.queue.writeTexture(
                { texture: texture.__gpuTexture, origin: [0, 0] },
                image.data,
                { offset: 0, bytesPerRow: image.data.byteLength / image.height },
                [image.width, image.height]
            );

        }

    } else { // regular Texture (image, video, canvas)

        // use manually created mipmaps if available
        // if there are no manual mipmaps
        // set 0 level mipmap and then use GL to generate other mipmap levels

        if (mipmaps.length > 0) {

            for (let i = 0, il = mipmaps.length; i < il; i++) {

                let mipmap = mipmaps[i];

                device.queue.copyExternalImageToTexture(
                    { source: mipmap, flipY: !!texture.flipY },
                    { texture: texture.__gpuTexture, mipLevel: i, premultipliedAlpha: !!texture.premultiplyAlpha },
                    [mipmap.width, mipmap.height]
                );

            }

            texture.generateMipmaps = false;

        } else {

            device.queue.copyExternalImageToTexture(
                { source: image, flipY: !!texture.flipY },
                { texture: texture.__gpuTexture },
                [image.width, image.height]
            );

        }

    }

    // TODO: optimization?
    if (texture.generateMipmaps) {
        mipmapPipeline.generateMipmaps(texture, descriptor);
        texture.generateMipmaps = false;
    }

    texture.needsUpdate = false;

    if (texture.onUpdate) texture.onUpdate();

}

// (Only) used for textures with unclear ownership, e.g., env map used by multiple renderers.
export function refTexture(texture) {
    if (!(texture instanceof THREE.Texture))
        return;

    texture.__refCount = (texture.__refCount || 0) + 1;
}

export function unrefTexture(texture) {
    if (!(texture instanceof THREE.Texture) || !Number.isFinite(texture.__refCount))
        return;

    if (texture.__refCount < 1) {
        console.warn('Texture was unreferenced more times than referenced.');
    }
    texture.__refCount--;

    if (texture.__refCount < 1) {
        texture.dispose();
    }
}
