/** @import { OutOfCoreTileManager } from "../OutOfCoreTileManager" */

/**
 * Base class for out of core tasks
 */
export class OutOfCoreTaskBase {

    /** @type {OutOfCoreTileManager} */
    outOfCoreTileManager;

    /** @type {Number|undefined} */
    #resourceAmountConsumedByLastExecution;

    /** @type {Number|undefined} */
    #freeableResourceAmount;

    /**
     * @param {OutOfCoreTileManager} outOfCoreTileManager The OutOfCoreTileManager instance creating this task
    */
    constructor(outOfCoreTileManager) {
        this.outOfCoreTileManager = outOfCoreTileManager;
        this.canceled = false;
    }

    /**
     * Executes the task
     *
     * We support synchronous or asynchronous tasks.
     *
     * For a synchronous tasks, execute runs the tasks and then returns the resource amount consumed by the task.
     *
     * Asynchronous tasks can be implemented via a promise or an (asynchronous) generator. The difference between a
     * generator and a promise is, that the generator version will respect the time budgets for each frames.
     * We cannot guarantee this for promises, since those are executed within the normal JavaScript event loop.
     *
     * An asynchronous generator should be used, if the tasks needs to use promises to wait for IO, but still
     * has to execute some code within the time budget. In such a case, it is important, that the code after the
     * yield will run within the time budget, but once an await is encountered the code after the await will run
     * in the normal event loop. If this code is slow, there should be a yield following the await to make sure
     * the subsequent code will run within the time budget.
     *
     * A task can set the accurate amount of the associated resource that has been computed during the execution by invoking
     * setResourceAmountConsumedByLastExecution. If this function is not called, getResourceCost will be used. To make sure
     * we do not allocate too much resources for asynchronous task, an async task must compute the required
     * resources within the first synchronous code block that gets executed (the code before the first await or yield)
     * and call setResourceAmountConsumedByLastExecution within this block.
     *
     * @returns {undefined|Promise|Generator|AsyncGenerator} If the tasks runs async, it can return a promise or generator.
     */
    execute() {
        return 0;
    }

    /**
     * Frees the resources consumed by the task
     *
     * Note: For asynchronous tasks, it is possible that the execution of the execute methods gets canceled. In such a case,
     * cancel will invoke the undo method to free the resources. So undo should be either implemented in such a way that it can safely
     * be called after a partial initalization, or cancel should be overriden to handle the cancelation.
     */
    undo() {

    }

    /**
     * Returns the resource cost of the task
     * @returns The resource cost of the task
     */
    getResourceCost() {
        return 0;
    }

    /**
     * This function can be called to supply the accurate amount of the associated resource consumed by the last execution of the task.
     *
     * The amount of the associated resource reported by the execute function may be smaller that the resource cost returned by getResourceCost,
     * but must not be larger. An example for this case is that some resource is being shared among multiple tasks and thus
     * the actual resource cost depends on the order of execution of the nodes and whether another task has been executed before
     * or not.
     *
     * This function must be called within the first synchronous code block of an asynchronous task, because the resources will be
     * reserved at that point (to avoid a situation where the resources are no longer available later during the execution of
     * the async task).
     *
     * If the function is not called, the resource cost from getResourceCost will be used.
     *
     * @param {Number} consumedResourceAmount - The amount of the resource consumed by the last execution of the task
     */
    setResourceAmountConsumedByLastExecution(consumedResourceAmount) {
        this.#resourceAmountConsumedByLastExecution = consumedResourceAmount;
    }

    /**
     * This function can be called to supply the accurate amount of the associated resource freed by the last execution of the undo
     * function. In some cases, it might happen that the undo function does not free all resources allocated by the execute function
     * (if there are shared resources and those are still used by some other task). In such a case, the undo function should
     * report the amount of the resource that has actually been freed by the undo function.
     *
     * Note: It is still expected that undoing all tasks will finally free all resources, just the fact that allocation and
     * freeing might happen in different order, can lead to cases where the amount freed by the individual undo functions
     * is smaller than the amount allocated by the corresponding execute functions.
     *
     * If the function is not called, the resource cost reported by the execute function is used.
     *
     * @param {Number} freedResourceAmount - The amount of the associated resource freed by the last undo of the task
     */
    setResourceAmountFreedByLastExecution(freedResourceAmount) {
        this.#freeableResourceAmount = freedResourceAmount;
    }

    /**
     * Once the first synchronous section of a task has been run, this function must be called to determine
     * the amount of the associated resource used by the execution. It will return the amount of the resource that was reported by
     * calling setResourceAmountConsumedByLastExecution, or the resource cost returned by getResourceCost if the function
     * has not been called.
     *
     * It can only be called once, because it resets the resources consumed by the last execution.
     *
     * @returns {number} The amount of the associated resource consumed by the last execution of the task
     */
    _getResourceAmountConsumedByLastExecution() {
        let resourceAmount = this.#resourceAmountConsumedByLastExecution ?? this.getResourceCost();
        this.#freeableResourceAmount = resourceAmount;
        this.#resourceAmountConsumedByLastExecution = undefined;
        return resourceAmount;
    }

    /**
     * The amount of the associated resource that has been freed by the execution of the last call to undo
     *
     * @returns {number} The amount of the associated resource that has been freed by the last call to undo
     */
    _getFreedResourceAmount() {
        let resourceAmount = this.#freeableResourceAmount;
        this.#freeableResourceAmount = undefined;
        return resourceAmount;
    }

    /**
     * Cancels the execution of the task
     *
     * During the usual operation tasks will always complete processing, before the node can be demoted again. However, in some cases
     * execution of all asynchronous tasks is instantly canceled (e.g. when the model is unloaded, the context has been lost, etc.) In such cases,
     * the task should free all resources and return immediately.
     *
     * The default implementation will call undo, assuming that undo is implemented in an idempotent way. If not the task should override this method.
     */
    cancel() {
        this.canceled = true;
        this.undo();
    }

    /**
     * Some tasks might have constraints that only a certain number of tasks can be executed in the same tick. One example for this are GPU uploads,
     * which only have a limited amount of staging buffer size available each frame. In such a case, a task can overwrite this function and return false
     * to delay execution of the task to the next tick.
     * @returns {boolean} True if the task can be executed this tick, false otherwise
     */
    canBeExecutedThisTick() {
        return true;
    }
}