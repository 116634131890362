import { isMobileDevice, isWebGPUSupportedAsync } from "../compat";
import { InitWebGPU, GetDefaultWebGPUDevice } from "./wgpu/globals";

export let GPU_MEMORY_LIMIT = (isMobileDevice() ? 64 : 256) * 1024 * 1024;
export let GPU_OBJECT_LIMIT = isMobileDevice() ? 2500 : 10000;
export let CONSOLIDATION_MEMORY_LIMIT = GPU_MEMORY_LIMIT / 2;

export function setCONSOLIDATION_MEMORY_LIMIT(value) {
    CONSOLIDATION_MEMORY_LIMIT = value;
}

// Default value for the amount of memory that we allow to consume before removing models from memory.
export let MODEL_MEMORY_LIMIT = 1000 * 1024 * 1024;

export const disableGpuObjectLimit = function() { GPU_OBJECT_LIMIT = 0xffffffff; };

//VAO objects do have quite a bit of memory overhead, so use of VAO can be optionally
//turned off
export let USE_VAO = !isMobileDevice();

// Overhead for geometry buffer. 112 bytes by the BufferGeometry object, 112 bytes for
// each of the index and vertex buffer arrays. The buffer used by the index and vertex
// buffer arrays is shared by multiple geometry objects, so we don't include the 64
// byte overhead for that.
//TODO: TS Check with Cleve how the 112 for the index and vertex arrays is calculated. The 112 for BufferGeometry
//comes from the memory profiler which shows 104 for those.
export const GEOMETRY_OVERHEAD = 336;

// This is the threshold of the projected screen pixel for culling.
export const PIXEL_CULLING_THRESHOLD = 0.75;

// Maximum amount of time in ms we allow for consolidation per frame
export const PER_FRAME_CONSOLIDATION_TIME_BUDGET = 10;

export let USE_OUT_OF_CORE_TILE_MANAGER = false;

// For WebGPU: Use new code path for ObjectUniform buffers that allows dynamic alloc/free of GPU buffers.
export let USE_DYNAMIC_UNIFORM_ALLOCATION = true;

// Defines whether to use deferred consolidation by default
export let USE_DEFERRED_CONSOLIDATION = false;

// Defines whether to use the BufferManager
export let USE_BUFFER_MANAGER = false;

// Use HLOD based rendering and settings?
export let USE_HLOD = false;

// Cache BVH with fragment list and consolidation map in OPFS. Only works when USE_HLOD. Set by useHLOD.
export let BVH_CACHING = false;

// Use WebGPU instead of WebGL
export let USE_WEBGPU = false;

// This flag indicates that the consolidation of the geometry will be done via
// transform feedback instead of using the CPU.
export const USE_TRANSFORM_FEEDBACK_FOR_CONSOLIDATION = true;

// Maximum amount of time in ms we allow for tasks per frame
export const PER_FRAME_TASK_TIME_BUDGET_MAX = 15;

// The fraction of the time (between target and max frame time) that we allow for tasks
export const PER_FRAME_TASK_TIME_BUDGET_FACTOR = 0.4;

// Number of polygons in a mesh to allow consolidation for that mesh
export const DEFAULT_CONSOLIDATION_POLYCOUNT_LIMIT = 10000;

// While loading we limit the render budget to save CPU time
export const RENDER_BUDGET_SCALING_FACTOR_WHILE_LOADING = 0.125;

// Limits HW instancing to fragments in the same tile
export let PER_TILE_INSTANCING = false;

// Use Uniform Buffer Objects in WebGL to render fragments with compatible materials in the same draw call
// Only used with consolidated meshes
export let USE_MULTI_MATERIAL_RENDER_CALLS = false;

export let INCREMENTAL_CONSOLIDATION = false;

// Sizes of the regions that get allocated by the buffer manager
// 65536 is the maximum number of vertices that can be addressed with a 16 bit index buffer
// The additional factors account for used types and stored data, but are not necessarily optimal
export const VERTEX_BUFFER_REGION_SIZE = 65536 * 4 * 4;
export const INDEX_BUFFER_REGION_SIZE = 8 * 65536 * 2;

/**
 * Feature Flag to enable/disable BufferManager usage
 * @param {Boolean} enable
 *
 * @experimental This function is experimental and may be changed or removed in future versions.
 */
export function useBufferManager(enable = true) {
    USE_BUFFER_MANAGER = enable;
    USE_VAO = !USE_BUFFER_MANAGER;
}

const DEFAULT_SETTINGS = {
    gpu_memory: 2048 * 1024 * 1024,
    gpu_objects: 512_000,
    model_memory: 4 * 1024 * 1024 * 1024,
    consolidation_memory: 1024 * 1024 * 1024,
};

/**
 * Feature Flag to enable/disable HLOD usage
 * @param {Boolean} enable
 * @param {Object} settings
 * @param {Number} settings.gpu_memory - The amount of GPU memory to use
 * @param {Number} settings.gpu_objects - The amount of GPU objects to use
 * @param {Number} settings.model_memory - The amount of model memory to use
 * @param {Number} settings.consolidation_memory - The amount of consolidation memory to use
 *
 * @experimental This function is experimental and may be changed or removed in future versions.
 */
export function useHLOD(enable = true, settings = DEFAULT_SETTINGS) {
    if (enable && settings) {
        if (!isMobileDevice()) {

            USE_HLOD = true;
            useBufferManager(true);
            USE_DEFERRED_CONSOLIDATION = true;

            GPU_MEMORY_LIMIT = settings.gpu_memory;
            GPU_OBJECT_LIMIT = settings.gpu_objects;
            MODEL_MEMORY_LIMIT = settings.model_memory;
            CONSOLIDATION_MEMORY_LIMIT = settings.consolidation_memory;

            console.log(`Using HLOD settings. Memory: ${GPU_MEMORY_LIMIT}, Object Limit: ${GPU_OBJECT_LIMIT}, Consolidation Memory: ${CONSOLIDATION_MEMORY_LIMIT}`);
        } else {
            console.warn('HLOD is not supported on mobile devices');
        }
    }
}

export function enableOutOfCoreTileManager() {
    if (USE_HLOD) {
        USE_OUT_OF_CORE_TILE_MANAGER = true;
        BVH_CACHING = true;

        console.log(`Using dynamic GPU Memory Management`);
    }
}

let wantWebGPU = false;
export function enableUBOs() {
    if (USE_HLOD && !wantWebGPU) {
        USE_MULTI_MATERIAL_RENDER_CALLS = true;
        PER_TILE_INSTANCING = true;
        INCREMENTAL_CONSOLIDATION = true;
        console.log(`Using UBOs for multi-material rendering`);
    }
}

/**
 * Feature Flag to enable/disable WebGPU usage. If activated, Viewer instances will use WebGPU instead of WebGL.
 * @param {GPURequestAdapterOptions} gpuRequestAdapterOptions - Passed to navigator.gpu.requestAdapter
 *
 * @experimental This function is experimental and may be changed or removed in future versions.
 */
export async function useWebGPU(gpuRequestAdapterOptions) {
    const isWebGPUSupported = await isWebGPUSupportedAsync();
    if (!isWebGPUSupported) {
        console.warn("WebGPU initilization requested but client doesn't support LMV with WebGPU. CI/CD/testing might use WebGPU on unsupported platforms in which case it is fine if you see this message.");
    }

    wantWebGPU = true;
    const device = await InitWebGPU(gpuRequestAdapterOptions);
    if (device) {
        USE_WEBGPU = true;

        // Temporary workaround until HLOD and WebGPU are fully compatible - to make sure that there is just "One mode of WebGPU Demo".
        //
        // Note: If WebGPU is on, USE_HLOD does not really activate HLOD, but rather just ensures here that the same BVH settings are used.
        //       Many important HLOD code parts are currently "silently bypassed" because we block consolidation if WebGPU is on.
        if (!USE_HLOD) {
            useHLOD(true);
        }

    } else {
        alert("WebGPU beta option was enabled, but WebGPU is not supported in this browser. To get back to normal mode, please disable the WebGPU beta option in the viewer settings and reload the page.");
    }
}

// Note: This should only be used if the viewer is not available yet (e.g. in the setup phase). Whereever possible, prefer
//       the runtime check variant viewer.impl.isWebGPU() instead.
export function IsUsingWebGPU() {
    return GetDefaultWebGPUDevice() !== null;
}

// Assuming 24 bits depth resolution this should be a working epsilon to distinguish between individual depth values
export const DEPTH_EPSILON = 2 * Math.pow(2,-24);
