
import { DepthCompare, DepthFormat } from "../CommonRenderTargets";
import { getBufferLayout, getPipelineHash } from "../Pipelines";

import { getGroundShadowShader } from "./GroundShadowShaders";

export class GroundShadowDepthPipeline {

	#renderer;
	#device;

	#bindGroupLayouts = [];
	#targets = [];

	#pipelines = new Map();
	#activePipeline;
	#vb;

	// This is used as a placeholder in the pipeline helper functions.
	// They calculate a material key for the pipeline hash, but we don't need this,
	// because the entire scene is rendered with a single material.
	#dummyMaterial = {};

	constructor(renderer, frameLayout, objectUniformLayout, targetFormat) {
		this.#renderer = renderer;
		this.#device = renderer.getDevice();

		this.#bindGroupLayouts = [
			frameLayout,
			objectUniformLayout,
		];

		this.#targets.push({
			format: targetFormat
		});
	}

	getPipeline(geometry) {
		const key = getPipelineHash(geometry, this.#dummyMaterial, false, false, false, false);

		let pipeline = this.#pipelines.get(key);

		if (pipeline) {
			return pipeline;
		}

		const shader = this.#device.createShaderModule({
			label: 'ground shadow shader',
			code: getGroundShadowShader(),
		});

		// "depthBias, depthBiasSlopeScale, and depthBiasClamp have no effect on 'point-list', 'line-list', and
		// 'line-strip' primitives, and must be 0." ...from https://www.w3.org/TR/webgpu/#depth-stencil-state
		const depthBias = geometry.isLines ? 0 : -1;
		const depthBiasSlopeScale = geometry.isLines ? 0 : -1;

		const pipelineConfig = {
			label: 'ground shadow pipeline',
			layout: this.#device.createPipelineLayout({
				bindGroupLayouts: this.#bindGroupLayouts
			}),
			vertex: {
				module: shader,
				entryPoint: 'vsmain',
				buffers: getBufferLayout(geometry, false, false, false, false),
			},
			fragment: {
				module: shader,
				entryPoint: 'psmain',
				targets: this.#targets,
			},
			primitive: {
				cullMode: 'none',
			},
			depthStencil: {
				depthWriteEnabled: true,
				depthCompare: DepthCompare,
				format: DepthFormat,
				depthBias,
				depthBiasSlopeScale,
			},
		};

		if (geometry.isLines) {
			pipelineConfig.primitive.topology = 'line-list';
		} else {
			pipelineConfig.primitive.topology = 'triangle-list';
		}

		pipeline = this.#device.createRenderPipeline(pipelineConfig);

		this.#pipelines.set(key, pipeline);

		return pipeline;
	}

	reset() {
		this.#activePipeline = null;
		this.#vb = this.#renderer.getVB();
	}

	drawOne(passEncoder, objectIndex, geometry) {
		const pipeline = this.getPipeline(geometry);

		if (pipeline !== this.#activePipeline) {
			passEncoder.setPipeline(pipeline);
			this.#activePipeline = pipeline;
		}

		this.#vb.draw(passEncoder, geometry, objectIndex);
	}
}
