import { ModelIteratorBVH } from '../../../ModelIteratorBVH';
import { NO_MESH_FOR_FRAGMENT } from '../../../consolidation/Consolidation';
import { ConsolidationTask } from './ConsolidationTask';
import { InstancedMeshUploadTask } from './InstancedMeshUploadTask';
import { RemainingFragmentsTask } from './RemainingFragmentsTask';
import { UniformUploadTask } from "./UniformUploadTask";
import { INCREMENTAL_CONSOLIDATION } from "../../../../globals";

/** @import { BvhNode } from "../../BvhNode"; */

/**
 * Creates all tasks that are needed to upload the node to the GPU
 * for the WebGl renderer.
 *
 * @param {BvhNode} node - The ID of the BVH node
 * @param {Stage} stage - The stage of the task
 */
export function initializeOptimizedOnGpuTasksWebGL(node, stage) {
    const bvhNodeId = node.nodeId;
    const consolidation = node.model.getConsolidation(bvhNodeId);
    const fragList = node.model.getFragmentList();

    const bvhModelIterator = node.model.getIterator();

    if (!(bvhModelIterator instanceof ModelIteratorBVH)) {
        console.error("ModelIterator is not a BVH iterator");
        return;
    }

    const renderBatch = bvhModelIterator.getGeomScenes()[bvhNodeId];
    if (!renderBatch) {
        // TODO this works around a bug with loaders other than the OtgLoader.
        // They call setBVH multiple times, potentially with entirely different nodes.
        // If there are less nodes in a later BVH, the code below would crash, which this works around.
        // However, it's still broken, since e.g. `node.transparent` is initialized with the first BVH,
        // and the node with the same ID in the current BVH might need a different value.
        return;
    }

    let renderBatchIndices = renderBatch.getIndices();

    if (node.transparent) { // transparent nodes cannot be consolidated because we need to sort individual fragments by depth
        return;
    }

    // Add tasks for all meshes in the render batch
    const alreadyAddedMeshIndices = new Set();
    let instancedMeshUploadTask = undefined;

    const createTasksForMesh = (meshIndex) => {
        if (meshIndex === NO_MESH_FOR_FRAGMENT) {
            return;
        }
        if (!consolidation.meshes[meshIndex].geometry) {
            return;
        }

        // Node already consolidated?
        if (!consolidation.meshGeometryAvailable(meshIndex)) {
            // Did we already create a task for this meshIndex?
            if (!alreadyAddedMeshIndices.has(meshIndex)) {
                let newTask = new ConsolidationTask(
                    node.outOfCoreTileManager,
                    meshIndex,
                    fragList,
                    bvhNodeId
                );

                node.addTask(newTask, stage);
                alreadyAddedMeshIndices.add(meshIndex);
            }
        }

        let geometry = consolidation.meshes[meshIndex].geometry;
        // should the geometry be rendered via hardware instancing?
        if (geometry.numInstances > 1) {
            // Is there already a InstancedMeshUploadTask for this meshIndex?

            // If not create the task and add it to the node
            if (!instancedMeshUploadTask) {
                instancedMeshUploadTask = new InstancedMeshUploadTask(
                    node.outOfCoreTileManager,
                    meshIndex,
                    fragList,
                    bvhNodeId
                );

                node.addTask(instancedMeshUploadTask, stage);
            } else {
                // Otherwise add the mesh to the existing task
                instancedMeshUploadTask.addGeometry(meshIndex);
            }
        }
    };

    if (INCREMENTAL_CONSOLIDATION) {
        for (let i = 0; i < consolidation.meshes.length; i++) {
            createTasksForMesh(i);
        }
    } else {
        for (let i = renderBatch.start; i < renderBatch.lastItem; i++) {
            let fragId = renderBatchIndices[i];
            let meshIndex = consolidation.fragId2MeshIndex[fragId];
            createTasksForMesh(meshIndex);
        }
    }

    // Add tasks for all remaining fragments
    const indices = consolidation.singleFragments ?? (consolidation.nodeId2SingleFragIds && consolidation.nodeId2SingleFragIds[bvhNodeId]);
    if (indices && indices.length > 0) {
        const fragsPerTask = 100;
        for (let i = 0; i < indices.length; i += fragsPerTask) {
            node.addTask(new RemainingFragmentsTask(node.outOfCoreTileManager, fragList, bvhNodeId, indices.slice(i, i + fragsPerTask)), stage);
        }
    }
}

/**
 * Creates all tasks that are needed to upload the node to the GPU
 * for the WebGpu renderer.
 *
 * @param {BvhNode} node - The ID of the BVH node
 * @param {StageNames} stage - The stage of the task
 */
export function initializeOptimizedOnGpuTasksWebGpu(node, stage) {
    const bvhNodeId = node.nodeId;
    const fragList = node.model.getFragmentList();

    const bvhModelIterator = node.model.getIterator();

    if (!(bvhModelIterator instanceof ModelIteratorBVH)) {
        console.error("ModelIterator is not a BVH iterator");
        return;
    }

    const renderBatch = bvhModelIterator.getGeomScenes()[bvhNodeId];
    if (!renderBatch) {
        // TODO this works around a bug with loaders other than the OtgLoader.
        // They call setBVH multiple times, potentially with entirely different nodes.
        // If there are less nodes in a later BVH, the code below would crash, which this works around.
        // However, it's still broken, since e.g. `node.transparent` is initialized with the first BVH,
        // and the node with the same ID in the current BVH might need a different value.
        return;
    }

    // Add tasks for all remaining fragments
    const indices = renderBatch.getIndices();
    let span = indices.subarray(renderBatch.start, renderBatch.lastItem);

    // Kick out any faulty shapes. Note that when we reach this code, the fragments are all loaded,
    // so any fragment that isn't ready here is hopeless and waiting will not help.
    span = span.filter(fragId => fragList.isFragmentActive(fragId));
    if (span.length > 0) {
        node.addTask(new RemainingFragmentsTask(node.outOfCoreTileManager, fragList, bvhNodeId, span), stage);
    }

    // Add a task to upload the batched Uniforms. Note that we rely here on the fact that tasks are executed strictly in
    // the given order. Reason is:
    //  - Uniform batches must be created in the same order as the fragments in the RenderBatch. So, we defer that until the order is fully settled.
    //  - Fragments in RenderBatch are sorted by GPU vertexBuffer page.
    //  - The vertex buffer page is not known until geometry is uploaded
    const uploadTask = new UniformUploadTask(node.outOfCoreTileManager, renderBatch);
    node.addTask(uploadTask, stage);
}
