import {initCubeMap, refTexture, unrefTexture} from "../Texture";
import {$wgsl} from "../../wgsl-preprocessor/wgsl-preprocessor";
import {CreateCubeMapFromColors} from "../../render/DecodeEnvMap";
import IBLShader from "./IBL.wgsl";

const NUM_HEATMAP_STOPS = 5;

const LightOffsets = {
    // Directional light
    direction: 0, // vec3f
    useDirLight: 3, // bool
    dirColor: 4, // vec3f,
    pad0: 7,

    // Ambient light
    ambColor: 8, //vec3f

    SIZE_IN_FLOATS: 12, // must be 4-float-aligned
};

export class IBL {

    static getDeclaration(bindGroup, firstBinding) {
        return $wgsl(IBLShader, {
            NUM_HEATMAP_STOPS,
            bindGroup,
            firstBinding,
        });
    }

    #renderer;
    #device;

    /** @type {THREE.Texture} */
    #defaultCubeMap;
    /** @type {THREE.Texture} */
    #reflMap;
    /** @type {THREE.Texture} */
    #irrMap;
    #groundShadowEnvMapDirty = false;

    #exposureBias = 1.0;
    #envMapExposure = 1.0;
    #tonemapMethod = 0;

    #uBufferCPU = new Float32Array(4 + NUM_HEATMAP_STOPS * 4 + 4);
    #uBufferInt = new Int32Array(this.#uBufferCPU.buffer);
    #uBuffer;

    #cutBufferCPU = new Float32Array(24);
    #numCutplanes = 0;
    #cutBuffer;
    #cutplanesDirty = false;

    #colorsAndStops = new Float32Array(NUM_HEATMAP_STOPS * 4);
    #heatmapAlpha;
    #heatmapSensorBufferCPU;
    #heatmapSensorBuffer;
    #heatmapSensorsDirty = false;

    #lightBufferCPU = new Float32Array(LightOffsets.SIZE_IN_FLOATS);
    #lightBuffer;
    #defaultAmbientColor = { r: 0, g: 0, b: 0 }; // matches with WebGL (see _lights struct WebGLRenderer ctor)
    #tmpVec = new THREE.Vector3();
    #tmpVec2 = new THREE.Vector3();

    #needsUpdate;
    #uniformsDirty;

    constructor(renderer) {
        this.#renderer = renderer;

        this.#defaultCubeMap = CreateCubeMapFromColors({x:1, y:1, z:1}, {x:1, y:1, z:1});
        this.#reflMap = this.#defaultCubeMap;
        this.#irrMap = this.#defaultCubeMap;

        refTexture(this.#defaultCubeMap);
        refTexture(this.#reflMap);
        refTexture(this.#irrMap);

        this.#uBufferCPU;
    }

    init() {
        this.#device = this.#renderer.getDevice();

        this.#uBuffer = this.#device.createBuffer({
            size: this.#uBufferCPU.byteLength,
            usage: GPUBufferUsage.UNIFORM | GPUBufferUsage.COPY_DST,
        });

        this.#cutBuffer = this.#device.createBuffer({
            size: this.#cutBufferCPU.byteLength,
            usage: GPUBufferUsage.UNIFORM | GPUBufferUsage.COPY_DST,
        });

        // We're just allocating this as a placeholder here.
        // The buffer will be allocated with the required size when heatmaps are activated.
        this.#heatmapSensorBuffer = this.#device.createBuffer({
            size: 64,
            usage: GPUBufferUsage.STORAGE | GPUBufferUsage.COPY_DST,
        });

        this.#lightBuffer = this.#device.createBuffer({
            size: this.#lightBufferCPU.byteLength,
            usage: GPUBufferUsage.UNIFORM | GPUBufferUsage.COPY_DST,
        });

    }

    /**
     * @param {number} firstBinding
     * @returns {GPUBindGroupLayoutEntry[]}
     */
    getLayoutEntries(firstBinding) {
        return [
            {
                binding: firstBinding + 0,
                visibility: GPUShaderStage.FRAGMENT,
                buffer: {}
            },
            {
                binding: firstBinding + 1,
                visibility: GPUShaderStage.FRAGMENT,
                texture: {
                    sampleType: 'float',
                    viewDimension: "cube"
                }
            },
            {
                binding: firstBinding + 2,
                visibility: GPUShaderStage.FRAGMENT,
                sampler: {}
            },
            {
                binding: firstBinding + 3,
                visibility: GPUShaderStage.FRAGMENT,
                texture: {
                    sampleType: 'float',
                    viewDimension: "cube"
                }
            },
            {
                binding: firstBinding + 4,
                visibility: GPUShaderStage.FRAGMENT,
                sampler: {}
            },
            {
                binding: firstBinding + 5,
                visibility: GPUShaderStage.FRAGMENT,
                buffer: {}
            },
            {
                binding: firstBinding + 6,
                visibility: GPUShaderStage.FRAGMENT,
                buffer: {
                    type: 'read-only-storage'
                }
            },
            {
                binding: firstBinding + 7,
                visibility: GPUShaderStage.FRAGMENT,
                buffer: {}
            }
        ];
    }

    setReflectionMap(map) {
        if (map === this.#reflMap)
            return;
        // Setting to null would make the pipeline creation fail. Use this.#defaultCubeMap as a fallback to avoid this.
        // Note: This currently happens for 2D materials, for which IBL doesn't make sense anyway. So we should revise this.
        map ||= this.#defaultCubeMap;

        refTexture(map);
        unrefTexture(this.#reflMap);

        this.#reflMap = map;

        this.#needsUpdate = true;
    }

    setIrradianceMap(map) {
        // Tolerate null without breaking the pipeline creation. (see comment in setReflectionMap)
        map ||= this.#defaultCubeMap;

        refTexture(map);
        unrefTexture(this.#irrMap);

        this.#irrMap = map;

        this.#needsUpdate = true;
    }

    isComplete() {
        return this.#reflMap && this.#irrMap;
    }

    setEnvExposure = function(exposure) {

        const newValue = Math.pow(2.0, exposure);

        if (newValue !== this.#envMapExposure) {
            this.#envMapExposure = newValue;
            this.#uniformsDirty = true;
        }
    }

    setExposureBias(bias) {
        let newValue = Math.pow(2.0, bias);

        if (newValue !== this.#exposureBias) {
            this.#exposureBias = newValue;
            this.#uniformsDirty = true;
        }
    }

    setTonemapMethod(value) {
        if (this.#tonemapMethod !== value) {
            this.#tonemapMethod = value;
            this.#uniformsDirty = true;
        }
    }

    setEnvRotation(rotation) {
        //TODO:
    }

    /**
     * @typedef {Object} CutPlane
     * @property {number} x
     * @property {number} y
     * @property {number} z
     * @property {number} w
     */

    /**
     * Sets global cutplanes. Some materials may also have "local" cutplanes
     * that apply only to those materials, and other material can ignore the
     * global cutplanes via doNotCut setting.
     * @param {CutPlane[]} cutplanes
     */
    setCutPlanes(cutplanes) {
        if (this.#numCutplanes !== (cutplanes?.length || 0)) {
            this.#numCutplanes = cutplanes?.length || 0;
            this.#uniformsDirty = true;
        }

        if (!cutplanes) {
            return;
        }

        if (cutplanes.length > 6) {
            console.warn("too many cut planes");
        }

        let off = 0;
        for (let i=0; i<cutplanes.length; i++) {
            let plane = cutplanes[i];
            let v = plane.x;
            if (this.#cutBufferCPU[off] !== v) {
                this.#cutplanesDirty = true;
                this.#cutBufferCPU[off] = v;
            }
            off++;
            v = plane.y;
            if (this.#cutBufferCPU[off] !== v) {
                this.#cutplanesDirty = true;
                this.#cutBufferCPU[off] = v;
            }
            off++;
            v = plane.z;
            if (this.#cutBufferCPU[off] !== v) {
                this.#cutplanesDirty = true;
                this.#cutBufferCPU[off] = v;
            }
            off++;
            v = plane.w;
            if (this.#cutBufferCPU[off] !== v) {
                this.#cutplanesDirty = true;
                this.#cutBufferCPU[off] = v;
            }
            off++;
        }
    };

    /**
     * Get the global cutplane objects.
     * @returns {CutPlane[]}
     */
    #getCutPlanes() {
        const cutplanes = new Array(this.#numCutplanes);
        for (let i = 0; i < this.#numCutplanes; i++) {
            cutplanes[i] = {
                x: this.#cutBufferCPU[4 * i],
                y: this.#cutBufferCPU[4 * i + 1],
                z: this.#cutBufferCPU[4 * i + 2],
                w: this.#cutBufferCPU[4 * i + 3],
            }
        }
        return cutplanes;
    }

    setHeatmaps(colors, stops, alpha) {
        let offset = 0;
        for (let i = 0; i < NUM_HEATMAP_STOPS; ++i) {
            this.#colorsAndStops.set([
                colors[i * 3],
                colors[i * 3 + 1],
                colors[i * 3 + 2],
                stops[i]
            ], offset);
            offset += 4;
        }

        this.#heatmapAlpha = alpha;

        this.#uniformsDirty = true;
    };

    setHeatmapSensors(sensors) {
        if (!this.#heatmapSensorBufferCPU || this.#heatmapSensorBufferCPU.length < sensors.length) {
            this.#heatmapSensorBufferCPU = new Float32Array(sensors);
        } else {
            this.#heatmapSensorBufferCPU.set(sensors);
        }

        this.#heatmapSensorsDirty = true;
    };

    /**
     * @param {number} firstBinding
     * @returns {GPUBindGroupEntry[]}
     */
    getEntries(firstBinding) {
        return [
            {
                binding: firstBinding + 0,
                resource: {
                    buffer: this.#uBuffer,
                },
            },
            {
                binding: firstBinding + 1,
                resource: this.#reflMap?.__gpuTextureCube.createView({
                        dimension: "cube"
                })
            },
            {
                binding: firstBinding + 2,
                resource: this.#reflMap?.__gpuSampler
            },
            {
                binding: firstBinding + 3,
                resource: this.#irrMap?.__gpuTextureCube.createView({
                        dimension: "cube"
                })
            },
            {
                binding: firstBinding + 4,
                resource: this.#irrMap?.__gpuSampler
            },
            {
                binding: firstBinding + 5,
                resource: {
                    buffer: this.#cutBuffer
                }
            },
            {
                binding: firstBinding + 6,
                resource: {
                    buffer: this.#heatmapSensorBuffer
                }
            },
            {
                binding: firstBinding + 7,
                resource: {
                    buffer: this.#lightBuffer,
                },
            }
        ];
    }

    /**
     * @param {THREE.Light[]} lights
     **/
    setLights(lights) {
        // TODO: This expects one of the three cases:
        // 1. lights is undefined, 2. lights is an empty array
        // 3. lights is an array with two lights, where the first one
        // is a directional light and the second one an ambient light.
        // We don't support an arbitrary list of lights, but the cases above are what the viewer uses right now.

        // Directional light
        let dirLight = lights?.[0];
        if (dirLight?.type !== 'DirectionalLight') {
            dirLight = undefined;
        }

        const intensity = dirLight?.intensity;

        if (!dirLight || !dirLight.visible || intensity === 0) {
            // Just disable the light.
            this.#lightBufferCPU[LightOffsets.useDirLight] = 0;
        } else {
            // Enable the light and set its values.
            this.#lightBufferCPU[LightOffsets.useDirLight] = 1;

            // Direction
            this.#tmpVec.setFromMatrixPosition(dirLight.matrixWorld);
            this.#tmpVec2.setFromMatrixPosition(dirLight.target.matrixWorld);
            this.#tmpVec.sub(this.#tmpVec2);
            this.#tmpVec.normalize();
            this.#lightBufferCPU[LightOffsets.direction] = this.#tmpVec.x;
            this.#lightBufferCPU[LightOffsets.direction + 1] = this.#tmpVec.y;
            this.#lightBufferCPU[LightOffsets.direction + 2] = this.#tmpVec.z;

            // Color
            const color = dirLight.color;
            this.#lightBufferCPU[LightOffsets.dirColor] = color.r * intensity;
            this.#lightBufferCPU[LightOffsets.dirColor + 1] = color.g * intensity;
            this.#lightBufferCPU[LightOffsets.dirColor + 2] = color.b * intensity;
        }

        // Ambient light
        let ambLight = lights?.[1];
        if (ambLight?.type !== 'AmbientLight') {
            ambLight = undefined;
        }

        let ambColor;
        if (!ambLight || !ambLight.visible) {
            // All zero, no ambient light.
            ambColor = this.#defaultAmbientColor;
        } else {
            ambColor = ambLight.color;
        }

        this.#lightBufferCPU[LightOffsets.ambColor] = ambColor.r;
        this.#lightBufferCPU[LightOffsets.ambColor + 1] = ambColor.g;
        this.#lightBufferCPU[LightOffsets.ambColor + 2] = ambColor.b;

        this.#device.queue.writeBuffer(this.#lightBuffer, 0, this.#lightBufferCPU.buffer, 0, this.#lightBufferCPU.byteLength);
    }

    /**
     * @param {THREE.Light[]} lights
     * @returns {boolean} True if the bind group needs updating.
     */
    update(lights) {
        if (!this.#device) {
            return false;
        }

        if (this.#uniformsDirty) {
            this.#uBufferCPU[0] = this.#envMapExposure;
            this.#uBufferCPU[1] = this.#exposureBias;
            this.#uBufferInt[2] = this.#tonemapMethod;
            this.#uBufferInt[3] = this.#numCutplanes;
            this.#uBufferCPU[4] = this.#heatmapAlpha;

            this.#uBufferCPU.set(this.#colorsAndStops, 8);


            this.#device.queue.writeBuffer(
                this.#uBuffer,
                0,
                this.#uBufferCPU.buffer,
                0,
                this.#uBufferCPU.byteLength
            );

            this.#uniformsDirty = false;
        }

        if (this.#cutplanesDirty) {
            this.#device.queue.writeBuffer(
                this.#cutBuffer,
                0,
                this.#cutBufferCPU.buffer,
                0,
                this.#cutBufferCPU.byteLength
            );

            this.#cutplanesDirty = false;
        }

        if (this.#heatmapSensorsDirty) {
            if (!this.#heatmapSensorBuffer || this.#heatmapSensorBuffer.size < this.#heatmapSensorBufferCPU.byteLength) {
                if (this.#heatmapSensorBuffer) {
                    this.#heatmapSensorBuffer.destroy();
                }

                this.#heatmapSensorBuffer = this.#device.createBuffer({
                    size: this.#heatmapSensorBufferCPU.byteLength,
                    usage: GPUBufferUsage.STORAGE | GPUBufferUsage.COPY_DST,
                });

                this.#needsUpdate = true;
            }

            this.#device.queue.writeBuffer(
                this.#heatmapSensorBuffer,
                0,
                this.#heatmapSensorBufferCPU.buffer,
                0,
                this.#heatmapSensorBufferCPU.byteLength
            );

            this.#heatmapSensorsDirty = false;
        }

        this.setLights(lights);

        if (this.#needsUpdate || this.#reflMap?.needsUpdate || this.#irrMap?.needsUpdate) {

            initCubeMap(this.#device, this.#reflMap);
            initCubeMap(this.#device, this.#irrMap);
            this.#groundShadowEnvMapDirty = true;

            this.#needsUpdate = false;
            return true;
        }
        return false;
    }

    /** @param {IBL} ibl */
    copyFrom(ibl) {
        this.#reflMap = ibl.getReflectionMap();
        this.#irrMap = ibl.getIrradianceMap();
        this.#envMapExposure = ibl.getEnvMapExposure();
        this.#exposureBias = ibl.getExposureBias();
        this.#tonemapMethod = ibl.getTonemapMethod();
        this.#needsUpdate = true;
        this.#uniformsDirty = true;
        this.setCutPlanes(ibl.#getCutPlanes());
    }

    /** @returns {Three.TEXTURE} */
    getIrradianceMap() {
        return this.#irrMap;
    }

    /** @returns {Three.TEXTURE} */
    getReflectionMap() {
        return this.#reflMap;
    }

    /** @returns {number} */
    getEnvMapExposure() {
        return this.#envMapExposure;
    }

    /** @returns {number} */
    getExposureBias() {
        return this.#exposureBias;
    }

    /** @returns {number} */
    getTonemapMethod() {
        return this.#tonemapMethod;
    }

    /** @returns {boolean} */
    getGroundShadowEnvMapDirty() {
        return this.#groundShadowEnvMapDirty;
    }

    /** @param {boolean} value */
    setGroundShadowEnvMapDirty(value) {
        this.#groundShadowEnvMapDirty = value;
    }
}
