/**
 * @author bhouston / http://exocortex.com
 * @author WestLangley / http://github.com/WestLangley
 */
/* Pruned version of THREE.Box3, for use in the LMV web worker */

import { LmvVector3 } from './LmvVector3';

export function LmvBox3(min, max) {

    this.min = ( min !== undefined ) ? min : new LmvVector3( Infinity, Infinity, Infinity );
    this.max = ( max !== undefined ) ? max : new LmvVector3( - Infinity, - Infinity, - Infinity );

}


LmvBox3.prototype.constructor = LmvBox3;

LmvBox3.prototype.set = function(min, max) {

    this.min.copy(min);
    this.max.copy(max);

    return this;

};

LmvBox3.prototype.setFromPoints = function(points) {

    this.makeEmpty();

    for (var i = 0, il = points.length; i < il; i++) {

        this.expandByPoint(points[i]);

    }

    return this;

};

LmvBox3.prototype.setFromArray = function(array, offset) {

    this.min.x = array[offset];
    this.min.y = array[offset + 1];
    this.min.z = array[offset + 2];

    this.max.x = array[offset + 3];
    this.max.y = array[offset + 4];
    this.max.z = array[offset + 5];

    return this;

};

LmvBox3.prototype.copyToArray = function(array, offset) {

    array[offset] = this.min.x;
    array[offset + 1] = this.min.y;
    array[offset + 2] = this.min.z;

    array[offset + 3] = this.max.x;
    array[offset + 4] = this.max.y;
    array[offset + 5] = this.max.z;

};

LmvBox3.prototype.setFromCenterAndSize = function() {

    var v1 = new LmvVector3();

    return function(center, size) {

        var halfSize = v1.copy(size).multiplyScalar(0.5);

        this.min.copy(center).sub(halfSize);
        this.max.copy(center).add(halfSize);

        return this;

    };

    }(),

LmvBox3.prototype.clone = function() {

    return new this.constructor().copy(this);

    };

LmvBox3.prototype.copy = function(box) {

    this.min.copy(box.min);
    this.max.copy(box.max);

    return this;

};

LmvBox3.prototype.makeEmpty = function() {

    this.min.x = this.min.y = this.min.z = Infinity;
    this.max.x = this.max.y = this.max.z = - Infinity;

    return this;

};

LmvBox3.prototype.empty = function() {

    // this is a more robust check for empty than ( volume <= 0 ) because volume can get positive with two negative axes

    return (this.max.x < this.min.x) || (this.max.y < this.min.y) || (this.max.z < this.min.z);

};

LmvBox3.prototype.isEmpty = function() {
    // threejs backport - https://github.com/mrdoob/three.js/blob/dev/src/math/Box3.js
    return this.empty();
};

LmvBox3.prototype.center = function(optionalTarget) {
    console.warn("LmvBox3.center() is deprecated. Use LmvBox3.getCenter() instead.");
    return this.getCenter(optionalTarget);
};

LmvBox3.prototype.getCenter = function(optionalTarget) {

    var result = optionalTarget || new LmvVector3();
    return result.addVectors(this.min, this.max).multiplyScalar(0.5);

};

LmvBox3.prototype.size = function(optionalTarget) {

    var result = optionalTarget || new LmvVector3();
    return result.subVectors(this.max, this.min);

};

    // For compatibility with latest THREE
LmvBox3.prototype.getSize = function(optionalTarget) {

    var result = optionalTarget || new LmvVector3();
    return result.subVectors(this.max, this.min);

};

LmvBox3.prototype.expandByPoint = function(point) {

    this.min.min(point);
    this.max.max(point);

    return this;

};

LmvBox3.prototype.expandByVector = function(vector) {

    this.min.sub(vector);
    this.max.add(vector);

    return this;

};

LmvBox3.prototype.expandByScalar = function(scalar) {

    this.min.addScalar(- scalar);
    this.max.addScalar(scalar);

    return this;

};

LmvBox3.prototype.containsPoint = function(point) {

    if (point.x < this.min.x || point.x > this.max.x ||
        point.y < this.min.y || point.y > this.max.y ||
        point.z < this.min.z || point.z > this.max.z) {

        return false;

    }

    return true;

};

LmvBox3.prototype.containsBox = function(box) {

    if ((this.min.x <= box.min.x) && (box.max.x <= this.max.x) &&
        (this.min.y <= box.min.y) && (box.max.y <= this.max.y) &&
        (this.min.z <= box.min.z) && (box.max.z <= this.max.z)) {

        return true;

    }

    return false;

};

LmvBox3.prototype.getParameter = function(point, optionalTarget) {

    // This can potentially have a divide by zero if the box
    // has a size dimension of 0.

    var result = optionalTarget || new LmvVector3();

    return result.set(
        (point.x - this.min.x) / (this.max.x - this.min.x),
        (point.y - this.min.y) / (this.max.y - this.min.y),
        (point.z - this.min.z) / (this.max.z - this.min.z)
    );

};

LmvBox3.prototype.isIntersectionBox = function(box) {

    // using 6 splitting planes to rule out intersections.

    if (box.max.x < this.min.x || box.min.x > this.max.x ||
        box.max.y < this.min.y || box.min.y > this.max.y ||
        box.max.z < this.min.z || box.min.z > this.max.z) {

        return false;

    }

    return true;

};

    // For compatibility with latest THREE
LmvBox3.prototype.intersectsBox = function(box) {
    return this.isIntersectionBox(box);
};

LmvBox3.prototype.clampPoint = function(point, optionalTarget) {

    var result = optionalTarget || new LmvVector3();
    return result.copy(point).clamp(this.min, this.max);

};

LmvBox3.prototype.distanceToPoint = function() {

    var v1 = new LmvVector3();

    return function(point) {

        var clampedPoint = v1.copy(point).clamp(this.min, this.max);
        return clampedPoint.sub(point).length();

    };

    }(),

LmvBox3.prototype.intersect = function(box) {

    this.min.max(box.min);
    this.max.min(box.max);

    return this;

    };

LmvBox3.prototype.union = function(box) {

    this.min.min(box.min);
    this.max.max(box.max);

    return this;

};

LmvBox3.prototype.applyMatrix4 = function() {

    var min3 = new LmvVector3();
    var max3 = new LmvVector3();

    return function(matrix) {
        // use Jim Arvo's AABB transform algorithm
        // https://github.com/erich666/GraphicsGems/blob/7ef8b6990e125fa908dfaeb80bc42f4b4fae65b8/gems/TransBox.c
        // More digestible version here: https://stackoverflow.com/questions/6053522/how-to-recalculate-axis-aligned-bounding-box-after-translate-rotate/58630206#58630206
        var e = matrix.elements;
        for (var i = 0; i < 3; i++) {
            let min = e[12 + i];
            let max = min;
            for (var j = 0; j < 3; j++) {
                var a = e[i + j * 4] * this.min.getComponent(j);
                var b = e[i + j * 4] * this.max.getComponent(j);
                min += Math.min(a, b);
                max += Math.max(a, b);
            }
            min3.setComponent(i, min);
            max3.setComponent(i, max);
        }
        this.min.copy(min3);
        this.max.copy(max3);

        return this;

    };

    }(),

LmvBox3.prototype.translate = function(offset) {

    this.min.add(offset);
    this.max.add(offset);

    return this;

    };

LmvBox3.prototype.equals = function(box) {

    return box.min.equals(this.min) && box.max.equals(this.max);

};

