
//Base class for simple uniform buffers
export class UniformBuffer {

    #device;

    #bufferCPU;
    #bufferCPUInt;

    /** @property {GPUBuffer} */
    #buffer;
    #layout;
    #bindGroup;

    #visibility;

    #dirty = false;

    #label;

    #readBuffer;
    #debug = false;

    constructor(device, sizeInFloats, visibleInFragment = true, visibleInVertex = true, label = '', debug = false) {
        this.#device = device;
        this.#label = label || '';
        this.#debug = debug;

        let roundedSize = sizeInFloats;
        let rm = sizeInFloats % 4;
        if (rm) {
            roundedSize += 4 - rm;
        }

        this.#bufferCPU = new Float32Array(roundedSize);
        this.#bufferCPUInt = new Int32Array(this.#bufferCPU.buffer);

        let visibility = 0;
        if (visibleInFragment) {
            visibility |= GPUShaderStage.FRAGMENT;
        }

        if (visibleInVertex) {
            visibility |= GPUShaderStage.VERTEX;
        }

        this.#visibility = visibility;

        this.#createResources();
    }

    #createResources() {
        if (this.#buffer || !this.#device) {
            return;
        }

        if (this.#debug && !this.#readBuffer) {
            this.#readBuffer = this.#device.createBuffer({
                size: this.#bufferCPU.byteLength,
                usage: GPUBufferUsage.COPY_DST | GPUBufferUsage.MAP_READ,
            });
        }

        this.#buffer = this.#device.createBuffer({
            label: this.#label,
            size: this.#bufferCPU.byteLength,
            usage: GPUBufferUsage.UNIFORM | GPUBufferUsage.COPY_DST | (this.#debug ? GPUBufferUsage.COPY_SRC : 0),
        });

        this.#layout = this.#device.createBindGroupLayout({
            entries: [
                {
                    binding: 0,
                    visibility: this.#visibility,
                    buffer: {}
                },
            ]
        });

        this.#bindGroup = this.#device.createBindGroup({
            layout: this.#layout,
            entries: [
                {
                    binding: 0,
                    resource: {
                        buffer: this.#buffer,
                    },
                }
            ],
        });

    }


    setDevice(device) {
        this.#device = device;
        this.#createResources();
    }

    getLayout() {
        return this.#layout;
    }

    getBindGroup() {
        return this.#bindGroup;
    }

    getVisibility() {
        return this.#visibility;
    }

    /** @returns {GPUBuffer} */
    getBuffer() {
        return this.#buffer;
    }

    setFloat(offset, value) {
        this.#bufferCPU[offset] = value;
    }

    getFloat(offset) {
        return this.#bufferCPU[offset];
    }

    setFloat2(offset, x, y) {
        this.#bufferCPU[offset] = x;
        this.#bufferCPU[offset + 1] = y;
    }

    setFloat4(offset, x, y, z, w) {
        this.#bufferCPU[offset] = x;
        this.#bufferCPU[offset + 1] = y;
        this.#bufferCPU[offset + 2] = z;
        this.#bufferCPU[offset + 3] = w;
    }

    setVector3(offset, value) {
        this.#bufferCPU[offset] = value.x;
        this.#bufferCPU[offset + 1] = value.y;
        this.#bufferCPU[offset + 2] = value.z;
    }

    setVector4(offset, value) {
        this.#bufferCPU[offset] = value.x;
        this.#bufferCPU[offset + 1] = value.y;
        this.#bufferCPU[offset + 2] = value.z;
        this.#bufferCPU[offset + 3] = value.w;
    }

    setColor(offset, color, opacity) {
        this.#bufferCPU[offset] = color.r;
        this.#bufferCPU[offset + 1] = color.g;
        this.#bufferCPU[offset + 2] = color.b;

        if (opacity !== undefined) {
            this.#bufferCPU[offset + 3] = opacity;
        }
    }

    setMatrix3x3(offset, src) {
    //Note the 4-float padding on the destination
        for (let i = 0; i < 3; i++)
            for (let j = 0; j < 3; j++)
                this.#bufferCPU[offset + j + i * 4] = src[j + i * 3];
    }

    setMatrix4x4(offset, src) {
        this.#bufferCPU.set(src, offset);
    }

    setInt = function(offset, value) {
        this.#bufferCPUInt[offset] = value;
    };

    getInt(offset) {
        return this.#bufferCPUInt[offset];
    }

    setBuffer(offset, buffer) {
        this.#bufferCPU.set(buffer, offset);
    }

    upload() {
        this.#device.queue.writeBuffer(
            this.#buffer,
            0,
            this.#bufferCPU.buffer,
            0,
            this.#bufferCPU.byteLength
        );
    }

    copyBufferToRead(commandEncoder) {
        if (!this.#readBuffer) return;

        commandEncoder.copyBufferToBuffer(this.#buffer, 0, this.#readBuffer, 0, this.#buffer.size);
    }

    async download() {
        if (!this.#readBuffer) return new ArrayBuffer();

        await this.#readBuffer.mapAsync(GPUMapMode.READ, 0);
        const data = this.#readBuffer.getMappedRange().slice(0);
        this.#readBuffer.unmap();
        return data;
    }

}
