import { getGlobal } from "../../compat";

export const ENDPOINT_API_DERIVATIVE_SERVICE_V2 = 'derivativeV2';
export const ENDPOINT_API_MODEL_DERIVATIVE_V2 = 'modelDerivativeV2'; // Forge
export const ENDPOINT_API_DERIVATIVE_STREAMING = 'streamingV2'; // SVF2

const FEDRAMP_STAGING_ENV = 'FedrampStaging2';
const FEDRAMP_PRODUCTION_ENV = 'FedrampProduction2';

export const DEVELOPER_API_URLS = Object.freeze({
    local: "",
    dev: "https://developer-dev.api.autodesk.com",
    stg: "https://developer-stg.api.autodesk.com",
    prod: "https://developer.api.autodesk.com"
});

export const FEDRAMP_API_URLS = Object.freeze({
    stg: "https://api-stg.afg.us.autodesk.com",
    prod: "https://api.afg.us.autodesk.com",
});

const DS_ENDPOINTS_API = '/derivativeservice/v2/endpoints/:urn';

const DS_PROXY_PORT = 3000;
const location = getGlobal().location || '';
const currentHost = location ? `${location.protocol}//${location.host}` : '';
const dsProxyHost = location ? `${location.protocol}//${location.hostname}:${DS_PROXY_PORT}` : '';

var _env; //formerly avp.env
export function getEnv() {
    return _env;
}
export function setEnv(env) {
    _env = env;
    if (env.startsWith('MD20')) {
        console.warn(`env=${env} is deprecated and will be removed in a future release. Use Autodesk{env}2 instead, where env=Development, Staging, or Production`);
    }
}

export function loadEndpoints(documentId, options, authPromise) {
    if (!documentId) {
        if (options.endpoint) {
            Endpoints.setGlobalEndpoint(options.endpoint);
        }
        return Promise.resolve();
    }

    const endpoints = Endpoints.getInstance(documentId, options);

    return authPromise?.then(() => endpoints.loadEndpoints()) ?? endpoints.loadEndpoints();
}

/**
 * Returns the developer API URL used to create REST API requests
 *
 * @example `developer.api.autodesk.com`
 *
 * @param {string} [env] - The optional env to get the API URL for
 * @returns {string}
 */
export function getDeveloperApiUrl(env) {
    const __env = env || getEnv();

    if (__env === 'Local') return '';

    // backward compatibility
    if (__env === 'Test') return dsProxyHost;
    if (/Local/.test(__env)) return currentHost;

    // fedramp
    if (__env === FEDRAMP_STAGING_ENV) return FEDRAMP_API_URLS.stg;
    if (__env === FEDRAMP_PRODUCTION_ENV) return FEDRAMP_API_URLS.prod;

    // developer.api
    if (/Prod/.test(__env)) return DEVELOPER_API_URLS.prod;
    if (/Dev/.test(__env)) return DEVELOPER_API_URLS.dev;
    return DEVELOPER_API_URLS.stg;
}

export class Endpoints {
    /**
     * The "user-defined" global endpoint
     *
     * @type {string|undefined}
     * @private
     */
    static _globalEndpoint;
    /**
     * Sets the user-defined global endpoint
     *
     * There are two moments in time, when a loading of the endpoints can be triggered:
     * - at `Autodesk.Viewing.Initializer`, iff `documentId` is provided as an option (a "non-standard" case),
     * - at `Document.load`, prior to the manifest loading (a "standard" case).
     *
     * Even though it's possible to configure `Document.load` to use a custom endpoint,
     * it is an inconvinient "non-standard" scenario. In order to help LMV users to avoid that,
     * the custom endpoint supplied at `Autodesk.Viewing.Initializer` can be cached (also see {@link loadEndpoints})
     * and used as a fallback at the moment the `documentId` is known, i.e., at `Document.load`.
     *
     * @param {string} endpoint - The user-defined endpoint
     */
    static setGlobalEndpoint(endpoint) {
        Endpoints._globalEndpoint = endpoint;
    }
    /**
     * The URN of the document THIS instance will be providing endpoints for
     *
     * @type {string}
     */
    _documentUrn;
    /**
     * The "user-defined" endpoint, overrides any other endpoint
     *
     * It is set when provided with constructor's options.
     *
     * @type {string|undefined}
     */
    #endpoint;
    /**
     * The JSON object, which stores the response of the `/endpoints` API
     *
     * @type {object|undefined}
     */
    _endpoints;

    /**
     * @type {number|undefined}
     */
    _loadingTime;

    /**
     * @private
     */
    static #documentEndpoints = new Map();
    /**
     * @param {string} documentUrn - The document URN
     * @param {object} options - An optional object that allows configuring manually the API request parameters such as headers, endpoint etc.
     * @return {Endpoints}
     */
    static getInstance(documentUrn, options) {
        documentUrn = documentUrn.replace(/^urn:/i, '');

        const endpoints = Endpoints.#documentEndpoints.get(documentUrn) ?? new Endpoints(documentUrn, options);
        if (!Endpoints.#documentEndpoints.has(documentUrn)) {
            Endpoints.#documentEndpoints.set(documentUrn, endpoints);
        } else {
            endpoints.setEndpointAndApi(options?.endpoint, options?.api);
        }
        return endpoints;
    }

    /**
     * @param {string} documentUrn - The document URN
     * @param {object} options - An optional object that allows configuring manually the API request parameters such as headers, endpoint etc.
     * @private
     */
    constructor(documentUrn, options) {
        this._documentUrn = documentUrn.replace(/^urn:/i, '');

        this._env = options?.env || getEnv();
        this._api = options?.api || Autodesk.Viewing.endpoint.getApiFlavor() || ENDPOINT_API_DERIVATIVE_STREAMING;
        // defaults to the global endpoint
        this.#endpoint = options?.endpoint || Endpoints._globalEndpoint;
        this.HTTP_REQUEST_HEADERS = options?.headers ?? {};

        this.#validateEndpointAndApi();
    }

    #validateEndpointAndApi() {
        if (this._api.startsWith(ENDPOINT_API_DERIVATIVE_STREAMING)) {
            if (this.#endpoint === getDeveloperApiUrl(this._env)) {
                throw new Error('developer.api endpoints do not support streaming APIs');
            }
            if (this.hasCustomEndpoint()) {
                console.warn(`Using custom endpoints with streaming APIs might be troublesome, since streaming APIs are not region-independent.`);
            }
        }
    }

    async #load() {
        if (this._env === 'Local') return;

        // temporary workaround until `Endpoints` will replace `Autodesk.Viewing.endpoint`
        const legacyEndpoint = Autodesk.Viewing.endpoint;
        this.HTTP_REQUEST_HEADERS = Object.assign(legacyEndpoint.HTTP_REQUEST_HEADERS, this.HTTP_REQUEST_HEADERS);

        const loadStart = performance.now();

        const headers = new Headers(this.HTTP_REQUEST_HEADERS);
        const endpoint = this.#endpoint || getDeveloperApiUrl(this._env);
        // Since historically (?) LMV and thus DS endpoints define paths as absolute (with trailing "/"),
        // we have to support base URLs with and w/o "/" at the end and not to omit additional "/.." segments,
        // so that, e.g., "http://foo.com/bar/" + "/buz" results in "http://foo.com/bar/buz"
        // and not in "http://foo.com/bar//buz" (simple concat) or "http://foo.com/buz" (`new URL()` spec way).
        // Note that we don't use `new URL()` because of that.
        // See https://developer.mozilla.org/en-US/docs/Web/API/URL/URL
        // and https://developer.mozilla.org/en-US/docs/Web/API/URL_API/Resolving_relative_references
        const baseUrl = endpoint.endsWith('/') ? endpoint.slice(0, -1) : endpoint;
        const url = `${baseUrl}${DS_ENDPOINTS_API.replace(':urn', this._documentUrn)}`;
        const response = await fetch(url, { headers });

        const result = await response.json();
        if (!response.ok) {
            console.warn('Could not fetch the document `endpoints`, is this a local bubble?');
            legacyEndpoint.setEndpointAndApi(null, this._api);
            return;
        }

        // we can't skip loading since FeatureFlags initialize async, i.e.,
        // there is no flag set at the moment we start loading, but now it's set
        if (!Autodesk.Viewing.Private.useDSEndpoints()) return;

        this._endpoints = result;

        legacyEndpoint._endpoints = this;

        this.#setRegionHeader();

        this._loadingTime = Number((performance.now() - loadStart).toFixed(0));
    }

    getLoadingTime() {
        return this._loadingTime ?? false;
    }

    #setRegionHeader(endpoint) {
        // Note that similar to EnvironmentConfigurations this implementation uses cdn endpoint only.
        // Developer API ("apigee") endpoint could occur:
        // - when set explicitly as a custom endpoint
        // - or by the LMV itself for non-fluent URNs with `USE_OTG_DS_PROXY !== true`.
        // Always preemptively set the region if the custom endpoint is defined.
        if (endpoint === this._endpoints.apigee.endpoint || this.hasCustomEndpoint()) {
            Autodesk.Viewing.endpoint.HTTP_REQUEST_HEADERS = this.HTTP_REQUEST_HEADERS = Object.assign(this.HTTP_REQUEST_HEADERS, this._endpoints.apigee.headers);
        }
    }

    /**
     * @returns {Promise<void>}
     */
    loadEndpoints() {
        if (!this._loadPromise) {
            this._loadPromise = this.#load();
        }
        // don't wait for endpoints if the feature is disabled
        return Autodesk.Viewing.Private.useDSEndpoints() ? this._loadPromise : Promise.resolve();
    }

    get endpoint() {
        if (!this._endpoints) {
            if (this._env !== 'Local') console.warn('No endpoints available, are they loaded?');
            return '';
        }
        const endpoint = this.#endpoint || this._endpoints.cdn.endpoint;
        // Since historically (?) LMV and thus DS endpoints define paths as absolute (with trailing "/"),
        // we have to support base URLs with and w/o "/" at the end and not to omit additional "/.." segments,
        // so that, e.g., "http://foo.com/bar/" + "/buz" results in "http://foo.com/bar/buz"
        // and not in "http://foo.com/bar//buz" (simple concat) or "http://foo.com/buz" (`new URL()` spec way).
        return endpoint.endsWith('/') ? endpoint.slice(0, -1) : endpoint;
    }

    hasCustomEndpoint() {
        return !!this.#endpoint;
    }

    /**
     * Returns the endpoint plus the API used to create REST API requests
     *
     * @example `developer.api.autodesk.com/modelderivative/v2`
     *
     * @returns {string}
     */
    getEndpointAndApi() {
        if (!this.endpoint) return '';

        if (this._api.startsWith(ENDPOINT_API_DERIVATIVE_SERVICE_V2)) {
            if (this.endpoint === this._endpoints.apigee.endpoint || this.hasCustomEndpoint()) {
                return `${this.endpoint}${this._endpoints.apigee.dsAPIs.base}`;
            }
            return `${this.endpoint}${this._endpoints.cdn.dsAPIs.base}`;
        }
        if (this._api === ENDPOINT_API_MODEL_DERIVATIVE_V2) {
            if (this.endpoint === this._endpoints.apigee.endpoint || this.hasCustomEndpoint()) {
                return `${this.endpoint}${this._endpoints.apigee.mdAPIs.base}`;
            }
            return `${this.endpoint}${this._endpoints.cdn.mdAPIs.base}`;
        }
        return `${this.endpoint}${this._endpoints.cdn.streamAPIs.base}`;
    }

    setEndpointAndApi(endpoint, api) {
        if (endpoint && endpoint !== this.#endpoint) {
            this.#endpoint = endpoint;
        }
        if (api && api !== this._api) {
            this._api = api;
        }
        this.#validateEndpointAndApi();
    }

    /**
     * Returns the endpoint used to create REST API requests
     *
     * @example `developer.api.autodesk.com`
     *
     * @returns {string}
     */
    getApiEndpoint() {
        // ! this sets the `region` header (if any) within `initLoadContext` !
        this.#setRegionHeader(this.endpoint);

        return this.endpoint;
    }

    /**
     * @returns {string}
     */
    getApiFlavor() {
        return this._api;
    }

    /**
     * Returns the default shared resource CDN location
     *
     * @example `cdn.derivative.autodesk.com/regions/eu/cdn`
     *
     * @returns {string}
     */
    getCdnUrl() {
        if (!this.endpoint) return '';

        return `${this._endpoints.cdn.endpoint}${this._endpoints.cdn.streamAPIs.cdn}`;
    }

    getCdnWebSocketEndpoint() {
        if (!this.endpoint) return '';

        return `${this._endpoints.cdn.endpoint}${this._endpoints.cdn.streamAPIs.cdnWS}`;
    }

    /**
     * Returns a REST API string to request the manifest of the provided urn
     *
     * @example `developer.api.autodesk.com/modelderivative/v2/designdata/:urn/manifest`
     *
     * @param {string|null} endpoint - When provided is used instead of the globally set endpoint
     * @param {string} urn
     * @param {string} [api] - When provided is used instead of the globally set API flavor
     * @returns {string}
     */
    getManifestApi(endpoint, urn, api) {
        if (!this.endpoint) return '';

        const _api = api || this._api;
        const _endpoint = endpoint || this.endpoint;

        this.#setRegionHeader(_endpoint);

        if (_api.startsWith(ENDPOINT_API_DERIVATIVE_SERVICE_V2)) {
            if (_endpoint === this._endpoints.apigee.endpoint || this.hasCustomEndpoint()) {
                return `${_endpoint}${this._endpoints.apigee.dsAPIs.manifest}`;
            }
            return `${_endpoint}${this._endpoints.cdn.dsAPIs.manifest}`;
        }
        if (_api === ENDPOINT_API_MODEL_DERIVATIVE_V2) {
            if (_endpoint === this._endpoints.apigee.endpoint) {
                return `${_endpoint}${this._endpoints.apigee.mdAPIs.manifest}`;
            }
            // `cdn.mdAPIs` currently contains no manifest, even though it exists.
            // Workaround until it's fixed at the backend side
            const cdnBase = this._endpoints.cdn.mdAPIs.base;
            const apigeeBase = this._endpoints.apigee.mdAPIs.base;
            return `${_endpoint}${this._endpoints.apigee.mdAPIs.manifest.replace(apigeeBase, cdnBase)}`;
        }
        return `${_endpoint}${this._endpoints.cdn.streamAPIs.manifest}`;
    }

    /**
     * Returns a REST API string to request a derivative urn
     *
     * @example: `developer.api.autodesk.com/modelderivative/v2/designdata/:urn/manifest/:derivativeUrn`
     *
     * @param {string|null} endpoint - When provided is used instead of the globally set API endpoint
     * @param {string} derivativeUrn
     * @param {string} [api] - When provided is used instead of the globally set API flavor
     * @returns {string}
     */
    getItemApi(endpoint, derivativeUrn, api) {
        if (!this.endpoint) return '';

        const _api = api || this._api;
        const _endpoint = endpoint || this.endpoint;

        this.#setRegionHeader(_endpoint);

        if (_api.startsWith(ENDPOINT_API_DERIVATIVE_SERVICE_V2)) {
            if (_endpoint === this._endpoints.apigee.endpoint || this.hasCustomEndpoint()) {
                return `${_endpoint}${this._endpoints.apigee.dsAPIs.derivative}`;
            }
            return `${_endpoint}${this._endpoints.cdn.dsAPIs.derivative}`;
        }
        if (_api === ENDPOINT_API_MODEL_DERIVATIVE_V2) {
            const cdnBase = this._endpoints.cdn.mdAPIs.base;
            const apigeeBase = this._endpoints.apigee.mdAPIs.base;
            const derivative = _endpoint === this._endpoints.apigee.endpoint
                ? this._endpoints.cdn.mdAPIs.derivative.replace(cdnBase, apigeeBase)
                : this._endpoints.cdn.mdAPIs.derivative;

            // TODO: `manifest/:derivativeurn` and `manifest/:derivativeurn/singedcookies` are two different APIs,
            // but `endpoints` API currently returns `../singedcookies` as a `derivative` endpoint of the modelderivative service.
            // Workaround until it's fixed at the backend side
            return `${_endpoint}${derivative.replace(/(\/signedcookies)$/i, '')}`;
        }
        return `${_endpoint}${this._endpoints.cdn.streamAPIs.file}`;
    }

    /**
     * Returns a REST API string to request the thumbnail for a specific urn
     *
     * @example `developer.api.autodesk.com/modelderivative/v2/designdata/:urn/thumbnail`
     *
     * @param {string | null} endpoint - When provided is used instead of the globally set endpoint.
     * @param {string} urn
     * @param {string} [api] - When provided is used instead of the globally set API flavor
     * @returns {string}
     */
    getThumbnailApi(endpoint, urn, api) {
        if (!this.endpoint) return '';

        const _api = api || this._api;
        const _endpoint = endpoint || this.endpoint;

        this.#setRegionHeader(_endpoint);

        if (_api === ENDPOINT_API_MODEL_DERIVATIVE_V2) {
            if (_endpoint === this._endpoints.apigee.endpoint) {
                return `${_endpoint}${this._endpoints.apigee.mdAPIs.thumbnail}`;
            }
            // `cdn.mdAPIs` currently contains no `thumbnail`, even though it exists.
            // Workaround until it's fixed at the backend side
            const cdnBase = this._endpoints.cdn.mdAPIs.base;
            const apigeeBase = this._endpoints.apigee.mdAPIs.base;

            return `${_endpoint}${this._endpoints.apigee.mdAPIs.thumbnail.replace(apigeeBase, cdnBase)}`;
        }
        if (_api.startsWith(ENDPOINT_API_DERIVATIVE_SERVICE_V2) && (_endpoint === this._endpoints.apigee.endpoint || this.hasCustomEndpoint())) {
            return `${_endpoint}${this._endpoints.apigee.dsAPIs.thumbnail}`;
        }
        // streaming API has no thumbnail (same was with old endpoints)
        return `${_endpoint}${this._endpoints.cdn.dsAPIs.thumbnail}`;
    }

    /**
     * Returns a REST API string to make a property query
     *
     * @example `developer.api.autodesk.com/modelderivative/v2/designdata/:urn/metadata/:guid/properties:query`
     *
     * @param {string | null} endpoint - When provided is used instead of the globally set endpoint.
     * @param {string} urn
     * @param {string|null} api - When provided is used instead of the globally set API flavor
     * @param {string} guid
     * @returns {string}
     */
    getPropertyQueryApi(endpoint, urn, api, guid) {
        if (!this.endpoint) return '';

        const _endpoint = endpoint || this.endpoint;

        this.#setRegionHeader(_endpoint);

        if (_endpoint === this._endpoints.apigee.endpoint) {
            return `${_endpoint}${this._endpoints.apigee.mdAPIs.property}`;
        }

        // `cdn.mdAPIs` currently contains no `property`, even though it exists.
        // Workaround until it's fixed at the backend side
        const cdnBase = this._endpoints.cdn.mdAPIs.base;
        const apigeeBase = this._endpoints.apigee.mdAPIs.base;

        return `${_endpoint}${this._endpoints.apigee.mdAPIs.property.replace(apigeeBase, cdnBase)}`;
    }
}
