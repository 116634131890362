import { initializeGeometryLoadedTasks } from "./GEOMETRY_LOADED/initializeTasks.js";
import { initializeFraglistLoadedTasks } from "./FRAGLIST_LOADED/initializeTasks.js";
import { initializeConsolidationComputedTasks } from "./CONSOLIDATION_COMPUTED/initializeTasks.js";
import { initializeOptimizedOnGpuTasksWebGL, initializeOptimizedOnGpuTasksWebGpu } from "./OPTIMIZED_ON_GPU/initializeTasks.js";
import { INCREMENTAL_CONSOLIDATION, USE_WEBGPU } from "../../../globals.js";

/** @import {OutOfCoreTileManagerStageConfiguration} from "../OutOfCoreTileManager.js" */
/**
 * The names of the stages
 * @enum {string}
 */
export const StageNames = {
    INITIAL: "Initial Stage",
    FRAGLIST_LOADED: "Fragment List Loaded",
    GEOMETRY_LOADED: "Geometry Loaded",
    CONSOLIDATION_COMPUTED: "Consolidation Computed",
    OPTIMIZED_ON_GPU: "On GPU (optimized)",
};

/**
 * The type of resource that is used when promoting to the next stage by the tasks in that stage
 * @enum {string}
 */
export const ResourceType = {
    NONE: "None",
    GPU_MEMORY: "Gpu Memory"
};

/**
 * Get the default stage configuration for the OutOfCoreTileManager
 * @returns {OutOfCoreTileManagerStageConfiguration[]}
 */
export const getDefaultStageConfiguration = () => {

    return [
        {
            stage: StageNames.INITIAL,
            screenSpaceErrorScalingFactor: INCREMENTAL_CONSOLIDATION ? 1 : 10,
            resource: ResourceType.NONE, // No promotion to this stage, so no resource is needed
            taskInitializationFunctions: [],
        },
        {
            stage: StageNames.FRAGLIST_LOADED,
            screenSpaceErrorScalingFactor: INCREMENTAL_CONSOLIDATION ? 1 : 10,
            resource: ResourceType.NONE,
            taskInitializationFunctions: [
                initializeFraglistLoadedTasks
            ]
        },
        {
            stage: StageNames.GEOMETRY_LOADED,
            screenSpaceErrorScalingFactor: INCREMENTAL_CONSOLIDATION ? 1 : 10,
            resource: ResourceType.NONE,
            taskInitializationFunctions: [
                initializeGeometryLoadedTasks
            ]
        },
        ...(USE_WEBGPU ?
            []
            :
            [{
                stage: StageNames.CONSOLIDATION_COMPUTED,
                screenSpaceErrorScalingFactor: 1,
                resource: ResourceType.NONE,
                taskInitializationFunctions: [
                    initializeConsolidationComputedTasks
                ]
            }]
        ),
        {
            stage: StageNames.OPTIMIZED_ON_GPU,
            screenSpaceErrorScalingFactor: 1,
            resource: ResourceType.GPU_MEMORY,
            taskInitializationFunctions: [
                USE_WEBGPU ? initializeOptimizedOnGpuTasksWebGpu : initializeOptimizedOnGpuTasksWebGL
            ]
        }
    ];
};
