import {AnyColumn, Column, CustomPropertyWrapperColumn} from "./columns";
import {AttributeDef, EMPTY_ROW_FLAG, fail, FilterTableDescriptor, isStringType} from "./common";
import type {ReadonlyFilterTable, SortConfiguration} from "./ReadonlyFilterTable";
import {FlatStringStorage} from "../../../wgs/scene/InstanceTreeStorage";
import {PropDbLoader} from "../../main/PropDbLoader";
import {PROPERTY_ACTIONS} from "../../main/WorkerActions";



export class ReadonlyFilterTableImpl implements ReadonlyFilterTable {

    #EMPTY_ROW: Array<unknown>;

    get rowCount() {
        return this.#ids.length / 2;
    }

    get columnCount() {
        return this.#attributes.length;
    }

    get rowDefinition(): AttributeDef[] {
        return this.#attributes;
    }

    #attributes: AttributeDef[];
    #ids: Uint32Array;
    #idsView: DataView;
    // Indexes the IDs array for custom sorting
    #idSearchIndex: Uint32Array;
    #columns: (AnyColumn[] | undefined)[];
    #chunkStride: number;
    #stringStorage: FlatStringStorage;

    constructor(descriptor: FilterTableDescriptor) {
        this.#initializeFromDescriptor(descriptor);
    }

    // Needed to re-initialize when sorting in worker
    #initializeFromDescriptor(descriptor: FilterTableDescriptor) {
        this.#ids = descriptor.ids;
        this.#idsView = new DataView(this.#ids.buffer);
        this.#idSearchIndex = descriptor.idSearchIndex;
        this.#chunkStride = descriptor.stride;
        const {attributes, columns} = descriptor;
        this.#attributes = attributes;
        const storage = this.#stringStorage = new FlatStringStorage(descriptor.strings);

        // Check for no columns
        this.#EMPTY_ROW = (new Array(this.#attributes.length)).fill(undefined);
        const hasNoData = !columns || columns.length === 0;
        if (hasNoData) {
            return;
        }

        const attibutesByHash = new Map();
        for (const a of attributes) {
            if (!a) continue; //empty colunm
            attibutesByHash.set(a.propertyHash, a);
        }

        const sCustomAttributes = new Set(
            descriptor.attributes.filter(it => it && it?.propType === 'Custom').map(prop => prop.propertyHash)
        );

        // instantiate one column instance for instance of attribute
        const columnsByHash = new Map();

        for (const column of columns) {
            const isCustom = sCustomAttributes.has(column.hash);

            columnsByHash.set(
                column.hash,
                column.descriptors.map(desc => {

                        const chunk = Column.fromWireType(
                            this.#ids.length / 2,
                            attibutesByHash.get(column.hash),
                            desc,
                            storage);

                        return isCustom ? CustomPropertyWrapperColumn.wrap(chunk) : chunk;

                    }
                )
            );
        }

        this.#columns = attributes.map(a => columnsByHash.get(a?.propertyHash));
    }

    idAt(index: number) {
        return this.#ids[index * 2];
    }

    containsObjectId(objectId: number) {
        return this.indexOf(objectId) >= 0;
    }

    // TODO there is a bug with this: If the row is blank it will return 2,147,... because we don't encode the index alongside the flag
    // Need to have both in those fields and extract the "synthetic" index for this API call
    indexOf(objectId: number) {
        if (objectId < 0) return -1;
        return this.#binarySearch(objectId);
    }

    getRowById(objectId: number) {
        return this.getRow(this.indexOf(objectId));
    }

    getRow(index: number): unknown[] | null {
        if (index < 0 || index >= this.rowCount) return null;
        if (!this.#columns) return this.#EMPTY_ROW;

        // Check for empty row flag
        if (this.isEmpty(index)) return this.#EMPTY_ROW;

        index = this.#ids[index * 2 + 1]; // map to internal index

        const chunkIndex = Math.floor(index / this.#chunkStride);
        const subIndex = index % this.#chunkStride;

        const result = new Array(this.#attributes.length);

        for (let i = 0; i < result.length; i++) {
            const column = this.#columns[i];
            if (!column) {
                result[i] = undefined;
            } else {
                const colChunk: AnyColumn = column[chunkIndex];
                result[i] = colChunk.getValueAt(subIndex);
            }
        }

        return result;
    }

    getValueAt(rowIndex: number, columnIndex: number): unknown {
        if (rowIndex < 0 || rowIndex >= this.rowCount) return undefined;
        if (!this.#columns) return this.#EMPTY_ROW;

        // Check for empty row flag
        if (this.isEmpty(rowIndex)) return undefined;

        rowIndex = this.#ids[rowIndex * 2 + 1]; // map to internal index

        const column = this.#columns[columnIndex];
        if (!column) {
            return undefined;
        } else {
            const chunkIndex = Math.floor(rowIndex / this.#chunkStride);
            const subIndex = rowIndex % this.#chunkStride;
            const colChunk: AnyColumn = column[chunkIndex];
            return colChunk?.getValueAt(subIndex);
        }
    }

    async orderByAsync(propertyDb: PropDbLoader, configuration: (AttributeDef | SortConfiguration)[]) {
        if (!configuration.length) return;
        if (typeof window !== 'undefined' && self === window) {
            const result: any = await new Promise((res, rej) => {
                const descriptor = this.toWireType();
                const buffers = descriptor.buffers;
                delete descriptor.buffers;
                propertyDb.asyncPropertyOperation({
                    operation: PROPERTY_ACTIONS.SORT_FILTER_TABLE,
                    descriptor,
                    configuration
                }, res, rej, () => null, buffers);
            });
            this.#initializeFromDescriptor(result.descriptor);
            return;
        }

        const sortingConfig: SortConfiguration[] = configuration.map(config => {
            if (typeof (<AttributeDef>config).propertyHash === 'string') {
                return <SortConfiguration>{attr: config, descending: false};
            } else {
                return <SortConfiguration>config;
            }
        });

        const configToColIdx = new Map(sortingConfig.map(config => [
            config,
            this.#attributes.indexOf(<any>this.#attributes.find(attr => attr.propertyHash === config.attr.propertyHash)
                ?? fail(`No such attribute ${JSON.stringify(config ?? '')}`))
        ]));


        // is inside worker thread
        this.#sortInternal((aId, aIdx, bId, bIdx) => {
            for (const config of sortingConfig) {
                const colIdx = <number>configToColIdx.get(config);
                const aVal = config.descending ? this.#getColumnValue(colIdx, bIdx) : this.#getColumnValue(colIdx, aIdx);
                const bVal = config.descending ? this.#getColumnValue(colIdx, aIdx) : this.#getColumnValue(colIdx, bIdx);
                if (aVal === bVal) continue;
                if (isStringType(config.attr)) {
                    return aVal
                        ? (<string>aVal)?.localeCompare(<string>bVal)
                        : (config.descending ? 1 : -1); // handles nulls & asc/desc
                } else {
                    return <number>aVal - <number>bVal;
                }
            }
            return 0;
        });
    }

    // For sorting when whole column doesn't have to be read
    #sortInternal(compareFn: (aId: number, aIdx: number, bId: number, bIdx: number) => number): this {
        if (!compareFn) return this;

        const doubleSort = new BigUint64Array(this.#ids.buffer);
        const register = new BigUint64Array(1);
        const registerView = new DataView(register.buffer);

        doubleSort.sort((a, b) => {
            registerView.setBigUint64(0, a, true);
            const aId = registerView.getUint32(0, true);
            const aIndex = registerView.getUint32(4, true);
            registerView.setBigUint64(0, b, true);
            const bId = registerView.getUint32(0, true);
            const bIndex = registerView.getUint32(4, true);

            return compareFn(aId, aIndex, bId, bIndex);
        });

        return this;
    }

    #getColumnValue(columnIdx: number, rowIdx: number): unknown {
        if (!this.#columns[columnIdx]) return;
        const chunkIndex = Math.floor(rowIdx / this.#chunkStride);
        const subIndex = rowIdx % this.#chunkStride;
        const col = (<AnyColumn[]>this.#columns[columnIdx])[chunkIndex];
        return col.getValueAt(subIndex);
    }

    sort(compareFn: (a: [number, unknown[]], b: [number, unknown[]]) => number): this {
        if (!compareFn) return this;

        const doubleSort = new BigUint64Array(this.#ids.buffer);
        const register = new BigUint64Array(1);
        const registerView = new DataView(register.buffer);

        doubleSort.sort((a, b) => {
            registerView.setBigUint64(0, a, true);
            const aId = registerView.getUint32(0, true);
            const aIndex = registerView.getUint32(4, true);
            registerView.setBigUint64(0, b, true);
            const bId = registerView.getUint32(0, true);
            const bIndex = registerView.getUint32(4, true);

            const rowA = <unknown[]>this.getRow(aIndex);
            const rowB = <unknown[]>this.getRow(bIndex);

            return compareFn([aId, rowA], [bId, rowB]);
        });

        this.#updateSearchIndex();

        return this;
    }

    enumIds(cb: (id: number, index: number) => boolean, rowsWithValuesOnly = false) {
        for (let i = 0; i < this.rowCount; i++) {
            if (rowsWithValuesOnly && this.isEmpty(i)) continue;
            if (cb(this.#ids[i * 2], i)) return;
        }
    }

    isEmpty(index: number) {
        return !!(this.#idsView.getUint8(index * 8 + 7) & EMPTY_ROW_FLAG);
    }

    #updateSearchIndex() {
        for (let i = 0; i < this.#ids.length / 2; i++) {
            this.#idSearchIndex[i * 2] = this.#ids[i * 2];
            this.#idSearchIndex[i * 2 + 1] = i;
        }

        const doubleSort = new BigUint64Array(this.#idSearchIndex.buffer);
        const register = new BigUint64Array(1);
        const registerView = new DataView(register.buffer);

        doubleSort.sort((a, b) => {
            registerView.setBigUint64(0, a, true);
            const aId = registerView.getUint32(0, true);
            registerView.setBigUint64(0, b, true);
            const bId = registerView.getUint32(0, true);
            return aId - bId;
        });
    }

    #binarySearch(id: number) {
        let left = 0;
        let right = (this.#idSearchIndex.length / 2) - 1;

        while (left <= right) {
            const mid = Math.floor((left + right) / 2);
            const midIndex = mid * 2;
            const midValue = this.#idSearchIndex[midIndex];

            if (midValue === id) {
                return this.#idSearchIndex[midIndex + 1];
            } else if (midValue < id) {
                left = mid + 1;
            } else {
                right = mid - 1;
            }
        }

        return -1;
    }

    _byteCount() {
        if (!this.#columns) return this.#EMPTY_ROW.length * 2;

        let byteCount = 0;
        byteCount += this.#stringStorage.buf.byteLength;
        byteCount += this.#stringStorage.idx.byteLength;
        for (const chunks of this.#columns.values()) {
            if (!chunks) continue;
            for (const column of chunks) {
                byteCount += column.byteCount();
            }
        }
        return byteCount;
    }

    toWireType(): FilterTableDescriptor {
        const {buf, idx, next} = this.#stringStorage;

        const uniqueHashes = new Set();
        this.#attributes.forEach(a => a && uniqueHashes.add(a.propertyHash));
        const hashToColumn = new Map<string, AnyColumn[]>();
        for (let i = 0; i < this.#attributes.length; i++) {
            const attr = this.#attributes[i];
            if (attr && !hashToColumn.has(attr.propertyHash)) {
                hashToColumn.set(attr.propertyHash, <AnyColumn[]>this.#columns[i]);
            }
        }


        const result: FilterTableDescriptor =  {
            attributes: this.#attributes,
            ids: this.#ids,
            idSearchIndex: this.#idSearchIndex,
            columns: [...hashToColumn.entries()].map(([hash, colChunks]) => ({ hash, descriptors: colChunks.map(it => it.toWireType())})),
            stride: this.#chunkStride,
            strings: {buf, idx, next},
            buffers: []
        };

        result.buffers = result.columns.map(it => it.descriptors).flat(2).map(x => (<any>x).buffer).filter(it => it);

        // Special case: if no buffers after flattening columns, just make the columns an empty array
        // No columns but attributes present means it was a fully empty filter, but with IDs present
        if (result.buffers.length === 0) {
            result.columns = [];
        }

        result.buffers.push(buf.buffer);
        result.buffers.push(idx.buffer);
        result.buffers.push(result.ids.buffer);
        result.buffers.push(result.idSearchIndex.buffer);

        return result;
    }

    static getStorage(table: ReadonlyFilterTableImpl): unknown {
        return {
            columns: table.#columns,
            ids: table.#ids,
        };
    }
}