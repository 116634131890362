import {$wgsl} from "../../wgsl-preprocessor/wgsl-preprocessor";
import {UniformBuffer} from "../UniformBuffer";
import {initTexture} from "../Texture";
import {MipmapPipeline} from "./MipmapPipeline";
import materialUniforms from "./material_uniforms.wgsl";

function makeTextureBinding(binding = 0, visibility = GPUShaderStage.FRAGMENT, sampleType = "float") {
    return {
        binding,
        visibility,
        texture: {
            sampleType,
        }
    };
}

function makeSamplerBinding(binding = 0, visibility = GPUShaderStage.FRAGMENT ) {
    return {
        binding,
        visibility,
        sampler: {}
    };
}

export function getMaterialUniformsDeclaration(bindGroup = 0) {
    return $wgsl(materialUniforms, { bindGroup });
}

export const MaterialUniformFlags = {
    NO_UNIFORMS: 0x10000000,

    TEXTURE_MASK: 0x000000ff,
    MAP_DIFFUSE: 1,
    MAP_SPECULAR: 2,
    MAP_BUMP: 4,
    MAP_NORMAL: 8,
    MAP_ALPHA: 16,
};

const _tmpTexMatrix = new Float32Array(9);

export class MaterialUniforms extends UniformBuffer {

    #device;
    #defaultTexture;
    #layout;
    #bindGroup;
    #mipmapPipeline;

    constructor(device, material, defaultTexture) {
        // buffer size in floats
        let bufferSize = 40; // texture uniforms
        super(device, bufferSize, true, false);

        this.#device = device;
        this.#defaultTexture = defaultTexture;

        this.#layout = device.createBindGroupLayout({
            label: "material bind group layout",
            entries: [
                {
                    binding: 0,
                    visibility: GPUShaderStage.FRAGMENT | GPUShaderStage.VERTEX,
                    buffer: {}
                },
                makeTextureBinding(1),
                makeSamplerBinding(2),
                makeTextureBinding(3),
                makeSamplerBinding(4),
                makeTextureBinding(5),
                makeSamplerBinding(6),
                makeTextureBinding(7),
                makeSamplerBinding(8)
            ]
        });

        if (material) {
            if (!this.#mipmapPipeline) {
                this.#mipmapPipeline = new MipmapPipeline(device);
            }

            this.#initFromMaterial(material);
        } else {
            this.#initEmpty();
        }
    }

    #initEmpty() {

        let view = this.#defaultTexture.view;
        let smpl = this.#defaultTexture.sampler;

        this.#bindGroup = this.#device.createBindGroup({
            layout: this.#layout,
            entries: [
                {
                    binding: 0,
                    resource: {
                        buffer: this.getBuffer(),
                    }
                },
                { binding: 1, resource: view },
                { binding: 2, resource: smpl },
                { binding: 3, resource: view },
                { binding: 4, resource: smpl },
                { binding: 5, resource: view },
                { binding: 6, resource: smpl },
                { binding: 7, resource: view },
                { binding: 8, resource: smpl },
            ]
        });


    }

    #setTexTransforms(texture, uOffset) {

        let offset = texture.offset;
        let repeat = texture.repeat;

        if (texture.matrix) {
            _tmpTexMatrix.set(texture.matrix.elements);
        } else {
            for (let i = 0; i < 9; i++)
                _tmpTexMatrix[i] = (i % 4 ? 0 : 1);
        }

        _tmpTexMatrix[6] += offset.x;
        _tmpTexMatrix[7] += offset.y;
        _tmpTexMatrix[0] *= repeat.x;
        _tmpTexMatrix[3] *= repeat.x;
        _tmpTexMatrix[1] *= repeat.y;
        _tmpTexMatrix[4] *= repeat.y;

        this.setMatrix3x3(uOffset, _tmpTexMatrix);
    }


    #initFromMaterial(material) {

        let mapsHash = 0;

        let entries = [
            {
                binding: 0,
                resource: {
                    buffer: this.getBuffer(),
                },
            }
        ];


        const addMap = (name, bit, binding) => {
            let mapView, mapSampler;

            let texture = material[name];
            if (texture) {
                initTexture(this.#device, this.#mipmapPipeline, texture);
                mapView = texture.__gpuTexture.createView();
                mapSampler = texture.__gpuSampler;
                mapsHash |= bit;
            } else {
                mapView = this.#defaultTexture.view;
                mapSampler = this.#defaultTexture.sampler;
            }

            entries.push({ binding: binding, resource: mapView });
            entries.push({ binding: binding + 1, resource: mapSampler });
        };

        addMap("map", MaterialUniformFlags.MAP_DIFFUSE, 1);
        addMap("specularMap", MaterialUniformFlags.MAP_SPECULAR, 3);
        if (material.bumpMap) {
            addMap("bumpMap", MaterialUniformFlags.MAP_BUMP, 5);
        } else {
            addMap("normalMap", MaterialUniformFlags.MAP_NORMAL, 5);
        }
        addMap("alphaMap", MaterialUniformFlags.MAP_ALPHA, 7);

        let texForMatrix = material.map || material.specularMap;
        if (texForMatrix) {
            this.#setTexTransforms(texForMatrix, 0);
        }

        let texForBumpMatrix = material.normalMap || material.bumpMap;
        if (texForBumpMatrix) {
            this.#setTexTransforms(texForBumpMatrix, 12);
        }

        let texForAlphaMatrix = material.alphaMap;
        if (texForAlphaMatrix) {
            this.#setTexTransforms(texForAlphaMatrix, 24);
        }

        if (material.bumpMap) {
            this.setFloat(36, material.bumpScale);
            this.setFloat(37, material.bumpScale);
        }

        if (material.normalMap) {
            this.setFloat(36, material.normalScale.x);
            this.setFloat(37, material.normalScale.y);
        }

        this.upload();

        this.#bindGroup = this.#device.createBindGroup({
            layout: this.#layout,
            entries
        });

        return mapsHash;
    }

    getLayout() {
        return this.#layout;
    }

    getBindGroup() {
        return this.#bindGroup;
    }

}

export function getMaterialTextureMask(material) {

    let mask = 0;

    if (material.map) mask |= MaterialUniformFlags.MAP_DIFFUSE;
    if (material.specularMap) mask |= MaterialUniformFlags.MAP_SPECULAR;
    if (material.bumpMap) mask |= MaterialUniformFlags.MAP_BUMP;
    if (material.normalMap) mask |= MaterialUniformFlags.MAP_NORMAL;
    if (material.alphaMap) mask |= MaterialUniformFlags.MAP_ALPHA;

    return mask;
}

function materialDisposeHandler(event) {
    const material = event.target;
    if (material.__gpuMaterialUniforms) {
        material.__gpuMaterialUniforms.getBuffer().destroy();
        // TODO: Do we need to destroy more resources? Textures?
        material.__gpuMaterialUniforms = undefined;
    }

    material.removeEventListener('dispose', materialDisposeHandler);
}

let materialTextureUpdateCallback;
export function setMaterialTextureUpdateCallback(callback) {
    materialTextureUpdateCallback = callback;
}

export function initMaterialBindings(device, material, defaultTexture) {

    let mask = getMaterialTextureMask(material);

    if (mask !== 0) {
        if (material.__gpuMaterialUniforms) {
            materialDisposeHandler({ target: material });
        }

        const mu = new MaterialUniforms(device, material, defaultTexture);
        material.__gpuMaterialUniforms = mu;

        material.addEventListener('dispose', materialDisposeHandler);
    }

    if (materialTextureUpdateCallback) {
        material.addEventListener('update', materialTextureUpdateCallback);
    }

    material.__gpuUniformsMask = mask = (mask | MaterialUniformFlags.NO_UNIFORMS);
    return mask;
}
