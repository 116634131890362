
/**
 * The depth format, compare function, and clear values are exported as constants intentionally to facilitate the
 * specification of WebGPU layouts without requiring access to the actual depth(-stencil) render target.
 */
export const DepthFormat = 'depth32float'; // 'depth24plus', 'depth16unorm'
export const DepthCompare = 'greater';
export const DepthClearValue = 0.0;

export function invertDepthCompare(depthCompare) {
    switch (depthCompare) {
        case 'less': return 'greater';
        case 'greater': return 'less';
        case 'less-equal': return 'greater-equal';
        case 'greater-equal': return 'less-equal';
        default:
    }
    return depthCompare;
}


export const NumIdTargets = 2;

export const NormalBlend = {
    color: {
        operation: 'add',
        srcFactor: 'src-alpha',
        dstFactor: 'one-minus-src-alpha'
    },
    alpha: {
        operation: 'add',
        srcFactor: 'one',
        dstFactor: 'one-minus-src-alpha'
    }
};


export class CommonRenderTargets {

    #renderer;
    #device;

    #colorTarget;
    #depthTarget;
    #normalsTarget;
    #viewDepthTarget;
    #overlayTarget;
    #idTargets;
    #postTargets;

    #colorTargetView;

    #size = [0, 0];

    #presentationFormat;
    #colorTargetFormat;

    #readBuffer;
    #cachedWholeBuffers;
    #cachedIdBuffersDirty = true;
    #cacheInProgress = false;

    #targetsList; #targetsListEdge; #targetsListOverlay;

    /** @import { RenderContextWebGPU } from './RenderContextWebGPU' */

    /**
     * External callback to notify when it's safe to do sync idBuffer reads. Called with true on success,
     * with false on failure (may happen readback is invalidated by a resize).
     * @see RenderContextWebGPU.waitForIdReadback
     * @type {(null | (boolean) => void)}
     */
    onIdReadbackCacheFinished = null;

    constructor(renderer) {
        this.#renderer = renderer;

    }

    init(useHdrTarget) {
        this.#device = this.#renderer.getDevice();
        this.#presentationFormat = navigator.gpu.getPreferredCanvasFormat();
        if (useHdrTarget) {
            this.#colorTargetFormat = 'rgba16float';
        } else {
            this.#colorTargetFormat = this.#presentationFormat;
        }

    }

    cleanup() {
        this.#depthTarget?.destroy();
        this.#colorTarget?.destroy();
        this.#overlayTarget?.destroy();
        this.#normalsTarget?.destroy();
        this.#viewDepthTarget?.destroy();
        this.#idTargets?.forEach(t => t?.destroy());
        this.#postTargets?.forEach(t => t?.destroy());

        this.#depthTarget = null;
        this.#colorTarget = null;
        this.#overlayTarget = null;
        this.#normalsTarget = null;
        this.#idTargets = [];
        this.#postTargets = [];
    }

    resize(w, h) {

        // Avoid reallocation if size didn't change
        if (this.#size[0] == w && this.#size[1] == h) {
            return;
        }

        this.#targetsList = null;
        this.#targetsListEdge = null;
        this.#targetsListOverlay = null;

        this.#size[0] = w;
        this.#size[1] = h;

        this.#cachedWholeBuffers = null;

        this.cleanup();

        if (w === 0 || h === 0) {
            return;
        }

        this.#colorTarget = this.#device.createTexture({
            label: 'color-target',
            size: [w, h],
            format: this.#colorTargetFormat, //TODO: always use half float for better HDR? they take the same space as 8unorm
            usage: GPUTextureUsage.RENDER_ATTACHMENT | GPUTextureUsage.TEXTURE_BINDING | GPUTextureUsage.COPY_SRC,
        });

        this.#colorTargetView = this.#colorTarget.createView();

        this.#depthTarget = this.#device.createTexture({
            label: 'depth-target',
            size: [w, h],
            format: DepthFormat,
            usage: GPUTextureUsage.RENDER_ATTACHMENT | GPUTextureUsage.TEXTURE_BINDING,
        });

        this.#overlayTarget = this.#device.createTexture({
            label: 'overlay-target',
            size: [w, h],
            format: this.#presentationFormat,
            usage: GPUTextureUsage.RENDER_ATTACHMENT | GPUTextureUsage.TEXTURE_BINDING,
        });

        this.#normalsTarget = this.#device.createTexture({
            label: 'normals-target',
            size: [w, h],
            format: 'rgb10a2unorm',
            usage: GPUTextureUsage.RENDER_ATTACHMENT | GPUTextureUsage.TEXTURE_BINDING,
        });

        this.#viewDepthTarget = this.#device.createTexture({
            label: 'view-depth-target',
            size: [w, h],
            format: 'rgb10a2unorm',
            usage: GPUTextureUsage.RENDER_ATTACHMENT | GPUTextureUsage.TEXTURE_BINDING,
        });

        this.#idTargets = new Array(NumIdTargets);
        for (let i = 0; i < this.#idTargets.length; i++) {
            let idt = this.#device.createTexture({
                label: `id-target-${i}`,
                size: [w, h],
                format: 'rgba8uint',
                usage: GPUTextureUsage.RENDER_ATTACHMENT | GPUTextureUsage.TEXTURE_BINDING | GPUTextureUsage.COPY_SRC,
            });
            this.#idTargets[i] = idt;
        }

        this.#postTargets = [null, null];
        for (let i = 0; i < this.#postTargets.length; i++) {
            let idt = this.#device.createTexture({
                label: `post-target-${i}`,
                size: [w, h],
                format: this.#presentationFormat,
                usage: GPUTextureUsage.RENDER_ATTACHMENT | GPUTextureUsage.TEXTURE_BINDING,
            });
            this.#postTargets[i] = idt;
        }
    }

    getPreferredFormat() {
        return this.#presentationFormat;
    }

    getColorTarget() {
        return this.#colorTarget;
    }

    getNormalsTarget() {
        return this.#normalsTarget;
    }

    getDepthTarget() {
        return this.#depthTarget;
    }

    getViewDepthTarget() {
        return this.#viewDepthTarget;
    }

    getOverlayTarget() {
        return this.#overlayTarget;
    }

    getIdTarget(index) {
        return this.#idTargets[index];
    }

    getPostTarget(index) {
        return this.#postTargets[index];
    }

    getTargetViewsForBlend() {
        let res = [
            this.#colorTarget.createView(),
            this.#overlayTarget.createView(),
        ];

        for (let i = 0; i < NumIdTargets; i++) {
            res.push(this.#idTargets[i].createView());
        }

        return res;
    }

    getColorTargetView() {
        return this.#colorTargetView;
    }

    getTargetSize() {
        return this.#size;
    }

    getTargetsListMainPass() {

        if (!this.#targetsList) {

            this.#targetsList = [
                {
                    format: this.#colorTarget.format,
                    blend: NormalBlend
                },
                {
                    format: this.#normalsTarget.format,
                    blend: NormalBlend
                },
                {
                    format: this.#viewDepthTarget.format,
                    blend: NormalBlend
                },
            ];

            for (let i = 0; i < NumIdTargets; i++) {
                this.#targetsList.push({
                    format: this.#idTargets[i].format
                });
            }
        }

        return this.#targetsList;
    }

    getTargetsListEdgePass() {

        if (!this.#targetsListEdge) {
            this.#targetsListEdge = [
                {
                    format: this.#colorTarget.format,
                    blend: NormalBlend
                }
            ];
        }

        return this.#targetsListEdge;
    }

    getOverlayTargetsList() {

        if (!this.#targetsListOverlay) {
            this.#targetsListOverlay = [
                {
                    format: this.#colorTarget.format,
                    blend: NormalBlend
                }
            ];
        }

        return this.#targetsListOverlay;
    }


    //Async reading from render targets. This is a better approach than the simulated
    //synchronous reading in readIdTargetPixelsSyncOrFail, and we need to transition
    //APIs like idAtPixel() to use this approach in the long term. This will require
    //adjusting tools that use mouse rollover to use Promises rather than expecting
    //the result directly.
    async readIdTargetPixelsAsync(x, y, width, height, bufs) {
    //TODO: This is just a PoC, for a robust implementation we will
    //need to read chunks of data from the target, in e.g. tiles
    //so that we optimize the number of reads (done on mouse move)
    //compared to amount of data transfer and duplicate memory usage

        function copyBuffers(dst, src, width, height, srcStride) {

            const buf1 = dst[0];
            const buf2 = dst[1];
            const targetDataOffset = srcStride * height;

            for (let j = 0; j < height; j++) {

                let hOffSrc = srcStride * j;
                let hOffSrc2 = hOffSrc + targetDataOffset;
                let hOffDst = width * j;

                for (let i = 0; i < width; i++) {
                    buf1[hOffDst] = src[hOffSrc++];
                    buf2[hOffDst] = src[hOffSrc2++];
                    hOffDst++;
                }
            }
        }


        if (this.#readBuffer?.mapState === 'pending') {
            return;
        }

        let bytesPerRow = width * 4;
        let remainder = bytesPerRow % 256;
        if (remainder > 0) {
            bytesPerRow += 256 - remainder;
        }

        let bufferSize = bytesPerRow * height;
        if (!this.#readBuffer || this.#readBuffer.size < bufferSize * 2) {
            this.#readBuffer?.destroy();

            this.#readBuffer = this.#device.createBuffer({
                size: bufferSize * 2,
                usage: GPUBufferUsage.COPY_DST | GPUBufferUsage.MAP_READ,
            });
        }

        let commandEncoder = this.#device.createCommandEncoder();

        commandEncoder.copyTextureToBuffer(
            { texture: this.#idTargets[0], origin: [x, y] },
            { buffer: this.#readBuffer, offset: 0, bytesPerRow: bytesPerRow },
            [width, height]
        );

        commandEncoder.copyTextureToBuffer(
            { texture: this.#idTargets[1], origin: [x, y] },
            { buffer: this.#readBuffer, offset: bufferSize, bytesPerRow: bytesPerRow },
            [width, height]
        );

        this.#device.queue.submit([commandEncoder.finish()]);

        return this.#readBuffer.mapAsync(GPUMapMode.READ, 0).then(() => {
            let range = new Uint8Array(this.#readBuffer.getMappedRange());
            copyBuffers(bufs, range, width * 4, height, bytesPerRow);

            //let test = new Int32Array(bufs[0].buffer, bufs[0].byteOffset);
            //let dbId = test[0];
            //console.log(dbId);
            this.#readBuffer.unmap();
        });
    }

    async #cacheWholeIdTargets() {

        this.#cacheInProgress = true;

        let [width, height] = this.#size;

        let bytesPerRow = width * 4;
        let remainder = bytesPerRow % 256;
        if (remainder > 0) {
            bytesPerRow += 256 - remainder;
        }

        let bufferSize = bytesPerRow * height;
        if (!this.#readBuffer || this.#readBuffer.size < bufferSize * 2) {
            this.#readBuffer?.destroy();

            this.#readBuffer = this.#device.createBuffer({
                size: bufferSize * 2, //one for dbIds and one for modelIds buffers
                usage: GPUBufferUsage.COPY_DST | GPUBufferUsage.MAP_READ,
            });
        }

        let commandEncoder = this.#device.createCommandEncoder();

        commandEncoder.copyTextureToBuffer(
            { texture: this.#idTargets[0], origin: [0, 0] },
            { buffer: this.#readBuffer, offset: 0, bytesPerRow: bytesPerRow },
            [width, height]
        );

        commandEncoder.copyTextureToBuffer(
            { texture: this.#idTargets[1], origin: [0, 0] },
            { buffer: this.#readBuffer, offset: bufferSize, bytesPerRow: bytesPerRow },
            [width, height]
        );

        this.#device.queue.submit([commandEncoder.finish()]);

        return this.#readBuffer.mapAsync(GPUMapMode.READ, 0).then(async () => {

            // If buffer was resized during async readback, the result is worthless.
            const wasResized = width !== this.#size[0] || height !== this.#size[1];
            if(wasResized) {
                this.#readBuffer.unmap();

                // Set in-progress back to false, but don't clear the dirty flag
                this.#cacheInProgress = false;

                // Indicate that readback was not successful. In theory, we could auto-restart
                // here, but we don't want to introduce a risk of infinite loop or hidden waste (e.g. on resize-animations).
                // Note that this callback is currently null and only used for tests anyway.
                if(this.onIdReadbackCacheFinished) {
                    this.onIdReadbackCacheFinished(false);
                }

                return;
            }

            let range = new Uint8Array(this.#readBuffer.getMappedRange());

            if (!this.#cachedWholeBuffers) {
                this.#cachedWholeBuffers = new Uint8Array(range.byteLength);
            }

            this.#cachedWholeBuffers.set(range);

            this.#readBuffer.unmap();

            this.#cachedIdBuffersDirty = false;
            this.#cacheInProgress = false;

            // Notify sync readback works until next invalidation
            if (this.onIdReadbackCacheFinished) {
                this.onIdReadbackCacheFinished(true);
            }
        });
    }

    //Hacky implementation of reading from a render target that is meant to work
    //with the synchronous idAtPixel() API that is used for mouse rollover.
    //Initial attempt will trigger an operation to read the entire ID buffer
    //to CPU memory, and subsequent attempts will return result immediately
    //Ideally we will transition all code that needs to read back buffers to use async/Promise
    //way
    /**
     * @param {number} x, y, width, height - region to read
     * @param {Uint8Array} [bufs] - 2 Buffers [objectIdBuf, modelIdBuf] read into.
     * 								May be omitted for a "dry run" to only check if cache is available without reading.
     * @returns {boolean} - true if cache data was available.
     */
    readIdTargetPixelsSyncOrFail(x, y, width, height, bufs) {

        const copyBuffers = (dst, src, width, height, srcStride, srcX, srcY) => {

            const buf1 = dst[0];
            const buf2 = dst[1];
            const targetDataOffset = srcStride * this.#size[1];
            const startX = srcX || 0;
            const startY = srcY || 0;

            for (let j = 0; j < height; j++) {

                let hOffSrc = srcStride * (j + startY) + startX * 4;
                let hOffSrc2 = hOffSrc + targetDataOffset;
                let hOffDst = width * j;

                for (let i = 0; i < width; i++) {
                    buf1[hOffDst] = src[hOffSrc++];
                    buf2[hOffDst] = src[hOffSrc2++];
                    hOffDst++;
                }
            }
        };

        function setZero() {
            if (!bufs) {
                return;
            }
            for (let i = 0; i < bufs.length; i++) {
                bufs[i].fill(0);
            }
        }

        if (this.#readBuffer?.mapState === 'pending') {
            setZero();
            return false;
        }

        if (this.#cacheInProgress) {
            setZero();
            return false;
        }

        if (this.#cachedIdBuffersDirty) {
            setZero();
            this.#cacheWholeIdTargets();
            return false;
        }

        let bytesPerRow = this.#size[0] * 4;
        let remainder = bytesPerRow % 256;
        if (remainder > 0) {
            bytesPerRow += 256 - remainder;
        }

        bufs && copyBuffers(bufs, this.#cachedWholeBuffers, width * 4, height, bytesPerRow, x, y);

        // Note that we also return true if bufs are not provided, because the return value
        // is only used to indicate whether the cache was available.
        return true;
    }


    setIdTargetsDirty() {
        this.#cachedIdBuffersDirty = true;
    }

}
