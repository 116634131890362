import { OutOfCoreTaskBase } from '../OutOfCoreTaskBase';
import { createConsolidationMapForNode, applyInstancing } from "../../../consolidation/NodeConsolidation";
import { needToWaitForGeometry } from "../../../consolidation/Consolidation";

export class ConsolidationMapTask extends OutOfCoreTaskBase {
    /**
     * Creates a new task to update consolidation readiness
     *
     * @param {OutOfCoreTileManager} outOfCoreTileManager - The OutOfCoreTileManager instance
     * @param {number} bvhNodeId - The BVH node ID
     */
    constructor(outOfCoreTileManager, nodeId) {
        super(outOfCoreTileManager);
        this.nodeId = nodeId;
    }

    async execute() {
        const model = this.outOfCoreTileManager.model;
        // For non SVF2 models we have to wait for all geometry to be loaded
        if (needToWaitForGeometry(model)) {
            const options = { geometry: true, propDb: false, textures: false, hidden: true, onlyModels: model };
            await model.loader.viewer3DImpl.api.waitForLoadDone(options);
        }

        const matman = model.loader.viewer3DImpl.matman();

        let consolidation;
        const bvh = model.getIterator().getBVH();
        const consMap = createConsolidationMapForNode(model, bvh, this.nodeId, bvh.bvhOptions?.consolidation_polycount_limit);
        consolidation = consMap.buildConsolidation(model.getFragmentList(), matman, model, true);

        // Apply instancing to all remaining fragments that were not consolidated yet
        applyInstancing(model, matman, consolidation);

        model._setConsolidation(this.nodeId, consolidation);
    }

    undo() {
        this.outOfCoreTileManager.model._setConsolidation(this.nodeId, null);
    }
}
