import { mergeGeometries } from './ConsolidationCommon';
import { InstanceBufferBuilder } from './InstanceBufferBuilder';
import THREE from "three";
import { MeshFlags } from "../MeshFlags";
import { MATERIAL_VARIANT } from "../../render/MaterialManager";
import { ConsolidatedRenderBatch } from '../RenderBatch';
import { StageNames } from '../out-of-core-tile-manager/tasks/defaultStageConfiguration';
import { RenderFlags } from "../RenderFlags";

/** @import { ModelIteratorBVH } from "../ModelIteratorBVH" */
/** @import { Consolidation } from "./Consolidation" */
/** @import { MaterialManager } from ""../../render/MaterialManager" */

/**
 * NodeConsolidationIterator is used by RenderModel to replace fragments of a BVH Node by optimized representations.
 *
 * Instead of rendering each fragment individually, these optimized representations use consolidation and hardware
 * instancing to batch many fragments together to reduce the number of draw calls and improve rendering performance.
 *
 * We try to minimize CPU overhead by avoiding per fragment operations each frame. Culling is done beforehand on the BVH level
 * and per fragment rendering customizations like visibility changes/ghosting, theming and regular selection are evaluated in
 * the shader.
 *
 * @constructor
 * @param {FragmentList}  fragList - Original fragment list
 * @param {ModelIteratorBVH} bvhModelIterator - The BVH model iterator.
 * @param {MaterialManager} materialManager - The material manager
 * @param {Consolidation[]} consolidations - Array mapping bvh node ids to per node consolidations.
 */
export function NodeConsolidationIterator(fragList, bvhModelIterator, materialManager, consolidations = []) {
    let _bvhModelIterator = bvhModelIterator;
    // FragmentList
    var _frags = fragList;

    var _matman = materialManager;

    // Out of Core Tile Manager
    let _outOfCoreTileManager = bvhModelIterator.getOutOfCoreTileManager();
    _outOfCoreTileManager?.activateIterator(_bvhModelIterator.getIteratorOutOfCoreManagerId(), _bvhModelIterator);

    // If true, we must use original fragments in the current traversal. This flag is determined at the beginning
    // of a traversal and is set as long as a fragment has moved away from its original position.
    var _consolidationDisabled = false;

    /**
     * Stores a ConsolidatedRenderBatch for each BVH under its node index. It is returned instead of the original RenderBatch.
     * @type {ConsolidatedRenderBatch[]}
     */
    const _consolidatedRenderBatchCache = [];

    // some reused temp objects
    var _tempMatrix = new THREE.Matrix4();
    var _tempBox = new THREE.Box3();

    const _consolidations = consolidations;

    // Apply optional model matrix. Note:
    //  - We assume here that the consolidation was computed using the original fragment matrices without considering a model transform.
    //  - We cannot set the modelTransform on the meshes themselves: Reason is that single-fragment meshes still have the transform of the original fragment
    this.applyModelMatrix = function(consolidatedRenderBatch) {
        const modelMatrix = _frags.matrix;
        const scene = consolidatedRenderBatch.consolidatedScene;
        if (modelMatrix) {
            scene.matrixWorld.copy(modelMatrix);
            scene.matrixAutoUpdate = false; // Avoid worldMatrix from being recomputed by THREE based on pos/rotation/scale
        } else {
            scene.matrixWorld.identity();
        }

        const consolidation = this.getConsolidation(consolidatedRenderBatch.nodeIndex);
        if (consolidation) {
            const meshes = consolidation.meshes;
            for (let i = 0; i < meshes.length; i++) {
                meshes[i].matrixWorldNeedsUpdate = true;
            }
        }
    };

    // get next scene from cache
    this.acquireConsolidatedRenderBatch = function(renderBatch) {
        const index = renderBatch.nodeIndex;

        // create new scene on first use
        if (!_consolidatedRenderBatchCache[index]) {
            _consolidatedRenderBatchCache[index] = new ConsolidatedRenderBatch(this, renderBatch, this.getConsolidation(index));

            // Make sure that current model matrix is applied to each scene
            this.applyModelMatrix(_consolidatedRenderBatchCache[index]);
        }

        const scene = _consolidatedRenderBatchCache[index].consolidatedScene;

        // We need to clear the scene here because the meshes are reused for multiple renderers and cannot be shared.
        while (scene.children.length > 0) {
            scene.remove(scene.children[scene.children.length - 1]);
        }

        return _consolidatedRenderBatchCache[index];
    };

    this.modelMatrixChanged = () => {
        // Set scene matrices
        for (let i = 0; i < _consolidatedRenderBatchCache.length; i++) {
            // Note that _consolidatedRenderBatchCache may contain null elements, because elements are indexed by BVH nodeIdx
            const scene = _consolidatedRenderBatchCache[i];
            if (scene) {
                this.applyModelMatrix(scene);
            }
        }
    };

    this.getConsolidation = function(nodeId) {
        return nodeId !== undefined ? _consolidations[nodeId] : _consolidations;
    };

    this.setConsolidation = function(nodeId, consolidation) {
        _consolidations[nodeId] = consolidation;
    };

    /**
     * Called at the beginning of a scene traversal.
     */
    this.reset = function() {
        // Disable consolidation if any fragment was moved
        _consolidationDisabled = _frags.movedFragments > 0;

        _outOfCoreTileManager?.resetLockedTiles(_bvhModelIterator.getIteratorOutOfCoreManagerId());
    };

    this.dispose = function() {
        _consolidations.forEach(cons => cons?.dispose());
    };

    /**
     * Given a RenderBatch that would normally be rendered next, this function
     * returns an optimized ConsolidatedRenderBatch to replace it.
     *
     *  @param   {RenderBatch}          renderBatch
     *  @param   {FrustumIntersector}   frustum (unused, here for compatibility)
     *  @param   {number}               drawMode
     *  @returns {ConsolidatedRenderBatch|RenderBatch} If fragments must be rendered individually, the input RenderBatch
     *           is returned, otherwise the optimized ConsolidatedRenderBatch is returned
     *
     *  TODO: Rename once we get rid of the old ConsolidationIterator
     */
    this.consolidateNextBatch = function(renderBatch, frustum, drawMode) {

        // get bvh node index associated with this RenderBatch. We need this to make sure that
        // a RenderBatch is always replaced by the same THREE.Scene object.
        var nodeIndex = renderBatch.nodeIndex;

        // TODO: Should we move this out of the consolidation iterator?
        const bvhNode = _outOfCoreTileManager.getBvhNode(nodeIndex);
        bvhNode.updateLastRendered(_bvhModelIterator.getIteratorOutOfCoreManagerId(),
            _outOfCoreTileManager.getFrameCount(_bvhModelIterator.getIteratorOutOfCoreManagerId()));

        // Fallback: Just use original fragments if no optimized meshes are available
        if (_consolidationDisabled || bvhNode.transparent || !bvhNode.isStageCompleted(StageNames.OPTIMIZED_ON_GPU)) {
            return renderBatch;
        }

        let consolidatedRenderBatch = this.acquireConsolidatedRenderBatch(renderBatch);
        let scene;

        // Consolidated and instanced meshes are still THREE.Mesh objects and need special treatment.
        // The remaining single fragments are contained in consolidatedRenderBatch.
        scene = consolidatedRenderBatch.consolidatedScene;
        scene.numFragsStreamingDraw = 0;

        const consolidation = this.getConsolidation(renderBatch.nodeIndex);
        if (consolidation.meshes.length > 0) {
            scene.add(...consolidation.meshes);
        }

        for (var i = 0; i < consolidation.meshes.length; i++) {
            const mesh = consolidation.meshes[i];
            var geom = mesh && mesh.geometry;
            var isLines = geom && (geom.isLines || geom.isWideLines);
            var isPoints = geom && geom.isPoints;
            var isHiddenPrimitive = (_frags.linesHidden && isLines) || (_frags.pointsHidden && isPoints);

            // Only render meshes that are not deactivated via the settings or are completely irrelevant for the current draw mode
            mesh.visible = !(isHiddenPrimitive || ((drawMode === RenderFlags.RENDER_HIDDEN) && mesh.allFragsVisible) || ((drawMode !== RenderFlags.RENDER_HIDDEN) && mesh.noFragsVisible));

            if (geom?.streamingDraw)
                scene.numFragsStreamingDraw++;
        }

        _outOfCoreTileManager?.lockTile(_bvhModelIterator.getIteratorOutOfCoreManagerId(), nodeIndex);

        // Update bounding box of the scene
        if (!scene.boundingBox) {
            scene.boundingBox = new THREE.Box3();
        }

        // Note: The model matrix is included in the renderBatch bbox already. It is not affected by the scene.matrix.
        renderBatch.getBoundingBox(scene.boundingBox);

        consolidatedRenderBatch.renderImportance = renderBatch.renderImportance;

        // adopt sortObjects flag from original RenderBatch - so that RenderScene can use it to detect which
        // scenes contain transparency.
        scene.sortObjects = renderBatch.sortObjects;

        if (renderBatch.lights) {
            scene.children.push(renderBatch.lights);
        }

        return consolidatedRenderBatch;
    };

    // enum to describe in which way a fragment has been rendered.
    var ConsolidationType = {

        Merged: 1, // Fragment is represented by a merged geometry composed from different fragment geometries.
        Instanced: 2, // Fragment is represented by an instanced shape that represents multiple fragments that
        // are sharing the same geometry.
        Original: 3  // Fragment was not combined with others and is still sharing the original fragment's geometry
        // and material.
    };

    /**
     *  Checks if a given geometry is instanced, the result of merging, or original fragment geometry.
     *
     *   @param {THREE.Mesh} currently used render proxy
     *   @param {Number}     fragId represented by this proxy
     **/
    function getConsolidationType(geom) {
        if (geom) {
            if (geom.numInstances) {
                // This geom combines multiple fragments using instancing
                // Note that we also enter this section if numInstances==1. This is correct, because numInstances
                // is always undef if no instance buffer is used.
                return ConsolidationType.Instanced;

                // It is possible that a geometry is a consolidation placeholder that has no attributes yet due to
                // the limited time budget for consolidation. Since it will eventually be consolidated, we treat it as
                // a merged geometry.
            } else if (geom.attributes) {
                // When merging fragments, we always use per-vertex ids.
                return ConsolidationType.Merged;
            }
        }

        return ConsolidationType.Original;
    }

    /**
     *  Checks which type of consolidation has been used to represent a given fragment in the last
     *  rendering traversal.
     *
     *   @returns {ConsolidationType}
     */
    function getFragmentConsolidationType(fragId) {
        let consolidationType = ConsolidationType.Original;
        // Check if consolidation was used for this fragment in last frame.
        if (!_consolidationDisabled) {
            if (_frags.isFlagSet(fragId, MeshFlags.MESH_CONSOLIDATED))
                consolidationType = ConsolidationType.Merged;
            else if (_frags.isFlagSet(fragId, MeshFlags.MESH_INSTANCED))
                consolidationType = ConsolidationType.Instanced;
        }
        return consolidationType;
    }

    /** Updates a given render proxy mesh to make sure that it matches exactly with the fragment's representation
     *  used in the last rendered frame.
     *
     *   @param {THREE.Mesh} proxy  - currently used render proxy
     *   @param {Number}     fragId - fragment represented by this proxy
     **/
    this.updateRenderProxy = function(proxy, fragId) {
        // if the proxy has no valid geometry, do nothing
        if (!proxy.geometry || !proxy.geometry.attributes) {
            return;
        }

        // check which type of geometry has been used in last rendering traversal (See ConsolidationType enum)
        var requiredType = getFragmentConsolidationType(fragId);
        var currentType = getConsolidationType(proxy.geometry);

        // if type is already correct, we are done.
        if (!proxy.needsUpdate && currentType == requiredType) {
            return;
        }

        // get original fragment geometry
        var origGeom = _frags.getGeometry(fragId);

        if (requiredType === ConsolidationType.Original) {

            // recover original geometry, material, and matrix
            proxy.geometry = origGeom;
            proxy.material = _frags.getMaterial(fragId);
            _frags.getWorldMatrix(fragId, proxy.matrix);


        } else if (requiredType === ConsolidationType.Instanced) {

            // This fragment was rendered using an instanced shape.

            // replace proxy geometry by instanced mesh with single instance
            _frags.getWorldMatrix(fragId, _tempMatrix);
            var dbId = _frags.fragments.fragId2dbId[fragId];

            // create proxy mesh with 1-element instance buffer
            var builder = new InstanceBufferBuilder(origGeom, 1);
            builder.addInstance(_tempMatrix, dbId);
            proxy.geometry = builder.finish();

            // use container material (needed to activate instancing)
            proxy.material = _matman.getMaterialVariant(_frags.getMaterial(fragId), MATERIAL_VARIANT.INSTANCED, _outOfCoreTileManager.model);

            // reset matrix to identity, because the transform is done per instance
            proxy.matrix.identity();

        } else { // ConsolidationType.Merged:

            // This fragment was rendered using a merged shape

            // create consolidation proxy which just contains the single fragment with baked matrix
            _frags.getWorldMatrix(fragId, _tempMatrix);
            _frags.getWorldBounds(fragId, _tempBox);
            dbId = _frags.fragments.fragId2dbId[fragId];
            proxy.geometry = mergeGeometries([origGeom], _tempMatrix.elements, [dbId], _tempBox);

            // share container material
            proxy.material = _matman.getMaterialVariant(_frags.getMaterial(fragId), MATERIAL_VARIANT.VERTEX_IDS, _outOfCoreTileManager.model);

            // reset matrix to identity, because the transform is baked into the vertex buffer
            proxy.matrix.identity();
        }

        // Make sure we don't create the proxy mesh again until actually needed.
        proxy.needsUpdate = false;

        // make sure that WebGLRenderer does not keep an outdated cache object. Without this line,
        // WebGLRenderer will still use the previous GeometryBuffer if it is already cached.
        proxy.dispatchEvent({ type: 'removed' });
    };

    /**
     * Creates a copy of the iterator
     * @param {ModelIteratorBVH} bvhModelIterator - The new BVH model iterator. Since we are cloning
     * the bvh model iterator and the consolidation iterator independent from each other, we have to
     * provide the new BVH model iterator to the cloned consolidation iterator to make sure that the
     * cloned consolidation iterator references the new BVH model iterator.
     *
     * @returns {ConsolidationIterator} Cloned iterator
     */
    this.clone = function(bvhModelIterator) {
        let clone = new NodeConsolidationIterator(_frags, bvhModelIterator, _matman, _consolidations);
        return clone;
    };

    this.onRenderBatchComplete = function(renderBatch) {
        // Mark the node as no longer locked in the tile manager, once it has been rendered and
        // the render batch that references consolidated geometry from the tile will no longer
        // be needed
        if (renderBatch.nodeIndex !== undefined) {
            _outOfCoreTileManager.unlockTile(_bvhModelIterator.getIteratorOutOfCoreManagerId(), renderBatch.nodeIndex);
        }
    };
}
