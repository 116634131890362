export const PROPERTY_ACTIONS = {
    GET_PROPERTIES :"GET_PROPERTIES",
    GET_PROPERTY_SET :"GET_PROPERTY_SET",
    SEARCH_PROPERTIES :"SEARCH_PROPERTIES",
    FIND_PROPERTY :"FIND_PROPERTY",
    FIND_LAYERS :"FIND_LAYERS",
    BUILD_EXTERNAL_ID_MAPPING :"BUILD_EXTERNAL_ID_MAPPING",
    BUILD_LAYER_TO_NODE_ID_MAPPING :"BUILD_LAYER_TO_NODE_ID_MAPPING",
    LOAD_PROPERTYDB :"LOAD_PROPERTYDB",
    CREATE_TREE :"CREATE_TREE",
    UNLOAD_PROPERTYDB :"UNLOAD_PROPERTYDB",
    DIFF_PROPERTIES :"DIFF_PROPERTIES",
    USER_FUNCTION :"USER_FUNCTION",
    LOAD_EXTERNAL_IDS :"LOAD_EXTERNAL_IDS",
    REFRESH_CUSTOM_PROPERTIES :"REFRESH_CUSTOM_PROPERTIES",
    REFRESH_CUSTOM_PROPERTIES_VALUES :"REFRESH_CUSTOM_PROPERTIES_VALUES",
    APPLY_CUSTOM_PROPERTIES :"APPLY_CUSTOM_PROPERTIES",
    SET_CUSTOM_PROPERTIES_VALUES :"SET_CUSTOM_PROPERTIES_VALUES",
    GET_ATTR_DEFS :"GET_ATTR_DEFS",
    GET_PROPERTY_DATA :"GET_PROPERTY_DATA",
    GET_ATTR_VALUES :"GET_ATTR_VALUES",
    CREATE_FILTER_TREE: "CREATE_FILTER_TREE"
};
