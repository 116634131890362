import { createWorker } from "../main/WorkerCreator";

let jobCounter = 0;

/**
 * Manages a pool of workers to execute tasks in parallel
 */
export class WorkerPool {
    /**
     * Constructor
     * @param {number} poolSize - The number of workers in the pool
     */
    constructor(poolSize) {
        this._workerGroups = new Map();
        this._groupsByPriority = [];

        this.poolSize = poolSize;
        this._freeWorkers = [];
        this._busyWorkers = new Set();
        this._pendingJobs = new Map();

        this.createGroup('default', 10);
        this.createGroup('low priority', 1);
    }

    /**
     * Start the worker pool and create the workers
     */
    start() {
        for (let i = 0; i < this.poolSize; i++) {
            const worker = createWorker('pool-worker-' + i);
            worker.addEventListener('message', this._handleMessage.bind(this, worker));
            this._freeWorkers.push(worker);
        }
        this._started = true;
    }

    /**
     * Creates a new groups of jobs.
     * By default, there is a 'default' group with priority 10 & a 'low priority' group with priority 1
     * @param {string} name
     * @param {number} priority - The higher the number, the higher the priority
     */
    createGroup(name, priority) {
        const group = {
            name,
            jobQueue: [],
            priority,
        };

        this._workerGroups.set(name, group);
        this._groupsByPriority.push(group);
        this._groupsByPriority.sort((a, b) => b.priority - a.priority);
    }

    /**
     * Enqueue a job in the given group
     * @param {string} operation - The operation to perform on the worker
     * @param {Object} data - The data to send to the worker
     * @param {Object[]} [transferables] - Transferable objects to send to the worker
     * @param {string} [jobGroup]
     * @returns
     */
    enqueueJob(operation, data, transferables = [], jobGroup = 'default') {
        const workerGroup = this._workerGroups.get(jobGroup);
        if (!workerGroup) {
            return;
        }

        const job = {
            operation,
            data,
            transferables,
            id: jobCounter++
        };

        const resultPromise = new Promise((resolve) => {
            job.resolve = resolve;
        });

        workerGroup.jobQueue.push(job);
        this._processJobQueue();

        return resultPromise;
    }

    _handleMessage(worker, message) {
        const jobId = message.data.jobId;
        const job = this._pendingJobs.get(jobId);

        if (!job) {
            console.error('Received message for unknown job', jobId);
            return;
        }

        this._pendingJobs.delete(jobId);

        if (job.resolve) {
            job.resolve(message.data);
        }

        this._busyWorkers.delete(worker);
        this._freeWorkers.push(worker);

        this._processJobQueue();
    }

    _getNextJob() {
        for (const group of this._groupsByPriority) {
            if (group.jobQueue.length > 0) {
                return group.jobQueue.shift();
            }
        }
        return null;
    }

    _processJobQueue() {
        if (!this._started) {
            this.start();
        }

        while (this._freeWorkers.length > 0) {
            const job = this._getNextJob();

            if (!job) {
                return;
            }

            const worker = this._freeWorkers.pop();
            if (!worker) {
                return;
            }

            this._pendingJobs.set(job.id, job);
            this._busyWorkers.add(worker);

            worker.postMessage(
                {
                    operation: job.operation,
                    ...job.data,
                    jobId: job.id
                },
                job.transferables
            );
        }
    }
}

export const DefaultWorkerPool = new WorkerPool(1);
globalThis.DefaultWorkerPool = DefaultWorkerPool;
