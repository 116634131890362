import { useEarlyConsolidation } from "./Consolidation";
import { applyInstancingToRange } from "./FragmentListConsolidation";
import { DefaultWorkerPool } from "../../../file-loaders/workers/WorkerPool";
import { DEFAULT_CONSOLIDATION_POLYCOUNT_LIMIT } from "../../globals";
import { BVH } from "../BVHBuilder";
import { MeshFlags } from "../MeshFlags";
import { canBeMerged, MaxVertexCountPerMesh, mergeGeometries } from "./ConsolidationCommon";
import { getVertexCount } from "../VertexEnumerator";
import { materialsAreUBOCompatible, getMaxMaterialsPerUBO, isSupportedUBOMaterial, MAX_FRAGMENTS_PER_CONSOLIDATED_MESH } from "../../render/UniformBufferObjects";
import { MATERIAL_VARIANT } from "../../render/MaterialManager";
import { getByteSize } from "../BufferGeometryUtils";
import { logger } from "../../../logger/Logger";
import { USE_MULTI_MATERIAL_RENDER_CALLS } from "../../globals";

export class NodeConsolidation {

    constructor(modelId) {
        // consolidated and instanced meshes
        /** @type {THREE.Mesh[]} */ this.meshes = [];

        // fragments that are rendered individually
        /** @type {(Number[])[]} */ this.singleFragments = [];

        // Id of the model that has been consolidated
        this.modelId = modelId;

        // keep intermediate result to make reruns faster
        this.consolidationMap = null;

        // track summed size
        this.byteSize = 0;
    }

    /**
     * Add a consolidation mesh that combines several source geometries.
     *
     * @param {THREE.BufferGeometry} geom
     * @param {THREE.Material}       material
     * @param {number[]}             fragIds         - array of fragment ids associated with this container
     * @param {number}               [firstFrag]     - Optional: Use (firstFrag, fragCount) to specify
     * @param {number}               [fragCount]       a range within the fragIds array.
     * @param {number}               [oldRangeIndex] - Index of the entry in the ranges array
     */
    addContainerMesh(geom, material, fragIds, firstFrag, fragCount, oldRangeIndex, fragList) {

        // add new mesh
        var newMesh = new THREE.Mesh(geom, material);

        // Flags are used to whether the mesh needs to be rendered or not based on the drawMode
        newMesh.allFragsVisible = geom.allFragsVisible;
        newMesh.noFragsVisible = geom.noFragsVisible;

        newMesh.modelId = this.modelId;
        this.meshes.push(newMesh);

        // track byte size
        this.byteSize += geom.byteSize;

        // default range: full array
        var rangeStart = firstFrag || 0;
        var rangeLength = fragCount || fragIds.length;
        var rangeEnd = rangeStart + rangeLength;

        // Disable THREE frustum culling for all shapes. We do culling on a per node basis.
        newMesh.frustumCulled = false;

        // The indices in the meshes array are not in 1:1 correspondence with the indices in the rages array,
        // because processing a single range may result in multiple meshes. Therefore, we store the range index
        // and the start and length of  the range in the generated mesh object.
        newMesh.rangeBegin = firstFrag;
        newMesh.rangeCount = fragCount;
        newMesh.oldRangeIndex = oldRangeIndex;

        // For each source fragment, set the flag to indicate that it is part of a consolidated mesh
        for (var i = rangeStart; i < rangeEnd; i++) {
            var fragId = fragIds[i];
            fragList.setFlagFragment(fragId, oldRangeIndex ? MeshFlags.MESH_CONSOLIDATED : MeshFlags.MESH_INSTANCED);
        }
    }

    /**
     *  Shortcut to add a single fragment that should not be rendered via consolidation or instancing.
     *  Only used with per node consolidation. We later use nodeId2SingleFragIds to create ConsolidatedRenderbatches
     *  containing these single fragments per bvh node.
     *  @param {number} fragId
     */
    addSingleFragment(fragId) {
        this.singleFragments.push(fragId);
    }

    /**
     * Create the consolidation geometry for the requested meshIndex
     * @param {Number} meshIndex Index of consolidate/instanced mesh
     * @param {FragmentList} fragList Fragment list for the model
     * @return {THREE.Mesh|null} Consolidate/instanced mesh. If the time didn't suffice
     *                           to compute a consolidated mesh, it will return null.
     */
    createMeshGeometry(meshIndex, fragList) {
        var curMesh = this.meshes[meshIndex];// Current mesh
        var curGeom = curMesh.geometry;// Current geometry

        var consolidationMap = this.consolidationMap;

        curMesh.proxyGeometry = curMesh.geometry;
        consolidationMap._buildConsolidationGeometry(curMesh, fragList);

        // copy over memory assignment
        curMesh.geometry.streamingDraw = curGeom.streamingDraw;
        curMesh.geometry.streamingIndex = curGeom.streamingIndex;

        // If the out of core tile manager is used, we always must discard the geometry after upload, since it is using the temporary buffer
        curMesh.geometry.discardAfterUpload = true;
        curGeom = curMesh.geometry;

        return curMesh;
    }

    /**
     * Free the consolidated/instanced geometry for the specified mesh
     *
     * @param {Number} meshIndex Index of consolidate/instanced mesh
     */
    freeMeshGeometry(meshIndex) {
        var curMesh = this.meshes[meshIndex];// Current mesh
        var curGeom = curMesh.geometry;// Current geometry
        if (curGeom === undefined) {
            return;
        }

        if (curGeom.attributes) {
            let parent = curMesh.parent;
            if (parent) {
                curMesh.parent.remove(curMesh);
            }
            curMesh.geometry.dispose();
            if (curMesh.ubos) {
                for (const ubo in curMesh.ubos) {
                    curMesh.ubos[ubo].dispose();
                }
                delete curMesh.ubos;
            }

            curMesh.geometry = curMesh.proxyGeometry;

            curGeom.vb = null;
            curGeom.ib = null;
            curGeom.iblines = null;

            for (let attrib in curGeom.attributes) {
                if (curGeom.attributes[attrib].array) {
                    curGeom.attributes[attrib].array = null;
                }
            }

            if (parent) {
                parent.add(curMesh);
            }
        }
    }

    /**
     * Check, whether a consolidated mesh geometry is available for the given meshIndex
     *
     * @param {number} meshIndex - The mesh index
     * @returns {boolean} - True if the mesh geometry is available, false otherwise
     */
    meshGeometryAvailable(meshIndex) {
        return this.meshes[meshIndex]?.geometry?.attributes !== undefined;
    }

    /**
     * Returns the number of bytes the consolidated geometry for the given meshIndex would consume.
     *
     * @param {Number} meshIndex Index of consolidate/instanced mesh
     * @param {FragmentList} fragList Fragment list for the model
     * @returns {number} The number of bytes the consolidated geometry would consume
     */
    getMemoryCostForConsolidatedMesh(meshIndex, fragList) {
        const curMesh = this.meshes[meshIndex];// Current mesh

        if (curMesh.geometry.numInstances > 1 || curMesh.fragId !== undefined) {
            throw new Error('getMemoryCostForConsolidatedMesh() is only valid for consolidated meshes');
        }

        // Compute the accurate cost
        let requiredMemory = 0;
        let mesh = this.meshes[meshIndex];
        const rangeStart = mesh.rangeBegin;
        const rangeEnd = mesh.rangeBegin + mesh.rangeCount;

        // The use of 3 bytes is currently hardcoded in the geom merge task
        const IDBytesPerVertex = 3;

        const fragIds = this.consolidationMap.fragOrder;
        for (let i = rangeStart; i < rangeEnd; i++) {
            const fragId = fragIds[i];
            const geom = fragList.getGeometry(fragId);

            requiredMemory += geom.getAccurateByteSize();

            // Add memory that will be needed for the id buffer
            const vertexCount = geom.vb.length / geom.vbstride;
            requiredMemory += IDBytesPerVertex * vertexCount;
        }

        return requiredMemory;
    }

    dispose() {
        var DISPOSE_EVENT = { type: 'dispose' };
        var REMOVED_EVENT = { type: 'removed' };

        for (var i = 0; i < this.meshes.length; i++) {
            var mesh = this.meshes[i];
            var geom = mesh?.geometry;
            if (geom) {
                //Both of these are needed -- see also how it's done in FragmentList dispose
                mesh.dispatchEvent(REMOVED_EVENT);
                geom.dispatchEvent(DISPOSE_EVENT);

                // In case of later reuse, setting needsUpdate is essential to render it again.
                geom.needsUpdate = true;
            }
        }

    }
}

/**
 * Get the geom and material IDs for the model.
 * @param {RenderModel} model - The model
 * @returns { {geomIds: Uint32Array, materialIds: Uint32Array} } - The geom and material IDs
 */
function getGeomAndMaterialIDs(model) {
    const fl = model.getFragmentList();
    let geomIds, materialIds;

    if (useEarlyConsolidation(model)) {
        geomIds = model.loader.svf.fragments.geomDataIndexes;
        materialIds = model.loader.svf.fragments.materials;
    } else {
        geomIds = fl.geomids;
        materialIds = fl.materialids;
    }
    return { geomIds, materialIds };
}

/**
 * For node based consolidation a BVH is needed. So we compute a one suited for consolidation
 * @param {RenderModel} model                  - The model to consolidate
 * @param {Object}      bvhOptions             - Options for the BVH computation
 * @returns {Promise<BVH>}                     - The computed BVH
 */
export async function computeConsolidationBVH(model, bvhOptions) {

    const fl = model.getFragmentList();

    // We are copying the data from the fragment list into a format that can be passed to a worker
    // This is necessary because the fragment list is not available in the worker
    // This creates some temporary memory overhead, but if we don't have enough memory for this we shouldn't
    // do consolidation anyway
    let { geomIds, materialIds } = getGeomAndMaterialIDs(model);

    const fragments = {
        boxes: fl.fragments.boxes,
        polygonCounts: new Uint32Array(geomIds.length),
        flags: new Uint8Array(geomIds.length),
        materials: materialIds,
        length: geomIds.length,
        geomids: geomIds,
        wantSort: true
    };

    const polyCountLimit = bvhOptions?.consolidation_polycount_limit ?? DEFAULT_CONSOLIDATION_POLYCOUNT_LIMIT;
    const materialIdMap = model.getFragmentList().materialIdMap;
    for (let fragId = 0; fragId < fl.fragments.length; ++fragId) {
        if (useEarlyConsolidation(model)) {
            let geomId = model.loader.svf.fragments.geomDataIndexes[fragId];
            const extraFragInfo = model.loader.svf.extraFragInfo;

            fragments.polygonCounts[fragId] = geomId !== 0 ?
                extraFragInfo.getPolygonCount(fragId) :
                0;

            if (fragments.polygonCounts[fragId] < polyCountLimit) {
                fragments.flags[fragId] = 1; // can be consolidated
            }

            fragments.flags[fragId] |= extraFragInfo.isTransparent(fragId) ? 2 : 0;
        } else {
            const geom = fl.getGeometry(fragId);
            fragments.polygonCounts[fragId] = geom ? geom.polyCount : 0;

            if (fragments.polygonCounts[fragId] < polyCountLimit) {
                fragments.flags[fragId] = 1; // can be consolidated
            }

            const materialDef = materialIdMap && materialIdMap[fragments.materials[fragId]];
            fragments.flags[fragId] |= materialDef && materialDef.transparent ? 2 : 0;
        }
    }

    const resultData = await DefaultWorkerPool.enqueueJob("COMPUTE_BVH", { fragments, modelId: model.id, bvhOptions }, [fragments.polygonCounts.buffer, fragments.flags.buffer]);
    const bvh = new BVH(resultData.bvh.nodes, resultData.bvh.useLeanNodes, resultData.bvh.primitives, bvhOptions, resultData.bvh.fragId2nodeId);

    return bvh;
}

/**
 *  Computes which fragments of the specified node should be consolidated, which should be instanced or rendered individually.
 *
 *  @param {RenderModel} model            - The model to consolidate
 *  @param {BVH}         bvh              - Bounding Volume Hierarchy for the model
 *  @param {number}      nodeidx          - Index of the node to consolidate
 *  @param {number}      polyCountLimit   - The maximum number of polygons a mesh can have to be consolidated
 *
 *  @returns {ConsolidationMap}
 */
export function createConsolidationMapForNode(model, bvh, nodeidx, polyCountLimit = DEFAULT_CONSOLIDATION_POLYCOUNT_LIMIT) {
    const fragList = model.getFragmentList();

    // reused in loop below
    var fragBox = new THREE.Box3();
    const geomList = model.getGeometryList();

    var consolidationBuilder = new ConsolidationBuilder();

    const nodes = bvh.nodes;
    const fragStart = nodes.getPrimStart(nodeidx);
    const fragCount = nodes.getPrimCount(nodeidx);
    const fragEnd = fragCount + fragStart;

    let fragOrder = new Uint32Array(fragCount);
    let nextInstancingFragId = fragCount - 1;
    let numConsolidated = 0;
    let numInvalid = 0;

    const geometryInstanceLookup = computeInstanceCounts(model, fragStart, fragCount, fragList, bvh.primitives);

    for (let i = fragStart; i < fragEnd; i++) {
        let fragId = bvh.primitives[i];

        let allInstancePolyCount = getMergeablePolyCount(fragId, fragList, geomList, geometryInstanceLookup);
        if (allInstancePolyCount < 0) {
            numInvalid++;
            continue;
        }

        // get world box
        fragList.getWorldBounds(fragId, fragBox);

        // add mesh to consolidation
        let geometry, isTransparent;

        geometry = fragList.getGeometry(fragId);
        const material = fragList.getMaterial(fragId);
        isTransparent = material.transparent;

        if (allInstancePolyCount > polyCountLimit || isTransparent) {
            fragOrder[nextInstancingFragId--] = fragId;
        } else {
            numConsolidated++;
            consolidationBuilder.addGeom(geometry, material, fragBox, fragId);
        }
    }

    // When creating the consolidation map, fragOrder first contains all ids of fragment that should be consolidated, followed by the ones being instanced.
    // To avoid a two pass approach, first partitioning into consolidated and instanced fragments, we simply add the instanced fragments to the end of the array.
    // The consolidated fragments will be added in createConsolidationMap based on the merge buckets. In case of invalid fragments we need to shrink the array accordingly.
    if (numInvalid > 0) {
        fragOrder = fragOrder.subarray(numInvalid, fragCount);
    }

    // Sort by geometry and material to get maximal instancing ranges
    const instanceFrags = new Uint32Array(fragOrder.buffer, numConsolidated * Uint32Array.BYTES_PER_ELEMENT);
    instanceFrags.sort((fragId1, fragId2) => {
        const geomId1 = fragList.getGeometryId(fragId1);
        const geomId2 = fragList.getGeometryId(fragId2);
        if (geomId1 != geomId2) {
            return geomId1 - geomId2;
        }

        const mat1 = fragList.getMaterialId(fragId1);
        const mat2 = fragList.getMaterialId(fragId2);
        return mat1 - mat2;
    });

    // create ConsolidationMap
    const consolidationMap = consolidationBuilder.createConsolidationMap(numConsolidated, fragOrder);
    consolidationMap.numInvalid = numInvalid;
    return consolidationMap;
}

function computeInstanceCounts(model, rangeStart, rangeLength, fragList, primitives) {
    let geometryInstanceLookUp = {};
    geometryInstanceLookUp.instanceCounts = new Map();
    geometryInstanceLookUp.getInstanceCount = (fragId) => {
        const key = fragList.getGeometryId(fragId);

        if (key > 0) {
            const keyMap = geometryInstanceLookUp.instanceCounts.get(fragList.getMaterialId(fragId));
            return (keyMap?.get(key) ?? -1);
        } else {
            return -1;
        }
    };

    const rangeEnd = rangeStart + rangeLength;
    for (let i = rangeStart; i < rangeEnd; i++) {
        const fragId = primitives[i];
        const key = fragList.getGeometryId(fragId);

        if (key > 0) {
            const matId = fragList.getMaterialId(fragId);
            let keyMap = geometryInstanceLookUp.instanceCounts.get(matId);
            if (!geometryInstanceLookUp.instanceCounts.has(matId)) {
                keyMap = new Map();
                geometryInstanceLookUp.instanceCounts.set(matId, keyMap);
            }

            const instanceCount = keyMap.get(key) ?? 0;
            keyMap.set(key, instanceCount + 1);
        }
    }
    return geometryInstanceLookUp;
}

function getMergeablePolyCount(fragId, fragList, geomList, geometryInstanceLookUp) {
    let polyCount;

    const geomId = fragList.getGeometryId(fragId);
    polyCount = geomList.getGeometry(geomId)?.polyCount || 0;

    const instanceCount = geometryInstanceLookUp.getInstanceCount(fragId);
    return instanceCount > 0 ? polyCount * instanceCount : instanceCount;
}

/**
 * Combines fragments with shared geometries into instanced meshes. Note that creating instanced meshes
 * only makes sense for fragments that share geometry and material.
 *
 * Requirement: fragIds must already be sorted in a way that meshes with identical geometry and material form
 *              a contiguous range.
 *
 * @param {RenderModel}       model         - Model to be consolidated
 * @param {MaterialManager}   materials     - Needed to create new materials for instanced shapes
 * @param {NodeConsolidation} consolidation - Consolidation object to which the instancing is applied
 */
export function applyInstancing(model, materials, consolidation) {

    // the first n=numConsolidated fragments in fragIds are consolidated already.
    // The remaining fragIds are now processed using hardware instancing.
    const fragIds = consolidation.consolidationMap.fragOrder;
    const startIndex = consolidation.consolidationMap.numConsolidated;

    if (startIndex >= fragIds.length) {
        // range empty
        // This may happen if we could consolidate all fragments per mesh merging already, so
        // that instancing is not needed anymore.
        return;
    }

    // track ranges of equal geometry and material
    var rangeStart = startIndex;
    var lastGeomId = -1;
    var lastMatId = -1;

    let { geomIds, materialIds } = getGeomAndMaterialIDs(model);

    for (let i = startIndex; i < fragIds.length; i++) {
        var fragId = fragIds[i];
        var geomId = geomIds[fragId];
        var matId = materialIds[fragId];

        // check if a new range starts here
        // If case of per node consolidation, we allow pulling in more fragments from other nodes
        // to a certain degree to increase render batch sizes.
        if (geomId != lastGeomId || matId != lastMatId) {

            // a new range starts at index i
            // => process previous range [rangeStart, ..., i-1]
            if (i != startIndex) {
                applyInstancingToRange(model, materials, fragIds, rangeStart, i, consolidation);
            }

            // start new range
            rangeStart = i;
            lastGeomId = geomId;
            lastMatId = matId;
        }
    }

    // process final range
    applyInstancingToRange(model, materials, fragIds, rangeStart, fragIds.length, consolidation);
}

/**
 *  Helper class to collect shapes with identical materials and merge them into a single large shape.
 *
 *  @constructor
 *    @param {number} materialId - Material must be the same for all added geometries.
 *    @param {number} bvhNodeId - BVH node id of the bucket
 */
export function MergeBucket(material, bvhNodeId) {
    this.geoms = [];
    this.matrices = [];
    this.vertexCount = 0;
    this.material = material;
    this.materialIds = new Set([material.id]);
    this.fragIds = [];
    this.worldBox = new THREE.Box3();
    this.cost = 0;
    this.bvhNodeId = bvhNodeId;
}

MergeBucket.prototype = {
    constructor: MergeBucket,

    /**
     * @param {THREE.BufferGeometry} geom
     * @param {THREE.Box3}           worldBox
     * @param {Number}               fragId
     * @returns {Number}             costs - memory cost increase caused by the new geometry
     */
    addGeom: function(geom, worldBox, fragId, material) {

        this.fragIds.push(fragId);
        this.materialIds.add(material.id);

        this.worldBox.union(worldBox);

        if (geom === null) {
            return 0;
        }

        this.geoms.push(geom);
        this.vertexCount += getVertexCount(geom);

        // Track memory costs. As long as the bucket has only a single shape,
        // we have no costs at all.
        var numGeoms = this.geoms.length;
        if (numGeoms == 1) {
            return 0;
        }

        // Fragment geometries are usually BufferGeometry, which provide a byteSize for the
        // interleaved buffer. Anything else is currently unexpected and needs code change.
        if (geom.byteSize === undefined) {
            logger.warn("Error in consolidation: Geometry must contain byteSize.");
        }

        // For any bucket with >=2 geoms, all geometries must be considered for the costs.
        let costDelta = geom.byteSize + (numGeoms == 2 ? this.geoms[0].byteSize : 0);
        this.cost += costDelta;

        return costDelta;
    }
};

export function ConsolidationMap(bucketCount, fragOrder) {
    // Offsets into fragOrder. ranges[i] is the startIndex of the range corresponding to merge bucket i.
    this.ranges = new Uint32Array(bucketCount);

    // Cached bboxes of consolidated meshes
    this.boxes = new Array(bucketCount);

    // Store how many fragIds in fragOrder have been added to merge buckets.
    // (fragIds[0], ..., fragIds[numConsolidated-1].
    this.numConsolidated = -1; // will be set in createConsolidationMap

    this.fragOrder = fragOrder;

    // tmp objects
    this.tmpGeoms = [];
    this.tmpMatrix = new THREE.Matrix4();

    /** @type {Map<THREE.Material, Map<THREE.Material, boolean>>} */ this._compatibleMaterialLUT = new Map();
}

ConsolidationMap.prototype = {

    /**
     * Create consolidated meshes.
     *  @param {FragmentList}    fragList
     *  @param {MaterialManager} matman
     *  @param {RenderModel}     model
     *  @returns {NodeConsolidation}
     */
    buildConsolidation: function(fragList, matman, model) {

        const result = new NodeConsolidation(model.getModelId());

        // store this consolidation map with the consolidation, so that we can rebuild it faster.
        result.consolidationMap = this;

        // each range of fragIds is merged into a consolidated mesh
        for (let c = 0; c < this.ranges.length; c++) {
            this._buildConsolidationMesh(c, fragList, matman, model, result);
        }

        return result;
    },

    /**
     * Internal function for creating a single consolidated meshes and adding it to the result.
     *  @param {Number}            rangeIdx - index into this.ranges
     *  @param {FragmentList}      fragList
     *  @param {MaterialManager}   matman
     *  @param {RenderModel}       model
     *  @param {NodeConsolidation} result   - output
     */
    _buildConsolidationMesh: function(rangeIdx, fragList, matman, model, result) {
        var fragIds = this.fragOrder;
        var rangeCount = this.ranges.length;

        // get range of fragIds in this.fragOrder from which we build the next consolidated mesh.
        // Note that this.ranges only contains the range begins and the last range ends at this.numConsolidated.
        var rangeBegin = this.ranges[rangeIdx];
        var rangeEnd = (rangeIdx === (rangeCount - 1)) ? this.numConsolidated : this.ranges[rangeIdx + 1];
        var rangeLength = rangeEnd - rangeBegin;

        // just 1 shape? => just share original geometry and material
        if (rangeLength === 1) {
            const fragId = fragIds[rangeBegin];
            result.addSingleFragment(fragId, fragList);
            return;
        }

        let mergedGeom = this._buildConsolidationPlaceholder(rangeBegin, rangeLength, fragIds, fragList);

        // use material of first frag in the bucket
        var firstFrag = fragIds[rangeBegin];
        var material = fragList.getMaterial(firstFrag);
        var newMaterial = matman.getMaterialVariant(material, MATERIAL_VARIANT.VERTEX_IDS, model);

        // add result
        result.addContainerMesh(mergedGeom, newMaterial, fragIds, rangeBegin, rangeLength, rangeIdx, fragList);
    },

    /**
     * Internal function for creating a single consolidated geometry.
     *  @param {THREE.Mesh}        mesh                       - mesh object containing the geometry
     *  @param {FragmentList}      fragList
     *  @returns {BufferGeometry}
     */
    _buildConsolidationGeometry: function(mesh, fragList) {
        var fragIds = this.fragOrder;

        // get range of fragIds in this.fragOrder from which we build the next consolidated mesh.
        let rangeBegin = mesh.rangeBegin;
        let rangeLength = mesh.rangeCount;

        mesh.geometry = this._buildConsolidationGeometryImpl(mesh.oldRangeIndex, rangeBegin, rangeLength, fragIds, fragList);
    },

    /**
     * Very internal function for creating a single consolidated geometry from a given range.
     *  @param {Number}          rangeIdx                   - index into this.ranges
     *  @param {Number}          rangeBegin                 - first fragment in fragIds
     *  @param {Number}          rangeLength                - length of this range
     *  @param {Uint32Array}     fragIds                    - list of fragIds, from which the range is taken
     *  @param {FragmentList}    fragList
     *  @returns {BufferGeometry}
     */
    _buildConsolidationGeometryImpl: function(rangeIdx, rangeBegin, rangeLength, fragIds, fragList) {

        // create array of BufferGeometry pointers
        this.tmpGeoms.length = rangeLength;

        // create Float32Array containing the matrix per src fragment
        var matrices = new Float32Array(16 * rangeLength);

        // create Int32Array of dbIds
        var dbIds = new Uint32Array(rangeLength);

        let fragId;
        for (var i = 0; i < rangeLength; i++) {
            fragId = fragIds[rangeBegin + i];

            // fill geoms
            this.tmpGeoms[i] = fragList.getGeometry(fragId);

            // store matrix as 16 floats
            fragList.getOriginalWorldMatrix(fragId, this.tmpMatrix);
            matrices.set(this.tmpMatrix.elements, 16 * i);

            // store dbId in Int32Array
            dbIds[i] = USE_MULTI_MATERIAL_RENDER_CALLS ? i : fragList.getDbIds(fragId);
        }

        // get box of consolidated mesh
        var box = this.boxes[rangeIdx];
        var mergedGeom = mergeGeometries(this.tmpGeoms, matrices, dbIds, box);

        // Make sure, that consolidated meshes are always kept on the GPU
        mergedGeom.streamingDraw = false;
        mergedGeom.streamingIndex = false;

        return mergedGeom;
    },

    /**
     * Very internal function for creating a single placeholder for consolidated geometry from a given range.
     *  @param {Number}          rangeBegin                 - first fragment in fragIds
     *  @param {Number}          rangeLength                - length of this range
     *  @param {Uint32Array}     fragIds                    - list of fragIds, from which the range is taken
     *  @param {FragmentList}    fragList
     *  @returns {Object}
     */
    _buildConsolidationPlaceholder: function(rangeBegin, rangeLength, fragIds, fragList) {
        // compute byteSize (for memory assignment)
        let byteSize = 0;
        for (let i = 0; i < rangeLength; i++) {
            const fragId = fragIds[rangeBegin + i];

            // fill geoms
            const geom = fragList.getGeometry(fragId);
            byteSize += getByteSize(geom);
        }

        // create temporary "geometry" (to be replaced on first use)
        // need to define dispatchEvent in case the geometry is never uploaded
        const mergedGeom = { byteSize, rangeStart: rangeBegin, rangeCount: rangeLength, dispatchEvent: function() {} };
        return mergedGeom;
    },
};

/**
 *  @class ConsolidationBuilder is a utility to merge several (usually small) objects into larger ones to
 *  improve rendering performance.
 */
function ConsolidationBuilder() {
    this.buckets = new Map(); // Map<MergeBucket[]>
    this.bucketCount = 0;
    this.costs = 0;  // Consolidation costs in bytes (=costs of merged Geometries for each bucket with >=2 geoms)
    this._compatibleMaterialLUT = new Map();
}

ConsolidationBuilder.prototype = {

    /**
     *  Add a new Geometry for consolidation. Note that some geometries cannot be merged (e.g., if their material
     *  is different from all others.). In this case, the output mesh just shares input geometry and material.
     *
     *  @param {THREE.BufferGeometry} geom     - geometry
     *  @param {THREE.Material}       material - the material used for this geometry
     *  @param {THREE.Box3}           worldBox - worldBox (including matrix transform!)
     *  @param {Number}               fragId   - used to find out later in which output mesh you find this fragment
     */
    addGeom: function(geom, material, worldBox, fragId) {

        // find bucket of meshes that can be merged with the new one
        var bucket = null;
        let id = isSupportedUBOMaterial(material) ? -1 : material.id;

        var buckets = this.buckets.get(id);
        if (buckets) {
            for (var i = 0; i < buckets.length; i++) {

                // get next bucket
                var nextBucket = buckets[i];

                // compatible primitive type and vertex format?
                var bucketGeom = nextBucket.geoms[0];

                if (geom !== null) {
                    // We only check for mergeability and bucket vertex count here, if
                    // we are not using the out-of-core tile manager
                    // Otherwise, we will have to check these conditions later when
                    // building the mesh
                    if (!canBeMerged(bucketGeom, geom)) {
                        continue;
                    }

                    if (isSupportedUBOMaterial(material)) { // only check for compatibility if we the materials supports merging
                        if (nextBucket.fragIds.length >= MAX_FRAGMENTS_PER_CONSOLIDATED_MESH ||
                            nextBucket.materialIds.size >= getMaxMaterialsPerUBO(nextBucket.material) ||
                            !materialsAreUBOCompatible(nextBucket.material, material, this._compatibleMaterialLUT)) {
                            continue; // look for next bucket if it cannot be merged into this one
                        }
                    } else {
                        // for non-UBO materials, we can only merge if the material is the same
                        if (nextBucket.material !== material)
                            continue;
                    }

                    // this bucket would allow merging, but only if the vertex count doesn't grow too much
                    var vertexCount = getVertexCount(geom);
                    if (vertexCount + nextBucket.vertexCount > MaxVertexCountPerMesh) {
                        continue;
                    }
                }

                // we found a bucket to merge with
                bucket = nextBucket;
                break;
            }
        }

        // create a new bucket to collect this mesh
        if (!bucket) {
            bucket = new MergeBucket(material);
            this.bucketCount++;

            if (!buckets)
                this.buckets.set(id, [bucket]);
            else
                buckets.push(bucket);
        }

        // add geometry to bucket
        this.costs += bucket.addGeom(geom, worldBox, fragId, material);
    },

    /**
     * When all geometries have been added to buckets using addGeom() calls, this function converts the buckets into a
     * more compact representation called ConsolidationMap. This map summarizes all information that we need to build
     * the FragmentList consolidation.
     *
     * @param {number} numConsolidated - Number of fragments that have been consolidated already. Sll remaining ones are processed separately by instancing.
     * @param {Uint32Array} fragOrder          - Array of fragIds, in the order they were added to the ConsolidationBuilder
     * @returns {ConsolidationMap}
     */
    createConsolidationMap: function(numConsolidated, fragOrder) {

        // init result object
        let result = new ConsolidationMap(this.bucketCount, fragOrder);

        // fill fragOrder and ranges. Each range contains all fragIds of a single bucket
        var nextIndex = 0;
        var bucketIdx = 0;
        this.buckets.forEach((buckets) => {

            for (var b = 0; b < buckets.length; b++) {

                var bucket = buckets[b];

                // store start index of the range in fragOrder that corresponds to this bucket
                result.ranges[bucketIdx] = nextIndex;

                // store bucket box (no need to copy)
                result.boxes[bucketIdx] = bucket.worldBox;

                // append all fragIds in this bucket
                result.fragOrder.set(bucket.fragIds, nextIndex);

                // move nextIndex to the next range start
                nextIndex += bucket.fragIds.length;
                bucketIdx++;
            }
        });

        // remember which fragIds remain and must be processed by instancing
        result.numConsolidated = numConsolidated;

        return result;
    }
};