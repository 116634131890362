
import { LoadingSpinner } from "./LoadingSpinner";
import i18n from "i18next";
import { GlobalManagerMixin } from '../application/GlobalManagerMixin';

/** @import { TreeDelegate } from './TreeDelegate.js' */

var ELEMENT_POOL_LENGHT = 150;
var SCROLL_SAFE_PADDING = 300; // Pixels

const EXPAND_ALL = -1000;

// per model delegate tree state (expanded node ids)
class TreeState {
    expanded = new Set();
}

/**
 * TreeOnDemand view control
 * It takes ownership of the contents in parentContainer.
 *
 * @constructor
 * @param {HTMLElement} scrollContainer - DOM element parent of the tree.
 */
export function TreeOnDemand(scrollContainer, options) {

    this.dirty = false;
    this.scrollY = 0;

    /**
     * keep an ordered list of tree delegates (one for each model)
     * @type {TreeDelegate[]}
     */
    this.delegates = [];
    this.idToDelegate = {};
    this.options = options;

    var _document = this.getDocument();

    // Initialize root container.
    this.rootContainer = _document.createElement('div');
    this.rootContainer.classList.add('docking-panel-container-gradient');
    this.rootContainer.classList.add('treeview');
    this.rootContainer.classList.add('on-demand');
    scrollContainer.appendChild(this.rootContainer);

    this.paddingDiv = _document.createElement('div');
    this.paddingDiv.style['border'] = 0;
    this.paddingDiv.style['margin'] = 0;
    this.paddingDiv.style['padding'] = 0;
    this.sizedDiv = scrollContainer.parentNode; // Just a reference, we are not supposed to change it.

    // tree state per model delegate (to track expanded nodes)
    /** @type {Object.<number, TreeState>} */
    this.statePerModel = {};

    // persist until redraw
    this.scrollTarget = null;

    // Creates element pools.
    var elementsPool = [];
    var elementsPoolCount = ELEMENT_POOL_LENGHT;

    for (var i = 0; i < elementsPoolCount; ++i) {
        var element = createNodeHTmlElement(_document);
        elementsPool[i] = element;
    }

    this.elementsPool = elementsPool;
    this.elementsUsed = 0;

    this.spinner = new LoadingSpinner(scrollContainer);
    this.spinner.setGlobalManager(this.globalManager);
    this.spinner.addClass('tree-loading-spinner');

    // Add input event listeners.
    for (var i = 0; i < elementsPoolCount; ++i) {
        var element = elementsPool[i];

        element.addEventListener('click', onElementClick.bind(this));
        element.addEventListener('dblclick', onElementDoubleClick.bind(this));
        element.addEventListener('contextmenu', onElementContextMenu.bind(this));

        element.icon.addEventListener('click', onElementIconClick.bind(this));
        element.icon.addEventListener('mousedown', onElementIconMouseDown.bind(this));
    }

    redraw(this);
};

TreeOnDemand.prototype.constructor = TreeOnDemand;
GlobalManagerMixin.call(TreeOnDemand.prototype);

/**
 * A delegate is added whenever a new model is loaded into the scene.
 * The instanceTree is not available at this point.
 */
TreeOnDemand.prototype.pushDelegate = function(delegate) {

    this.delegates.push(delegate);
    this.idToDelegate[delegate.model.id] = delegate;
    this.statePerModel[delegate.model.id] = new TreeState();

    redraw(this);
};

/**
 * Removes the delegate and tree-ui for a given model id.
 *
 * Note: it will call the destructor of the delegates removed from
 *       the UI to free ressources. The delegates should not be
 *       used again.
 */
TreeOnDemand.prototype.removeDelegate = function(modelId) {
    for (var i=0; i<this.delegates.length; ++i) {
        var delegate = this.delegates[i];
        if (delegate.model.id === modelId) {
            this.delegates.splice(i, 1);
            delete this.idToDelegate[modelId];
            delete this.statePerModel[modelId];
            delegate.dtor();
            redraw(this);
            return true;
        }
    }
    return false;
};

/**
 * Specifies that the model associated to the delegate doesn't have a tree structure.
 * Probably because the property database wasn't loaded or is broken somehow.
 */
TreeOnDemand.prototype.setInstanceTree = function(delegate, instanceTree) {

    delegate.setInstanceTree(instanceTree);
    redraw(this);

    if (!instanceTree)
        return;

    var rootId = delegate.getRootId();

    var childId = 0;
    var childCount = 0;
    instanceTree.enumNodeChildren(rootId, function(child) {
        if (!childCount) {
            childId = child;
        }
        childCount++;
    });

    // Initialize collapsed states.
    this.setAllCollapsed(delegate, true);

    var excludeRoot = this.options.excludeRoot;
    var startCollapsed = this.options.startCollapsed;

    if (excludeRoot || !startCollapsed) {
        this.setCollapsed(delegate, rootId, false);
    }

    redraw(this);
};

/**
 * Show/hide the tree control
 * @param {boolean} show - true to show the tree control, false to hide it
 */
TreeOnDemand.prototype.show = function(show) {

    this.rootContainer.style.display = show ? 'block' : 'none';
};

/**
 * Get the root container
 * @nosideeffects
 * @returns {string}
 */
TreeOnDemand.prototype.getRootContainer = function() {

    return this.rootContainer;
};

/**
 * Get the tree delegate
 *
 * @nosideeffects
 * @returns {TreeDelegate}
 */
TreeOnDemand.prototype.getDelegate = function(modelId) {

    return this.idToDelegate[parseInt(modelId)];
};

/**
 * Is the given group node in the tree collapsed?
 * @nosideeffects
 * @param {Number} group the group node id
 * @returns {boolean} true if group node is collapsed, false if expanded
 */
TreeOnDemand.prototype.isCollapsed = function(delegate, group) {

    const set = this.statePerModel[delegate.model.id].expanded;

    return !set.has(EXPAND_ALL) && !set.has(group);
};

/**
 * Collapse/expand the given group node in the tree
 * @param {Object} delegate
 * @param {Number} group - the group node id
 * @param {boolean} collapsed - true to collapse the group node, false to expand it
 */
TreeOnDemand.prototype.setCollapsed = function(delegate, group, collapsed, recursive = false) {

    const set = this.statePerModel[delegate.model.id].expanded;

    // handle recursive root collapse/expand
    if (recursive && group == delegate.getRootId()) {
        this.setAllCollapsed(delegate, collapsed);
        return;
    }

    // handle specific case that all nodes are currently expanded
    if (set.has(EXPAND_ALL)) {

        // we stay expanded?
        if (!collapsed)
            return;

        set.delete(EXPAND_ALL);

        // add all child nodes
        delegate.forEachChild(delegate.getRootId(), id => { set.add(id) }, true);
    }

    const action = collapsed ? id => set.delete(id) : id => set.add(id);

    action(group);

    if (recursive) {
        delegate.forEachChild(group, (cid) => {
            delegate.isTreeNodeGroup(cid) && action(cid);
        }, true);
    }

    redraw(this);
};

/**
 * Collapse/expand all group nodes in the tree
 * @param {object} delegate
 * @param {boolean} collapsed - true to collapse tree, false to expand it
 */
TreeOnDemand.prototype.setAllCollapsed = function(delegate, collapsed) {

    const set = this.statePerModel[delegate.model.id].expanded;

    collapsed ? set.clear() : set.add(EXPAND_ALL);

    redraw(this);
};

/**
 * Add the given nodes to the current selection
 * @param {Array.<Number>} nodes - nodes to add to the current selection
 */
TreeOnDemand.prototype.addToSelection = function(delegate, nodes) {
    this.updateState();
};

/**
 * Remove the given nodes from the current selection
 * @param {Array.<Number>} nodes - The nodes to remove from the current selection
 */
TreeOnDemand.prototype.removeFromSelection = function(delegate, nodes) {
    this.updateState();
};

/**
 * Set the current selection
 * @param {Array.<Number>} nodes - nodes to make currently selected
 */
TreeOnDemand.prototype.setSelection = function(delegate, nodes) {

    this.clearSelection(delegate);
    this.addToSelection(delegate, nodes);
};

/**
 * Clear the current selection
 */
TreeOnDemand.prototype.clearSelection = function(delegate) {

    this.updateState();
};

/**
 * Is the given node selected?
 * @nosideeffects
 * @param {Object} node - The tree node
 * @returns {boolean} - true if node is selected, false otherwise
 */
TreeOnDemand.prototype.isSelected = function(node) {
    throw new Error('not implemented');
};

/**
 * Expands the Tree to have the node UI be visible.
 *
 * @param {Number} nodeId - The node id
 * @param {Autodesk.Viewing.Model} model - The model that owns the id.
 *
 */
TreeOnDemand.prototype.scrollTo = function(nodeId, model, smooth = true) {

    var delegate = this.getDelegate(model.id);
    if (!delegate || !delegate.isAvailable()) {
        // There is no tree for the model and so
        // no target to scroll to
        return;
    }

    // persist until next redraw
    this.scrollTarget = { nodeId, delegate, smooth };

    // make sure the parent nodes are expanded
    let parent = delegate.getTreeNodeParentId(nodeId);
    while (parent > 0) {
        this.setCollapsed(delegate, parent, false);
        parent = delegate.getTreeNodeParentId(parent);
    }

    redraw(this);
};

/**
 * Add a class to a node
 * @param {object} delegate
 * @param {Number|Object} node - The tree node
 * @param {string} className
 * @returns {boolean} - true if the class was added, false otherwise
 */
TreeOnDemand.prototype.addClass = function(delegate, node, className, recursive) {
    // not supported in a virtualized List that might contain millions of nodes
    throw new Error("not supported");
};

/**
 * Remove a class from a node
 * @param {object} delegate
 * @param {Number|Object} node - The tree node or its dbId
 * @param {string} className
 * @returns {boolean} - true if the class was removed, false otherwise
 */
TreeOnDemand.prototype.removeClass = function(delegate, node, className, recursive) {

    // see addClass
    throw new Error("not supported");
};

/**
 * Clears the contents of the tree
 */
TreeOnDemand.prototype.clear = function() {

    var container = this.rootContainer;
    var child;
    while (child = container.lastChild) {
        container.removeChild(child);
    }

    // clear children of delegate divs
    for (var i=0; i<this.delegates.length; ++i) {
        this.delegates[i].clean();
    }

    this.elementsUsed = 0;
};

/**
 * Iterates through nodes in the tree in pre-order.
 * @param {Object} delegate
 * @param {Object|Number} node - node at which to start the iteration.
 * @param {function(Object)} callback - callback function for each iterated node, if callbak returns false, node's chidren are not visited.
 */
TreeOnDemand.prototype.iterate = function(delegate, node, callback) {

    // roodId === 0 is a valid root node
    if (node === undefined || node === null) {
        return;
    }

    if(!delegate.shouldCreateTreeNode(node)) {
        return;
    }

    if(!callback(node)) {
        return;
    }

    delegate.forEachChild(node, function(child) {
        this.iterate(delegate, child, callback);
    }.bind(this));
};

TreeOnDemand.prototype.forEachDelegate = function(callback) {

    for (var i = 0; i < this.delegates.length; ++i) {
        callback(this.delegates[i]);
    }
};

TreeOnDemand.prototype.destroy = function() {
    this.clear();

    var scrollContainer = this.rootContainer.parentNode;
    scrollContainer.removeChild(this.rootContainer);

    this.rootContainer = null;
    this.rootId = -1;
    this.elementsPool = null;
    this.elementsUsed = -1;
    this.scrollY = -1;
};

// is also called on native scroll event (see ModelStructurePanel.js)
TreeOnDemand.prototype.setScroll = function(scrollY) {

    this.scrollTarget = null;

    // Avoid re-building the tree unless we have scrolled far enough.
    if (Math.abs(this.scrollY - scrollY) > SCROLL_SAFE_PADDING) {
        this.scrollY = scrollY;
        clearTimeout(this.scrollThrottle);
        this.scrollThrottle = setTimeout(() => redraw(this), 50);
    }
};

TreeOnDemand.prototype.displayNoProperties = function(display) {

    var _document = this.getDocument();
    if (display) {
        if (!this.divNoProps) {
            this.divNoProps = _document.createElement('div');
            var msgKey = 'Model Browser is not available';
            this.divNoProps.innerText = i18n.t(msgKey);
            this.divNoProps.setAttribute('data-i18n', msgKey);
            this.divNoProps.classList.add('lmv-no-properties');
        }
        if (!this.divNoProps.parentNode) {
            var scrollContainer = this.rootContainer.parentNode;
            scrollContainer.appendChild(this.divNoProps);
        }
    } else {
        if (this.divNoProps && this.divNoProps.parentNode) {
            this.divNoProps.parentNode.removeChild(this.divNoProps);
        }
    }
};

TreeOnDemand.prototype.isExcludeRoot = function() {
    return this.delegates.length === 1 ? this.options.excludeRoot : false;
};

TreeOnDemand.prototype.getDelegateCount = function() {
    return this.delegates.length;
};

// sync css state (visible, selected) of rendered elements
TreeOnDemand.prototype.updateState = function() {

    for (let i = 0; i < this.elementsUsed; i++) {
        const elm = this.elementsPool[i];

        const modelId = getModelIdFromElement(this, elm);
        const nodeId = getNodeIdFromElement(this, elm);
        const delegate = this.getDelegate(modelId);

        if (delegate.isTreeNodeGroup(nodeId)) {
            const collapsed = this.isCollapsed(delegate, nodeId);
            elm.classList.toggle('collapsed', collapsed);
            elm.classList.toggle('expanded', !collapsed);
        }

        delegate.updateTreeNodeState(nodeId, elm);
    }
}

function getTreeNodeParentMaxSize(tree)
{
    return {
        width:  tree.sizedDiv.clientWidth  | 0x0,
        height: tree.sizedDiv.clientHeight | 0x0
    }
}

/**
 * @private
 * Renders the current state of the tree and its delegates (if any)
 * Handles rendering when there are no delegates and when there is only 1 in loading state.
 * Delegates most of the hard rendering to createVisibleElements()
 *
 * @param {TreeOnDemand} tree
 */
function renderNow(tree) {

    // already refreshed in previous frame
    if (!tree.dirty)
        return;

    // already destroyed?
    if (!tree.rootContainer)
        return;

    // Clear
    tree.dirty = false;
    clearElementTree(tree);
    tree.displayNoProperties(false);

    // Special case 1: There are no models loaded into LMV
    if (tree.delegates.length === 0) {
        // An empty panel is all we want.
        tree.spinner.setVisible(false);
        return;
    }

    // Special case 2: Single model, properties are still loading
    if (tree.delegates.length === 1 && tree.delegates[0].isLoading()) {
        tree.spinner.setVisible(true);
        return;
    }

    // Special case 3: Single model, properties failed to load
    if (tree.delegates.length === 1 && tree.delegates[0].isNotAvailable()) {
        tree.spinner.setVisible(false);
        tree.displayNoProperties(true);
        return;
    }

    // Will render the tree delegate items at this point...
    tree.spinner.setVisible(false);

    // Render InstanceTree nodes
    createVisibleElements(tree);

    tree.updateState();
}

function getRootChildIds(delegate) {
    const ret = [];
    const rootId = delegate.getRootId();

    delegate.forEachChild(rootId, (cid) => { ret.push(cid) });

    return ret;
}

function numParents(delegate, nodeId) {
    let count = 0;
    let parent = nodeId;
    while (parent != delegate.getRootId()) {
        count++;
        parent = delegate.getTreeNodeParentId(parent);
    }
    return count;
}

/**
 * @private
 * Generates the visible DIVs for the model browser.
 *
 * @param {TreeOnDemand} tree
 */
function createVisibleElements(tree) {

    var container = tree.rootContainer;
    var parentDimensions = getTreeNodeParentMaxSize(tree);
    var CONTAINER_HEIGHT = parentDimensions.height;
    var currentHeight = 0;
    var paddingHeight = 0;
    var adding = true;

    const scrollTarget = tree.scrollTarget;

    // Add a top-padding element that stretches until the first element shows
    container.appendChild(tree.paddingDiv);

    var delegates = tree.delegates.slice(0);
    var excludeRoot = tree.isExcludeRoot();

    while (delegates.length) {

        var delegate = delegates.shift();

        // Each tree element gets added into its parent model-div
        var modelDiv = delegate.modelDiv;
        container.appendChild(modelDiv);

        var ids = excludeRoot ? getRootChildIds(delegate) : [delegate.getRootId()];

        while (ids.length && adding) {

            // Any more room vertically?
            if (currentHeight > tree.scrollY + CONTAINER_HEIGHT + SCROLL_SAFE_PADDING) {
                adding = false;
                break;
            }

            // Any more DIVs in the pool?
            if (tree.elementsUsed === tree.elementsPool.length) {
                adding = false;
                break;
            }

            var id = ids.shift();

            //skip non-loaded nodes
            if (!delegate.shouldCreateTreeNode(id)) {
                continue;
            }

            var elemHeight = delegate.getTreeNodeClientHeight(id);
            var elemTop = currentHeight;
            var elemBtm = elemTop + elemHeight;

            // render this node
            if ((elemHeight > 0) && (elemBtm + SCROLL_SAFE_PADDING) >= tree.scrollY) {

                // Actually add the element...
                var element = tree.elementsPool[tree.elementsUsed++];
                element.setAttribute("lmv-nodeId", id);
                element.className = '';

                delegate.createTreeNode(id, element.header);
                var css = delegate.getTreeNodeClass(id);
                if (css) {
                    element.classList.add(css);
                }

                // calculate indent
                let indent = numParents(delegate, id);
                excludeRoot && indent--;

                var offset = delegate.getTreeNodeDepthOffset(id, indent);
                element.header.style.paddingLeft = offset + 'px';

                modelDiv.appendChild(element);
            }

            if (elemBtm + SCROLL_SAFE_PADDING < tree.scrollY) {
                paddingHeight = elemBtm;
            }

            // move height counter
            currentHeight = elemBtm;

            // store scroll target offset
            if (scrollTarget && scrollTarget.nodeId === id && scrollTarget.delegate === delegate) scrollTarget.top = elemTop;

            // enqueue children
            if (!tree.isCollapsed(delegate, id)) {

                let i = 0;
                delegate.forEachChild(id, cId => { ids.splice(i++, 0, cId); return false; });
            }

        } // while-ids


        // Update top-padding height.
        tree.paddingDiv.style.height = paddingHeight + 'px';

        // recursively visit node+children
        const traverse = (id, cb) => {
            // is the node filtered?
            if (!delegate.shouldCreateTreeNode(id)) return;

            cb(id);

            const expanded = !tree.isCollapsed(delegate, id);
            expanded && delegate.forEachChild(id, cid => traverse(cid, cb));
        }

        // If there are ids left, we need to process them to get the total height
        for (const id of ids) {

            let recursiveHeight = 0;
            traverse(id, (id) => {

                // store scroll target offset
                if (scrollTarget && scrollTarget.nodeId === id && scrollTarget.delegate === delegate) scrollTarget.top = currentHeight + recursiveHeight;

                recursiveHeight += delegate.getTreeNodeClientHeight(id);
            });

            currentHeight += recursiveHeight;
        }


    } // while-delegates

    container.style.height = currentHeight + 'px';

    if (scrollTarget) {
        const maxDistance = 25000;
        const distance = Math.abs(scrollTarget.top - container.parentElement.scrollTop);

        // disable smooth scrolling on very tall lists (makes you dizzy)
        const smooth = scrollTarget.smooth && distance < maxDistance;

        const behavior = smooth ? 'smooth' : 'instant';
        container.parentElement.scroll({ top: scrollTarget.top, behavior });
    }
}

function clearElementTree(tree) {
    var elementsUsed = tree.elementsUsed;
    var elementsPool = tree.elementsPool;

    // Return used elements to the elements pool.
    for (var i = 0; i < elementsUsed; ++i) {

        var element = elementsPool[i];

        // Remove all controls and listeners added by tree delegate, we spare the icon.
        var header = element.header;
        var childrenToRemove = header.childNodes.length - 1;

        // Remove event listener
        if (header._mouseDownListener) {
            header.removeEventListener('mousedown', header._mouseDownListener);
            header._mouseDownListener = null;
        }

        for (var j = 0; j < childrenToRemove; ++j) {
            header.removeChild(header.lastChild);
        }

        if (header.lastChild._mouseDownListener) {
            header.lastChild.removeEventListener('mousedown', header.lastChild._mouseDownListener);
            header.lastChild._mouseDownListener = null;
        }

        if (header.lastChild._clickListener) {
            header.lastChild.removeEventListener('click', header.lastChild._clickListener);
            header.lastChild._clickListener = null;
        }
    }
    tree.clear();
}

/**
 *
 * @param {TreeOnDemand} tree
 * @param {boolean} immediate
 */
function redraw(tree, immediate = false) {

     // If the panel is not dirty, marked as dirty and schedule an update during next frame.
    if (tree.dirty && !immediate) {
        return;
    }

    if (immediate) {
        renderNow(tree);
    } else {
        tree.dirty = true;

        // All update requests are executed as one during next frame.
        requestAnimationFrame(() => renderNow(tree));
    }
}

/**
 * Returns the node associated to the html element provided
 * @private
 * @param {*} tree - A TreeOnDemand object instance.
 * @param {*} element - A node object or a string or number with the id of the node.
 * @returns {Number} Node object associated with with the html control.
 */
function getNodeIdFromElement(tree, element) {

    var nodeElement = null;

    while (element && element !== tree.rootContainer) {
        if (element.hasAttribute("lmv-nodeId")) {
            nodeElement = element;
            break;
        }
        element = element.parentElement;
    }

    if(!nodeElement) {
        return null;
    }

    var nodeId = nodeElement.getAttribute("lmv-nodeId");
    return parseInt(nodeId);
};

function getModelIdFromElement(tree, element) {

    var nodeElement = null;

    while (element && element !== tree.rootContainer) {
        if (element.hasAttribute("lmv-modelId")) {
            nodeElement = element;
            break;
        }
        element = element.parentElement;
    }

    if(!nodeElement) {
        return null;
    }

    var modelId = nodeElement.getAttribute("lmv-modelId");
    return parseInt(modelId);
};

/**
 * Given a node, create the corresponding HTML elements for the node and all of its descendants
 * @private
 * @param {Object} tree - TreeOnDemand node
 * @param {Object=} [options] - An optional dictionary of options.  Current parameters:
 *                              {boolean} [localize] - when true, localization is attempted for the given node; false by default.
 * @param {Number} [depth]
 */
function createNodeHTmlElement(_document, tree, options) {

    var header = _document.createElement('lmvheader');

    var icon = _document.createElement('icon');
    header.appendChild(icon);

    var element = _document.createElement('div');
    element.header = header;
    element.icon = icon;
    element.appendChild(header);

    return element;
};

/**
 *
 * @param {*} event
 */
function onElementClick(event) {

    // Click has to be done over the children of the tree elements.
    // Group and leaf nodes are only containers to layout consumer content.
    if (event.target.classList.contains('group') ||
        event.target.classList.contains('leaf')) {
        return;
    }

    var nodeId = getNodeIdFromElement(this, event.target);
    if(!nodeId) {
       return;
    }

    var modelId = getModelIdFromElement(this, event.target);
    var delegate = this.getDelegate(modelId);
    if (!delegate) {
        return;
    }

    delegate.onTreeNodeClick(this, nodeId, event);
    event.stopPropagation();
    event.preventDefault();
}

/**
 *
 * @param {*} event
 */
function onElementDoubleClick(event) {

    // Click has to be done over the children of the tree elements.
    // Group and leaf nodes are only containers to layout consumer content.
    if (event.target.classList.contains('group') ||
        event.target.classList.contains('leaf')) {
        return;
    }

    var nodeId = getNodeIdFromElement(this, event.target);
    if(!nodeId) {
        return;
    }

    var modelId = getModelIdFromElement(this, event.target);
    var delegate = this.getDelegate(modelId);
    if (!delegate) {
        return;
    }

    delegate.onTreeNodeDoubleClick(this, nodeId, event);
    event.stopPropagation();
    event.preventDefault();
}

/**
 *
 * @param {*} event
 */
function onElementContextMenu(event) {

    // Click has to be done over the children of the tree elements.
    // Group and leaf nodes are only containers to layout consumer content.
    if (event.target.classList.contains('group') ||
        event.target.classList.contains('leaf')) {
        return;
    }

    var nodeId = getNodeIdFromElement(this, event.target);
    if(!nodeId) {
        return;
    }

    var modelId = getModelIdFromElement(this, event.target);
    var delegate = this.getDelegate(modelId);
    if (!delegate) {
        return;
    }

    delegate.onTreeNodeRightClick(this, nodeId, event);
    event.stopPropagation();
    event.preventDefault();
}

/**
 *
 * @param {*} event
 */
function onElementIconClick(event) {

    var nodeId = getNodeIdFromElement(this, event.target);
    if(!nodeId) {
        return;
    }

    var modelId = getModelIdFromElement(this, event.target);
    var delegate = this.getDelegate(modelId);
    if (!delegate) {
        return;
    }

    delegate.onTreeNodeIconClick(this, nodeId, event);
    event.stopPropagation();
    event.preventDefault();
}

/**
 *
 * @param {*} event
 */
function onElementIconMouseDown(event) {

    event.stopPropagation();
    event.preventDefault();
}
