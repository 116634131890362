import { CpuBuffer } from './CpuBuffer';

//Base class for simple uniform buffers
export class UniformBuffer extends CpuBuffer {

    /** @type {GPUDevice} */
    #device;

    /** @property {GPUBuffer} */
    #buffer;
    #layout;
    #bindGroup;

    #visibility;

    #dirty = false;

    #label;

    #readBuffer;
    #debug = false;

    constructor(device, sizeInFloats, visibleInFragment = true, visibleInVertex = true, label = '', debug = false) {
        let roundedSize = sizeInFloats;
        let rm = sizeInFloats % 4;
        if (rm) {
            roundedSize += 4 - rm;
        }
        super(roundedSize);

        this.#device = device;
        this.#label = label || '';
        this.#debug = debug;


        let visibility = 0;
        if (visibleInFragment) {
            visibility |= GPUShaderStage.FRAGMENT;
        }

        if (visibleInVertex) {
            visibility |= GPUShaderStage.VERTEX;
        }

        this.#visibility = visibility;

        this.#createResources();
    }

    #createResources() {
        if (this.#buffer || !this.#device) {
            return;
        }

        const size = this.getCpuBuffer().byteLength;
        if (this.#debug && !this.#readBuffer) {
            this.#readBuffer = this.#device.createBuffer({
                size,
                usage: GPUBufferUsage.COPY_DST | GPUBufferUsage.MAP_READ,
            });
        }

        this.#buffer = this.#device.createBuffer({
            label: this.#label,
            size,
            usage: GPUBufferUsage.UNIFORM | GPUBufferUsage.COPY_DST | (this.#debug ? GPUBufferUsage.COPY_SRC : 0),
        });

        this.#layout = this.#device.createBindGroupLayout({
            entries: [
                {
                    binding: 0,
                    visibility: this.#visibility,
                    buffer: {}
                },
            ]
        });

        this.#bindGroup = this.#device.createBindGroup({
            layout: this.#layout,
            entries: [
                {
                    binding: 0,
                    resource: {
                        buffer: this.#buffer,
                    },
                }
            ],
        });

    }


    setDevice(device) {
        this.#device = device;
        this.#createResources();
    }

    getLayout() {
        return this.#layout;
    }

    getBindGroup() {
        return this.#bindGroup;
    }

    getVisibility() {
        return this.#visibility;
    }

    /** @returns {GPUBuffer} */
    getBuffer() {
        return this.#buffer;
    }

    upload() {
        const cpuBuffer = this.getCpuBuffer();
        this.#device.queue.writeBuffer(
            this.#buffer,
            0,
            cpuBuffer.buffer,
            0,
            cpuBuffer.byteLength
        );
    }

    copyBufferToRead(commandEncoder) {
        if (!this.#readBuffer) return;

        commandEncoder.copyBufferToBuffer(this.#buffer, 0, this.#readBuffer, 0, this.#buffer.size);
    }

    async download() {
        if (!this.#readBuffer) return new ArrayBuffer();

        await this.#readBuffer.mapAsync(GPUMapMode.READ, 0);
        const data = this.#readBuffer.getMappedRange().slice(0);
        this.#readBuffer.unmap();
        return data;
    }

}
