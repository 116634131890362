
import {$wgsl} from "../../wgsl-preprocessor/wgsl-preprocessor";
import pack_depth from "../chunks/pack_depth.wgsl";
import theming_and_highlighting from "../chunks/theming_and_highlighting.wgsl";
import { getObjectUniformsDeclaration } from "./uniforms/ObjectUniforms";
import {getMaterialUniformsDeclaration} from "./MaterialUniforms";
import basicShader from "./basic.wgsl";
import {FrameBindGroup} from "./FrameBindGroup";

export function getBasicShader() {
    return $wgsl(basicShader, {
        frameBindGroup: FrameBindGroup.getDeclaration(0),
        objectUniforms: getObjectUniformsDeclaration(1),
        materialUniforms: getMaterialUniformsDeclaration(2),
        pack_depth,
        theming_and_highlighting
    });
}
