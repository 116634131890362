import {initCubeMap, refTexture, unrefTexture} from "../Texture";
import vertexTextureQuad from "../post/quad.vert.wgsl";
import {getEnvMapShader} from "./EnvMapShader";
import {UniformBuffer} from "../UniformBuffer";
import {LmvVector3} from "../../scene/LmvVector3";

let _tmp = new LmvVector3();

class EnvMapUniforms extends UniformBuffer {

    constructor(device) {
        super(device, 14, true, false);
    }

    update(camera, size, envMapExposure, exposureBias, tonemapMethod) {

        let uCamDir = camera.worldUpTransform ? camera.getWorldDirection(_tmp).applyMatrix4(camera.worldUpTransform) : camera.getWorldDirection(_tmp);
        this.setVector3(0, uCamDir);

        let uCamUp = camera.worldUpTransform ? camera.up.clone().applyMatrix4(camera.worldUpTransform) : camera.up;
        this.setVector3(4, uCamUp);

        this.setFloat(8, size[0]);
        this.setFloat(9, size[1]);

        this.setFloat(10, Math.tan(THREE.Math.degToRad(camera.fov * 0.5)));
        this.setFloat(11, envMapExposure);
        this.setFloat(12, exposureBias);
        this.setInt(13, tonemapMethod);
    }

}


export function EnvMapPass(renderer) {

    let _renderer = renderer;
    let _device;

    let _cubeMap;
    let _isGammaEncoded = true;
    let _bindGroupNeedsUpdate = false;

    let _exposureBias = 1.0;
    let _envMapExposure = 1.0;
    let _tonemapMethod = 0;
    let _uniforms;

    // Opacity used for EnvMap pass output.
    let _alpha = 1.0;

    let _presentPipeline;
    let _pipelineNeedsUpdate = false;
    let _presentBindGroupLayout;
    let _presentBindGroup;
    let _presentPassDescriptor;

    function _createPipeline() {
        _presentPipeline = _device.createRenderPipeline({
            layout: _device.createPipelineLayout({
                bindGroupLayouts: [_presentBindGroupLayout, _uniforms.getLayout()]
            }),
            vertex: {
                module: _device.createShaderModule({
                    label: 'envmap vertex shader',
                    code: vertexTextureQuad
                }),
                entryPoint: "main"
            },
            fragment: {
                module: _device.createShaderModule({
                    label: 'envmap fragment shader',
                    code: getEnvMapShader(_isGammaEncoded, _tonemapMethod)
                }),
                entryPoint: "main",
                targets: [
                    {
                        format: _renderer.getRenderTargets().getColorTarget().format,
                        blend: {
                            // The EnvMapPass overwrites destination values.
                            // We output pre-multiplied alpha with a value set by pass.setBlendConstant([r, g, b, a]);
                            // The shader must output unmultiplied-alpha rgb values with alpha set to 1.0.
                            color: {
                                operation: 'add',
                                srcFactor: 'constant', // set by pass.setBlendConstant
                                dstFactor: 'zero',
                            },
                            alpha: {
                                operation: 'add',
                                srcFactor: 'constant', // set by pass.setBlendConstant
                                dstFactor: 'zero',
                            }
                        }
                    }
                ]
            },
            primitive: {
                topology: 'triangle-list',
                cullMode: 'back',
            }
        });

        _pipelineNeedsUpdate = false;
    }

    this.init = function() {
        _device = _renderer.getDevice();
        _uniforms = new EnvMapUniforms(_device);

        if (!_presentBindGroupLayout) {
            _presentBindGroupLayout = _device.createBindGroupLayout({
                label: 'env map pass present bind group layout',
                entries: [
                    {
                        binding: 0,
                        visibility: GPUShaderStage.FRAGMENT,
                        texture: {
                            sampleType: 'float',
                            viewDimension: "cube"
                        }
                    },
                    {
                        binding: 1,
                        visibility: GPUShaderStage.FRAGMENT,
                        sampler: {}
                    }
                ]
            });
        }

        if (!_presentPipeline) {
            _createPipeline();
        }

        if (!_presentPassDescriptor) {
            _presentPassDescriptor = {
                colorAttachments: [
                    {
                        // view is acquired and set in render loop.
                        view: undefined,

                        // Note on 'load':  Our blend settings overwrite the destination.
                        // Clear is not necessary and breaks multi-viewport rendering (as it clears the whole target).
                        loadOp: 'load',
                        storeOp: 'store',
                    },
                ],

            };
        }

    };



    this.setCubeMap = function(map) {
        if (_presentBindGroup) {
            _bindGroupNeedsUpdate = true;
        }

        refTexture(map);
        unrefTexture(_cubeMap);

		_cubeMap = map;

		// Note that the new _cubeMap value might be null.
		// For this case, gammaEncoded flag is meaningless and we just assume it to be false.
		const gammaEncoded = !!_cubeMap?.GammaEncoded;
		if (gammaEncoded !== _isGammaEncoded) {
			_isGammaEncoded = gammaEncoded;
			_pipelineNeedsUpdate = true;
		}
	};

    this.getCubeMap = function() {
        return _cubeMap;
    };

    this.hasCubeMap = function() {
        return !!_cubeMap;
    };

    this.setEnvExposure = function(exposure) {

        const newValue = Math.pow(2.0, exposure);

        if (newValue !== _envMapExposure) {
            _envMapExposure = newValue;
        }
    };

    this.setExposureBias = function(bias) {
        let newValue = Math.pow(2.0, bias);

        if (newValue !== _exposureBias) {
            _exposureBias = newValue;
        }
    };

    this.setTonemapMethod = function(value) {
        if (_tonemapMethod !== value) {
            _tonemapMethod = value;
            _pipelineNeedsUpdate = true;
        }
    };

    this.setEnvRotation = function(rotation) {
        //TODO:
    };

    this.setCamera = function(camera) {

        if (!_device) {
            return;
        }

        let size = _renderer.getRenderTargets().getTargetSize();
        _uniforms.update(camera, size, _envMapExposure, _exposureBias, _tonemapMethod);

    };

    this.setAlpha = function(alpha) {
        _alpha = alpha;
    };

    this.run = function() {

        if (!_device) {
            return;
        }

        if(_cubeMap.needsUpdate || !_presentBindGroup || _bindGroupNeedsUpdate) {
            initCubeMap(_device, _cubeMap);

            //This needs to be recreated when render targets change
            _presentBindGroup = _device.createBindGroup({
                layout: _presentBindGroupLayout,
                entries: [
                    {
                        binding: 0,
                        resource: _cubeMap.__gpuTextureCube.createView({
                            label: "cube map view",
                            dimension: "cube"
                        })
                    },
                    {
                        binding: 1,
                        resource: _cubeMap.__gpuSampler
                    }
                ]
            });

            _bindGroupNeedsUpdate = false;
        }

        if (_pipelineNeedsUpdate) {
            _createPipeline();
        }

        _uniforms.upload();

        let commandEncoder = _device.createCommandEncoder({ label: 'env map encoder' });

        _presentPassDescriptor.colorAttachments[0].view = _renderer.getRenderTargets().getColorTargetView();

        const pass = commandEncoder.beginRenderPass(_presentPassDescriptor);
        // false-positive issue detected by Chorus, see https://semgrep.dev/docs/ignoring-files-folders-code
        // nosemgrep
        pass.label = 'envmap pass';
        _renderer.passViewport(pass);

        pass.setPipeline(_presentPipeline);
        pass.setBlendConstant([_alpha, _alpha, _alpha, _alpha]);
        pass.setBindGroup(0, _presentBindGroup);
        pass.setBindGroup(1, _uniforms.getBindGroup());
        pass.draw(3);
        pass.end();

        _device.queue.submit([commandEncoder.finish()]);
    };

}
