// Convenience class for setting various types in a TypedArray.
export class CpuBuffer {

    /** @type {Float32Array} */
    #buffer;
    /** @type {Into32Array} */
    #intBuffer;

    /**
     * @param {number} sizeInFloats
     */
    constructor(sizeInFloats) {
        this.#buffer = new Float32Array(sizeInFloats);
        this.#intBuffer = new Int32Array(this.#buffer.buffer);
    }

    /**
     * @returns {Float32Array}
     */
    getCpuBuffer() {
        return this.#buffer;
    }

    setFloat(offset, value) {
        this.#buffer[offset] = value;
    }

    getFloat(offset) {
        return this.#buffer[offset];
    }

    setFloat2(offset, x, y) {
        this.#buffer[offset] = x;
        this.#buffer[offset + 1] = y;
    }

    setFloat4(offset, x, y, z, w) {
        this.#buffer[offset] = x;
        this.#buffer[offset + 1] = y;
        this.#buffer[offset + 2] = z;
        this.#buffer[offset + 3] = w;
    }

    setVector2(offset, value) {
        this.#buffer[offset] = value.x;
        this.#buffer[offset + 1] = value.y;
    }

    setVector3(offset, value) {
        this.#buffer[offset] = value.x;
        this.#buffer[offset + 1] = value.y;
        this.#buffer[offset + 2] = value.z;
    }

    setVector4(offset, value) {
        this.#buffer[offset] = value.x;
        this.#buffer[offset + 1] = value.y;
        this.#buffer[offset + 2] = value.z;
        this.#buffer[offset + 3] = value.w;
    }

    setColor(offset, color, opacity) {
        this.#buffer[offset] = color.r;
        this.#buffer[offset + 1] = color.g;
        this.#buffer[offset + 2] = color.b;

        if (opacity !== undefined) {
            this.#buffer[offset + 3] = opacity;
        }
    }

    /**
     * @param {number} offset write offset in floats
     * @param {THREE.Matrix3 | number[]} src - source matrix (as Matrix3 or plain array)
     */
    setMatrix3x3(offset, src) {
        const values = src.elements ?? src;
        //Note the 4-float padding on the destination
        for (let i = 0; i < 3; i++) {
            for (let j = 0; j < 3; j++) {
                this.#buffer[offset + j + i * 4] = values[j + i * 3];
            }
        }
    }

    /**
     * @param {number} offset write offset in floats
     * @param {THREE.Matrix4 | number[]} src - source matrix (as Matrix3 or plain array)
     */
    setMatrix4x4(offset, src) {
        const values = src.elements || src;
        this.#buffer.set(values, offset);
    }

    setInt = function(offset, value) {
        this.#intBuffer[offset] = value;
    };

    getInt(offset) {
        return this.#intBuffer[offset];
    }

    setBuffer(offset, buffer) {
        this.#buffer.set(buffer, offset);
    }

}
