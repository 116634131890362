import { endpoint, getEnv, setEnv } from "../file-loaders/net/endpoints";
import { EnvironmentConfigurations, derivativeStreamingAUSUrls } from "../envinit";
import { fromUrlSafeBase64 } from "../globals";

/**
 * Legacy function to provide a backward compatibility when the `endpoints` API is disabled
 *
 * @deprecated
 * @param {string} documentId
 */
export function setRegionalEndpointAndApi(documentId) {
    if (Autodesk.Viewing.Private.useDSEndpoints()) return;

    function urnMatches(patterns) {
        let urn = documentId.split(':');
        urn = urn[1];
        const decodedUrn = fromUrlSafeBase64(urn);
        for (let i = 0; i < patterns.length; i++) {
            if (decodedUrn.indexOf(patterns[i]) > -1) {
                return true;
            }
        }
        return false;
    }

    const ausPatterns = ['urn:adsk.wipprodanz', 'urn:adsk.wipstganz'];
    if ((documentId.startsWith('urn') && urnMatches(ausPatterns)) || endpoint.getApiFlavor().match(/AUS/i) || endpoint.getApiFlavor().match(/APAC/i)) {

        let api = endpoint.getApiFlavor();
        if (!api.match(/AUS/i) && !api.match(/APAC/i)) api += "_AUS";

        if (urnMatches(["wipstganz"])) { // namespace in the URN indicates staging environment
            endpoint.setEndpointAndApi(derivativeStreamingAUSUrls.stg, api);
        } else if (urnMatches(["wipprodanz"])) { // namespace in the URN indicates production environment
            endpoint.setEndpointAndApi(derivativeStreamingAUSUrls.prod, api);
        } else { // must derive environment from user inputted parameters

            const env = getEnv();
            if (env.match(/prod/i) !== null) {
                endpoint.setEndpointAndApi(derivativeStreamingAUSUrls.prod, api);
            } else if (env.match(/stag/i) !== null) {
                endpoint.setEndpointAndApi(derivativeStreamingAUSUrls.stg, api);
            }
        }
    }

    const euPatterns = ['urn:adsk.wipemea', 'urn:adsk.wipbimemea'];
    if (documentId.startsWith('urn') && urnMatches(euPatterns)) {
        var api = endpoint.getApiFlavor();
        var env = getEnv();
        var isDSv2 = api === endpoint.ENDPOINT_API_DERIVATIVE_SERVICE_V2 && !env.endsWith('EU');
        var isD3S = env.endsWith('US') && api === endpoint.ENDPOINT_API_D3S;
        var isStreamingV2 = api === endpoint.ENDPOINT_API_DERIVATIVE_STREAMING && !api.endsWith('_EU');
        api += '_EU';
        if (isDSv2) {
            endpoint.setEndpointAndApi(null, api);
        } else if (isD3S) {
            env = env.replace("US", "EU");
            setEnv(env);
            var config = EnvironmentConfigurations[env];
            endpoint.setEndpointAndApi(config['ROOT'], api);
        } else if (isStreamingV2) {
            endpoint.setEndpointAndApi(null, api);
        }
    }
}
