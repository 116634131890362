import { useEarlyConsolidation } from '../../../consolidation/Consolidation';
import { OutOfCoreTaskBase } from "../OutOfCoreTaskBase";

export class LoadFraglistTask extends OutOfCoreTaskBase {

    /**
     *
     * @param {OutOfCoreTileManager} outOfCoreTileManager The OutOfCoreTileManager instance
     * @param {*} callback The callback to be executed
     */
    constructor(outOfCoreTileManager, node) {
        super(outOfCoreTileManager);

        this.node = node;
    }

    async *execute() {
        const loader = this.node.model.loader;
        const data = await loader.getCachedFragListChunk(this.node.nodeId);

        // Need to do this before parsing, otherwise mat hashes might be missing in tryToActivateFragment
        await loader.svf.materialHashlistPromise;

        if (!data) {
            // TODO: Once server side BVH chunks are available, request the chunk from the server
            throw new Error(`Cache corrupted. Failed to load fragment list chunk for node ${this.node.nodeId} from cache.`);
        }

        yield;

        loader.processFragListChunk(this.node.nodeId, data);

        // Update the node's bounding box after the fragment list has been loaded
        this.node.model.getIterator().updateBounds(this.node.nodeId);

        this.outOfCoreTileManager.requestGeometryForNode(this.node.nodeId);
    }
}

/**
 * Creates a task to load the fragment list
 *
 * @param {BvhNode} node - The ID of the BVH node
 * @param {Stage} stage - The stage of the task
 */
export function initializeFraglistLoadedTasks(node, stage) {
    const loader = node.model.loader;
    if (useEarlyConsolidation(node.model) && loader.loadFromCache) {
        node.addTask(new LoadFraglistTask(node.outOfCoreTileManager, node), stage);
    }
}
