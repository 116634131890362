import {VertexBuffer} from "./VertexBuffer";
import {BlendPass} from "./post/BlendPass";
import {MainPass} from "./main/MainPass";
import {GradientPass} from "./clear/GradientPass";
import {EnvMapPass} from "./clear/EnvMapPass";
import {SAOPass} from "./ssao/SAOPass";
import {GroundShadowPass} from "./ground/GroundShadowPass";
import {CommonRenderTargets} from "./CommonRenderTargets";
import {RenderBatchUniforms} from "./main/uniforms/RenderBatchUniforms";
import {getMaterialTextureMask, setMaterialTextureUpdateCallback, MaterialUniformFlags} from "./main/MaterialUniforms";
import { BufferGeometry } from "three";
import { getFallbackGeometry } from "./sceneToBatch";
import { BufferGeometryUtils } from "../../wgs/scene/BufferGeometry";
import { Model } from "../../application/Model";

const Events = {
    WEBGPU_DEVICE_LOST: 'webgpudevicelost',
    WEBGPU_INIT_FAILED: 'webgpuinitfailed',
    WEBGPU_RENDER_DONE: 'webgpurenderdone',
};


/**
 * @enum {Number}
 */
export const ToneMapMethod = {
    None: 0,
    CanonLum: 1,
    CanonRGB: 2
};


// TODO: Increasing this value leads to significantly faster rendering for many models,
// but it also introduces input delay and affects overall smoothness.
// I've set it to a low value for now and need to investigate more later.
const commandSubmitThreshold = 3;

export function Renderer(device, params) {

    let _gpu;
    /** @type {GPUDevice} */
    let _device = device;
    let _canvas;
    let _initDone;
    let _pixelRatio;
    let _tonemapMethod = 0;
    const _envEncoding = { isGamma: true, isRGBM: false };

    /** @type {VertexBuffer} */
    let _vb;
    /** @type {GPUTextureFormat} */
    let _presentationFormat;
    /** @type {RenderBatchUniforms} */
    let _renderBatchUniforms;
    let _commandGroups = [];
    let _3dModels = new Set();
    let _3dModelBundlesInvalidated = new Map();
    let _3dModelVisibilityListener = new Map();

    let _renderTargets = new CommonRenderTargets(this);
    /** @type {GPUTexture|null} */
    let _offscreenTarget = null;
    let _mainPass = new MainPass(this);
    let _postPass = new BlendPass(this);
    let _gradientPass = new GradientPass(this);
    let _envMapPass = new EnvMapPass(this);
    let _sao = new SAOPass(this);
    let _groundShadowPass = new GroundShadowPass(this);

    /** @type {GPUSampler} */
    let _repeatSampler;
    /**
     * @typedef {Object} TextureInfo
     * @property {GPUTexture} texture
     * @property {GPUTextureView} view
     * @property {GPUSampler} sampler
     */
    /** @type {TextureInfo} Ugly pattern that can signal a missing texture. */
    let _missingTexture;
    /** @type {TextureInfo} */
    let _transparentTexture;

    _canvas = params.canvas || document.createElement("canvas");
    _gpu = _canvas.getContext("webgpu");
    _pixelRatio = params.pixelRatio;

    // Initialized by a call to setSize during initialization.
    let _viewportX = null;
    let _viewportY = null;
    let _viewportWidth = null;
    let _viewportHeight = null;

    // Used to allow temporary replacements of the viewport and recovering the original afterwards
    let _viewportStack = [];

    this.context = _gpu;

    Autodesk.Viewing.EventDispatcher.prototype.apply(this);

    // Register a material update callback that invalidates render bundles if the textures of a material changed.
    setMaterialTextureUpdateCallback((event) => {
        const material = event.target;
        const materialTextureMask = material.__gpuUniformsMask & MaterialUniformFlags.TEXTURE_MASK;
        const newMaterialTextureMask = getMaterialTextureMask(material);
        if (materialTextureMask !== newMaterialTextureMask) {
            // Note that this might be called quite often while loading the model.
            // Up to number of textures * number of materials that use the texture.
            // It would be better to disable render bundles while loading large textured models altogether,
            // but determining this and re-enabling render bundles after loading seems complex and invasive.
            // We might still want to improve things, e.g. by using a heuristic that deactivates bundles if
            // this gets called too often, and enables them again if they haven't been invalidated for some
            // time or number of frames.
            this.invalidateRenderBundles();
        }
    });


    // If the device is already available, we can initialize synchronously
    this.initSync = function(targetWidth, targetHeight) {

        _renderBatchUniforms = new RenderBatchUniforms(this);
        _presentationFormat = navigator.gpu.getPreferredCanvasFormat();

        // Note: While the WebGL renderer's context is configurable on construction via
        //       params.premultipliedAlpha, WebGPU always uses premultiplied.
        _gpu.configure({
            device: _device,
            format: _presentationFormat,
            alphaMode: "premultiplied",
        });

        _initDone = true;

        //Order matters below

        initGlobalTextures();

        _vb = new VertexBuffer(this);

        _renderTargets.init();
        _mainPass.init(_renderBatchUniforms);
        _sao.init();
        _postPass.init();
        _groundShadowPass.init(_renderBatchUniforms);

        this.setSize(targetWidth, targetHeight);

        _gradientPass.init();
        _envMapPass.init();

        _device.lost.then((info) => {
            console.error(`WebGPU device was lost: ${info.message}`);

            _device = null;

            // Please also note there is no "restore" event
            this.fireEvent({ type: Events.WEBGPU_DEVICE_LOST });
        });
    };

    function modelVisibilityDirtyCallback(model, renderer) {
        if (!this.visibilityDirty) {
            this.visibilityDirty = true;

            renderer.invalidateRenderBundles(model);
        }
    }


    /**
      * Reset dirty flag for model visibility.
      * @param {number} modelId
      */
    this.clear3dModelVisibilityDirty = function(modelId) {
        _3dModelVisibilityListener.get(modelId).visibilityDirty = false;
    };

    /**
     * The WebGPU renderer needs knowledge about 3D models for some features (e.g. render bundles, uniform batching).
     * @param {Model} model
     */
    this.add3dModel = function(model) {
        if (!model.is3d()) {
            console.warn("Called add3dModel with 2D model.")
            return;
        }

        _3dModels.add(model);
        _renderBatchUniforms.addModel(model);

        const listener = { visibilityDirty: false };
        const callback = modelVisibilityDirtyCallback.bind(listener, model, this);
        listener.callback = callback;

        // Not all types of models have a fragment list (e.g. Leaflets) and should therefore not be registered
        if (model.getFragmentList()) {
            model.getFragmentList().registerVisibilityDirtyCallback(callback);
        }
        _3dModelVisibilityListener.set(model.id, listener);
    };

    /**
     * Remove a model from the renderer. See add3dModel for details.
     * @param {Model} model
     */
    this.remove3dModel = function(model) {
        if (!model.is3d()) {
            console.warn("Called remove3dModel with 2D model.")
            return;
        }

        _3dModels.delete(model);
        _renderBatchUniforms.removeModel(model);

        const callback = _3dModelVisibilityListener.get(model.id).callback;
        if (model.getFragmentList()) {
            model.getFragmentList().removeVisibilityDirtyCallback(callback);
        }
        _3dModelVisibilityListener.delete(model.id);

        this.invalidateRenderBundles(model);

        // The ModelIteratorTexQuad does not implement getGeomScenes. Instead of using render batches
        // it uses the THREE.Scene. This means that the render batches are not invalidated and the render bundles
        // are not cleared. A possible solution would be a common interface for iterators that either implement
        // getGeomScenes and return render batches or basically return an empty array. This would allow us to remove
        // the if statement below.
        if (Object.hasOwn(model.getIterator(), 'getGeomScenes')) {
            // Reset render batch state
            const batches = model.getIterator().getGeomScenes();
            for (const batch of batches) {
                if (batch) {
                    batch.isComplete = false;
                    batch.useRenderBundles = false;
                }
            }
        }
    };

    /**
     * Whether this Renderer has this 3D model added.
     * @param {Model} model
     * @returns {boolean}
     */
    this.has3dModel = function(model) {
        return _3dModels.has(model);
    };

    function initGlobalTextures() {
        _repeatSampler = _device.createSampler({
            addressModeU: "repeat",
            addressModeV: "repeat",
        });

        initMissingTexture();
        _transparentTexture = createColorTexture(0x00000000);
    }

    /**
     * Create a 1x1 pixel texture of the given color.
     * @param {number} color A 4-byte color value in BGRA format (0xAARRGGBB).
     */
    function createColorTexture(color) {
        const texture = _device.createTexture({
            label: `color 0x${color.toString(16)}`,
            dimension: '2d',
            format: 'bgra8unorm',
            size: [1, 1],
            usage: GPUTextureUsage.TEXTURE_BINDING | GPUTextureUsage.COPY_DST
        });
        const view = texture.createView();

        const info = {
            texture,
            view,
            sampler: _repeatSampler,
        };

        const data = new Uint32Array([color]);
        _device.queue.writeTexture(
            { texture }, data, { offset: 0, bytesPerRow: 4 }, [1, 1]);

        return info;
    }

    function initMissingTexture() {
        //Texture we bind to unused slots in bind groups that are reused with multiple configurations
        let texture  = _device.createTexture({
            label: 'missing',
            dimension: "2d",
            format: "bgra8unorm",
            size: [4, 4],
            usage: GPUTextureUsage.TEXTURE_BINDING | GPUTextureUsage.COPY_DST
        });

        let view = texture.createView();

        _missingTexture = {
            texture,
            view,
            sampler: _repeatSampler,
        };

        // Winner of most annoying looking texture in the world for 2016
        const data = new Uint32Array(16);
        const w = 0xffffffff; const r = 0xffff0000;
        data[0]  = r; data[1]  = w; data[2]  = w; data[3]  = r;
        data[4]  = r; data[5]  = w; data[6]  = r; data[7]  = r;
        data[8]  = r; data[9]  = r; data[10] = w; data[11] = r;
        data[12] = w; data[13] = w; data[14] = r; data[15] = w;

        _device.queue.writeTexture({ texture },
                                data,
                                { offset: 0, bytesPerRow: 16 },
                                [ 4, 4 ]
                                );
    }

    /** @returns {RenderBatchUniforms} */
    this.getRenderBatchUniforms = function() {
        return _renderBatchUniforms;
    };

    this.getPixelRatio = function() {
        return _pixelRatio || window?.devicePixelRatio || 1;
    };
    this.setPixelRatio = function(value) {
        _pixelRatio = value;
    };

    this.setSize = function(width, height, updateStyle) {

        _canvas.width = width * this.getPixelRatio();
        _canvas.height = height * this.getPixelRatio();

        if (updateStyle !== false) {

            _canvas.style.width = width + 'px';
            _canvas.style.height = height + 'px';
        }

        //This one needs to be first
        _renderTargets.resize(_canvas.width, _canvas.height);

        _mainPass.resize(_canvas.width, _canvas.height);
        _sao.resize(_canvas.width, _canvas.height);
        _postPass.resize(_canvas.width, _canvas.height);

        this.setViewport(0, 0, width, height);
    };

    /**
     * @param {ToneMapMethod} value
     */
    this.setTonemapMethod = function(value) {
        if(_tonemapMethod !== value) {
            _tonemapMethod = value;
            _mainPass.resetPipelines();
            this.invalidateRenderBundles();
        }
    };

    this.getTonemapMethod = function() {
        return _tonemapMethod;
    };

    this.setEnvMapEncoding = function(map) {
        // If no map is provided, just assume both flags as false.
        // Without an envMap, they don't have an effect anyway.
        const isGamma = map ? !!map.GammaEncoded : false;
        const isRGBM = map ? !!map?.RGBM : false;
        if(_envEncoding.isGamma !== isGamma || _envEncoding.isRGBM !== isRGBM) {
            _envEncoding.isGamma = isGamma,
            _envEncoding.isRGBM = isRGBM;
            _mainPass.resetPipelines();
            this.invalidateRenderBundles();
        }
    };

    this.getEnvMapEncoding = function() {
        return _envEncoding;
    };

    this.renderBackground = function(useEnvMap) {

        if (useEnvMap && _envMapPass.hasCubeMap()) {
            _envMapPass.run();
        } else {
            _gradientPass.run();
        }

    };

    this.present = function(antialias, camera, waitForDone, userFinalPass = null) {

        if (!_initDone) {
            this.fireEvent({ type: Events.WEBGPU_RENDER_DONE });
            return;
        }

        let finalTarget = _offscreenTarget || _gpu.getCurrentTexture();

        if (!userFinalPass) {
            // Default way: PostPass => finalTarget
            _postPass.run(finalTarget.createView(), antialias, camera);
        } else {
            // render blendPass into post1
            const post1 = _renderTargets.getPostTarget(1);
            _postPass.run(post1.createView(), antialias, camera);

            // render userFinalPass from post1 into finalTarget
            userFinalPass.run(finalTarget, post1);
        }

        if (waitForDone) {
            // Inform listeners as soon as all currently submitted commands are done.
            _device.queue.onSubmittedWorkDone().then(() => {
                this.fireEvent({ type: Events.WEBGPU_RENDER_DONE });
            });
        }
    };

    this.beginScene = function(camera, lights) {

        if (!_initDone) return;

        _3dModelBundlesInvalidated.clear();

        _mainPass.beginScene(camera, lights);
    };

    //TODO: needClear and updateLights are bogus
    this.renderScenePart = function(scene, showEdges) {

        if (!_initDone) return;

        const commandGroup = _mainPass.renderScenePart(scene, showEdges);
        if (commandGroup) {
            _commandGroups.push(commandGroup);
            if (_commandGroups.length >= commandSubmitThreshold) {
                _vb.flushWrites();
                _device.queue.submit(_commandGroups);
                _commandGroups.length = 0;
            }
        }
    };

    this.renderOverlay = function(scene, camera, materialPre, materialPost, showEdges, customEdgeColor, lights) {

        if (!_initDone) return;

        _mainPass.renderOverlay(scene, camera, materialPre, materialPost, showEdges, customEdgeColor, lights);
    };

    this.flushCommandQueue = function() {
        if (_commandGroups.length) {
            _vb.flushWrites();
            _device.queue.submit(_commandGroups);
            _commandGroups.length = 0;
        }
    };

    this.clearPerFrameBudgets = function() {
        _vb.cleanup();
    }

    /**
     * @returns {GPUDevice}
     */
    this.getDevice = function() {
        return _device;
    };

    /** @return {CommonRenderTargets} */
    this.getRenderTargets = function() {
        return _renderTargets;
    };

    /** @returns {VertexBuffer} */
    this.getVB = function() {
        return _vb;
    };

    this.getGradientPass = function() {
        return _gradientPass;
    };

    this.getEnvMapPass = function() {
        return _envMapPass;
    };

    this.getIBL = function() {
        return _mainPass.getIBL();
    };

    this.getSAO = function() {
        return _sao;
    };

    this.getMainPass = function() {
        return _mainPass;
    };

    this.getGroundShadowPass = function() {
        return _groundShadowPass;
    };

    this.getPostPass = function() {
        return _postPass;
    }

    this.getBlendSettings = function() {
        return _postPass.getBlendSettings();
    };

    this.setRenderTarget = function(target) {
        //console.log("deprecated setRenderTarget");
    };

    /**
     * @returns {GPUTexture|null}
     */
    this.getOffscreenTarget = function() {
        return _offscreenTarget;
    }

    /**
     * @param {GPUTexture|null} target
     */
    this.setOffscreenTarget = function(target) {
        _offscreenTarget = target;
    }

    this.clearTarget = function(target) {
        //TODO: this is to be removed
    };
    this.clearMainTargets = function() {
        //TODO: we really only needs this clear if there is no initial scene to draw
        //from the beginScene() call of RenderContext, otherwise it can be
        //done via implicit clear with that scene
        _initDone && _mainPass.clearMainTargets();
    };
    this.clearOverlayTargets = function() {
        //TODO: we only need this explicit clear if there aren't
        //any overlays to draw -- otherwise the implicit clear
        //during the pass that draws the overlays can do it.
        _initDone && _mainPass.clearOverlayTargets();
    };

    this.clear = function() {

    };
    this.depthFunc = function() {

    };

    this.updateTimestamp = function(ts) {

    };

    this.getMaxAnisotropy = function() {
        return 16;
    };

    this.supportsMRT = function() { return true; };
    this.verifyMRTWorks = function() { return true; };

    this.cleanup = function() {
        //TODO: destroy all targets/buffers
        //destroy passes that own targets/other resources
    };

    /** @returns {TextureInfo} A 1x1 transparent texture */
    this.getPlaceholderTexture = function() {
        return _transparentTexture;
    };

    this.stats = function() {
        _vb.stats();
    };

    this.setLineStyleBuffer = function(buffer, width) {
        _mainPass.setLineStyleBuffer(buffer, width);
    };

    this.invalidateRenderBundles = function(model) {
        let models;
        if (model) {
            models = [model];
        } else {
            models = _3dModels;
        }

        models.forEach((model) => {
            if (!_3dModelBundlesInvalidated.has(model.id)) {
                const iter = model.getIterator();
                if (iter.getGeomScenes) {
                    const scenes = model.getIterator().getGeomScenes();
                    for (const scene of scenes) {
                        if (scene) {
                            _mainPass.clearRenderBundles(scene);
                        }
                    }
                }
                _3dModelBundlesInvalidated.set(model.id);
            }
        });
    };

    this.getContext = function() {
        return this.context;
    };

    /**
     * @param {number} size
     * @returns {boolean} Whether or not size bytes can be uploaded this frame.
     */
    this.canUpload = function(size) {
        return _vb.canUpload(size);
    };

    /**
     * Uploads a new geometry to the GPU.
     * @param {BufferGeometry} geometry
     */
    this.uploadGeometry = function(geometry) {
        _vb.initUploaded(geometry);
    };

    /**
     * Upload range of uniforms for the given scene. Pre-uploading the uniform speeds-up rendering,
     * but requires to be done again whenever the order or count changes.
     * @param {RenderBatch} scene
     */
    this.uploadUniforms = function(scene) {
        _renderBatchUniforms.updateBatch(scene);
    }

    /**
     * Free GPUMemory that was previously allocated by uploading uniforms for the given scene.
     * @param {RenderBatch} scene
     */
    this.freeUniforms = function(scene) {
        _renderBatchUniforms.freeUniforms(scene);
    }

    /**
     * The total memory used by this Renderer.
     * @returns {number}
     */
    this.getTotalMemory = function() {
        // TODO: Add ObjectUniforms and any other GPU memory users.
        return _vb.getTotalMemory();
    };

    /**
     * Returns a valid geometry if it can be drawn, or null if it can't be drawn.
     * This may modify the geometry passed in or return a different object that is
     * drawable.
     * @param {THREE.Geometry|BufferGeometry} geometry
     * @return {BufferGeometry|null}
     */
    this.initGeometry = function(geometry) {
        // THREE.Geometry is not supported by the WebGPU renderer and needs to be converted
        // before use. Possible usages include overlay scenes, the pivot (SphereGeometry)
        // and hypermodel gizmo (PlaneGeometry).
        if (geometry instanceof THREE.Geometry) {
            geometry = getFallbackGeometry(geometry);
            if (!geometry) {
                return null;
            }
            geometry.streamingDraw = true;
        }

        if (!geometry.vb) {
            BufferGeometryUtils.interleaveGeometry(geometry, true);
            geometry.streamingDraw = true;
        }

        if (!_vb.canDraw(geometry)) {
            return null;
        }

        return geometry;
    }

    /**
     * Sets the viewport for rendering. Use passViewport(encoder) later to actually use the viewport.
     * @Note The position is in WebGL coordinates, a y-flip is perfomed internally using the canvas heigth.
     *       This implies the current implementation can only set viewports in passes where the render
     *       attachments share the same size as the canvas!
     *
     * @Note Unlike WebGL, WebGPU does not allow viewports outside the bounds of the render attachments.
     *       The caller must make sure this is true or a validation error will be thrown.
     *
     * @Note By default WebGL only applies the viewport when rendering to the canvas unless setting
     *       enableViewportOnOffscreenTargets(). WebGPU does not have this options and always renders
     *       to offscreen targets. Viewport support is incomplete as of now.
     *
     * @see https://jira.autodesk.com/browse/VIZX-1879 for remaining viewport issues.
     */
    this.setViewport = function(glX, glY, width, height) {
        const pixelRatio = this.getPixelRatio();
        _viewportX = Math.trunc(glX * pixelRatio);
        // (x=0, y=0) is bottom-left in WebGL but top-left in WebGPU, so we need to flip y.
        _viewportY = _canvas.height - Math.trunc((glY + height) * pixelRatio);

        _viewportWidth = Math.trunc(width * pixelRatio);
        _viewportHeight = Math.trunc(height * pixelRatio);
    };

    /** Pass the current viewport to a GPURenderPassEncoder. */
    this.passViewport = function(passEncoder) {
        passEncoder.setViewport(_viewportX, _viewportY, _viewportWidth, _viewportHeight, 0, 1);
    };

    /** Push current viewport to viewport stack, so that it can be recovered by popViewport later. */
    this.pushViewport = function() {
        _viewportStack.push(_viewportX);
        _viewportStack.push(_viewportY);
        _viewportStack.push(_viewportWidth);
        _viewportStack.push(_viewportHeight);
    };

    /** Recover previously pushed viewport. */
    this.popViewport = function() {
        if (_viewportStack.length == 0) {
            console.warn("Called popViewport on empty stack")
            return;
        }

        const index = _viewportStack.length - 4;
        _viewportX = _viewportStack[index];
        _viewportY = _viewportStack[index + 1];
        _viewportWidth = _viewportStack[index + 2];
        _viewportHeight = _viewportStack[index + 3];
        // Do not call set viewport here, as this flips the y coordinate and multiplies by pixelRatio!
        //this.setViewport(_viewportX, _viewportY, _viewportWidth, _viewportHeight);

        _viewportStack.length = index;
    };
}

Renderer.Events = Events;
