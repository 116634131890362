
import { $wgsl } from '../../wgsl-preprocessor/wgsl-preprocessor';
import blendUniformsCode from './blend_uniforms.wgsl';
import unProjectUniformsCode from './unproject_uniforms.wgsl';

import { UniformBuffer } from '../UniformBuffer';
import { LmvMatrix4 } from '../../scene/LmvMatrix4';
import { toReverseZ } from '../main/CameraUniforms';


export function getBlendSettingsDeclaration(bindGroup) {
    return $wgsl(blendUniformsCode, (bindGroup + 0 || 0));
}

const Offsets = {
    highlightColor: 0, // 0, 1, 2, 3,
    selectionColor: 4, // 4, 5, 6, 7
    objectId: 8,
    modelId: 9,
    glowFlagInt: 10,
    glowCompFunc: 11,
    highlightIntensity: 12,
    useAO: 13,
    aoBias: 14,
    edgeObjectId: 15,
    edgeModelId: 16,
    useOverlay: 17,
    zFloorRange: 18,  // 18, 19
    is2d: 20,
    // Update size when adding values:
    SIZE_IN_FLOATS: 21,
};

export class BlendSettings extends UniformBuffer {

    constructor() {
        super(null, Offsets.SIZE_IN_FLOATS, true, false, 'blend-settings');
    }

    init(device) {
        this.setDevice(device);

        // Defaults
        this.setAOEnabled(false);
        this.setHighlightObjectId(0);
        this.setHighlightModelId(0);
        this.setHighlightIntensity(1);
        this.setRolloverHighlightColor(new THREE.Color(1, 1, 1));
        this.setEdgeHighlightObjectId(0, 0);
        this.setSelectionColor(new THREE.Color(0, 0, 0));
        this.setUseOverlay(false);
        this.setIs2d(false);
        // TODO: Consider adding defaults for other values.
    }

    setAOEnabled(on) {
        this.setInt(Offsets.useAO, on ? 1 : 0);
    }

    setHighlightObjectId(dbId) {
        this.setInt(Offsets.objectId, dbId);
    }

    setHighlightModelId(modelId) {
        this.setInt(Offsets.modelId, modelId);
    }

    setHighlightIntensity(value) {
        this.setFloat(Offsets.highlightIntensity, value);
    }

    getHighlightIntensity() {
        return this.getFloat(Offsets.highlightIntensity);
    }

    setRolloverHighlightColor(color) {
        this.setColor(Offsets.highlightColor, color, 1.0); //TODO: this changes so rarely that it would be better off as constant
    }

    setEdgeHighlightObjectId(dbId, modelId) {
        this.setInt(Offsets.edgeObjectId, dbId);
        this.setInt(Offsets.edgeModelId, modelId);
    }

    setGlowFlag(flag) {
        this.setInt(Offsets.glowFlagInt, flag);
    }

    setGlowOptions(color, compFunc) {
        //TODO: glow color uniform
        this.setInt(Offsets.glowCompFunc, compFunc);
    }

    setAOBias(blendBias) {
        this.setFloat(Offsets.aoBias, blendBias);
    }

    /** @returns {number} */
    getAOBias() {
        return this.getFloat(Offsets.aoBias);
    }

    setSelectionColor(color) {
        this.setColor(Offsets.selectionColor, color, 1.0);
    }

    setUseOverlay(value) {
        this.setInt(Offsets.useOverlay, value ? 1 : 0);
    }

    setZRange(min, max) {
        this.setFloat2(Offsets.zFloorRange, min, max);
    }

    /** @param {boolean} is2d */
    setIs2d(is2d) {
        this.setInt(Offsets.is2d, is2d ? 1 : 0);
    }
}

export function getUnProjectSettingsDeclaration(bindGroup) {
    return $wgsl(unProjectUniformsCode, (bindGroup + 0 || 0));
}


let auxMat4 = new LmvMatrix4(); // for temporary storage to avoid creating new matrices every time

export class UnProjectSettings extends UniformBuffer {

    constructor() {
        super(null, 36, true, false);
    }

    init(device) {
        this.setDevice(device);
    }

    setCamera(camera) {
        let projectionInverse = auxMat4;
        projectionInverse.copy(camera.projectionMatrix);
        toReverseZ(projectionInverse);
        projectionInverse.invert();

        this.setMatrix4x4( 0, camera.matrixWorld.elements);
        this.setMatrix4x4(16, projectionInverse.elements);

        this.setFloat2(32, camera.near, camera.far);
        this.setFloat(34, camera.isPerspective);
    }
}
