
import { isMobileDevice, isChrome, getGlobal, isNodeJS } from "../../compat";
import { BufferGeometryUtils } from "../../wgs/scene/BufferGeometry";
import { createWorker } from "./WorkerCreator";
import { initLoadContext } from "../net/endpoints";
import { EventDispatcher } from "../../application/EventDispatcher";
import { getParameterByName } from "../../globals";
import { OtgPriorityQueue, updateGeomImportance } from "../lmvtk/otg/OtgPriorityQueue";
import { LocalDbCache } from "../lmvtk/otg/LocalDbCache";
import { BandwidthOptimizer } from './BandwidthOptimizer';
import { OutOfCoreTileManager } from '../../wgs/scene/out-of-core-tile-manager/OutOfCoreTileManager';
import { USE_OUT_OF_CORE_TILE_MANAGER } from '../../wgs/globals';
import {blobToJson} from "../lmvtk/common/StringUtils";
import { MaterialConverter } from "../../wgs/render/MaterialConverter";
import { bin16ToPackedString } from "../lmvtk/otg/HashStrings";

/**
 * Fired when new geometry has been received from the server
 *
 * @event OtgResourceCache#MESH_RECEIVE_EVENT
 */
export var MESH_RECEIVE_EVENT = "meshReceived";

/**
 * Fired when fetching a geometry has failed
 *
 * @event OtgResourceCache#MESH_FAILED_EVENT
 */
export var MESH_FAILED_EVENT = "meshFailed";

/**
 * Fired when a new material has been received from the server
 *
 * @event OtgResourceCache#MATERIAL_RECEIVE_EVENT
 */
export var MATERIAL_RECEIVE_EVENT = "materialReceived";

/**
 * Fired when fetching a material has failed
 *
 * @event OtgResourceCache#MATERIAL_FAILED_EVENT
 */
export var MATERIAL_FAILED_EVENT = "materialFailed";

let avp = Autodesk.Viewing.Private;
let useOpfs = getParameterByName('useOPFS') !== 'false' && getGlobal().USE_OPFS !== false;
const disableAsFlag = getParameterByName('useAdaptiveStreaming') || getGlobal().USE_ADAPTIVE_STREAMING;
const useAdaptiveStreaming = disableAsFlag !== 'false' && disableAsFlag !== false;
const disableIndexedDb = getParameterByName("disableIndexedDb").toLowerCase() === "true" || getGlobal().DISABLE_INDEXED_DB;
const disableWebSocket = getParameterByName("disableWebSocket").toLowerCase() === "true" || getGlobal().DISABLE_WEBSOCKET;
let disableHashCache = disableIndexedDb || useOpfs || !isChrome();

const GEOM_ERROR = {dummy_value: "error"};
const MAT_ERROR = {dummy_value: "error"};

function initLoadContextGeomCache(msg) {
    var ctx = initLoadContext(msg);
    ctx.disableIndexedDb = disableIndexedDb;
    ctx.disableWebSocket = disableWebSocket;
    return ctx;
}

// Helper function used for cache cleanup
function compareGeomsByImportance(geom1, geom2) {
    return geom1.importance - geom2.importance;
}

/** Shared cache of BufferGeometries and material JSONs used by different OtgLoaders.
 *  @param {Object} [options] - optional parameters
 *  @param {Object} [options.cache] - optional parameters for cache
 *  @param {string} [options.cache.type] - type of cache to use, 'OPFS' or unset
*/
export function OtgResourceCache(options) {

    // all geometries, indexed by geom hashes
    var _geoms = new Map();
    var _mats = new Map();

    this._customOpfsCallbacks = new Map();

    // A single geometry may be requested by one or more model loaders.
    // This map keeps track of requests already in progress so that
    // we don't issue multiple simultaneously
    var _hash2Requests = new Map();

    var opfsCacheEnabledByOptions = options?.cache?.type === 'OPFS';
    if (useOpfs || opfsCacheEnabledByOptions) {
        useOpfs = true;
        disableHashCache = true;
    }

    // worker for geometry loading
    var _loadWorker = createWorker('OtgLoadWorker');
    _loadWorker.addEventListener('message', handleWorkerMessage);

    var NUM_DECODE_WORKERS = isMobileDevice() ? 2 : 4;
    var _decodeWorkers = [];
    var _decoderPorts = [];
    var _numLoadersDownloadingFragmentList = 0;

    // Setup message channels for decodeWorkers
    // The loadWorker sends messages to the decodeWorkers, which send messages back here.
    for (var i=0; i<NUM_DECODE_WORKERS; i++) {
        _decodeWorkers.push(createWorker('OtgDecodeWorker'));
        _decodeWorkers[i].addEventListener('message', handleWorkerMessage);
        const channel = new MessageChannel();
        _decodeWorkers[i].doOperation({
            operation: "INSTALL_INPUT_PORT",
            port: channel.port2,
        }, [channel.port2]);
        _decoderPorts.push(channel.port1);
    }

    const ctx = initLoadContextGeomCache({
        operation: "INIT_WORKER_OTG",
        ports: _decoderPorts,
        useOpfs: useOpfs,
        // clear IndexedDB only if OPFS is enabled by the options (presumably by feature flags of our applications)
        // and not by global variable or URL parameter (presumably someone is just testing it)
        clearIndexedDbIfItsLarge: opfsCacheEnabledByOptions
    });
    let transferList = _decoderPorts.slice();
    _loadWorker.doOperation(ctx, transferList);

    this.initialized = true;

    let _bwo, startTs;
    this.bandwidthOptimizer = () => {
        if (!_bwo && !startTs) {
            startTs = Date.now();
        }

        // start the adaptive streaming after establishing initial conditions
        if (_bwo || this.requestsReceived < _maxRequestsInFlight * 2) {
            return _bwo;
        }

        // create initial fragment average
        // TODO: Need to figure out if we need separate out materials and geometries...
        const initialAssetEstimate = (this.byteSize / this.requestsReceived) * 0.9;
        const avgBytesSec = this.byteSize / ((Date.now() - startTs) / 1000);

        if (useAdaptiveStreaming) {
            _bwo = BandwidthOptimizer.createAndStart(
                _requestsInProgress, initialAssetEstimate, avgBytesSec,
                flightSize => _maxRequestsInFlight = flightSize,
                count => {
                    const remaining = _queue.waitingTasks.length / (this.requestsSent + _queue.waitingTasks.length) * 100;
                    if (remaining > 5) { // skip updating the websocket count if the amount of tasks in the queue is low compared to total count (5%)
                        _loadWorker.doOperation({operation: "SET_OTGWS_COUNT", count});
                    }
                });
        }

        return _bwo;
    };

    // track memory consumption
    this.byteSize = 0;
    this.refCount = 0;

    // improve hash lookup
    this.cachedHashesDb = undefined;
    this.cachedHashes = null;
    this.cachedHashesPending = false;
    this.cachedHashesEstimate = undefined;
    this.cachedHashesEstimatePending = false;
    this.fromCacheCount = 0;
    this.fromRemoteCount = 0;

    // track total counts to simplify debugging
    this.requestsSent = 0;
    this.requestsReceived = 0;

    // A request is called in-progress if we have sent it to the worker and didn't receive a result yet.
    // We restrict the number of requests in progress. If the limit is reached, all additional requests
    // are enqueued.
    var _requestsInProgress = 0;
    // When changing this, check constants in OtgLoadWorker
    const _defaultMaxRequestsInFlight = useAdaptiveStreaming ? BandwidthOptimizer.MIN_FLIGHT : BandwidthOptimizer.FALLBACK_FLIGHT;
    var _maxRequestsInFlight = _defaultMaxRequestsInFlight;
    var _timeout = null;
    var _recurringTaskActive = false;

    var _queue = new OtgPriorityQueue();

    var _this = this;

    // mem limits for cache cleanup
    var MB = 1024 * 1024;
    this._maxMemory  = 100 * MB; // geometry limit at which cleanup is activated
    this._minCleanup = 50  * MB; // minimum amount of freed memory for a single cleanup run

    // Keep track of unused geometries.
    // A geometry is tracked as unused if it was used by at least one model and then got removed from all models.
    // This map keep track of which geometries are unused (it stores geom.id -> geometry instance).
    var _unusedGeomsMap = new Map();

    // Needed to determine when the dtor can be called (once the last viewer got removed)
    var _viewers = [];

    this._numActiveLoaders = 0;
    var _activeLoaders = [];

    this._nextMessageId = 0;

    this.addViewer = function(viewer) {
        _viewers.push(viewer);
        _queue.addViewer(viewer);
    };

    this.removeViewer = function(viewer) {
        const index = _viewers.indexOf(viewer);

        if (index !== -1) {
            _queue.removeViewer(viewer);
            _viewers.splice(index, 1);
        }

        if (_viewers.length === 0) {
            this.dtor();
        }
    };

    this.dtor = function() {
        _viewers = [];

        _loadWorker.terminate();
        _loadWorker = null;

        for (const decodeWorker of _decodeWorkers) {
            decodeWorker.terminate();
        }
        _decodeWorkers = [];

        // Make sure all unused geoms are cleared
        this.cleanup(true);

        _geoms = null;
        _mats = null;

        this._clearHashCache();

        this.initialized = false;

        if (_timeout) {
            clearTimeout(_timeout);
            _timeout = null;
        }
        this.stopProcessing();
    };

    // Chrome's implementation of IndexDb (LevelDb) doesn't perform very well with our workload (heavy scattered writes when loading from the net
    // interspersed with reads). The mere attempt to read a non-existing mesh can become so slow that it starves the download from the backend.
    // Solution is to read the set of hashes in the cache once and use it to skip expensive cache misses.
    //
    // The hash cache will only contain approximate information. If the cache exceeds its quota, LMV will delete some meshes, which is not tracked.
    // This is why only the information "not in cache" is treated as certain.
    this._loadHashCache = function() {
        // db must have been created before
        if (this.cachedHashesPending) { return; }

        this.cachedHashesPending = true;

        this.cachedHashesDb.open(() => {
            this.cachedHashesDb.readAllCachedHashes((hashesArray) => {
                this.cachedHashes = new Set(hashesArray);
                this.cachedHashesPending = false;
            });

            // no need to read anything else
            this.cachedHashesDb = undefined;
        });
    };

    this._clearHashCache = function() {
        this.cachedHashes = null;
    };

    this._getHashCacheEstimate = function() {
        if (this.cachedHashesEstimatePending) { return; }

        this.cachedHashesDb = new LocalDbCache(disableIndexedDb, false);
        this.cachedHashesDb.open(null);
        this.cachedHashesEstimatePending = true;

        this.cachedHashesDb.open(() => {
            this.cachedHashesDb.estimateCachedHashCount((count) => {
                this.cachedHashesEstimate = count;
                // if an error happened (count is undefined), do not try again
                this.cachedHashesEstimatePending = (count === undefined);
            });
        });
    };

    function couldBeInCache(_this, hash) {
        return !_this.cachedHashes || _this.cachedHashes.has(hash);
    }

    // Reading all hashes from the cache can also be expensive. As a remediation, the cache miss rate is monitored. If the estimated
    // number of cache misses is sufficiently high, the number of cache entries is quickly estimated. If the ratio between cache entries
    // and cache misses falls below a threshold, all hashes are read and used for avoiding further cache misses.
    this._handleCache = function() {
        // cache already loaded or irrelevant?
        if (this.cachedHashes || disableHashCache) { return; }

        // constants for the heuristic
        const MIN_TOTAL_COUNT_FOR_FIRST_ESTIMATION = 200;
        const MIN_REMOTE_COUNT_FOR_SECOND_ESTIMATION = 1000;
        const MAX_CACHED_HASHES_PER_REMOTE_REQUEST = 50;

        // sufficient data to make a decision?
        const receivedCount = this.fromCacheCount + this.fromRemoteCount;
        if (receivedCount < MIN_TOTAL_COUNT_FOR_FIRST_ESTIMATION) { return; }

        // estimate how many requests will be served from remote
        const remoteRequestEstimate = _queue.waitCount() * this.fromRemoteCount / receivedCount;

        // need to find out how many entries are in the cache
        if (this.cachedHashesEstimate == undefined) {
            // only check if there will be sufficiently many network requests
            if (remoteRequestEstimate>MIN_REMOTE_COUNT_FOR_SECOND_ESTIMATION) { this._getHashCacheEstimate(); }
            return;
        }

        // check if it is worth loading the cache information given how many network requests we expect
        if (this.cachedHashesEstimate<remoteRequestEstimate * MAX_CACHED_HASHES_PER_REMOTE_REQUEST) {
            this._loadHashCache();
        }
    };

    /**
     * Remove a pending request from _hash2Requests, but makes sure that any attached callbacks are triggered first
     *  @param {string}  hash - hash of the request
     *  @param {boolean|Uint8Array} result - will be passed to the callbacks
     */
    function _deletePendingHashRequest(hash, result) {

        // Trigger attached callbacks
        const request = _hash2Requests.get(hash);
        request?.callbacks.forEach(cb => cb(result));

        _hash2Requests.delete(hash);
    }

    function handleWorkerMessage(msg) {
        let initialProcessingDone = false;
        let i = 0;

        let processMessage = (deadline = Infinity) => {
            // It can happen that this task is kept alive in the OutOfCoreTileManager task queue after
            // the viewer has been destroyed. Once a new model is being opened, the task processing will
            // resume and an old task might get processed. The destructor for the geom cache will set _geoms
            // to null and we check here whether this is a task from a destroyed geomcache. In that case,
            // we just report true to indicate that the task can be cleared.
            if (_geoms === null) {
                return true;
            }

            if (!initialProcessingDone) {
                if (msg.data.event && msg.data.properties) {
                    avp.analytics.track(msg.data.event, msg.data.properties);
                        return true;
                }

                if (typeof msg.data.customOpfsMessageId === "number") {
                    _this._customOpfsCallbacks.get(msg.data.customOpfsMessageId)(msg.data.result);
                    _this._customOpfsCallbacks.delete(msg.data.customOpfsMessageId);
                    _this.flushCacheAndDisconnect();
                        return true;
                }

                //Schedule another spin through the task queue
                if (!USE_OUT_OF_CORE_TILE_MANAGER && !_queue.isEmpty() && !_timeout) {
                    _timeout = setTimeout(processQueuedItems, 0);
                }

                if (msg.data.error) {
                    var error = msg.data.error;
                    var hash = error.args.hash;

                    // inform affected clients.
                    if (error.args.resourceType === "m") {
                        _mats.set(hash, MAT_ERROR);
                        _this.fireEvent({type: MATERIAL_FAILED_EVENT, hash:hash});
                        console.error("Error loading material.", error.msg, error.args);
                    } else {
                        _geoms.set(hash, GEOM_ERROR);
                        _this.fireEvent({type: MESH_FAILED_EVENT, hash:hash});
                        console.error("Error loading mesh.", error.msg, error.args);
                    }

                    _deletePendingHashRequest(hash, false);

                    // track number of requests in progress
                    _requestsInProgress--;
                    _this.requestsReceived++;
                    return true;
                }
                initialProcessingDone = true;
            }

            let totalSize = 0;

            if (msg.data.blobs) {
                // Just for the prism case, we might have to go async. Note that the out of core tile manager
                // doesn't know about this and the time budgeting will be less accurate.
                (async () => {
                    for (let i = 0; i < msg.data.blobs.length; i++) {
                        const hash = msg.data.hashes[i];
                        const blob = msg.data.blobs[i];

                        _requestsInProgress--;
                        _this.requestsReceived++;

                        if (msg.data.resourceType === "m") {

                            const mat = blobToJson(blob);

                            if (MaterialConverter.isPrismMaterial(mat)) {
                                await MaterialConverter.loadMaterialConverterPrismLibrary();
                            }

                            // add material to cache
                            _mats.set(hash, mat);

                            // pass material to all receiver callbacks
                            _this.fireEvent({ type: MATERIAL_RECEIVE_EVENT, material: mat, hash: hash });
                        }

                        if (blob) {
                            totalSize += blob.byteLength;
                        }
                        _deletePendingHashRequest(hash, blob);
                    }
                })();
            } else if (msg.data.mdatas) {

                const mdatas = msg.data.mdatas;

                for (i; i<mdatas.length; i++) {
                    _requestsInProgress--;
                    _this.requestsReceived++;

                    var mdata = mdatas[i];

                    if (mdata.hash && mdata.mesh) {

                        // convert geometry data to GeometryBuffer
                        // Moving this to the OtgLoadWorker results in "WebGL: INVALID_OPERATION: drawElements: no buffer is bound to enabled attribute"
                        var geom = BufferGeometryUtils.meshToGeometry(mdata);

                        // add geom to cache
                        _this.addGeometry(mdata.hash, geom);

                        // free old unused geoms if necessary
                        _this.cleanup();

                        // pass geometry to all receiver callbacks
                        _this.fireEvent({type: MESH_RECEIVE_EVENT, geom: geom});

                        _deletePendingHashRequest(mdata.hash, true);

                        mdata.fromCache ? ++_this.fromCacheCount : ++_this.fromRemoteCount;
                        totalSize += geom.byteSize;
                    }

                    if (i % 20 === 0 && performance.now() >= deadline) {
                        i++;
                        return false;
                    }
                }
            }

            _this.bandwidthOptimizer()?.onResourceReceived(
                msg.data.blobs?.length || msg.data.mdatas?.length, totalSize);

            _this._handleCache();

            return true;
        };

        if (USE_OUT_OF_CORE_TILE_MANAGER) {
            OutOfCoreTileManager.addOneShotTask(processMessage);
        } else {
            processMessage();
        }
    }

    this.modelAdded = function(modelUrn, lineageUrn) {
        var msg = {
            operation: "ADD_MODEL_OTG",
            modelUrn: modelUrn,
            cacheBucket: lineageUrn,
            useOpfs
        };

        _loadWorker.doOperation(initLoadContextGeomCache(msg));

        // a new model gets added => restart cache stats
        this.fromCacheCount = 0;
        this.fromRemoteCount = 0;
    };

    this.loaderAdded = function(loader) {
        this._numActiveLoaders++;
        _activeLoaders.push(loader);
    };

    this.loaderRemoved = function(loader) {
        this._numActiveLoaders--;

        const index = _activeLoaders.indexOf(loader);

        if (index !== -1) {
            _activeLoaders.splice(index, 1);
        }

        this.flushCacheAndDisconnect();
    };

    this.flushCacheAndDisconnect = function() {

        // don't disconnect if there are any active loaders or custom reads/stores left
        if (this._numActiveLoaders !== 0 || this._customOpfsCallbacks.size > 0) {
            return;
        }

        // let go of accumulated hashes.
        this._clearHashCache();

        var msg = {
            operation: "FLUSH_CACHE_AND_DISCONNECT_OTG"
        };
        _loadWorker.doOperation(msg);
    };


    /**  Get a geometry from cache or load it.
     *    @param {string}   url         - full request url of the geometry/ies resource
     *    @param {boolean}  isCDN       - deprecated, unused
     *    @param {string}   geomHash    - hash key to identify requested geometry/ies
     *    @param {int} geomIdx          - deprecated, unused
     *    @param {string}   queryParams - additional param passed to file query
     */
    this.requestGeometry = function(url, isCDN, geomHash, geomIdx, queryParams, lineageUrn, addToQueue = true) {

        // if this geometry is in memory, just return it directly
        var geom = _geoms.get(geomHash);
        if (geom === GEOM_ERROR) {
            //it failed to load previously
            if(isNodeJS()) {
                setTimeout(() => this.fireEvent({type:MESH_FAILED_EVENT, hash: geomHash}), 0);
            } else {
                this.fireEvent({type:MESH_FAILED_EVENT, hash: geomHash});
            }
            return;
        } else if (geom) {
            //it was already cached
            if(isNodeJS()) {
                setTimeout(() => this.fireEvent({type:MESH_RECEIVE_EVENT, geom:geom}), 0);
            } else {
                this.fireEvent({type:MESH_RECEIVE_EVENT, geom:geom});
            }
            return;
        }

        // if geometry is already loading, just increment
        // the request counter.
        var task = _hash2Requests.get(geomHash);
        if (task && task.refcount) {
            task.importanceNeedsUpdate = true;
            task.refcount++;
            return;
        }

        // geom is neither in memory nor loading.
        // we have to request it.
        var msg = {
            operation:    "LOAD_CDN_RESOURCE_OTG",
            type:         "g",
            url:          url,
            cacheBucket: lineageUrn,
            hash:         geomHash,
            queryParams:  queryParams,
            importance:   0.0,
            importanceNeedsUpdate: true, // compute actual importance later in updatePriorities
            refcount: 1,
            requested: false,
            callbacks: new Array(),
        };

        if (addToQueue) {
            _queue.addTask(msg);
        }
        _hash2Requests.set(geomHash, msg);

        if (USE_OUT_OF_CORE_TILE_MANAGER) {
            this.startProcessing();
        } else if (!_timeout) {
            _timeout = setTimeout(processQueuedItems, 0);
        }
    };

    this.requestMaterial = function(url, isCDN, matHash, matIdx, queryParams, lineageUrn) {

        // if this material is in memory, just return it directly
        var mat = _mats.get(matHash);
        if (mat === MAT_ERROR) {
            //it failed to load previously
            setTimeout(() => this.fireEvent({type:MATERIAL_FAILED_EVENT, error:mat, hash: matHash, repeated: true}), 0);
            return;
        } else if (mat) {
            //it was already cached
            // TODO fire the event synchronously instead of using setTimeout.
            // This would be faster and simplify some things (see setTimeout comment further down in this file),
            // but is not straightforward as tryToActivateFragment would become reentrant and the material processing logic becomes confused.
            // (It is already if requestGeometry fires its received event, but we got lucky there and it just works)
            setTimeout(() => this.fireEvent({ type: MATERIAL_RECEIVE_EVENT, material: mat, hash: matHash }));
            return;
        }

        // if material is already loading, just increment
        // the request counter.
        var task = _hash2Requests.get(matHash);
        if (task && task.refcount) {
            task.refcount++;
            return;
        }

        // material is neither in memory nor loading.
        // we have to request it.
        var msg = {
            operation:      "LOAD_CDN_RESOURCE_OTG",
            type:           "m",
            urls:           [url],
            cacheBuckets:   [lineageUrn],
            hashes:         [matHash],
            queryParams:    queryParams,
            refcount:       1,
            couldBeInCache: [couldBeInCache(this, matHash)],
            requested: true,
            callbacks: new Array(),
        };

            _hash2Requests.set(matHash, msg);

        //Material requests are sent to the worker immediately, without going through the
        //priority queue.
        _loadWorker.doOperation(initLoadContextGeomCache(msg));
        _requestsInProgress++;
        this.requestsSent++;
    };

    this.startProcessing = function() {
        if (!_recurringTaskActive) {
            OutOfCoreTileManager.addRecurringTask(processQueuedItems);
            _recurringTaskActive = true;
        }
    };

    this.stopProcessing = function() {
        if (_recurringTaskActive) {
            OutOfCoreTileManager.removeRecurringTask(processQueuedItems);
            _recurringTaskActive = false;
        }
    };

    function processQueuedItems(deadline = Infinity) {

        let maxRequestsInFlight = _maxRequestsInFlight;

        if (USE_OUT_OF_CORE_TILE_MANAGER && _numLoadersDownloadingFragmentList > 0) {
            maxRequestsInFlight = Math.min(maxRequestsInFlight, BandwidthOptimizer.THROTTLED_FLIGHT);
        }

        var howManyCanWeDo = maxRequestsInFlight - _requestsInProgress;


        // avoid the overhead of very small messages
        if (howManyCanWeDo <= maxRequestsInFlight * 0.01) {
            if (!USE_OUT_OF_CORE_TILE_MANAGER) {
                _timeout = setTimeout(processQueuedItems, 30);
            }
            return;
        }

        let requests = [];

        if (USE_OUT_OF_CORE_TILE_MANAGER) {
            while (requests.length < howManyCanWeDo && performance.now() < deadline) {
                const batch = OutOfCoreTileManager.getNextBVHNodeToPrefetchGeometryFor(_hash2Requests);
                if (!batch) {
                    break;
                }
                for (const hash of batch.hashes) {
                    let request = _hash2Requests.get(hash);
                    // requestMaterial uses setTimeout to fire the received event if the material is already there.
                    // So it can happen that materials get requested by the loader that are actually already known to the OtgResourceCache.
                    // So we need to catch that here.
                    if (_mats.has(hash)) {
                        // Because of the setTimeout, we must delay the callback.
                        // Otherwise, if we fire the callback here immediately, consolidation will be attempted before the loader received the material.
                        setTimeout(batch.callback, 0);
                        continue;
                    }
                    request.callbacks.push(batch.callback);

                    if (request.requested) {
                        continue;
                    }
                    request.requested = true;
                    requests.push(request);
                }
            }
        }

        // recompute importance for each geometry and sort queue by decreasing priority
        // We only do this, if we are not receiving BVH nodes from the OutOfCoreTileManager.
        if (!USE_OUT_OF_CORE_TILE_MANAGER || requests.length === 0) {
            _queue.updateRequestPriorities();
        }

        // process OtgPriorityQueue
        while (!_queue.isEmpty() && requests.length < howManyCanWeDo) {
            var task = _queue.takeTask();
            if (task.requested) {
                continue;
            }
            // can happen when cutting over to loading from BVH. Then we still need to process the queue to clear it.
            if (_geoms.has(task.hash)) {
                continue;
            }
            requests.push(task);
            task.requested = true;
        }

        var msg = null;
        for (let task of requests) {

            if(!msg) {
                msg = {
                    operation:    "LOAD_CDN_RESOURCE_OTG",
                    type:         "g",
                    urls:         [task.url],
                    cacheBuckets: [task.cacheBucket],
                    hashes:       [task.hash],
                    queryParams:  task.queryParams,
                    couldBeInCache: [couldBeInCache(_this, task.hash)],
                };
            } else {
                msg.urls.push(task.url);
                msg.hashes.push(task.hash);
                msg.cacheBuckets.push(task.cacheBucket);
                msg.couldBeInCache.push(couldBeInCache(_this, task.hash));
            }
        }

        if (msg) {
            // send request to worker
            _loadWorker.doOperation(initLoadContextGeomCache(msg));
            _requestsInProgress += msg.urls.length;
            _this.requestsSent+=msg.urls.length;
        }

        if (USE_OUT_OF_CORE_TILE_MANAGER) {
            if (_hash2Requests.size === 0) {
                _this.stopProcessing();
            }
        } else {
            if (_queue.isEmpty()) {
                _timeout = undefined;
            } else {
                _timeout = setTimeout(processQueuedItems, 30);
            }
        }
    }

    // remove all open requests of this client
    // input is a map whose keys are geometry hashes
    this.cancelRequests = function(hashes) {

        for (var hash of hashes) {
            var task = _hash2Requests.get(hash);

            if (task)
                task.refcount--;
            /*
            if (task.refcount === 1) {
                _hash2Requests.delete(hash);
            }*/
        }

        _queue.filterTasks((hash) => {
            // TODO: Analyze why `req` can be undefined. Story: https://jira.autodesk.com/browse/FLUENT-5734
            const req = _hash2Requests.get(hash);
            const keep = req && req.refcount;
            if (!keep) {
                _hash2Requests.delete(hash);
            }
            return keep;
        });

        // TODO: To make switches faster, we should also inform the worker thread,
        //       so that it doesn't spend too much time with loading geometries that noone is waiting for.
    };

    // To prioritize a geometry, we track the bbox surface area of all fragments using it.
    //
    // For this, this function must be called for each new loaded fragment.
    //  @param {RenderModel} model
    //  @param {number}      fragId
    this.updateGeomImportance = function(model, fragId) {
        return updateGeomImportance(model, fragId);
    };

    this.cleanup = function(force) {

        if (_unusedGeomsMap.size === 0 || (!force && this.byteSize < this._maxMemory)) {
            return;
        }

        var _unusedGeoms = [];
        for (let geom of _unusedGeomsMap.values()) {
            _unusedGeoms.push(geom);
        }

        // Sort unused geoms by ascending importance
        _unusedGeoms.sort(compareGeomsByImportance);

        // Since cleanup is too expensive to run per geometry,
        // we always remove a bit more than strictly necessary,
        // so that we can load some more new geometries before we have to
        // run cleanup again.
        var targetMem = force ? 0 : this._maxMemory - this._minCleanup;

        // Remove geoms until we reach mem target
        var i = 0;
        for (; i < _unusedGeoms.length && this.byteSize >= targetMem; i++) {

            var geom = _unusedGeoms[i];

            // remove it from cache
            _geoms.delete(geom.hash);
            _unusedGeomsMap.delete(geom.id);

            // update mem consumption. Note that we run this only for geoms that
            // are not referenced by any RenderModel in memory, so that removing them
            // should actually free memory.
            this.byteSize -= geom.byteSize;

            // Dispose GPU mem.
            // NOTE: In case we get performance issues in Chrome, try commenting this out
            // (see hack in GeometryList.dispose)
            geom.dispose();
        }
    };


    // Wait for specific hashes and push their priority to finish faster.
    //
    // Note: This function does not trigger own requests, i.e. can only be used for hashes of models
    //       that are currently loading.
    //
    //  @param {Object} hashMap          - keys specify hashes. All keys with hashMap[key]===(true|number) will be loaded. If a number is given, it will be used as priority.
    //  @param {function(Object)} onDone - called with hashMap. hashMap[hash] will contain the geometry.
    this.waitForGeometry = function(hashMap, onDone) {

        // track how many of our geoms are finished
        var geomsDone = 0;
        var geomsTodo = _queue.makeUrgent(hashMap);

        // avoid hanging if hashMap is empty
        if (geomsTodo === 0) {
            if (hashMap) {
                onDone(hashMap);
                return;
            }
        }

        processQueuedItems();

        function onGeomDone(hash, geom) {
            // If a geometry is not loading anymore, its priority has no relevance anymore.
            // Note that this is generally true - even if we didn't set the priority in this waitForGeometry call.
            _queue.removeUrgent(hash);

            // Only care for geometries that we need to fill the hashMap values
            if (!hashMap[hash] === true && typeof hashMap[hash] !== 'number') {
                return;
            }

            hashMap[hash] = geom;

            // check if all done
            geomsDone++;
            if (geomsDone < geomsTodo) {
                return;
            }

            // cleanup listeners
            _this.removeEventListener(MESH_RECEIVE_EVENT, onGeomReceived);
            _this.removeEventListener(MESH_FAILED_EVENT, onGeomFailed);

            onDone(hashMap);
        }

        function onGeomReceived(event) { onGeomDone(event.geom.hash, event.geom);      }
        function onGeomFailed(event)   { onGeomDone(event.hash, undefined); }

        this.addEventListener(MESH_RECEIVE_EVENT, onGeomReceived);
        this.addEventListener(MESH_FAILED_EVENT, onGeomFailed);

        // Don't wait forever for any meshes that were already loaded
        for (let hash in hashMap) {
            var geom = _geoms.get(hash);
            if (geom) {
                onGeomDone(hash, geom);
            }
        }
    };

    this.getGeometry = function(hash) {
        return _geoms.get(hash);
    };

    this.addGeometry = function(hash, geom) {
        _geoms.set(hash, geom);

        // track summed cache size in bytes
        _this.byteSize += geom.byteSize;

        if (Object.prototype.hasOwnProperty.call(geom, '_modelRefCount')) {
            return;
        }
        geom._modelRefCount = 0;

        const prototype = Object.getPrototypeOf(geom);
        if (Object.prototype.hasOwnProperty.call(prototype, 'modelRefCount')) {
            return;
        }
        Object.defineProperty(prototype, 'modelRefCount', {
            get: geometry_modelRefCount_get
        });
        prototype.setModelRefCount = geometry_setModelRefCount;
    };

    this.setGeomRefCount = function(geom, value) {
        if (geom._modelRefCount === 0 && value >= 1) {
            const unused = _unusedGeomsMap.get(geom.id);
            if (unused) {
                _unusedGeomsMap.delete(geom.id);
            }
        } else if (value === 0) {
            _unusedGeomsMap.set(geom.id, geom);
        }
        geom._modelRefCount = value;
    };

    // Add material to cache. Note that the cache doesn't store actual Material instances,
    // but rather the source data from the materials file.
    //  @param {string}     hash
    //  @param {Uint8Array} data - a Uint8 blob, containing a material-file json as Utf8.
    this.addMaterialData = function(hash, data) {
        _mats.set(hash, blobToJson(data));
    };

    this.clearOpfsCache = function() {
        // Note I wanted to print navigator.storage.estimate before and after, but it was incorrect.
        // Chrome underreported file system usage, it looked like it was confused by a second directory in the filesystem
        const msg = {
            operation: "CLEAR_OPFS_CACHE",
        };
        _loadWorker.doOperation(msg);
    };

    // For error diagnosis: If something gets stuck during loading, this report helps
    // figuring out where it happens.
    this.reportLoadingState = function() {

        // Report main thread stats
        console.log('OtgResourceCache:', {
            sent: this.requestsSent,
            received: this.requestsReceived
        });

        const msg = {
            operation:   "REPORT_LOADING_STATE",
        };
        _loadWorker.doOperation(msg);
    };

    /**
     * Stores data in the OPFS cache. Can fail e.g. if the cache is not open or on quota exceeded
     * @param {String} bucketName
     * @param {String[]} identifiers
     * @param {Uint8Array[]} datas
     * @returns promise that resolves to a boolean indicating whether the store was successful
     */
    this.opfsStore = function(bucketName, identifiers, datas) {
        return new Promise((resolve) => {
            const messageId = this._nextMessageId++;
            this._customOpfsCallbacks.set(messageId, resolve);
            const msg = {
                operation: "OPFS_CACHE_STORE",
                customOpfsMessageId: messageId,
                bucketName,
                identifiers,
                datas,
            };
            _loadWorker.doOperation(msg, datas.map(data => data.buffer));
        });
    };

    /**
     * Informs the OTGResourceCache that a fragment list download has started and that
     * the download of geometries should be throttled while the download is ongoing.
     */
    this.throttleDownload = function() {
        _numLoadersDownloadingFragmentList++;
    };

    /**
     * Stops throttling the download of geometries after the fragment list download has completed.
     */
    this.stopThrottleDownload = function() {
        _numLoadersDownloadingFragmentList--;
    };

    /**
     * Retrieves data from the OPFS cache
     * @param {String} cacheBucket
     * @param {String} hash
     * @returns {Uint8Array}
     */
    this.getResource = function(cacheBucket, hash) {
        console.assert(hash.byteLength === 20);
        hash = bin16ToPackedString(new Uint16Array(hash));

        var msg = {
            operation: "LOAD_CDN_RESOURCE_OTG",
            type: "x",
            cacheBuckets: [cacheBucket],
            hashes: [hash],
            callbacks: new Array(),
        };
        _hash2Requests.set(hash, msg);

        _loadWorker.doOperation(initLoadContextGeomCache(msg));
        _requestsInProgress++;
        this.requestsSent++;

        return new Promise((resolve) => {
            msg.callbacks.push((data) => resolve(data));
        });
    };
}

EventDispatcher.prototype.apply(OtgResourceCache.prototype);


// These two functions are intentionally defined outside of the
// upper scope to make sure they don't hold a reference to the
// closure. They will be added to a prototype later and thus
// would prevent the closure from being garbage collected.
function geometry_modelRefCount_get() {
  return this._modelRefCount;
}

let warningAboutRefcountPrinted = false;
function geometry_setModelRefCount(value, geomCache) {
    if (!geomCache) {
        // This should never happen, but as a fallback we will set the refcounts to 1
        // if we don't have a geomCache to keep track of them.
        if (!warningAboutRefcountPrinted) {
            console.warn("geometry_setModelRefCount: geomCache is not defined");
            warningAboutRefcountPrinted = true;
        }
        this._modelRefCount = 1;
    } else {
        geomCache.setGeomRefCount(this, value);
    }
}
