/**
 * Imports for the Autodesk.Viewing.Private namespace
 */


//TODO_TS: stuff from compat/globas/envinit goes into both namespaces because they
//contain a mix of public and private exports,
//make it consistent in client code, then remove one of those
export * from "../compat";
export * from "../globals";
export * from "../envinit";

// Analytics
export * from "../analytics/interface";
export * from "../analytics/index";


export * from "../resource-loader";
export * from "../globalization/i18init";

// Warning: We have to keep the .js in this import to make sure the ES6 import map works correctly.
import { Lang as _Lang } from "../globalization/langs.js";
export const Lang = _Lang;


export * from "../logger/Logger";

export * from "../wgs/globals";

export * from "../wgs/render/GroundReflection";
export * from "../wgs/render/GroundShadow";
export * from "../wgs/render/WebGLShader";
export * from "../wgs/render/PhongShader";
export * from "../wgs/render/BasicShader";
export * from "../wgs/render/DecodeEnvMap";
export * from "../wgs/render/ShaderChunks";
export * from "../wgs/render/SAOShader";
export * from "../wgs/render/ShaderPass";
export * from "../wgs/render/CelShader";
export * from "../wgs/render/CopyShader";
export * from "../wgs/render/RenderContextPostProcess";
export * from "../wgs/render/LineStyleDef";
export * from "../wgs/render/HalfFloat";
export * from "../wgs/render/ShaderUtils";
export * from "../wgs/render/WebGLProgram";
export * from "../wgs/render/PrismUtil";
export * from "../wgs/render/ShadowMap";
export * from "../wgs/render/MaterialConverterCommon";
export * from "../wgs/render/MaterialConverter";
export * from "../wgs/render/MaterialManager";
export * from "../wgs/render/RenderContext";
export * from "../wgs/render/WebGLRenderer";
export * from "../wgs/wgpu/Renderer";
export * from "../wgs/wgpu/utils/ShaderPassWebGPU";
export * from "../wgs/wgpu/utils/UniformGroup.ts";
export * from "../wgs/wgpu/utils/CPUTexture";
export * from "../wgs/render/GroundFlags";
export * from "../wgs/render/BufferManager";

export * from "../wgs/scene/MeshFlags";
export * from "../wgs/scene/RenderFlags";
export * from "../wgs/scene/ResetFlags";
export * from "../wgs/scene/LmvVector3";
export * from "../wgs/scene/LmvMatrix4";
export * from "../wgs/scene/LmvBox3";
export * from "../wgs/scene/VertexEnumerator";
export * from "../wgs/scene/VertexBufferReader";
export * from "../wgs/scene/BoundsCallback";
export * from "../wgs/scene/DeriveTopology";
export * from "../wgs/scene/VBIntersector";
export * from "../wgs/scene/GeometryList";
export * from "../wgs/scene/RenderBatch";
export * from "../wgs/scene/ModelIteratorLinear";
export * from "../wgs/scene/ModelIteratorBVH";
export * from "../wgs/scene/SceneTraversal";
export * from "../wgs/scene/NoOpTraversalController";
export * from "../wgs/scene/BufferGeometry";
export * from "../wgs/scene/BufferGeometryUtils";
export * from "../wgs/scene/RenderScene";
export * from "../wgs/scene/SortedList";
export * from "../wgs/scene/RenderModel";
export * from "../wgs/scene/FrustumIntersector";
export * from "../wgs/scene/FragmentIterator";
export * from "../wgs/scene/FragmentList";
export * from "../wgs/scene/InstanceTree";
export * from "../wgs/scene/InstanceTreeStorage";
export * from "../wgs/scene/BVHBuilder";
export * from "../wgs/scene/SceneMath";
export * from "../wgs/scene/MemoryTracker";
export * from "../wgs/scene/out-of-core-tile-manager/OutOfCoreTileManager";

export * from "../wgs/scene/leaflet/TileCoords";
export * from "../wgs/scene/leaflet/ModelIteratorTexQuad";

//TODO: Do all these need to be exported or just the entry point?
export * from "../wgs/scene/consolidation/FragmentListConsolidation"; // name exported differs
export * from "../wgs/scene/consolidation/ConsolidationIterator";
export * from "../wgs/scene/consolidation/InstanceBufferBuilder";
export * from "../wgs/scene/consolidation/Consolidation";
export * from "../wgs/scene/consolidation/GeomMergeTask";

export * from "../file-loaders/lmvtk/common/VertexBufferBuilder";
export * from "../file-loaders/lmvtk/otg/OtgGeomCodec";
export * from "../file-loaders/lmvtk/otg/HashStrings";
export * from "../file-loaders/lmvtk/f2d/F2d";
export * from "../file-loaders/lmvtk/common/Propdb";

export * from "../file-loaders/net/Xhr";

export {DDSLoader} from "../../thirdparty/three.js/DDSLoader";

export {patchShader} from "../../thirdparty/three.js/materials/PatchShader";


export * from "../application/LightPresets";
export * from "../application/ModelLayers";
export * from "../application/LocalStorage";
export * from "../application/Preferences";
export * from "../application/PreferenceNames";
export * from "../application/Viewer3DImpl";
export * from "../application/ViewerState";

import { FeatureFlags as _FeatureFlags, PublicFeatureFlags as _PublicFeatureFlags, InternalFeatureFlags as _InternalFeatureFlags } from "../application/FeatureFlags";
export const InternalFeatureFlags = _InternalFeatureFlags;

export * from "../measurement/UnitFormatter";

export * from "../measurement/UnitParser";
export * from "../measurement/DisplayUnits";

//Animation
export * from "../animation/Animation";
export * from "../animation/KeyFrameAnimator";
export * from "../animation/type/MeshAnimation";

// Loaders
export * from "../file-loaders/main/SvfLoader";
export * from "../file-loaders/main/F2DLoader";
export * from "../file-loaders/main/LeafletLoader";
export * from "../file-loaders/main/PropDbLoader";
export * from "../file-loaders/main/TextureLoader";
export * from "../file-loaders/main/OtgLoader";
export * from "../file-loaders/main/OtgResourceCache";
export * from "../file-loaders/main/Empty2DModelLoader";

export * from "../tools/Selector";
export * from "../tools/VisibilityManager";
export * from "../tools/viewtransitions/ViewTransition";


export * from "../file-loaders/main/WorkerCreator";

// Conditionally import wasm files
export * from "./conditional/wasm/avp";

// Conditionally import GUI files
export * from "./conditional/gui/avp";

// Register extensions bundled into their own JS files
export * from "../../extensions/registerExternalExtensions";

import { EventUtils } from '../application/EventUtils.js';
export const isMiddleClick = EventUtils.isMiddleClick;
export const isRightClick = EventUtils.isRightClick;

import { BUILD_FLAG__LMV_WORKER_FILE, BUILD_FLAG__INLINE_WORKER } from "../buildFlags.js";
//TODO: find a way to get those out of the avp namespace
/** @define {string} */
export const LMV_WORKER_URL = BUILD_FLAG__LMV_WORKER_FILE; // See webpack.js


export const ENABLE_DEBUG = globalThis.ENABLE_DEBUG;

//Set to true when we need to load the web worker via XHR and create
//it as a data blob URL. Needed for CORS, all other cases
//can set this to false. If you need to debug the worker, set this to false also.
export let ENABLE_INLINE_WORKER = BUILD_FLAG__INLINE_WORKER;
export function setENABLE_INLINE_WORKER(value) {
  ENABLE_INLINE_WORKER = value;
}