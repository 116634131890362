import { isPlaceholderMesh, isInstanceMesh } from '../../scene/consolidation/Consolidation';
import { MeshFlags } from '../../scene/MeshFlags';
import { StageNames } from '../../scene/out-of-core-tile-manager/tasks/defaultStageConfiguration';

export const UPDATE_TYPE = Object.freeze({ THEMING_COLOR: 1, VISIBILITY: 2, HIGHLIGHTING: 4, REOPTIMIZE: 8 });

export class RenderMeshUpdater {
    #requestedNodeUpdates = new Map();
    #uboManager = null;
    #callbackRegistry = new Map();

    constructor(uboManager) {
        this.#uboManager = uboManager;
    }

    /**
     * Register update callbacks for the model
     * @param {RenderModel} model
     */
    addModel(model) {
        const fragList = model.getFragmentList();
        if (fragList) {
            const callbacks = [
                this.requestUpdate.bind(this, model, UPDATE_TYPE.THEMING_COLOR),
                this.requestUpdate.bind(this, model, UPDATE_TYPE.VISIBILITY),
                this.requestUpdate.bind(this, model, UPDATE_TYPE.HIGHLIGHTING),
                this.requestUpdate.bind(this, model, UPDATE_TYPE.REOPTIMIZE)];

            fragList.registerThemingColorChangedCallback(callbacks[0]);
            fragList.registerVisibilityDirtyCallback(callbacks[1]);
            fragList.registerHighlightingChangedCallback(callbacks[2]);
            fragList.registerMaterialChangedCallback(callbacks[3]);
            this.#callbackRegistry.set(model.id, callbacks);
        }
    }

    /**
     * Unregister update callbacks for the model
     * @param {RenderModel} model
     */
    removeModel(model) {
        if (!model)
            return;

        const callbacks = this.#callbackRegistry.get(model.id);
        if (callbacks) {
            const fragList = model.getFragmentList();
            if (fragList) {

                fragList.removeThemingColorChangedCallback(callbacks[0]);
                fragList.removeVisibilityDirtyCallback(callbacks[1]);
                fragList.removeHighlightingChangedCallback(callbacks[2]);
                fragList.removeMaterialChangedCallback(callbacks[3]);
                this.#callbackRegistry.delete(model.id);
            }
        }
    }

    /**
     * Request an update of the theming color and/or visibility information of that fragment. If fragId is not provided, the update will be requested for all fragments of the model.
     * The update will be deferred to the next microtask to allow for multiple requests to be consolidated into a single update.
     * @param {RenderModel} model
     * @param {UPDATE_TYPE} requestType
     * @param {number} [fragId]
     */
    requestUpdate(model, requestType, fragId) {
        // Skip updates while the model is not consolidated yet
        if (!model.isConsolidated())
            return;

        const iterator = model.getIterator();
        const oocManager = iterator.getOutOfCoreTileManager();
        if (fragId === undefined) {
            // Apply the update to all nodes
            oocManager.bvhNodes.forEach(node => this.#queueUpdate(requestType, model, node));
        } else {
            const nodeId = iterator.getBVH().fragId2nodeId[fragId];
            this.#queueUpdate(requestType, model, oocManager.bvhNodes[nodeId]);
        }
    }

    #queueUpdate(requestType, model, node) {
        // is an update already requested?
        const queuedRequestType = this.#requestedNodeUpdates.get(node) ?? 0;
        if ((queuedRequestType & requestType) === 0) {
            this.#requestedNodeUpdates.set(node, queuedRequestType | requestType);

            // Only queue the update if it's the first request
            if (queuedRequestType === 0) {
                // defer the update to the next microtask to avoid redundant updates in the same frame
                queueMicrotask(() => {
                    const requestType = this.#requestedNodeUpdates.get(node);

                    // If we need to do a full reoptimization, we can skip the other updates as they will be done during the reoptimization
                    if (requestType & UPDATE_TYPE.REOPTIMIZE) {
                        const oocManager = model.getIterator().getOutOfCoreTileManager();
                        oocManager.demoteToStage(node, oocManager.stagesByName.get(StageNames.GEOMETRY_LOADED));
                    } else {
                        const consolidation = model.getConsolidation(node.nodeId);
                        if (!consolidation)
                            return;

                        for (let mesh of consolidation.meshes) {
                            // do we have a consolidated mesh?
                            if (!mesh || !mesh.geometry || isPlaceholderMesh(mesh))
                                continue;

                            if (isInstanceMesh(mesh)) {
                                if (requestType & UPDATE_TYPE.THEMING_COLOR) {
                                    this.#updateInstanceMeshThemingColors(mesh, model.getFragmentList(), consolidation.consolidationMap.fragOrder);
                                }

                                if (requestType & (UPDATE_TYPE.VISIBILITY | UPDATE_TYPE.HIGHLIGHTING)) {
                                    this.#updateInstanceMeshVisibility(mesh, model.getFragmentList(), consolidation.consolidationMap.fragOrder);
                                }
                            } else { // consolidated mesh
                                this.#uboManager.updateMeshFragmentUBO(mesh, model.getFragmentList(), consolidation.consolidationMap.fragOrder);
                            }
                        }
                    }

                    this.#requestedNodeUpdates.delete(node);
                });
            }
        }
    }

    #updateInstanceMeshThemingColors(instanceMesh, fragList, fragIds) {
        const attrib = instanceMesh.geometry.getAttribute('instThemingColor');
        const buffer = attrib.array;
        attrib.needsUpdate = true;
        const noThemeingColor = new THREE.Vector4(0, 0, 0, 0);
        for (let i = 0; i < instanceMesh.rangeCount; i++) {
            let fragId = fragIds[instanceMesh.rangeBegin + i];
            let dbID = fragList.getDbIds(fragId);
            const themingColor = fragList.db2ThemingColor[dbID] ?? noThemeingColor;

            buffer[i * 4 + 0] = themingColor.x * 255;
            buffer[i * 4 + 1] = themingColor.y * 255;
            buffer[i * 4 + 2] = themingColor.z * 255;
            buffer[i * 4 + 3] = themingColor.w * 255;
        }
    }

    #updateInstanceMeshVisibility(instanceMesh, fragList, fragIds) {
        const attrib = instanceMesh.geometry.getAttribute('instFlags');
        const buffer = attrib.array;
        attrib.needsUpdate = true;
        let allFragsVisible = true;
        let anyFragVisible = false;
        let fragVisible;
        for (let i = 0; i < instanceMesh.rangeCount; i++) {
            let fragId = fragIds[instanceMesh.rangeBegin + i];
            buffer[i] = fragList.vizflags[fragId] & 0xFF; // only the lowest 8 bits are used
            fragVisible = !!(buffer[i] & (MeshFlags.MESH_HIGHLIGHTED | MeshFlags.MESH_VISIBLE));
            allFragsVisible &&= fragVisible;
            anyFragVisible ||= fragVisible;
        }
        instanceMesh.allFragsVisible = allFragsVisible;
        instanceMesh.noFragsVisible = !anyFragVisible;
    }
}