import {RenderBatch} from "../scene/RenderBatch";
import {BufferGeometryUtils} from "../../wgs/scene/BufferGeometry";
import THREE from "three";
import { USE_OUT_OF_CORE_TILE_MANAGER } from "../globals";

// Sorting

// This method is for transparency
function painterSortStable ( a, b ) {

    // first see if there's a render order set - if so, this takes precedence
    if (a.renderOrder !== b.renderOrder) {

        return a.renderOrder - b.renderOrder;

        // If render order are the same, then use z distance.
        // We want to render from farthest to nearest.
    } else if (a.z !== b.z) {

        return a.z - b.z;

        // if z distances match, then use id, for a consistent result
    } else {

        return a.id - b.id;

    }

}

// This method is for opaque objects
function reversePainterSortStable ( a, b ) {

    // first see if there's a render order set - if so, this takes precedence
    if (a.renderOrder !== b.renderOrder) {

        return a.renderOrder - b.renderOrder;

        // Next, sort by material, for efficiency, to avoid state changes.
        // (Note this is not done for transparency, as back to front order is more significant.)
    } else if (a.material.id !== b.material.id) {

        return a.material.id - b.material.id;

        // If render order and material are the same, then use z distance.
        // To minimize processing fragments, we render roughly from nearest to farthest.
        // In this way, the closer objects cover pixels and so hide more distance objects.
    } if (a.z !== b.z) {

        return b.z - a.z;

        // if z distances match, then use id, for a consistent sorted result
    } else {

        return a.id - b.id;

    }

}


/**
 * Wraps a minimal RenderBatch interface implementation around the flattened THREE.Scene
 * so that the Renderer can consistently iterate
 */
export class RenderBatchShim {

    #opaqueObjects = [];
    #transparentObjects = [];
    #is2d = false;
    #deadline;
    #interruptedAtIndex;
    #allowInterruption = USE_OUT_OF_CORE_TILE_MANAGER;

    // Working memory
    #vector3 = new THREE.Vector3();
    #frustum = new THREE.Frustum();

    //Compatibility API to convert THREE.Scene to a render batch usable by the WebGPU renderer
    setFromScene(scene, projScreenMatrix) {

        this.#frustum.setFromProjectionMatrix(projScreenMatrix);

        if (scene.autoUpdate === true) scene.updateMatrixWorld();

        this.#opaqueObjects.length = 0;
        this.#transparentObjects.length = 0;
        this.#is2d = false;
        this.#deadline = undefined;
        this.#interruptedAtIndex = undefined;

        this.#projectObject(scene, scene.sortObjects === true, scene.forceVisible === true, projScreenMatrix);

        // note: the following flag is never set in WebGLRenderer; this may change in the future
        if (scene.sortObjects === true) {
            this.#opaqueObjects.sort(reversePainterSortStable);
            this.#transparentObjects.sort(painterSortStable);
        }
    }

    /**
     * Minimal RenderBatch interface for the renderer to iterate over the scene.
     * See {@link RenderBatch#forEachWGPU}.
     * If cb returns true and interruption is allowed, this signals that the loop
     * should be interrupted and restarted at the current index next time.
     */
    forEachWGPU(startIndex, batchSize, cb) {

        let loopLimit = batchSize || -1;
        let i = startIndex;
        if (this.#allowInterruption) {
            i = this.#interruptedAtIndex || i;
            this.#interruptedAtIndex = undefined;
        }
        let endIndex = 0;
        let oLength = this.#opaqueObjects.length;

        //loop over opaque objects first
        if (i < oLength) {
            let end = oLength;
            if (loopLimit > 0) {
                if (end - i > loopLimit) {
                    end = i + loopLimit;
                    endIndex = end;
                } else {
                    loopLimit = Math.max(0, loopLimit - (end - i));
                }
            }

            let instanceCount = 0;

            for (; i < end; i++) {
                const m = this.#opaqueObjects[i];

                // If there are instanced meshes, consider them for loop limit.
                // Otherwise, we get an overflow in ObjectUniforms, because each
                // instance requires an own slot in the UniformBuffer.
                instanceCount += m.geometry?.numInstances ?? 1;

                if (instanceCount > loopLimit) {
                    // Stop the range earlier, because the instance count filled the limit already.
                    endIndex = i;
                    break;
                }

                const interrupt = cb(m);
                if (this.checkInterrupt(i, interrupt)) {
                    return -1;
                }
            }
        }

        //If the batch ended somewhere in the middle of the opaque objects
        if (endIndex) {
            return endIndex;
        }

        //If the batch ended exactly on the end of the opaque objects array,
        //return its length as the endIndex
        if (loopLimit === 0) {
            return oLength;
        }

        //loop over transparent objects
        i -= oLength;
        let end = this.#transparentObjects.length;
        if (loopLimit > 0) {
            if (end - i > loopLimit) {
                end = i + loopLimit;
                endIndex = end + oLength;
            }
        }

        let instanceCount = 0;

        for (; i < end; i++) {
            const m = this.#transparentObjects[i];

            // Consider instancing for loop limit - see comment in opaque loop for details.
            instanceCount += m.geometry?.numInstances ?? 1;
            if (instanceCount > loopLimit) {
                // Stop the range earlier, because the instance count filled the limit already.
                endIndex = i;
                break;
            }

            const interrupt = cb(m);
            if (this.checkInterrupt(i, interrupt)) {
                return -1;
            }
        }

        return endIndex || -1;
    }

    is2d() {
        return this.#is2d;
    }

    /**
     * @returns {boolean} True if the scene should be affected by cutplanes.
     *
     * @note WebGL handles cutplanes on a per-material level, while WebGPU uses global cutplanes.
     *       This function assumes all objects in a THREE scene either use the cutplanes or don't.
     *       This allows e.g. the cut working for selection meshes without cutting gizmos.
     *
     * @todo If per-material cutplanes are desired (like WebGL), we can't use this implementation.
     */
    needsCutPlanes() {
        // Check first object as representative of the whole scene.
        const firstObject = this.#opaqueObjects[0] ?? this.#transparentObjects[0] ?? null;
        const sceneHasCutPlanes = firstObject?.material.cutplanes;
        return !!sceneHasCutPlanes
    }

    #projectObject(object, sortObjects, forceVisible, projScreenMatrix) {

        if (!forceVisible && object.visible === false)
            return;

        if (object instanceof THREE.Scene || object instanceof THREE.Group) {

            // skip

        } else if (object instanceof RenderBatch) {

            //TODO: Not sure if we need RenderBatch-in-Scene support, it's something used
            //by the 2D rendering and perhaps consolidation?
            //object.forEach(sortObjects ? renderBatchIterSort : renderBatchIterNoSort, 0, false, true);

        } else {

            //initObject( object );

            if (object instanceof THREE.Light) {

                //lights.push( object );

            } else if (object instanceof THREE.Mesh || object instanceof THREE.Line) {

                //This has to be THREE.Mesh (or compatible, e.g. THREE.Line)

                if ((object.frustumCulled === false || this.#frustum.intersectsObject(object) === true)) {

                    const material = object.material;

                    if (material) {
                        if (material.transparent) {
                            this.#transparentObjects.push(object);
                        } else {
                            this.#opaqueObjects.push(object);
                        }

                        if (material.is2d) {
                            this.#is2d = true;
                        }
                    }

                    if (sortObjects === true) {

                        this.#vector3.setFromMatrixPosition(object.matrixWorld);
                        this.#vector3.applyProjection(projScreenMatrix);
                        object.z = this.#vector3.z;

                    }
                }
            }
        }

        if (object.children) {
            for (let i = 0, len = object.children.length; i < len; i++) {
                this.#projectObject(object.children[i], sortObjects, forceVisible);
            }
        }

    }

    setDeadline(deadline) {
        this.#deadline = deadline;
    }

    hasBeenInterrupted() {
        return this.#interruptedAtIndex !== undefined;
    }

    resetInterrupted() {
        this.#interruptedAtIndex = undefined;
    }

    /**
     * @param {number} i
     * @param {boolean} interrupt
     * @returns {boolean} Whether to terminate loop
     */
    checkInterrupt(i, interrupt) {
        if (!this.#allowInterruption) {
            return false;
        }

        if (interrupt) {
            this.#interruptedAtIndex = i;
            return true;
        }
        if (this.#deadline !== undefined && (i % 10) === 0 && performance.now() > this.#deadline) {
            this.#interruptedAtIndex = i;
            return true;
        }

        return false;
    }
}

/**
 * Create index array that connects subsequent pairs of vertices as line segments.
 * @param {number} numVertices Number of vertices in the line strip.
 * @returns {Uint32Array} Index array for line segments.
 **/
const createIndicesFromLineStrip = (numVertices) => {
    const lineCount = numVertices - 1;
    const indices = new Uint32Array(2 * lineCount);
    for (let i = 0; i < lineCount; i++) {
        indices[i * 2] = i;
        indices[i * 2 + 1] = i + 1;
    }
    return indices;
}

/**
 * Returns an interleaved BufferGeometry for the input THREE.Geometry which can be rendered with WebGPU.
 * The fallback is created on demand and attached to the input object. Repeated calls just return the
 * attached property.
 * @param {THREE.Geometry} threeGeometry Geometry to get fallback attached.
 * @returns {THREE.BufferGeometry} Interleaved BufferGeometry for WebGPU rendering.
 *
 * @note This is extremely inefficient (however, THREE.Geometry isn't very efficient either)
 *       and best used for small gizmos, not big meshes.
 * @note If the input THREE.Geometry is changed between calls, this will not update the fallback.
 *       That said, THREE.Geometry is not supposed to be mutated after construction.
 */
export const getFallbackGeometry = (threeGeometry) => {

    let bufferGeometry = threeGeometry.__fallbackGeom;
    if (bufferGeometry && threeGeometry.verticesNeedUpdate) {
        // If the vertices changed, we currently just renew the full fallback geometry.
        // But since it's currently just a fallback to keep THREE-based gizmos working,
        // it's not a performance priority yet.
        bufferGeometry.dispose();
        bufferGeometry = undefined;
        threeGeometry.__fallbackGeom = undefined
    }

    // Return previously created fallbackGeom (if any)
    if (bufferGeometry) {
        return bufferGeometry;
    }

    // Only THREE.Geometry is supported
    if (!(threeGeometry instanceof THREE.Geometry)) {
        // Assign null so this check only runs the first time.
        threeGeometry.__fallbackGeom = null;
        console.warn("Skipping object with unsupported geometry.", threeGeometry);

        return threeGeometry.__fallbackGeom;
    }

    // Convert THREE.Geometry to interleaved BufferGeometry.
    // This is quite inefficient (as is THREE.Geometry), only use for small gizmo stuff.
    bufferGeometry = new THREE.BufferGeometry();
    if (threeGeometry.faces.length == 0) { // Probably line geometry
        // BufferGeometry.fromGeometry(..) does not support geometry without faces.
        // We use setFromPoints instead to fill the position attribute.
        // Note: The index buffer is added by interleaveGeometry below.
        bufferGeometry.setFromPoints(threeGeometry.vertices);
    } else { // 3D geometry
        bufferGeometry.fromGeometry(threeGeometry);
    }
    BufferGeometryUtils.interleaveGeometry(bufferGeometry);

    // Forward "isLines" flag of source geometry
    bufferGeometry.isLines = threeGeometry.isLines;

    // For LineStrip mode, we must add an index buffer that connects subsequent vertices.
    if (threeGeometry.isLineStrip) {
        bufferGeometry.ib = createIndicesFromLineStrip(bufferGeometry.attributes.position.count);
    }

    // Attach the fallback geometry.
    threeGeometry.__fallbackGeom = bufferGeometry;


    // If the geometry should be disposed, we forward to the fallback geometry.
    threeGeometry.__fallbackGeomOnDisposeProxy = function(event) {
        const geometry = event.target;
        geometry.__fallbackGeom?.dispatchEvent(event);
        geometry.removeEventListener('dispose', geometry.__fallbackGeomOnDisposeProxy);
    };
    threeGeometry.addEventListener('dispose', threeGeometry.__fallbackGeomOnDisposeProxy);

    // Don't update again until next change
    threeGeometry.verticesNeedUpdate = false;

    return threeGeometry.__fallbackGeom;
}
