
import { getUberShader } from "./UberShader";
import { getBasicShader } from "./BasicShader";
import { DepthFormat, invertDepthCompare } from "../CommonRenderTargets";
import {getBufferLayout, getPipelineHash} from "../Pipelines";
import {getLine3DShader} from "./Line3DShader";
import {sideToCullMode} from "../compat";
import {initMaterialBindings, MaterialUniformFlags} from "./MaterialUniforms";
import { ToneMapMethod } from "../Renderer";


export class UberPipeline {

    #renderer;
    #device;

    #pipelines = new Map();
    #activePipeline;
    #currentMaterial;
    #activeBindGroupLayout;
    #activeTargetsList;
    #vb;

    #getPipelineHash;

    constructor(renderer) {
        this.#renderer = renderer;
        this.#device = renderer.getDevice();
        this.#vb = this.#renderer.getVB();
        this.#getPipelineHash = getPipelineHash;
    }

    #createPipeline(key, geometry, material, materialTextureMask) {

        const attributes = geometry.attributes;
        const includeVC = !!(attributes.color && material.vertexColors);
        const isUVW = !!attributes.uvw;
        const hasUV = !!attributes.uv || isUVW;
        const hasTextures = hasUV && (materialTextureMask !== 0);
        const hasWideLines = !!material.wideLines;
        // Skip normals for THREE.BasicMaterial which signals that rendering without lighting is desired (e.g. gizmos).
        const includeNormals = !!attributes.normal && !(material instanceof THREE.MeshBasicMaterial);

        let pipeline;

        const depthCompare = material.depthTest ? invertDepthCompare(material.depthFunc || 'less-equal') : 'always';

        if (geometry.isLines) {

            let shader = this.#device.createShaderModule({
                label: 'line 3D shader',
                code: getLine3DShader(includeVC),
            });

            pipeline = this.#device.createRenderPipeline({
                label: 'uber shader lines',
                layout: this.#device.createPipelineLayout({
                    bindGroupLayouts: this.#activeBindGroupLayout
                }),
                vertex: {
                    module: shader,
                    entryPoint: 'vsmain',
                    buffers: getBufferLayout(geometry, false, false, includeVC, false),
                },
                fragment: {
                    module: shader,
                    entryPoint: 'psmain',
                    targets: this.#activeTargetsList,
                },
                primitive: {
                    topology: 'line-list',
                    cullMode: 'none',
                },
                depthStencil: {
                    depthWriteEnabled: material.depthWrite,
                    depthCompare,
                    format: DepthFormat,
                },
            });
        } else {

            let shader;
            if (includeNormals) {

                const { isGamma, isRGBM } = this.#renderer.getEnvMapEncoding();

                // Activate gamma-decoding for input colors if tone-mapping is used
                // (e.g., see inputToLinear() in uber.wgsl)
                const gammaInput = (this.#renderer.getEnvMapEncoding() != ToneMapMethod.None);

                // Default case to handle BasicMaterial shapes without normals
                shader = this.#device.createShaderModule({
                    label: 'uber shader triangles (' + key + ')',
                    code: getUberShader(
                        material, hasUV, hasTextures, includeVC, isUVW,
                        isGamma, isRGBM, gammaInput
                    )
                });
            } else {
                // Fallback for shapes without normals (e.g. gizmos with BasicMaterial)
                shader = this.#device.createShaderModule({
                    label: 'basic shader triangles (' + key + ')',
                    code: getBasicShader(includeVC, hasWideLines)
                });
            }

            // "depthBias, depthBiasSlopeScale, and depthBiasClamp have no effect on 'point-list', 'line-list', and
            // 'line-strip' primitives, and must be 0." ...from https://www.w3.org/TR/webgpu/#depth-stencil-state
            const depthBias = material.isRoomMaterial && material.heatmapSensorCount ? -1 : -2;
            const depthBiasSlopeScale = material.isRoomMaterial && material.heatmapSensorCount ? -0.5 : -1; // @todo: this might be too much

            const pipelineDesc = {
                label: 'uber pipeline triangles (' + key + ')',
                layout: this.#device.createPipelineLayout({
                    bindGroupLayouts: this.#activeBindGroupLayout
                }),
                vertex: {
                    module: shader,
                    entryPoint: 'vsmain',
                    buffers: getBufferLayout(geometry, includeNormals, hasUV, includeVC, isUVW, hasWideLines),
                },
                fragment: {
                    module: shader,
                    entryPoint: 'psmain',
                    targets: this.#activeTargetsList,
                },
                primitive: {
                    topology: 'triangle-list',
                    cullMode: sideToCullMode(material.side),
                },

                depthStencil: {
                    depthWriteEnabled: material.depthWrite,
                    depthCompare,
                    format: DepthFormat,
                    depthBias,
                    depthBiasSlopeScale,
                },
            };
            pipeline = this.#device.createRenderPipeline(pipelineDesc);
        }

        this.#pipelines.set(key, pipeline);

        return pipeline;
    }

    resetPipelines() {
        this.#pipelines.clear();
    }

    reset(layouts, targets) {
        this.#activePipeline = null;
        this.#currentMaterial = null;
        this.#activeBindGroupLayout = layouts;
        this.#activeTargetsList = targets;
    }

    #activateMaterialBindings(passEncoder, material) {

        let materialUniformsMask = material.__gpuUniformsMask | 0;

        if (materialUniformsMask === MaterialUniformFlags.NO_UNIFORMS && !material.needsUpdate) {
            return 0;
        }

        if (materialUniformsMask === 0 || material.needsUpdate) {
            materialUniformsMask = initMaterialBindings(this.#device, material, this.#renderer.getPlaceholderTexture());
            this.#currentMaterial = null;
        }

        if (materialUniformsMask === MaterialUniformFlags.NO_UNIFORMS) {
            return 0;
        }

        if (this.#currentMaterial !== material) {
            passEncoder.setBindGroup(2, material.__gpuMaterialUniforms.getBindGroup());
            this.#currentMaterial = material;
        }

        return materialUniformsMask;
    }

    drawOne(passEncoder, objectIndex, geometry, material) {
        const materialUniformsMask = this.#activateMaterialBindings(passEncoder, material);
        const materialTextureMask = materialUniformsMask & MaterialUniformFlags.TEXTURE_MASK;

        const attributes = geometry.attributes;
        const includeVC = attributes.color && material.vertexColors;
        const hasUV = attributes.uv;
        const hasTextures = hasUV && (materialTextureMask !== 0);
        const key = this.#getPipelineHash(geometry, material, true, hasUV, includeVC, hasTextures);

        let pipeline = this.#pipelines.get(key);
        if (!pipeline) {
            pipeline = this.#createPipeline(key, geometry, material, materialTextureMask);
        }

        if (pipeline !== this.#activePipeline) {
            passEncoder.setPipeline(pipeline);
            this.#activePipeline = pipeline;
        }

        this.#vb.draw(passEncoder, geometry, objectIndex);

        return materialTextureMask;
    }

}
