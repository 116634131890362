
export const TOL = 1e-6;

export function isZero(f) {
    return Math.abs(f) < TOL;
}

export function isEqual(a, b) {
    return isZero(a - b);
}

/**
 * Compares 2D Boxes with a tolerance.
 * @param {THREE.Box2} box1
 * @param {THREE.Box2} box2
 * @param {Double} tolerance
 * @returns True if the boxes are equal. False otherwise.
 */
export function isEqualBox2D(box1, box2, tolerance = TOL) {
    if (Math.abs(box1.max.x - box2.max.x) > tolerance) {
        return false;
    }
    if (Math.abs(box1.max.y - box2.max.y) > tolerance) {
        return false;
    }
    if (Math.abs(box1.min.x - box2.min.x) > tolerance) {
        return false;
    }
    if (Math.abs(box1.min.y - box2.min.y) > tolerance) {
        return false;
    }
    return true;
}