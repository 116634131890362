import { LmvVector3 } from "../../scene/LmvVector3";
import { LmvMatrix4 } from "../../scene/LmvMatrix4";
import { FrustumIntersector } from "../../scene/FrustumIntersector";
import { GroundFlags } from "../../render/GroundFlags";
import { MeshFlags } from "../../scene/MeshFlags";

import { DepthClearValue, DepthFormat } from "../CommonRenderTargets";
import { CameraUniforms } from "../main/CameraUniforms";
import { getTextureBindGroup, getTextureBindGroupLayout } from "./GroundShadowTextures";
import { GroundShadowDepthPipeline } from "./GroundShadowDepthPipeline";
import { GroundShadowColorPipeline } from "./GroundShadowColorPipeline";
import { GroundShadowBlurPipeline } from "./GroundShadowBlurPipeline";
import { FrameBindGroup } from "../main/FrameBindGroup";
import { Renderer } from "../Renderer";
import { USE_OUT_OF_CORE_TILE_MANAGER } from "../../globals";

const SMALL_FEATURE_CULLING_THRESHOLD = 2.0;
const ENABLE_PIXEL_CULLING = true;

// If minScenesPerFrame is provided when rendering the shadow, we either render this minimum number,
// or <# of available render batches> / MAX_PROCESS_FRAMES. Whatever is higher.
const MAX_PROCESS_FRAMES = 100;

const GROUND_PLANE_STRIDE = 5; // in floats

export class GroundShadowPass {
    /** @type {Renderer} */
    #renderer;
    #device;

    #texSize = 64.0;
    #pixScale = 1.0;
    #blurRadius = 7.0;

    // The scene is rendered into this.#hTarget.
    // We then blur from h into v (vertical), and from v into h again (horizontal).
    #hTarget; #vTarget; #depthTarget;
    #hTargetView; #vTargetView; #depthTargetView;
    #sampler;

    #cameraUniforms;
    /** @type {RenderBatchUniforms} */
    #renderBatchUniforms;
    /** @type {FrameBindGroup} */
    #frameBindGroup;

    #shadowPipeline; #colorPipeline; #blurHPipeline; #blurVPipeline;
    #shadowPassDescriptor; #clearPassDescriptor; #blurPassDescriptor; #colorPassDescriptor;
    #textureBindGroupLayout;
    #blurHTextureBindGroup; #blurVTextureBindGroup; #colorTextureBindGroup;

    #groundPlaneBuffer;
    #groundPlaneBufferCPU = new Float32Array(20); // 4 vertices * pos (vec3f) * uv (vec2f)
    #groundPlaneIndexBuffer;
    #groundPlaneIndexBufferCPU = new Uint16Array(6);
    #groundPlanePosition = new LmvVector3();
    #groundPlaneQuaternion = new THREE.Quaternion();
    #groundPlaneRotation = new THREE.Euler();
    #groundPlaneScale = new LmvVector3(1, 1, 1);

    #camera = new THREE.OrthographicCamera();
    #frustumIntersector = new FrustumIntersector();

    #needClear = true;
    #status = GroundFlags.GROUND_FINISHED;
    #bufferValid = false; // This means "was the blur post-process done?" not "are we done rendering?"

    #prevCenter = new LmvVector3(0, 0, 0);
    #prevSize = new LmvVector3(0, 0, 0);
    #prevLookDir = new LmvVector3(0, 0, 0);
    #prevUpDir = new LmvVector3(0, 0, 0);

    #tmpBox = new THREE.Box3();

    #scenesPerModel = [];
    #qScenes;
    #qSceneCount = 0;
    #qSceneIdx = 0;
    #maxScenesPerFrame = 0;

    constructor(renderer) {
        this.#renderer = renderer;
        this.enabled = false;
    }

    init(renderBatchUniforms) {
        this.#device = this.#renderer.getDevice();

        this.#cameraUniforms = new CameraUniforms(this.#device);
        this.#renderBatchUniforms = renderBatchUniforms;
        this.#frameBindGroup = new FrameBindGroup(
            this.#device, this.#cameraUniforms, this.#renderer.getIBL());

        this.#textureBindGroupLayout = getTextureBindGroupLayout(this.#device);

        this.#createResources();

        this.#shadowPipeline = new GroundShadowDepthPipeline(
            this.#renderer,
            this.#frameBindGroup.getLayout(),
            this.#renderBatchUniforms.getLayout(false),
            this.#hTarget.format);

        this.#colorPipeline = new GroundShadowColorPipeline(
            this.#renderer,
            this.#frameBindGroup.getLayout(),
            this.#textureBindGroupLayout,
            navigator.gpu.getPreferredCanvasFormat(),
            GROUND_PLANE_STRIDE);

        // Blurs the h target into v
        this.#blurVPipeline = new GroundShadowBlurPipeline(
            this.#renderer,
            this.#textureBindGroupLayout,
            this.#vTarget.format,
            false,
            (this.#pixScale / this.#texSize).toFixed(4),
            this.#blurRadius.toFixed(2));

        // Blurs the v target into h
        this.#blurHPipeline = new GroundShadowBlurPipeline(
            this.#renderer,
            this.#textureBindGroupLayout,
            this.#hTarget.format,
            true,
            (this.#pixScale / this.#texSize).toFixed(4),
            this.#blurRadius.toFixed(2));

        this.#createGroundPlane();

        this.setTransform(
            new LmvVector3(0, 0, 0),
            new LmvVector3(1, 1, 1),
            new LmvVector3(0, 1, 0),
            new LmvVector3(0, 1, 0)
        );
    }

    #createResources() {
        this.#hTarget = this.#device.createTexture({
            label: 'ground shadow h texture',
            size: { width: this.#texSize, height: this.#texSize },
            format: navigator.gpu.getPreferredCanvasFormat(),
            usage: GPUTextureUsage.RENDER_ATTACHMENT | GPUTextureUsage.TEXTURE_BINDING,
        });
        this.#hTargetView = this.#hTarget.createView({ label: 'ground shadow h texture view' });

        this.#vTarget = this.#device.createTexture({
            label: 'ground shadow v texture',
            size: [this.#texSize, this.#texSize],
            format: navigator.gpu.getPreferredCanvasFormat(),
            usage: GPUTextureUsage.RENDER_ATTACHMENT | GPUTextureUsage.TEXTURE_BINDING,
        });
        this.#vTargetView = this.#vTarget.createView({ label: 'ground shadow v texture view' });

        this.#depthTarget = this.#device.createTexture({
            label: 'ground shadow depth texture',
            size: [this.#texSize, this.#texSize],
            format: DepthFormat,
            usage: GPUTextureUsage.RENDER_ATTACHMENT
        });
        this.#depthTargetView = this.#depthTarget.createView();

        this.#sampler = this.#device.createSampler({
            label: 'ground shadow texture sampler',
            magFilter: 'linear',
            minFilter: 'linear'
        });

        this.#clearPassDescriptor = {
            colorAttachments: [
                {
                    view: this.#hTargetView,
                    clearValue: { r: 0, g: 0, b: 0, a: 0 },
                    loadOp: 'clear',
                    storeOp: 'store',
                },
                {
                    view: this.#vTargetView,
                    clearValue: { r: 0, g: 0, b: 0, a: 0 },
                    loadOp: 'clear',
                    storeOp: 'store',
                },
            ],
            depthStencilAttachment: {
                view: this.#depthTargetView,
                depthClearValue: DepthClearValue,
                depthLoadOp: 'clear',
                depthStoreOp: 'store',
            },
        };

        this.#shadowPassDescriptor = {
            colorAttachments: [
                {
                    view: this.#hTargetView,
                    loadOp: 'load',
                    storeOp: 'store',
                },
            ],
            depthStencilAttachment: {
                view: this.#depthTargetView,
                depthLoadOp: 'load',
                depthStoreOp: 'store',
            },
        };

        this.#colorPassDescriptor = {
            colorAttachments: [
                {
                    // The view will be set to the color target before rendering
                    loadOp: 'load',
                    storeOp: 'store',
                },
            ],
            depthStencilAttachment: {
                // The view will be set to the depth target before rendering
                depthLoadOp: 'load',
                depthStoreOp: 'store',
            },
        };

        this.#blurPassDescriptor = {
            colorAttachments: [
                {
                    // The view will be set to the correct target before rendering
                    loadOp: 'load',
                    storeOp: 'store',
                },
            ]
        };

        this.#colorTextureBindGroup = getTextureBindGroup(this.#device, this.#textureBindGroupLayout, this.#sampler, this.#hTargetView, 'ground shadow color texture bind group');

        this.#blurHTextureBindGroup = getTextureBindGroup(this.#device, this.#textureBindGroupLayout, this.#sampler, this.#vTargetView, 'ground shadow blur h texture bind group');

        this.#blurVTextureBindGroup = getTextureBindGroup(this.#device, this.#textureBindGroupLayout, this.#sampler, this.#hTargetView, 'ground shadow blur v texture bind group');

        this.#groundPlaneBuffer = this.#device.createBuffer({
            label: 'ground shadow plane buffer',
            size: this.#groundPlaneBufferCPU.byteLength,
            usage: GPUBufferUsage.VERTEX | GPUBufferUsage.COPY_DST,
        });

        this.#groundPlaneIndexBuffer = this.#device.createBuffer({
            label: 'ground shadow plane index buffer',
            size: this.#groundPlaneIndexBufferCPU.byteLength,
            usage: GPUBufferUsage.INDEX | GPUBufferUsage.COPY_DST,
        });
    }

    #createGroundPlane() {
        this.#groundPlaneBufferCPU.set([
            -0.5, 0.5, 0, 0, 0, // pos + uv
            0.5, 0.5, 0, 1.0, 0,
            -0.5, -0.5, 0, 0, 1.0,
            0.5, -0.5, 0, 1.0, 1.0
        ]);

        // original three order, which gets reverted in the old code: 0, 2, 1, 2, 3, 1
        this.#groundPlaneIndexBufferCPU.set([1, 3, 2, 1, 2, 0]);
    }

    #setGroundPlaneTransform(center, size, worldUp, rightAxis) {
        const mat = new LmvMatrix4(false);
        const vec3 = new LmvVector3();
        const bottomFaceCenter = new LmvVector3();

        // compute rotation
        vec3.subVectors(center, worldUp); // 'from' vector
        mat.lookAt(vec3, center, rightAxis);

        // the ground plane center is the lower-face center of the bbox
        bottomFaceCenter.copy(worldUp).multiplyScalar(-0.5 * size.y).add(center);

        // plane transform
        this.#groundPlanePosition.copy(bottomFaceCenter);
        this.#groundPlaneRotation.setFromRotationMatrix(mat);
        this.#groundPlaneQuaternion.setFromEuler(this.#groundPlaneRotation, false);
        this.#groundPlaneScale.set(size.z, size.x, size.y);

        mat.compose(this.#groundPlanePosition, this.#groundPlaneQuaternion, this.#groundPlaneScale);

        for (let i = 0; i < 4; ++i) {
            vec3.set(
                this.#groundPlaneBufferCPU[i * GROUND_PLANE_STRIDE],
                this.#groundPlaneBufferCPU[i * GROUND_PLANE_STRIDE + 1],
                this.#groundPlaneBufferCPU[i * GROUND_PLANE_STRIDE + 2]
            );

            vec3.applyMatrix4(mat);

            this.#groundPlaneBufferCPU[i * GROUND_PLANE_STRIDE] = vec3.x;
            this.#groundPlaneBufferCPU[i * GROUND_PLANE_STRIDE + 1] = vec3.y;
            this.#groundPlaneBufferCPU[i * GROUND_PLANE_STRIDE + 2] = vec3.z;
        }
    }

    /**
     * Set transform of the ground shadow system
     * @param {LmvVector3} center  center of bounding box
     * @param {LmvVector3} size    size in look&up coordinates, look = y
     * @param {LmvVector3} lookDir look direction, where ground camera is facing
     * @param {LmvVector3} upDir   up direction for ground camera
     */
    setTransform(center, size, lookDir, upDir) {
    // check if changed - if not, it saves us an entire ground shadow redraw!
        if (center.equals(this.#prevCenter) &&
            size.equals(this.#prevSize) &&
            lookDir.equals(this.#prevLookDir) &&
            upDir.equals(this.#prevUpDir)) {
            return;
        }

        // something's changing, so need to regenerate ground shadow
        this.setDirty();

        this.#prevCenter.copy(center);
        this.#prevSize.copy(size);
        this.#prevLookDir.copy(lookDir);
        this.#prevUpDir.copy(upDir);

        // ortho frustum
        this.#camera.left = -size.z / 2.0;
        this.#camera.right = size.z / 2.0;
        this.#camera.top = size.x / 2.0;
        this.#camera.bottom = -size.x / 2.0;
        this.#camera.near = 1.0;
        this.#camera.far = size.y + this.#camera.near;

        // update projection
        this.#camera.updateProjectionMatrix();

        this.#createGroundPlane();
        this.#setGroundPlaneTransform(center, size, lookDir, upDir);

        // camera transform
        this.#camera.position.addVectors(center, lookDir.clone().multiplyScalar(-size.y / 2.0 - this.#camera.near));
        if (upDir) this.#camera.up.set(upDir.x, upDir.y, upDir.z);
        this.#camera.lookAt(center);

        // support for small feature culling
        this.#camera.orthoScale = size.x;
        this.#camera.clientHeight = this.#texSize;
        this.#frustumIntersector.reset(this.#camera);
        this.#frustumIntersector.areaCullThreshold = SMALL_FEATURE_CULLING_THRESHOLD;
    }

    // --- Shadow pass ---
    #beginScene() {
        this.#cameraUniforms.update(this.#camera);
        // TODO: This shouldn't need to update every frame. We need a mechanism
        // to know when the IBL has created new buffers.
        this.#frameBindGroup.updateBindGroup();
    }

    #flushObjects(commandEncoder) {
        this.#renderer.getVB().flushWrites();
        const commandGroup = commandEncoder.finish();

        this.#device.queue.submit([commandGroup]);
    }

    #needsClear(oldScenes, newScenes) {
        if (oldScenes.length !== newScenes.length)
            return true;
        for (let i = 0; i < oldScenes.length; i++) {
            if (oldScenes[i] != newScenes[i]) {
                return true;
            }
        }
        return false;
    }

    #renderIntoShadow(scene) {

        // 3D model scenes usually have a fragment list. In case there is none (currently only possible for leaflets),
        // we skip it for shadow rendering.
        const fragList = scene.frags;

        // Skip ghosted objects and 2D scenes (e.g. hypermodel sheets)
        if (scene.edgesOnly || scene.is2d() || !fragList) {
            return;
        }

        if (ENABLE_PIXEL_CULLING) {
            // this is a RenderBatch. Check pixel size for each mesh and set vizFlags.
            scene.forEachNoMesh((fragId) => {
                scene.frags.getWorldBounds(fragId, this.#tmpBox);

                const visible = scene.frags.vizflags[fragId] & MeshFlags.MESH_VISIBLE;

                const culled = this.#frustumIntersector.estimateProjectedDiameter(this.#tmpBox) < this.#frustumIntersector.areaCullThreshold;

                scene.frags.setFlagFragment(fragId, MeshFlags.MESH_RENDERFLAG, visible && !culled);
            });

            // use MESH_RENDERFLAG in render call
            scene.forceVisible = false;
        }

        // Actual rendering
        const commandEncoder = this.#device.createCommandEncoder({ label: 'ground shadow encoder' });

        const passEncoder = commandEncoder.beginRenderPass(this.#shadowPassDescriptor);

        // false-positive issue detected by Chorus, see https://semgrep.dev/docs/ignoring-files-folders-code
        // nosemgrep
        passEncoder.label = 'ground shadow pass';

        const modelId = fragList.modelId;

        // When using OutOfCore, uploading uniform batches is controlled by OutOfCoreTileManager.
        // For RenderBatches that don't have them, we have to fallback to dynamic upload.
        const needsDynamicUniforms = (USE_OUT_OF_CORE_TILE_MANAGER && !scene.isComplete) || fragList.useThreeMesh;
        if (!needsDynamicUniforms) {
            this.#renderBatchUniforms.resetUpdateHeuristic(modelId);
            if (scene.uniformsNeedUpdate) {
                this.#renderBatchUniforms.updateBatch(scene);
                scene.uniformsNeedUpdate = false;
            }
        }

        passEncoder.setBindGroup(0, this.#frameBindGroup.getBindGroup());

        const renderBatchUniformsBindGroup = this.#renderBatchUniforms.getBindGroup(scene);
        passEncoder.setBindGroup(1, renderBatchUniformsBindGroup);

        this.#shadowPipeline.reset();

        let renderIndex;
        let counter = 0;
        scene.forEachWGPU(0, this.#renderBatchUniforms.MAX_BATCH, (geometry, material, index, fragId) => {

            geometry = this.#renderer.initGeometry(geometry);
            if (!geometry) {
                return true;
            }

            // Upload uniforms on-the-fly if needed and decide renderIndex needed.
            // Also decide the renderIndex, which tells the shader at which index the uniforms are stored.
            if (needsDynamicUniforms) {
                // renderIndex is the upload slot - just subsequent values 0,1,2...
                renderIndex = counter;

                // Pass uniform data for this fragment (upload is done for all at once at the end)
                if (fragList.useThreeMesh) {
                    const mesh = fragList.getVizmesh(fragId);
                    this.#renderBatchUniforms.setOneObjectData(mesh, renderIndex);
                } else {
                    const dynamicUniforms = this.#renderBatchUniforms.getDynamicUniforms();
                    dynamicUniforms.setOneFragmentCPU(fragList, fragId, renderIndex);
                }
            } else {
                // For pre-batched uniforms: renderIndex must be computed by RenderBatchUniforms to find the pre-uploaded uniform data.
                renderIndex = this.#renderBatchUniforms.getRenderIndex(index);
            }

            this.#shadowPipeline.drawOne(passEncoder, renderIndex, geometry);

            counter++;
        });

        passEncoder.end();

        // Upload uniform data that we collected in the loop above
        if (needsDynamicUniforms) {
            this.#renderBatchUniforms.writeToQueue(counter);
        }

        this.#flushObjects(commandEncoder);
    }

    /**
     * Generate ground shadow texture.
     * This has two modes:
     * 1. Render (at least) the given amount of RenderBatches.
     * 2. Render for a given amount of time (i.e. progressive rendering).
     *
     * @param {RenderScene} modelQueue The models to render.
     * @param {Number} minScenesPerFrame The minimum number of RenderBatches to process in one pass. 0
     *  means infinite. This does still respect the time budget, if provided.
     * @param {Number} [maxTime=undefined] Time budget. Infinite, if not specified.
     * @param {Number} [ratio=1.0] How much of this budget we get. 1.0 if not specified.
     * @returns {Number|undefined} Time left, if maxTime is specified; else just returns maxTime value (undefined).
     */
    prepareGroundShadow(modelQueue, minScenesPerFrame, maxTime = undefined, ratio = 1.0) {
    // if the ground shadow is off, don't continue
        if (!this.enabled || modelQueue.isEmpty()) {
            this.#status = GroundFlags.GROUND_FINISHED;
            return maxTime;
        }

        const newScenesPerModel = modelQueue.getGeomScenesPerModel();
        this.#needClear = this.#needsClear(this.#scenesPerModel, newScenesPerModel) || this.#needClear;

        // Get a separate set of scenes (render batches) for us to traverse. Everything gets traversed.
        if (this.#needClear) {
            this.clear();
            this.#needClear = false;

            this.#beginScene();

            this.#scenesPerModel = newScenesPerModel;
            this.#qScenes = modelQueue.getGeomScenes();
            this.#qSceneCount = this.#qScenes.length;
            this.#qSceneIdx = 0;
            if (minScenesPerFrame) {
                this.#maxScenesPerFrame = Math.max(Math.ceil(this.#qSceneCount / MAX_PROCESS_FRAMES), minScenesPerFrame);
            } else {
                this.#maxScenesPerFrame = this.#qSceneCount;
            }
        } else if (this.#status === GroundFlags.GROUND_RENDERED || this.#status === GroundFlags.GROUND_FINISHED) {
            // If drop shadow is valid, we're done, no rendering needed.
            // this call did not render it, so make sure the rendered status is set to finished.
            this.#status = GroundFlags.GROUND_FINISHED;
            return maxTime;
        } else if (minScenesPerFrame === 0) {
            // render rest of scene, time permitting
            this.#maxScenesPerFrame = this.#qSceneCount;
        }

        // progressive draw into shadow
        let startTime, budget;

        if (maxTime) {
            startTime = performance.now();
            budget = ratio * maxTime;
        }
        let retval;
        let i = 0;
        while((i < this.#maxScenesPerFrame) && (this.#qSceneIdx < this.#qSceneCount)) {
            // Note that we'll always render at least one batch here, regardless of time.
            // Not sure this is necessary, but it does avoid something going bad that causes
            // the timer to always fail and so get us caught in an infinite loop of calling
            // this method again and again.
            const qScene = this.#qScenes[this.#qSceneIdx++];

            if (!qScene) { continue; }

            // check culling for complete scene/RenderBatch
            const culled = ENABLE_PIXEL_CULLING && qScene.getBoundingBox && this.#frustumIntersector.estimateProjectedDiameter(qScene.getBoundingBox()) < this.#frustumIntersector.areaCullThreshold;

            if (culled) { continue; }

            // ok to render

            i++;
            // Force objects to be rendered. Note that this is ignored when small feature culling is used.
            // Ghosted objects are always skipped, regardless of this setting.
            qScene.forceVisible = true;
            // Note we render everything in the scene (render batch) to the ground plane,
            // so we don't have to worry about frustum culling, etc. - just blast through.
            this.#renderIntoShadow(qScene);
            qScene.forceVisible = false;

            // check time, if used
            if (maxTime) {
                const timeElapsed = performance.now() - startTime;
            // is time up and we're not done?
                if (budget < timeElapsed) {
                    // couldn't finish render in time
                    this.#status = GroundFlags.GROUND_UNFINISHED;
                    retval = maxTime - timeElapsed;
                    break;
                }
            }

        }
        // Did we finish? We only reach this path if the maxObj limit is reached.
        if (this.#qSceneIdx < this.#qSceneCount) {
            this.#status = GroundFlags.GROUND_UNFINISHED;
            // return time left, or 1, meaning we're not done.
            retval = maxTime ? (maxTime - performance.now() + startTime) : 1;
        }

        if (retval !== undefined) {
            // out of time, or done with object quota
            return retval;
        }

        // We just finished, great, do the post-process
        this.#postprocess();

        // We give back a sign that it was *this* call that actually finished up. By doing so,
        // the calling method may (or may not) want to signal for an invalidate to occur,
        // typically in a progressive rendering situation where a full redraw is then needed.
        this.#status = GroundFlags.GROUND_RENDERED;
        return maxTime ? (maxTime - performance.now() + startTime) : 1;
    }

    // --- Blur pass ---
    #runBlurPass(pipeline, textureBindGroup) {
        const commandEncoder = this.#device.createCommandEncoder({ label: 'ground shadow blur encoder' });

        const passEncoder = commandEncoder.beginRenderPass(this.#blurPassDescriptor);

        // false-positive issue detected by Chorus, see https://semgrep.dev/docs/ignoring-files-folders-code
        // nosemgrep
        passEncoder.label = 'ground shadow blur pass';

        const bindGroupOrder = pipeline.getBindGroupOrder();
        passEncoder.setBindGroup(bindGroupOrder.texture, textureBindGroup);

        passEncoder.setPipeline(pipeline.getPipeline());

        passEncoder.draw(3);
        passEncoder.end();

        this.#device.queue.submit([commandEncoder.finish()]);
    }

    #postprocess() {
        this.#blurPassDescriptor.colorAttachments[0].view = this.#vTargetView;
        this.#runBlurPass(this.#blurVPipeline, this.#blurVTextureBindGroup);

        this.#blurPassDescriptor.colorAttachments[0].view = this.#hTargetView;
        this.#runBlurPass(this.#blurHPipeline, this.#blurHTextureBindGroup);

        this.#bufferValid = true;
    }

    // --- Color pass ---
    // TODO: We currently only support rendering to the main/color target.
    // This needs to be configurable to support ground reflections.
    renderShadow(camera /*target*/) {
        if (!this.#bufferValid)
            return;

        if (this.#renderer.getIBL().getGroundShadowEnvMapDirty()) {
            this.#frameBindGroup.updateBindGroup();
            this.#renderer.getIBL().setGroundShadowEnvMapDirty(false);
        }

        this.#cameraUniforms.update(camera);

        // Update geometry buffers
        this.#device.queue.writeBuffer(this.#groundPlaneBuffer, 0, this.#groundPlaneBufferCPU);
        // The index buffer doesn't need to be updated every time.
        // We still do it because it's simpler and we don't do it very often.
        this.#device.queue.writeBuffer(this.#groundPlaneIndexBuffer, 0, this.#groundPlaneIndexBufferCPU);

        const commandEncoder = this.#device.createCommandEncoder({ label: 'ground shadow color encoder' });

        this.#colorPassDescriptor.colorAttachments[0].view = this.#renderer.getRenderTargets().getColorTargetView();
        this.#colorPassDescriptor.depthStencilAttachment.view = this.#renderer.getRenderTargets().getDepthTarget().createView();
        const passEncoder = commandEncoder.beginRenderPass(this.#colorPassDescriptor);

        // false-positive issue detected by Chorus, see https://semgrep.dev/docs/ignoring-files-folders-code
        // nosemgrep
        passEncoder.label = 'ground shadow color pass';
        this.#renderer.passViewport(passEncoder);

        passEncoder.setBindGroup(0, this.#frameBindGroup.getBindGroup());
        passEncoder.setBindGroup(1, this.#colorTextureBindGroup);

        passEncoder.setVertexBuffer(0, this.#groundPlaneBuffer);
        passEncoder.setIndexBuffer(this.#groundPlaneIndexBuffer, 'uint16');

        passEncoder.setPipeline(this.#colorPipeline.getPipeline());

        passEncoder.drawIndexed(this.#groundPlaneIndexBufferCPU.length, 1, 0, 0, 0);

        passEncoder.end();
        this.#device.queue.submit([commandEncoder.finish()]);
    }

    // --- Clear pass ---
    clear() {
        if (!this.enabled) {
            return;
        }

        const commandEncoder = this.#device.createCommandEncoder({ label: 'ground shadow clear encoder' });
        const passEncoder = commandEncoder.beginRenderPass(this.#clearPassDescriptor);

        // false-positive issue detected by Chorus, see https://semgrep.dev/docs/ignoring-files-folders-code
        // nosemgrep
        passEncoder.label = 'ground shadow clear pass';
        passEncoder.end();
        this.#device.queue.submit([commandEncoder.finish()]);

        this.#bufferValid = false;
    };

    setDirty() {
        this.#needClear = true;
        this.#status = GroundFlags.GROUND_UNFINISHED;
    };

    getStatus() {
        return this.#status;
    }

    // TODO: Other missing functions / API
}
