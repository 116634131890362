import {UniformBuffer} from "../UniformBuffer";


export class SAOBlurUniforms extends UniformBuffer {

    constructor(device) {
        super(device, 16, true, false);
    }

    setRadius(r) {
        this.setFloat(0, r);
        this.upload();
    }

}
