import { $wgsl } from "../../wgsl-preprocessor/wgsl-preprocessor";
import { LineMaterial } from "../../render/LineMaterial";
import { Renderer} from "../Renderer";
import { initTexture } from "../Texture";
import { MipmapPipeline } from "../main/MipmapPipeline";
import lineMaterialUniforms from "./line_material_uniforms.wgsl";

const Bindings = {
    rasterTexture: 0,
    rasterSampler: 1,
    selectionTexture: 2,
    layerTexture: 3,
    layerSampler: 4,
};

export class LineMaterialManager {
    /**
     * @param {number} group
     */
    static getUniformsDeclaration(group) {
        return $wgsl(lineMaterialUniforms, { group });
    }

    /** @type {GPUDevice} */
    #device;
    /** @type {TextureInfo} */
    #defaultTexture;
    /** @type {MipmapPipeline} */
    #mipmapPipeline;
    /** @type {GPUSampler} */
    #layerSampler;
    /** @type {GPUBindGroupLayout} */
    #layout;

    #boundUpdate;
    #boundDispose;

    // Only used as a placeholder in case uniforms.selectionColor is not specified.
    #defaultSelectionColor = new THREE.Vector4(0, 0, 1, 1);

    /**
     * @param {Renderer} renderer
     */
    constructor(renderer) {
        this.#device = renderer.getDevice();
        this.#defaultTexture = renderer.getPlaceholderTexture();
        this.#mipmapPipeline = new MipmapPipeline(this.#device);
        this.#layerSampler = this.#device.createSampler({
            label: '2d layer sampler',
            addressModeU: 'clamp-to-edge',
            addressModeV: 'clamp-to-edge',
            magFilter: 'nearest',
            minFilter: 'nearest',
        });

        this.#layout = this.#device.createBindGroupLayout({
            entries: [
                {
                    binding: Bindings.rasterTexture,
                    visibility: GPUShaderStage.FRAGMENT,
                    texture: {
                        sampleType: 'float',
                    }
                },
                {
                    binding: Bindings.rasterSampler,
                    visibility: GPUShaderStage.FRAGMENT,
                    sampler: {}
                },
                {
                    binding: Bindings.selectionTexture,
                    visibility: GPUShaderStage.FRAGMENT | GPUShaderStage.VERTEX,
                    texture: {
                        sampleType: 'float',
                    }
                },
                {
                    binding: Bindings.layerTexture,
                    visibility: GPUShaderStage.VERTEX,
                    texture: {
                        sampleType: 'float',
                    }
                },
                {
                    binding: Bindings.layerSampler,
                    visibility: GPUShaderStage.VERTEX,
                    sampler: {}
                },
            ]
        });

        this.#boundUpdate = this.handleUpdate.bind(this);
        this.#boundDispose = this.handleDispose.bind(this);
    }

    /**
     * @returns {GPUBindGroupLayout}
     */
    getLayout() {
        return this.#layout;
    }

    /**
     * @param {LineMaterial} material
     */
    initMaterial(material) {
        let rasterView;
        let rasterSampler;

        const rasterTexture = material.uniforms["tRaster"]?.value;
        if (rasterTexture) {
            initTexture(this.#device, this.#mipmapPipeline, rasterTexture);
        }
        if (rasterTexture && rasterTexture.__gpuTexture) {
            rasterView = rasterTexture.__gpuTexture.createView();
            rasterSampler = rasterTexture.__gpuSampler;
        } else {
            rasterView = this.#defaultTexture.view;
            rasterSampler = this.#defaultTexture.sampler;
        }

        let selectionTextureView = null;
        const selectionTex = material.uniforms["tSelectionTexture"]?.value;
        if (selectionTex) {
            // Actually, selectionTexture is usually never mipmapped and mipmapPipeline will not be needed.
            // Just passing here because we have it anyway and don't need to make implicit assumptions.
            initTexture(this.#device, this.#mipmapPipeline, selectionTex);
        }
        if (selectionTex && selectionTex.__gpuTexture) {
            selectionTextureView = selectionTex.__gpuTexture.createView();
        } else {
            selectionTextureView = this.#defaultTexture.view;
        }

        const layerTexture = material.uniforms['tLayerMask']?.value;
        if (layerTexture) {
            initTexture(this.#device, this.#mipmapPipeline, layerTexture);
        }
        let layerView;
        if (layerTexture && layerTexture.__gpuTexture) {
            layerView = layerTexture.__gpuTexture.createView();
        } else {
            layerView = this.#defaultTexture.view;
        }

        material.__gpuBindGroup = this.#createBindGroup(
            rasterView, rasterSampler, selectionTextureView, layerView);

        material.needsUpdate = false;

        if (!material.hasEventListener('dispose', this.#boundDispose)) {
            material.addEventListener('dispose', this.#boundDispose);
        }

        if (!material.hasEventListener('update', this.#boundUpdate)) {
            material.addEventListener('update', this.#boundUpdate);
        }
    }

    updateTextures(material) {
        const rasterTexture = material.uniforms["tRaster"]?.value;
        if (rasterTexture && rasterTexture.needsUpdate) {
            initTexture(this.#device, this.#mipmapPipeline, rasterTexture);
        }

        const selectionTex = material.uniforms["tSelectionTexture"]?.value;
        if (selectionTex && selectionTex.needsUpdate) {
            initTexture(this.#device, this.#mipmapPipeline, selectionTex);
        }

        const layerTexture = material.uniforms['tLayerMask']?.value;
        if (layerTexture && layerTexture.needsUpdate) {
            initTexture(this.#device, this.#mipmapPipeline, layerTexture);
        }
    }

    /**
     * @param {Object} event
     * @param {LineMaterial} event.target
     */
    handleUpdate(event) {
        const material = event.target;
        if (!material.needsUpdate) {
            // This can also trigger when uniformsNeedUpdate is set, but this
            // material bindgroup doesn't handle uniforms. Those are handled in
            // MaterialUniformBuffer.js
            return;
        }
        this.initMaterial(material);
    }

    /**
     * @param {Object} event
     * @param {LineMaterial} event.target
     */
   handleDispose(event) {
        const material = event.target;
        material.removeEventListener('dispose', this.#boundDispose);

        const texture = material.uniforms["tRaster"]?.value;
        if (texture) {
            texture.dispose();
        }
    }

    /**
     * @param {GPUTextureView} rasterView
     * @param {GPUSampler} rasterSampler
     * @param {GPUTextureView} selectionView
     * @param {GPUTextureView} layerView
     * @returns {GPUBindGroup}
     */
    #createBindGroup(rasterView, rasterSampler, selectionView, layerView) {
        return this.#device.createBindGroup({
            layout: this.#layout,
            entries: [
                {
                    binding: Bindings.rasterTexture,
                    resource: rasterView,
                },
                {
                    binding: Bindings.rasterSampler,
                    resource: rasterSampler,
                },
                {
                    binding: Bindings.selectionTexture,
                    resource: selectionView,
                },
                {
                    binding: Bindings.layerTexture,
                    resource: layerView,
                },
                {
                    binding: Bindings.layerSampler,
                    resource: this.#layerSampler,
                },
            ]
        });
    }
}
