import { resetGlobalArrayBuffer, createConsolidatedGeometry } from '../../../consolidation/ConsolidationCommon';
import { USE_TRANSFORM_FEEDBACK_FOR_CONSOLIDATION, USE_WEBGPU } from '../../../../globals';
import { OutOfCoreTaskBase } from '../OutOfCoreTaskBase';

/** @import { OutOfCoreTileManager } from "../../OutOfCoreTileManager" */
/** @import { FragmentList } from "../../../FragmentList" */

/**
 * This task represents the consolidation of a mesh
 */
export class ConsolidationTask extends OutOfCoreTaskBase {
    /** @type {number|undefined} */ #memoryCost;

    /**
     * Creates a new ConsolidationTask
     *
     * @param {OutOfCoreTileManager} OutOfCoreTileManager - The OutOfCoreTileManager instance
     * @param {number} meshIndex - The index of the mesh
     * @param {FragmentList} fragList - The fragment list
     * @param {number} bvhNodeId - The BVH node ID
     */
    constructor(outOfCoreTileManager, meshIndex, fragList, bvhNodeId) {
        super(outOfCoreTileManager);

        this.meshIndex = meshIndex;
        this.fragList = fragList;
        this.bvhNodeId = bvhNodeId;
    }

    /**
     * Executes the task
     *
     * @returns {number|undefined} The memory consumed when executing the task, undefined if no consolidation is available
     */
    execute() {
        const model = this.outOfCoreTileManager.model;
        const consolidation = model.getConsolidation(this.bvhNodeId);
        const renderer = this.outOfCoreTileManager.getRenderer();

        // Firefox on macOs doesn't support transform feedback currently.
        // Transform feedback doesn't exist in WebGPU. It could be implemented using a compute shader, but currently is not.
        const useTransformFeedback =
            ((Autodesk.Viewing.Private.isFirefox() && Autodesk.Viewing.Private.isMac()) || USE_WEBGPU) ?
                false :
                USE_TRANSFORM_FEEDBACK_FOR_CONSOLIDATION;
        const geometry = createConsolidatedGeometry(consolidation, this.meshIndex, model, useTransformFeedback, renderer);

        renderer.uploadGeometry(geometry);

        resetGlobalArrayBuffer();
    }

    /**
     * Frees the memory consumed by the task
     */
    undo() {
        const consolidation = this.outOfCoreTileManager.model.getConsolidation(this.bvhNodeId);
        if (!consolidation) {
            return;
        }
        consolidation.freeMeshGeometry(this.meshIndex);
    }

    /**
     * Returns the resource cost of the task
     * @returns {number} The resource cost of the task
     */
    getResourceCost() {
        if (this.#memoryCost === undefined) {
            const consolidation = this.outOfCoreTileManager.model.getConsolidation(this.bvhNodeId);
            if (!consolidation) {
                return 0;
            }
            this.#memoryCost = consolidation.getMemoryCostForConsolidatedMesh(this.meshIndex, this.fragList);
        }

        return this.#memoryCost;
    }
}
