import { $wgsl } from "../../wgsl-preprocessor/wgsl-preprocessor";
import tonemap from "../chunks/tonemap.wgsl";
import hatch from "../chunks/hatch_pattern.wgsl";
import blendShader from "./blend.wgsl";
import depthReadBackCode from '../shaders/depth-readback.wgsl';

import { getBlendSettingsDeclaration, getUnProjectSettingsDeclaration } from "./BlendSettings";

export function getBlendShader(spatialFilterEnabled = false) {
    return $wgsl(blendShader, {
        blendUniforms: getBlendSettingsDeclaration(1),
        unprojectUniforms: getUnProjectSettingsDeclaration(2),
        tonemap,
        hatch,
        spatialFilter: spatialFilterEnabled,
        depthReadBack: spatialFilterEnabled ? depthReadBackCode : ''
    });
}
