/**
 * Common networking exports for the Autodesk.Viewing.Private namespace
 */

import { Endpoints } from "./dsEndpoints";

// Internal Feature Flag to enable/disable DS endpoints,
// see https://wiki.autodesk.com/pages/viewpage.action?spaceKey=PDF&title=Derivative+Service+REST+API+V2#DerivativeServiceRESTAPIV2-GETEndpoints
let USE_DS_ENDPOINTS = false;
export function useDSEndpoints(enable = false) {
    if (enable) {
        USE_DS_ENDPOINTS = true;
    }
    return USE_DS_ENDPOINTS;
}

// Helper function to track the DS endpoints loading time
export function getDSEndpointsLoadingTime(documentUrn) {
    const endpoints = Endpoints.getInstance(documentUrn);
    return endpoints?.getLoadingTime() ?? false;
}
