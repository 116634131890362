import { $wgsl } from "../../wgsl-preprocessor/wgsl-preprocessor";
import { getBlendSettingsDeclaration, getUnProjectSettingsDeclaration } from "./BlendSettings";
import tonemap from "../chunks/tonemap.wgsl";
import hatch from "../chunks/hatch_pattern.wgsl";
import blendShader from "./blend.wgsl";
import spatialFilterShader from "./spatial_filter.wgsl";

export function getBlendShader(spatialFilterEnabled = false) {
    const spatialFilter = spatialFilterEnabled ? spatialFilterShader : '';
    return $wgsl(blendShader, {
        blendUniforms: getBlendSettingsDeclaration(1),
        unprojectUniforms: getUnProjectSettingsDeclaration(2),
        tonemap,
        hatch,
        spatialFilter
    });
}
