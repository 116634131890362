// No builtin union for TypedArrays, only private TypedArray type
export type TypedArrayLike = Int8Array|Uint8Array|Uint8ClampedArray|Int16Array|Uint16Array|Int32Array|Uint32Array|Float32Array|Float64Array|BigInt64Array|BigUint64Array;

export const EMPTY_ROW_FLAG = 1 << 7;

export type AttributeDef = {
    category: string,
    dataType: number,
    dataTypeContext?: string,
    description?: string,
    flags: number,
    name: string,
    precision: number
    propertyHash: string,
    propType?: 'Custom'
};

export type _AttrDefInternal = AttributeDef & { attrId: number };

export type FilterTableDescriptor = {
    /** The row definition for this table. There are allowed to be null columns and duplicated attributes */
    attributes: AttributeDef[],
    ids: Uint32Array,
    idSearchIndex: Uint32Array,
    stride: number,
    columns: {
        hash: string,
        descriptors: [TypedArrayLike, Uint8Array, boolean][]
    }[],
    strings?: {
        buf: Uint8Array,
        idx: Int32Array,
        next: number
    }
    buffers?: ArrayBuffer[]
};


export function isStringType(attr: AttributeDef) {
    return attr.dataType >= 20;
}

export function fail(message?: string): never {
    throw new Error(message ?? 'no message');
}