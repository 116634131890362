
import {$wgsl} from "../../wgsl-preprocessor/wgsl-preprocessor";
import pack_normals from "../chunks/pack_normals.wgsl";
import pack_depth from "../chunks/pack_depth.wgsl";
import mat_inverse from "../chunks/mat-inverse.wgsl";
import {getEnvMapSampleChunk} from "../chunks/env_sample";
import tonemap from "../chunks/tonemap.wgsl";
import hatch from "../chunks/hatch_pattern.wgsl";
import { getObjectUniformsDeclaration } from "./uniforms/ObjectUniforms";
import {getMaterialUniformsDeclaration} from "./MaterialUniforms";
import heatmap from "../chunks/heatmap.wgsl";
import heatmapDecl from "../chunks/heatmap_decl.wgsl";
import theming_and_highlighting from "../chunks/theming_and_highlighting.wgsl";
import uberShader from "./uber.wgsl";
import {FrameBindGroup} from "./FrameBindGroup";

export function getUberShader(material, hasUV, hasTextures, hasVertexColors, isUVW, isGamma, isRGBM, gammaInput) {
    return $wgsl(uberShader, {
        frameBindGroup: FrameBindGroup.getDeclaration(0),
        objectUniforms: getObjectUniformsDeclaration(1),
        materialUniforms: getMaterialUniformsDeclaration(2),
        pack_normals,
        pack_depth,
        mat_inverse,
        tonemap,
        envMapSample: getEnvMapSampleChunk(isGamma, isRGBM, gammaInput),
        hatch,
        heatmapDecl,
        heatmap,
        metalMaterial: material.metal,
        hasUV,
        hasTextures,
        hasVertexColors,
        isUVW,
        theming_and_highlighting,
        gammaInput
    });
}
