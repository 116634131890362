/** @import { Viewer3DImpl } from "./Viewer3DImpl" */

import { USE_WEBGPU } from "../wgs/globals";
import { RenderBatch } from "../wgs/scene/RenderBatch";
import { USE_MULTI_MATERIAL_RENDER_CALLS } from "../wgs/globals";

/**
 * @typedef {Object} ViewerRenderbudgetStatus
 * @property {number} priority                 - The priority of the viewer.
 * @property {number} renderTimeFraction       - The fraction of the frame time that can be used by this viewer
 * @property {number|undefined} usedCpuTime    - The CPU time used by this viewer
 * @property {boolean} isDone                  - Whether the viewer has finished rendering
 * @property {number} desiredFrameTime         - The desired frame time for this viewer
 *
 * @private
 */


class RenderBudgetManager {
    /** @type {Map<Viewer3DImpl, ViewerRenderbudgetStatus>} */
    viewers = new Map();

    /** Scaling factor for the render time budget */
    frameTimeScalingFactorAvg = 1.0;

    /** The time it took to execute tasks this frame */
    taskExecutionTime = 0;

    /** The minimum time a batch should take to avoid render command queue flooding */
    #batchMinTime = 0;
    #numRenderedBatches = 0;
    #lastBatchTime = undefined;

    constructor() {
    }

    /**
     * Registers a new viewer with the manager.
     * @param {Viewer3DImpl} viewer - The viewer to register.
     * @param {number} priority - The priority of the viewer.
     */
    registerViewer(viewer, priority) {
        this.viewers.set(viewer, {
            priority: priority,
            renderTimeFraction: 0,
            usedCpuTime: undefined,
            desiredFrameTime: 0,
        });
        this._updateRenderTimeFractions();
    }

    /**
     * Updates the priority of the given viewer.
     * @param {Viewer3DImpl} viewer - The viewer to register.
     * @param {number} priority - The priority of the viewer.
     */
    updateViewerPriority(viewer, priority) {
        this.viewers.get(viewer).priority = priority;
        this._updateRenderTimeFractions();
    }

    /**
     * Unregisters a viewer from the manager.
     * @param {Viewer3DImpl} viewer - The viewer to unregister.
     */
    unregisterViewer(viewer) {
        this.viewers.delete(viewer);
        this._updateRenderTimeFractions();
    }

    /**
     * Update the render time fractions for all viewers.
     */
    _updateRenderTimeFractions() {
        let totalPriority = 0;
        this.viewers.forEach(viewer => {
            totalPriority += viewer.priority;
        });

        this.viewers.forEach(viewer => {
            viewer.renderTimeFraction = viewer.priority / totalPriority;
        });
    }

    setTaskExecutionTime(time) {
        this.taskExecutionTime = time;
    }

    updateFrameTime(viewer, isDone, usedCpuTime, actualFrameTime, desiredFrameTime) {
        if (!usedCpuTime) {
            // If one of the viewers has provided an invalid usedCpu time
            // we reset all viewers since the measurement for this frame would
            // be unreliable
            usedCpuTime = undefined;
            for (let [, viewerStatus] of this.viewers) {
                viewerStatus.usedCpuTime = undefined;
            }
        }
        // We have been called a second time for the same viewer. We assume this means that a
        // frame has been presented to the user and that we now have a valid frame time and can
        // use the used cpu time to update the render time budget.
        if (usedCpuTime && this.viewers.get(viewer).usedCpuTime !== undefined) {
            let totalUsedCpuTime = 0;
            let allDone = true;
            let totalFrameDesiredTime = 0;
            for (let [, viewerStatus] of this.viewers) {
                if (viewerStatus.usedCpuTime !== undefined) {
                    totalUsedCpuTime += viewerStatus.usedCpuTime;
                    viewerStatus.usedCpuTime = undefined;
                    allDone &= viewerStatus.isDone;
                    totalFrameDesiredTime += viewerStatus.desiredFrameTime;
                }
            }

            // We subtract the task execution time from the frame time,
            // because it is also not included in the usedCpuTime and should
            // not be taken into account when updating the scaling factor.
            actualFrameTime -= this.taskExecutionTime;
            this.taskExecutionTime = 0;

            // If all viewers have completed rendering (allDone is true), we do not
            // know the ratio between CPU and GPU time (since rendering have completed in
            // less than one frame time). In that case, we set the ratio to 1.0 to avoid underestimating
            // the frame time scaling factor (e.g. if we rendered a tiny scene in 1ms and it took 16 ms
            // until the next frame is presented we would estimate the factor as 1/16, even though there was
            // no large GPU overhead). Such an underestimation could result in unnecessarily skipping rendering
            // of geometry for simple scenes.
            const newFrameScalingFactor = !allDone || actualFrameTime > totalFrameDesiredTime ? totalUsedCpuTime / actualFrameTime : 1.0;

            this.frameTimeScalingFactorAvg = 0.9 * this.frameTimeScalingFactorAvg + 0.1 * newFrameScalingFactor;

            for (let [viewerInstance, viewerStatus] of this.viewers) {
                viewerInstance._updateTargetFrameBudget(this.frameTimeScalingFactorAvg, viewerStatus.renderTimeFraction);
            }
        }

        let viewerStatus = this.viewers.get(viewer);
        viewerStatus.usedCpuTime = usedCpuTime;
        viewerStatus.isDone = isDone;
        viewerStatus.desiredFrameTime = desiredFrameTime;
        this.#batchMinTime = desiredFrameTime / (this.#numRenderedBatches + 20);
        this.#lastBatchTime = undefined;
    }

    /**
     * Should be called at the beginning of the frame.
     */
    beginFrame() {
        this.#numRenderedBatches = 0;
    }

    /**
     * Called when a batch has been rendered.
     */
    onBatchRendered() {
        this.#numRenderedBatches++;
    }
}


export let renderBudgetManager = new RenderBudgetManager();
