import {$wgsl} from "../../wgsl-preprocessor/wgsl-preprocessor";
import {UniformBuffer} from "../UniformBuffer";
import {LmvMatrix4} from "../../scene/LmvMatrix4";
import cameraUniforms from "./camera_uniforms.wgsl";

//GL style (-1,1) projection to (0,1) for depth value
const NO_2_ZO = new LmvMatrix4();
NO_2_ZO.elements[10] = 0.5;
NO_2_ZO.elements[14] = 0.5;

export class CameraUniforms extends UniformBuffer {

    /**
     * @param {number} bindGroup
     * @param {number} binding
     */
    static getDeclaration(bindGroup, binding) {
        return $wgsl(cameraUniforms, { bindGroup, binding });
    }

    #projScreenMatrix = new LmvMatrix4();
    #viewInverseEnv = new LmvMatrix4();
    #viewProjectionMatrix = new LmvMatrix4();

    /**
     * @param {GPUDevice} device
     */
    constructor(device) {
        super(device, 80, true, true);
    }

    update(camera) {
        if (camera.parent === undefined || camera.parent === null) camera.updateMatrixWorld();

        if (camera.worldUpTransform)
            this.#viewInverseEnv.multiplyMatrices(camera.worldUpTransform, camera.matrixWorld);
        else
            this.#viewInverseEnv.copy(camera.matrixWorld);

        this.#projScreenMatrix.multiplyMatrices(NO_2_ZO, camera.projectionMatrix);
        this.#viewProjectionMatrix.multiplyMatrices(this.#projScreenMatrix, camera.matrixWorldInverse);

        this.setBuffer(0, this.#projScreenMatrix.elements);
        this.setBuffer(16, camera.matrixWorldInverse.elements);
        this.setBuffer(32, camera.matrixWorld.elements);
        this.setBuffer(48, this.#viewInverseEnv.elements);

        this.setFloat(64, camera.near);
        this.setFloat(65, camera.far);

        this.upload();
    }

    getViewProjectionMatrix() {
        return this.#viewProjectionMatrix;
    }

}
