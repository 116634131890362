import sum from 'lodash/sum';
import { USE_WEBGPU } from '../../globals';
import { OutOfCoreTileManager } from './OutOfCoreTileManager';

/** @import { RenderModel } from "../RenderModel" */
/** @import { OutOfCoreTaskBase } from "./tasks/OutOfCoreTaskBase" */
/** @import { Stage } from "./OutOfCoreTileManager" */

/**
 * @typedef BvhNodeIteratorState
 * Describes the state of a BVH node for a specific viewer
 *
 * @property {number} screenSpaceError - The screen space error of the node
 * @property {number} lastUpdated - The frame count when the node was last updated
 * @property {number} lastRendered - The frame count when the node was last rendered
 */

/**
 * @typedef BvhNodeStageTasks
 * Tasks for a specific stage
 *
 * @property {boolean} tasksInitialized                  - The tasks for this stage have been initialized
 * @property {OutOfCoreTaskBase[]} remainingTasks        - Tasks for the stage that have not yet been executed
 * @property {OutOfCoreTaskBase[]} executedTasks         - Tasks for the stage that have already been executed
 * @property {OutOfCoreTaskBase[]} currentlyRunningTasks - Tasks which are currently running
 */

export class BvhNode {
    nodeId;

    // We track the state for the different iterators separately
    // (because each viewpoint shows a different POV and thus the nodes have
    //  different screen space errors)
    /** @type {BvhNodeIteratorState[]} */ iteratorStates = [];
    lockedCounter = 0;
    transparent = false;
    consolidationComputed = false;

    geometryLoaded = false;
    geometryLoadedPromise = undefined;
    #geometryLoadedPromiseResolve = undefined;

    /** @type {Stage} */ currentStage = null;
    /** @type {RenderModel} */ model;

    /** @type {Map<Stage, BvhNodeStageTasks>} */ stageTasks = new Map();

    /**
     * Represents a node in the BVH
     * @param {number} nodeId - The ID of the node
     * @param {RenderModel} model - The model to which the node belongs
     * @param {OutOfCoreTileManager} outOfCoreTileManager - The out of core tile manager
     * @param {Stage} currentStage - The current stage of the node
     * @param {boolean} transparent - is this node transparent?
     */
    constructor(nodeId, model, outOfCoreTileManager, currentStage, transparent) {
        this.nodeId = nodeId;
        this.model = model;
        this.outOfCoreTileManager = outOfCoreTileManager;
        this.currentStage = currentStage;
        this.transparent = transparent;
    }

    getNodeGeometryLoadedPromise() {
        if (this.geometryLoaded) {
            return Promise.resolve();
        }

        if (!this.geometryLoadedPromise) {
            this.geometryLoadedPromise = new Promise((resolve) => {
                this.#geometryLoadedPromiseResolve = resolve;
            });
        }

        return this.geometryLoadedPromise;
    }

    onNodeGeometryLoaded() {
        this.geometryLoaded = true;
        if (this.#geometryLoadedPromiseResolve) {
            this.#geometryLoadedPromiseResolve();
            this.#geometryLoadedPromiseResolve = undefined;
            this.geometryLoadedPromise = undefined;
        }
    }

    /**
     * Adds a new task to the node
     *
     * @param {OutOfCoreTaskBase} task - The task to be added
     * @param {Stage} stage - The stage to which the task should be added
     */
    addTask(task, stage) {
        this.stageTasks.get(stage).remainingTasks.push(task);
    }

    /**
     * Get the next stage to which this node could be promoted
     * @returns {Stage|undefined} - The next stage to which the node could be promoted or undefined if it is already in the last stage
     */
    getNextStage() {
        return this.currentStage.nextStage;
    }

    /**
     * Get the previous stage to which this node could be demoted
     * @returns {Stage|undefined} - The previous stage or undefined if there is no previous stage
     */
    getPreviousStage() {
        return this.currentStage.previousStage;
    }

    /**
     * Checks whether the given stage is there and execution of all tasks has been completed.
     * @param {string} stageName
     * @returns {boolean}
     */
    isStageCompleted(stageName) {
        const stage = this.outOfCoreTileManager.stagesByName.get(stageName);
        return this.stageTasks.get(stage)?.remainingTasks?.length === 0;
    }

    /**
     * Process the next task in the queue
     *
     * @returns {[number, boolean, Promise|undefined]} - Returns the resource cost of the task, whether there are more tasks to process, undefined or the task completion promise
     */
    processNextTask(deadline) {
        let nextStage = this.getNextStage();
        let remainingTasks = this.stageTasks.get(nextStage)?.remainingTasks;
        if (!remainingTasks || remainingTasks.length === 0) {
            return [0, false, undefined];
        }

        let task = remainingTasks.shift();

        // Execute the task (for more details on the possible return values for a task
        // see the execute method of OutOfCoreTaskBase)
        let result = task.execute();

        // If the task does not return a Promise or a generator, we can stop processing here
        if (result === undefined) {
            let consumedResource = task._getResourceAmountConsumedByLastExecution();
            if (!task.isOneShot) {
                this.stageTasks.get(nextStage).executedTasks.push(task);
            }

            return [consumedResource, remainingTasks.length > 0, undefined];
        }

        // Otherwise, it has to be either a Promise or a generator

        // If the return value has a next function, it is a generator
        // we store the generator here, and call next to get the first result
        // and make sure the amount of consumed resources has been computed
        let generator = undefined;
        if (result.next) {
            generator = result;
            result = generator.next();
        }
        let consumedResource = task._getResourceAmountConsumedByLastExecution();

        // Add the task to the list of currently running tasks
        let currentlyRunningTasks = this.stageTasks.get(nextStage).currentlyRunningTasks;
        currentlyRunningTasks.push(task);

        let promise = new Promise((resolve, fail) => {
            const processTaskResult = (result, taskDeadline) => {
                // async task or generator is done
                if (result === undefined || result.done) {
                    // Task is done, we remove it from the list of currently running tasks
                    currentlyRunningTasks.splice(currentlyRunningTasks.indexOf(task), 1);

                    if (!task.isOneShot) {
                        this.stageTasks.get(nextStage).executedTasks.push(task);
                    }
                    resolve();
                    return;
                }

                if (result instanceof Promise) {
                    // Task is still running asynchronously
                    result.then((res) => {
                        // The node could have been freed in the meantime
                        // In that case, we should not continue processing the tasks
                        if (!task.canceled) {
                            return processTaskResult(res, taskDeadline);
                        }
                    }, fail);
                    return;
                }
                // The result is an iterator from a generator

                // Do we still have time to process the next step?
                if (performance.now() < taskDeadline) {
                    processTaskResult(generator.next(), taskDeadline);
                } else {
                    // Otherwise, schedule a one shot task to continue processing the task
                    // in the next tick
                    OutOfCoreTileManager.addOneShotTask((newDeadline) => {
                        // The node could have been freed in the meantime
                        // In that case, we should not continue processing the tasks
                        if (!task.canceled) {
                            processTaskResult(generator.next(), newDeadline);
                        }
                        return true;
                    }, this, false);
                }
            };

            processTaskResult(result, deadline);
        });

        return [consumedResource, remainingTasks.length > 0, promise];
    }

    /**
     * Get the next task to execute
     *
     * @returns {OutOfCoreTaskBase|undefined} - The next task to be executed or undefined if there are no tasks left
     */
    getNextTask() {
        let remainingTasks = this.stageTasks.get(this.getNextStage())?.remainingTasks;

        return remainingTasks?.[0];
    }

    /**
     * Get remaining resource cost to move this node to the next stage
     *
     * @returns {number} The resource cost for processing the remaining tasks in the current stage of this node
     */
    getRemainingResourceCost() {
        let remainingTasks = this.stageTasks.get(this.getNextStage())?.remainingTasks;
        if (!remainingTasks) {
            return 0;
        }
        return sum(remainingTasks.map(x => x.getResourceCost()));
    }

    /**
     * Get the current resource cost for this node (across all stages)
     *
     * @param {ResourceType} resourceType - The type of the resource to get the cost for
     * @returns {number} The amount of the associated resource currently consumed by this node
     */
    getCurrentResourceCost(resourceType) {
        let resourceCost = 0;
        for (const [stage, tasks] of this.stageTasks) {
            if (stage.config.resource === resourceType) {
                resourceCost += sum(tasks.executedTasks.map(x => x.getResourceCost()));
            }
        }

        return resourceCost;
    }

    /**
     * Frees the resources allocated for the current stage of this node
     *
     * @returns {number} freed amount of the resource
     */
    freeResourcesForCurrentStage() {
        let freedResource = 0;
        if (!this.stageTasks.get(this.currentStage)) {
            return 0;
        }
        let executedTasks = this.stageTasks.get(this.currentStage).executedTasks || [];
        for (let task of executedTasks) {
            task.undo();
            freedResource += task._getFreedResourceAmount();
        }

        // Delete the tasks for the current stage so that it gets reinitialized again when the node is promoted
        this.stageTasks.delete(this.currentStage);

        return freedResource;
    }

    /**
    * Frees all resources in all stages allocated for this node
    *
    * @returns {Object<ResourceType, number>} - The amount of resources freed
    */
    freeAllResources() {
        let freedResources = {};

        // If there are any running tasks, we cancel them
        let currentlyRunningTasks = this.stageTasks.get(this.getNextStage())?.currentlyRunningTasks || [];
        for (let task of currentlyRunningTasks) {
            // All memory needed for an async task is marked as reserved after the first step of the task has synchronously been
            // executed. So when we get here for a task, we have to mark the whole reserved memory as freed again, independent from the actual
            // amount of memory the task has consumed up to this point.
            freedResources[this.getResourceType(true)] ??= 0;
            freedResources[this.getResourceType(true)] += task._getFreedResourceAmount();

            // The cancel function must make sure, it frees all resources, no matter where in the async execution the
            // task gets canceled.
            task.cancel();
        }

        while (true) {
            let previousStage = this.getPreviousStage();
            if (!previousStage) {
                break;
            }
            let resourceType = this.getResourceType(false);
            freedResources[resourceType] ??= 0;
            freedResources[resourceType] += this.freeResourcesForCurrentStage();
            this.currentStage = previousStage;
        }

        return freedResources;
    }

    /**
     * Calculates the current screen space error.
     *
     * In contrast to the screenspace getter, this function takes the time of the last
     * update into account and uses 0 as screenspace error if the node has not been updated for a while.
     *
     * It returns the maximum screen space error of all iterators.
     *
     * @param {Stage} [stage] - The stage to use for the calculation. If not given, the current stage is used.
     * @returns {number} The current screen space error.
     */
    getCurrentScreenSpaceError(stage = this.currentStage) {
        let screenSpaceError = -Infinity;

        for (let i = 0; i < this.iteratorStates.length; i++) {
            screenSpaceError = Math.max(screenSpaceError, this._getIteratorScreenSpaceError(i));
        }

        return screenSpaceError * stage.config.screenSpaceErrorScalingFactor;
    }

    /**
     * Computes the screen space error for a given iterator
     * @param {number} iteratorId
     * @returns {number}
     */
    _getIteratorScreenSpaceError(iteratorId) {
        const currentFrame = this.outOfCoreTileManager.getFrameCount(iteratorId);

        const state = this.iteratorStates[iteratorId];

        // If this node has never been updated for the given iterator, we return -Infinity
        // as the lowest possible screen space error
        if (!state) {
            return -Infinity;
        }

        // In the following code we use a few heuristics to take into account that we
        // won't always have the most recent screen space error available. Updating the
        // screenspace error happens only when the corresponding render batch is traversed
        // in the BVH traversal. So there might be render batches that have not been traversed
        // in the last frame. We don't want to throw those away right away, because due to jitter,
        // it could happen that they are needed in the next frame again. As a heuristic, we decide
        // after 10 frames without an update of the screenspace error, it is no longer valid and
        // return 0. Additionally, we consider a node that hasn't been rendered for 10 frames stale
        // and also return 0. This heuristic might change, once we perform updates of the screen space
        // error in the background independent from rendering.

        const screenSpaceError = state.screenSpaceError;
        if (currentFrame - state.lastRendered < 10) {
            return screenSpaceError;
        } else {
            const framesSinceUpdate = currentFrame - state.lastUpdated;
            return framesSinceUpdate < 10 ? screenSpaceError : 0;
        }
    }

    /**
     * Updates the screen space error for a given iterator ID.
     * @param {number} iteratorId - The ID of the iterator.
     * @param {number} screenSpaceError - The new screen space error value.
     * @param {number} lastUpdated - The frame number of the last update.
     */
    updateScreenSpaceError(iteratorId, screenSpaceError, lastUpdated) {
        if (this.iteratorStates[iteratorId] === undefined) {
            this.iteratorStates[iteratorId] = {
                screenSpaceError: screenSpaceError,
                lastUpdated: lastUpdated,
                lastRendered: lastUpdated
            };
        }

        this.iteratorStates[iteratorId].screenSpaceError = screenSpaceError;
        this.iteratorStates[iteratorId].lastUpdated = lastUpdated;
    }

    /**
     * Updates the last rendered frame number for the specified iterator.
     *
     * @param {string} iteratorId - The ID of the iterator.
     * @param {number} lastRendered - The frame number when the last render occurred.
     */
    updateLastRendered(iteratorId, lastRendered) {
        if (this.iteratorStates[iteratorId] === undefined) {
            this.iteratorStates[iteratorId] = {
                screenSpaceError: Infinity,
                lastUpdated: lastRendered,
                lastRendered: lastRendered
            };
        }

        this.iteratorStates[iteratorId].lastRendered = lastRendered;
    }

    /**
     * Compares two nodes based on their screen space error (also taking into account the loaded
     * flag and the transparency flag, sorting transparent objects behind opaque objects)
     *
     * @param {BvhNode} other
     * @param {Stage} [thisNodeStage] - The stage to use for this node, if none is given the current stage of the node is used
     * @param {Stage} [otherNodeStage] - The stage to use for the other node, if none is given the current stage of the node is used
     *
     * @returns {number} - Returns negative value if this node has a higher screen space error, positive value if the
     *                     other node has a higher screen space error and 0 if they are equal
     */
    compare(other, thisNodeStage, otherNodeStage) {
        if (this.transparent != other.transparent) {
            return this.transparent ? 1 : -1;
        }
        return other.getCurrentScreenSpaceError(otherNodeStage) - this.getCurrentScreenSpaceError(thisNodeStage);
    }

    /**
     * Returns the resource type required by this node
     *
     * @param {boolean} forPromotion - For promotions we will return the resource needed to promote to the next stage, otherwise we return
     *                                 the resource that will be freed when demoting the node.
     * @returns {ResourceType} - The resource type needed by this node
     */
    getResourceType(forPromotion) {
        return forPromotion ? this.getNextStage().config.resource : this.currentStage.config.resource;
    }

    /**
     * Initializes the tasks for the next stage
     */
    initializeTasksForPromotion() {
        let targetStage = this.getNextStage();
        if (!targetStage) {
            throw new Error("Nodes in the highest stage cannot be promoted. This should never happen.");
        }

        // Check whether the tasks for the next stage have already been initialized
        if (this.stageTasks.get(targetStage)) {
            return;
        }

        this.stageTasks.set(targetStage, {
            remainingTasks: [],
            executedTasks: [],
            currentlyRunningTasks: []
        });

        for (let initalizationFunction of targetStage.config.taskInitializationFunctions) {
            initalizationFunction(this, targetStage);
        }
    }
}
