
import { $wgsl } from '../../wgsl-preprocessor/wgsl-preprocessor';
import cameraUniformsCode from './camera_uniforms.wgsl';

import {UniformBuffer} from "../UniformBuffer";
import { LmvMatrix4 } from "../../scene/LmvMatrix4";

/**
 * Transforms a projection matrix to use reversed-z depth range. WebGL-style NDC with depth component in [-1, 1] to
 * WebGPU-style NDC and reversed-z in [1, 0].
 *
 * @param {LmvMatrix4} projection - The projection matrix to modify
 */
export function toReverseZ(projection) {
    projection.elements[10] = 0.5 * (projection.elements[11] - projection.elements[10]);
    projection.elements[14] = 0.5 * (projection.elements[15] - projection.elements[14]);
}

const Offsets = Object.freeze({
    Projection: 0,
    View: 16,
    ViewInverse: 32,
    EnvironmentInverse: 48,
    CameraNear: 64,
    CameraFar: 65,
    ClientSize: 66,
    SIZE_IN_FLOATS: 67, // update when modifying offsets
});

export class CameraUniforms extends UniformBuffer {

    /**
     * @param {number} bindGroup
     * @param {number} binding
     */
    static getDeclaration(bindGroup, binding) {
        return $wgsl(cameraUniformsCode, { bindGroup, binding });
    }

    #projection = new LmvMatrix4();
    #viewProjection = new LmvMatrix4();
    #viewInverseEnv = new LmvMatrix4();

    /**
     * @param {GPUDevice} device
     */
    constructor(device) {
        super(device, Offsets.SIZE_IN_FLOATS, true, true);
    }

    update(camera) {
        if (camera.parent === undefined || camera.parent === null) camera.updateMatrixWorld();

        this.#projection.copy(camera.projectionMatrix);
        toReverseZ(this.#projection);
        this.#viewProjection.multiplyMatrices(this.#projection, camera.matrixWorldInverse);

        if (camera.worldUpTransform) {
            this.#viewInverseEnv.multiplyMatrices(camera.worldUpTransform, camera.matrixWorld);
        } else {
            this.#viewInverseEnv.copy(camera.matrixWorld);
        }

        this.setBuffer(Offsets.Projection, this.#projection.elements);
        this.setBuffer(Offsets.View, camera.matrixWorldInverse.elements);
        this.setBuffer(Offsets.ViewInverse, camera.matrixWorld.elements);
        this.setBuffer(Offsets.EnvironmentInverse, this.#viewInverseEnv.elements);

        this.setFloat2(Offsets.CameraNear, camera.near, camera.far);
        this.setFloat2(Offsets.ClientSize, camera.clientWidth, camera.clientHeight);

        this.upload();
    }

    getViewProjectionMatrix() {
        return this.#viewProjection;
    }

}
