
import { BumpAllocator } from '../../BumpAllocator.js';
import { getMaterialTextureMask } from "../MaterialUniforms.js";
import { CpuBuffer } from '../../CpuBuffer.js';

const MaterialOffsets = {
    renderFlags: 0,
    diffuse: 1,
    specular: 2,
    shininess: 3,

    hatchParams: 4, // vec2f
    hatchTintIntensity: 6,
    heatmapSensorCountAndOffset: 7,

    SIZE_IN_FLOATS: 8,
};

const Material2dOffsets = {
    selectionColor: 0, // vec4f
    viewportBounds: 4, // vec4f,

    hatchParams: 8, // vec2f,
    hatchTintIntensity: 10,
    renderFlags: 11,

    hasTexture: 12,
    hasLayers: 13,
    hasViewportClipping: 14,
    diffuse: 15,

    SIZE_IN_FLOATS: 16,
};

// Manages a buffer with shared materials that are referenced by object uniforms.
// Currently, we share a single fixed-size buffer across all models and overlays.
//
// The buffer can be seen as a big array of MaterialUniform entries.
// An index into this array is called "material references". ObjectUniforms store a material reference per object.
export class MaterialUniformBuffer extends BumpAllocator {

    /** @type {GPUBuffer|null} */
    #gpuBuffer = null;

    /** @type {GPUDevice} */
    #device = null;

    // Small CPU-Side buffer to store properties for a single material.
    // Only used temporarily for uploading to the larger GPU buffer.
    #materialCpu = new CpuBuffer(Math.max(
        MaterialOffsets.SIZE_IN_FLOATS, Material2dOffsets.SIZE_IN_FLOATS));

    // Material event handlers to sync material updates with the GPU buffer.
    #boundMaterialUpdateCallback;
    #boundRemoveMaterialUpdateCallbacks;

    /** @type {Map<Material, BufferAlloc>} */
    #allocs = new Map();

    #defaultSelectionColor = new THREE.Vector4(0, 0, 1, 1);

    constructor(device) {
        super(device);

        this.#device = device;

        // NOTE: We keep the implementation simple by pre-allocating a material uniform buffer that is large enough to
        // hold > 1 million materials (see size constant in bump allocator) and assuming that this will be sufficient.
        // The more granular (and scalable) approach would be to allocate more, but smaller buffers on demand.
        // But this would require multiple bind groups. We would need to add code to switch the bind group inside the
        // render loop, which would require changes in different places and affect the overall flow / execution order.
        // Furthermore, we would need to keep track of the actual underlying buffer per material.
        const memAlloc = this.mAlloc(0); // Allocate a zero-size material to create an empty buffer
        this.#gpuBuffer = memAlloc.bufferEntry.buffer;

        // bind material event handlers
        this.#boundMaterialUpdateCallback        = this.#materialUpdateCallback.bind(this);
        this.#boundRemoveMaterialUpdateCallbacks = this.#removeMaterialUpdateCallbacks.bind(this);
    }

    /**
     * @returns {number} byteSize on GPU. Note that we track actually used memory, not including extra memory due to paging.
     */
    byteSize() {
        return this.computeUsedBytes().mbBytes;
    }

    /**
     * @param {Material} material
     * @returns {BufferAlloc|undefined}
     */
    getAlloc(material) {
        return this.#allocs.get(material);
    }

    /**
     * Ensures that space for the material is reserved in the GPU buffer for materials.
     * @param {THREE.Material} material
     * @returns {number} offset (in bytes) where the material is stored in the material GPUBuffer.
     */
    #initMaterialBuffer(material) {
        // If material was initialized already, just return its offset.
        let alloc = this.getAlloc(material);
        if (alloc) {
            return alloc.offset;
        }

        // Allocate space for the material in the GPU buffer.
        const size = material.is2d ?
            4 * Material2dOffsets.SIZE_IN_FLOATS :
            4 * MaterialOffsets.SIZE_IN_FLOATS;
        // Align materials to their struct size so we can index into the array
        // correctly in the shader.
        alloc = this.mAlloc(size, size);
        this.#allocs.set(material, alloc);

        // Uniforms need updating if we only just allocated it's storage.
        material.uniformsNeedUpdate = true;

        // Add a dispose handler to free the material's buffer when the material is disposed.
        const disposeHandler = () => {
            this.freeAlloc(alloc);
            this.#allocs.delete(material);
            material.removeEventListener('dispose', disposeHandler);
        };
        material.addEventListener('dispose', disposeHandler);

        // in bytes
        return alloc.offset;
    }

    /**
     *  @param {number} writeOffset - Offset in bytes in the GPUBuffer where to write the material
     *  @param {number} size - Size in bytes to write
     */
    #uploadMaterialBuffer(writeOffset, size) {
        this.#device.queue.writeBuffer(
            this.#gpuBuffer,
            writeOffset,
            this.#materialCpu.getCpuBuffer().buffer,
            0,
            size
        );
    }

    // Makes sure that a buffer slot is reserved to store the properties of the given material in the GPUBuffer.
    // Returns the "materialRef", i.e. an index that is stored in the per-object uniforms to reference this material.
    acquireMaterial(material) {
        const byteOffset = this.#initMaterialBuffer(material);
        const size = 4 * (material.is2d ?
            Material2dOffsets.SIZE_IN_FLOATS :
            MaterialOffsets.SIZE_IN_FLOATS);
        return byteOffset / size;
    }

    // Compose material flags into int value for storage in the UniformBuffer
    #getMaterialFlags(material, materialTextureMask) {
        const isGhosted    = (material.opacity < 0) ? 1 : 0;
        const doNotCut     = material.doNotCut      ? 2 : 0;
        const alphaTest    = material.alphaTest > 0 ? 4 : 0;
        const hatchPattern = material.hatchPattern  ? 8 : 0;
        return isGhosted | doNotCut | alphaTest | hatchPattern | (materialTextureMask << 16);
    }

    /**
     * Copies properties of a given material into the corresponding range of the GPUBuffer.
     *  @param {THREE.Material} material
     *  @param {Number}         materialTextureMask      - Bitmask indicating which texture types are used (see getMaterialTextureMask() in MaterialUniforms)
     *  @param {Boolean}        [updateThroughCallback]  - Triggered by change handlers of materials. If true, update is enforced without checking or reseting dirty flags.
     */
    updateMaterial(material, materialTextureMask, updateThroughCallback = false) {

        // Only update if something changed
        const needsUpdate = updateThroughCallback || material.needsUpdate || material.uniformsNeedUpdate;
        if (!needsUpdate) {
            return;
        }

        // Make sure that a write index is allocated in the material GPUBuffer.
        const offset = this.#initMaterialBuffer(material);

        // Pack flags and texture mask into integer 0
        const flags = this.#getMaterialFlags(material, materialTextureMask);
        this.#materialCpu.setInt(MaterialOffsets.renderFlags, flags);

        // Convert colors to integer RGBA format
        // TODO: Clarify if we can simplify it
        //  - Do we really need to check uniformsNeedUpdate again?
        //  - Is this attaching optimization worth of it? Possibly all of this is only for saving a few colorToIntCalls if uniformsNeedUpdate is false,
        //    which shouldn't make a big difference.
        if (material.uniformsNeedUpdate) {

            //TODO: gammaInput is hardcoded to true here, it's normally a property of the renderer
            //and we need it off when tone mapping is not used.
            material.__gpuDiffuse = colorToInt(material.color, Math.abs(material.opacity));

            if (material.specular) {
                material.__gpuSpecular = colorToInt(material.specular, material.reflectivity);
            } else {
                material.__gpuSpecular = 0;
            }
        }

        // Write diffuse and specular colors into CPU-Buffer
        this.#materialCpu.setInt(MaterialOffsets.diffuse, material.__gpuDiffuse);
        this.#materialCpu.setInt(MaterialOffsets.specular, material.__gpuSpecular);

        // Write shininess (float)
        this.#materialCpu.setFloat(MaterialOffsets.shininess, material.shininess);

        // Optional hatch pattern parameters for cap surfaces
        if (material.hatchPattern) {
            this.#materialCpu.setVector2(MaterialOffsets.hatchParams, material.hatchParams);
            this.#materialCpu.setFloat(MaterialOffsets.hatchTintIntensity, material.hatchTintIntensity);
        }

        // Tandem-specific heatmap stuff
        // TODO: We dont't really use it and currently just keep it for Tandem consistency.
        this.#materialCpu.setFloat(MaterialOffsets.heatmapSensorCountAndOffset,
            ((material.heatmapSensorOffset ?? 0) << 8) + material.heatmapSensorCount ?? 0);

        this.#uploadMaterialBuffer(offset, 4 * MaterialOffsets.SIZE_IN_FLOATS);

        // The logic in getPipelineHash and activateMaterialBindings checks this to know when
        // to recompute various material and shader flags. These methods need to be called before this one
        // (usually in pipeline.drawOne). We reset it here because this is the last step before actually rendering
        // the material.
        // There's one exception to that rule, though. Materials can be updated through an update event, in which
        // case this method is called before the draw function (which might not even be called at all). To manage
        // the needsUpdate state properly, passes that use material update callbacks need to reset the needsUpdate
        // flag when finishing a render pass.
        if (!updateThroughCallback) {
            material.needsUpdate = false;
        }
    }

    /** Used for 2D LineShader materials
     * TODO: Most of this function is duplicated code from updateMaterial() - so maybe we can make it one.
     * @param {THREE.Material} material
     */
    updateMaterial2D(material) {
        if (material.needsUpdate || material.uniformsNeedUpdate) {
            this.#initMaterialBuffer(material);

            // MaterialMask is not used for 2D materials (always 0)
            const flags = this.#getMaterialFlags(material, 0);
            this.#materialCpu.setInt(Material2dOffsets.renderFlags, flags);
            this.#materialCpu.setInt(Material2dOffsets.diffuse,
                colorToInt({ r: 0, g: 0, b: 0 }, material.opacity));

            if (material.hatchPattern) {
                this.#materialCpu.setVector2(Material2dOffsets.hatchParams, material.hatchParams);
                this.#materialCpu.setFloat(Material2dOffsets.hatchTintIntensity, material.hatchTintIntensity);
            }

            const hasTexture = material.uniforms["tRaster"]?.value ? 1 : 0;
            this.#materialCpu.setInt(Material2dOffsets.hasTexture, hasTexture);

            const selectionColor = material.uniforms.selectionColor?.value || this.#defaultSelectionColor;
            this.#materialCpu.setVector4(Material2dOffsets.selectionColor, selectionColor);

            const hasLayers = material.uniforms['tLayerMask']?.value ? 1 : 0;
            this.#materialCpu.setInt(Material2dOffsets.hasLayers, hasLayers);

            const hasViewportClipping = material.defines['VIEWPORT_CLIPPING'];
            this.#materialCpu.setInt(Material2dOffsets.hasViewportClipping, hasViewportClipping);
            const viewportBounds = material.uniforms['viewportBounds']?.value
            if (hasViewportClipping && viewportBounds) {
                this.#materialCpu.setVector4(Material2dOffsets.viewportBounds, viewportBounds);
            }

            const alloc = this.getAlloc(material);
            this.#uploadMaterialBuffer(alloc.offset, 4 * Material2dOffsets.SIZE_IN_FLOATS);

            // The logic in getPipelineHash and activateMaterialBindings checks this to know when
            // to recompute various material and shader flags. These methods need to be called before this one
            // (usually in pipeline.drawOne). We reset it here because this is the last step before actually rendering
            // the material.
            material.needsUpdate = false;
            material.uniformsNeedUpdate = false;
        }
    }

    /**
     * The MaterialUniformBuffer stores a GPU-Side array of materials.
     * The returned materialRef is an index into that array. It is used in the per-object uniforms to reference the given material.
     * @param {THREE.Material} material
     * @returns {Number} materialRef - Index into the MaterialUniformBuffer
     */
    getMaterialRef(material) {
        const alloc = this.getAlloc(material);
        const size = 4 * (material.is2d ?
            Material2dOffsets.SIZE_IN_FLOATS :
            MaterialOffsets.SIZE_IN_FLOATS);
        return alloc.offset / size;
    }

    /** @returns {GPUBuffer} */
    getGPUBuffer() {
        return this.#gpuBuffer;
    }

    /**
     * Initializes a material update hook on the material, to update material uniforms whenever the material changes.
     * The function will also upload the material uniforms when it encounters a material for the first time.
     * If the update hook has already been configured, this function is a no-op.
     *
     * Note: Use this only for materials that are referenced in the main scene, e.g. in RenderBatches.
     * Three.js scenes (scene, sceneAfter, overlays, 2d scenes, ...) have to update materials in the render loop via
     * setOneMaterialData(2D).
     * @param {THREE.Material} material The material that should be watched for updates.
     * @param {Number} materialTextureMask The material's texture mask, as returned by initMaterialBindings.
     */
    initMaterialUpdateHook(material, materialTextureMask) {
        if (!material.hasEventListener('update', this.#boundMaterialUpdateCallback)) {
            material.addEventListener('update', this.#boundMaterialUpdateCallback);
            material.addEventListener('dispose', this.#boundRemoveMaterialUpdateCallbacks);

            // We need to initialize the material for our Renderer even if the material is not dirty
            // (another renderer we share resources with might have updated it already).
            const updateThroughCallback = true; // forces upload
            this.updateMaterial(material, materialTextureMask, updateThroughCallback);
        }
    }

    /**
     * A callback that is invoked as a material's update handler.
     * @param {*} event The update event. The material can be accessed via event.target.
     */
    #materialUpdateCallback(event) {
        // TODO: How to handle the case where both needsUpdate and uniformsNeedUpdate are set?
        // This handler will be invoked twice then...
        const material = event.target;
        const materialTextureMask = getMaterialTextureMask(material);
        this.updateMaterial(material, materialTextureMask, true);
    }

    /**
     * A callback that is invoked when a material is disposed.
     * @param {*} event
     */
    #removeMaterialUpdateCallbacks(event) {
        const material = event.target;
        material.removeEventListener('update', this.#boundMaterialUpdateCallback);
        material.removeEventListener('dispose', this.#boundRemoveMaterialUpdateCallbacks);
    }
};

/** Convert color object into Uint32 rgba color
 * @param {THREE.Color} color   - r,g,b in [0,1]
 * @param {number}      opacity - opacity in [0,1]
 * @returns {number} uint32 color (format 0xAABBGGRR)
 */
export function colorToInt(color, opacity) {
    let r = color.r;
    let g = color.g;
    let b = color.b;

    // gamma is applied by the shader, so we don't need it here.

    return (r * 255) | ((g * 255) << 8) | ((b * 255) << 16) | ((opacity * 255) << 24);
}
