
import {getEdgeShader} from "./EdgeShader";
import {DepthFormat} from "../CommonRenderTargets";
import {getBufferLayout, getPipelineHash} from "../Pipelines";


export class EdgePipeline {

    #renderer;
    #device;

    #pipelines = new Map();
    #activePipeline;
    #activeBindGroupLayout;
    #activeTargetsList;
    #vb;
    #getPipelineHash = getPipelineHash;

    constructor(renderer) {
        this.#renderer = renderer;
        this.#device = renderer.getDevice();
    }

    #createPipeline(key, geometry, material, ghosting = false) {

        let shader = this.#device.createShaderModule({
            label: 'edge shader',
            code: getEdgeShader(ghosting)
        });

        let pipeline = this.#device.createRenderPipeline({
            layout: this.#device.createPipelineLayout({
                bindGroupLayouts: this.#activeBindGroupLayout
            }),
            vertex: {
                module: shader,
                entryPoint: 'vsmain',
                buffers: getBufferLayout(geometry, false, false, false, false),
            },
            fragment: {
                module: shader,
                entryPoint: 'psmain',
                targets: this.#activeTargetsList,
            },
            primitive: {
                topology: 'line-list',
                cullMode: 'none',
            },

            depthStencil: {
                depthWriteEnabled: material.depthWrite,
                depthCompare: material.depthTest ? (material.depthFunc || 'less-equal') : "always",
                format: DepthFormat,
                depthBias: 0,
                depthBiasSlopeScale: 0
            },
        });

        this.#pipelines.set(key, pipeline);

        return pipeline;
    }

    reset(layouts, targets) {
        this.#activePipeline = null;
        this.#activeBindGroupLayout = layouts;
        this.#activeTargetsList = targets;
        this.#vb = this.#renderer.getVB();
    }

    drawOne(passEncoder, objectIndex, geometry, material) {
        //Don't draw line geometry as its own edge -- it's already drawn in the main color pass
        if (geometry.isLines) {
            return;
        }

        let key = this.#getPipelineHash(geometry, material, false, false, false);

        let pipeline = this.#pipelines.get(key);

        if (!pipeline) {
            pipeline = this.#createPipeline(key, geometry, material);
        }

        if (pipeline !== this.#activePipeline) {
            passEncoder.setPipeline(pipeline);
            this.#activePipeline = pipeline;
        }

        this.#vb.drawEdges(passEncoder, geometry, objectIndex);
    }

    drawOneGhosted(passEncoder, objectIndex, geometry, material) {

        // We would usually not set the 'hasTextures' parameter for this pipeline, but we want to distinguish
        // normal edge pipelines from ghosting pipelines, so we kind of abuse the flag and set it to true here.
        // The reason is that we generate a different shader for ghosting.
        let key = this.#getPipelineHash(geometry, material, false, false, false, true);

        let pipeline = this.#pipelines.get(key);

        if (!pipeline) {
            pipeline = this.#createPipeline(key, geometry, material, true);
        }

        if (pipeline !== this.#activePipeline) {
            passEncoder.setPipeline(pipeline);
            this.#activePipeline = pipeline;
        }

        //Draw line geometry as its own edge for ghosting purposes
        if (geometry.isLines) {
            this.#vb.draw(passEncoder, geometry, objectIndex);
        } else {
            this.#vb.drawEdges(passEncoder, geometry, objectIndex);
        }
    }

}
