import {$wgsl} from "../../wgsl-preprocessor/wgsl-preprocessor";
import {getObjectUniformsDeclaration} from "./uniforms/ObjectUniforms";
import line3DShader from "./line3D.wgsl";
import {FrameBindGroup} from "./FrameBindGroup";

export function getLine3DShader(hasVertexColors) {
    return $wgsl(line3DShader, {
        frameBindGroup: FrameBindGroup.getDeclaration(0),
        objectUniforms: getObjectUniformsDeclaration(1),
        hasVertexColors
    });
}
