import { isPlaceholderMesh, isInstanceMesh } from '../../scene/consolidation/Consolidation';
import { MeshFlags } from '../../scene/MeshFlags';

export const UPDATE_TYPE = Object.freeze({ THEMING_COLOR: 1, VISIBILITY: 2, HIGHLIGHTING: 4 });

export class RenderMeshUpdater {
    #requestedMeshUpdates = new Map();
    #uboManager = null;
    #callbackRegistry = new Map();

    constructor(uboManager) {
        this.#uboManager = uboManager;
    }

    /**
     * Register update callbacks for the model
     * @param {RenderModel} model
     */
    addModel(model) {
        const fragList = model.getFragmentList();
        if (fragList) {
            const callbacks = [
                this.requestUpdate.bind(this, model, UPDATE_TYPE.THEMING_COLOR),
                this.requestUpdate.bind(this, model, UPDATE_TYPE.VISIBILITY),
                this.requestUpdate.bind(this, model, UPDATE_TYPE.HIGHLIGHTING)];

            fragList.registerThemingColorChangedCallback(callbacks[0]);
            fragList.registerVisibilityDirtyCallback(callbacks[1]);
            fragList.registerHighlightingChangedCallback(callbacks[2]);
            this.#callbackRegistry.set(model.id, callbacks);
        }
    }

    /**
     * Unregister update callbacks for the model
     * @param {RenderModel} model
     */
    removeModel(model) {
        const fragList = model.getFragmentList();
        if (fragList) {
            const callbacks = this.#callbackRegistry.get(model.id);
            fragList.removeThemingColorChangedCallback(callbacks[0]);
            fragList.removeVisibilityDirtyCallback(callbacks[1]);
            fragList.removeHighlightingChangedCallback(callbacks[2]);
            this.#callbackRegistry.delete(model.id);
        }
    }

    /**
     * Request an update of the theming color and/or visibility information of that fragment. If fragId is not provided, the update will be requested for all fragments of the model.
     * The update will be deferred to the next microtask to allow for multiple requests to be consolidated into a single update.
     * @param {RenderModel} model
     * @param {UPDATE_TYPE} requestType
     * @param {number} [fragId]
     */
    requestUpdate(model, requestType, fragId) {
        const consolidation = model.getConsolidation();

        if (consolidation) {
            if (fragId === undefined) {
                for (let mesh of consolidation.meshes) {
                    this.#queueUpdate(model, mesh, requestType);
                }
            } else {
                this.#queueUpdate(model, consolidation.meshes[consolidation.fragId2MeshIndex[fragId]], requestType);
            }
        }
    }

    #queueUpdate(model, mesh, requestType) {
        // do we have a consolidated mesh?
        if (!mesh || !mesh.geometry || isPlaceholderMesh(mesh))
            return;

        // is an update already requested?
        const queuedRequestType = this.#requestedMeshUpdates.get(mesh) ?? 0;
        if ((queuedRequestType & requestType) === 0) {
            this.#requestedMeshUpdates.set(mesh, queuedRequestType | requestType);

            // Only queue the update if it's the first request
            if (queuedRequestType === 0) {
                // defer the update to the next microtask to avoid redundant updates in the same frame
                queueMicrotask(() => {
                    const requestType = this.#requestedMeshUpdates.get(mesh);
                    const consolidation = model.getConsolidation();

                    if (isInstanceMesh(mesh)) {
                        if (requestType & UPDATE_TYPE.THEMING_COLOR) {
                            this.#updateInstanceMeshThemingColors(mesh, model.getFragmentList(), consolidation.consolidationMap.fragOrder);
                        }

                        if (requestType & (UPDATE_TYPE.VISIBILITY | UPDATE_TYPE.HIGHLIGHTING)) {
                            this.#updateInstanceMeshVisibility(mesh, model.getFragmentList(), consolidation.consolidationMap.fragOrder);
                        }
                    } else { // consolidated mesh
                        this.#uboManager.updateMeshFragmentUBO(mesh, model.getFragmentList(), consolidation.consolidationMap.fragOrder);
                    }

                    this.#requestedMeshUpdates.delete(mesh);
                });
            }
        }
    }

    #updateInstanceMeshThemingColors(instanceMesh, fragList, fragIds) {
        const attrib = instanceMesh.geometry.getAttribute('instThemingColor');
        const buffer = attrib.array;
        attrib.needsUpdate = true;
        const noThemeingColor = new THREE.Vector4(0, 0, 0, 0);
        for (let i = 0; i < instanceMesh.rangeCount; i++) {
            let fragId = fragIds[instanceMesh.rangeBegin + i];
            let dbID = fragList.getDbIds(fragId);
            const themingColor = fragList.db2ThemingColor[dbID] ?? noThemeingColor;

            buffer[i * 4 + 0] = themingColor.x * 255;
            buffer[i * 4 + 1] = themingColor.y * 255;
            buffer[i * 4 + 2] = themingColor.z * 255;
            buffer[i * 4 + 3] = themingColor.w * 255;
        }
    }

    #updateInstanceMeshVisibility(instanceMesh, fragList, fragIds) {
        const attrib = instanceMesh.geometry.getAttribute('instFlags');
        const buffer = attrib.array;
        attrib.needsUpdate = true;
        let anyFragVisible = false;
        for (let i = 0; i < instanceMesh.rangeCount; i++) {
            let fragId = fragIds[instanceMesh.rangeBegin + i];
            buffer[i] = fragList.vizflags[fragId] & 0xFF; // only the lowest 8 bits are used
            anyFragVisible ||= !!(buffer[i] & (MeshFlags.MESH_HIGHLIGHTED | MeshFlags.MESH_VISIBLE));
        }
        instanceMesh.visible = anyFragVisible;
    }
}