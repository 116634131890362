import { ConsolidationBuilder, MESH_STILL_PENDING, useEarlyConsolidation } from "./Consolidation";
import { InstanceBufferBuilder } from "./InstanceBufferBuilder";
import { MATERIAL_VARIANT } from "../../render/MaterialManager";
import THREE from "three";
import { USE_OUT_OF_CORE_TILE_MANAGER, DEFAULT_CONSOLIDATION_POLYCOUNT_LIMIT, PER_TILE_INSTANCING, USE_MULTI_MATERIAL_RENDER_CALLS } from "../../globals";
import { NodeArray, BVH } from "../BVHBuilder";
import { OtgLoader } from "../../../file-loaders/main/OtgLoader";


/** @import { MaterialManager } from "../../render/MaterialManager" */
/** @import { Consolidation } from "./Consolidation" */
/** @import { ConsolidationMap } from "./Consolidation" */

/**
 * This file contains code to create a Consolidation (see Consolidation.js) from all fraqments of a FragmentList.
 * Rendering the consolidation instead of the individual fragments can improve rendering performance
 * for models containing a large number of small objects.
 */

/**
 * Function to check if the consolidation job has been canceled. Throws if so.
 *
 * @callback fnStopIfCanceled
 * @throws {Error} If the consolidation job has been canceled
 */

/**
 * Context information for the consolidation job
 *
 * @typedef {Object} ConsolidationJobContext
 * @property {fnStopIfCanceled} stopIfCanceled            - Checks if the consolidation job has been canceled. Throws if so.
 * @property {ConsolidationMap|null} consolidationMap     - If available, the intermediate results can be reused from a previous
 *                                                          consolidation to accelerate preprocessing. Note that a ConsolidationMap
 *                                                          can only be reused if the FragmentList and consolidation parameters are exactly the same.
 * @property {number} byteLimit                           - The memory limit for consolidation
 * @property {boolean} forceConsolidationMapRecomputation - If true, the consolidation map will be recomputed
 * @property {Worker} worker                              - The worker that is used for consolidation
 * @property {number} id                                  - Consolidation Job identifier
 * @property {number} byteLimit                           - Merging geometries is the most efficient technique in terms
 *                                                          of rendering performance. But, it can strongly increase
 *                                                          the memory consumption, particularly because merged
 *                                                          geometry cannot be shared, i.e. multiple instances of
 *                                                          a single geometry must be replicated per instance for merging.
 *                                                          Therefore, the memory spent for merging is restricted.
 *                                                          A higher value may make rendering faster, but increases (also GPU) memory
 *                                                          workload.
 * @property {Object} bvhOptions                          - BVH computation options
 * @property {Object} consolidationBVH                    - The BVH computed during consolidation
 * @property {NodeArray} consolidationBVH.nodes           - The BVH nodes
 * @property {Int32Array} consolidationBVH.primitives     - The BVH primitives
 * @property {number} syncTimeSlice                       - Max miliseconds to spend on consolidation per iteration
 * @property {boolean} useDeferredConsolidation           - If true, consolidation will only compute the initial data. Consolidated buffers will be computed on demand.
 * @property {RenderModel} model                          - The model being consolidated
 */

export const CONSOLIDATION_STOP_MARKER = Object.freeze({
    BVH_SORTING: { name: 'Sorting fragments for BVH computation', progress: 10 },
    BVH: { name: 'Computing Consolidation BVH', progress: 30 },
    SORTING: { name: 'Sorting Fragments by Consolidation Costs', progress: 50 },
    MAP_CREATION : { name: 'Creating Consolidation Map', progress: 50 },
    INSTANCING: { name: 'Applying Instancing to Fragments', progress: 70 },
    FINAL_PROCESSING: { name: 'Finalizing Consolidation', progress: 95 },
});

const MINIMAL_INSTANCES_FOR_INSTANCED_RENDERING = 1; // This is a parameter we will want to tune
const MAX_INDICES_PER_RANGE = PER_TILE_INSTANCING ? 0 : 50_000;
const FRAGMENTS_PER_TIME_CHECK = 10_000;

let workerJobId = 0;

/**
 *  Creates a consolidated representation for a given list of fragment ids. Consolidation is only done for the
 *  first n elements of the fragIds array, where n is chosen in a way that we stop if a given memory cost limit is reached.
 *
 *  Consolidation is done here by merging fragment Geometries into larger vertex buffers. If multiple fragments share
 *  the same geometry, the geometry is replicated. Therefore, this step is only used for the smaller fragments
 *  with not too many instances.
 *
 *   @param {RenderModel}             model            - The model to consolidate
 *   @param {Int32Array[]}            fragIds          - Array of fragment ids to consolidate
 *   @param {Uint32Array|null}        fragIdToNodeIdx  - Optional: If available, the bvh node index for each fragment
 *   @param {ConsolidationJobContext} jobContext       - Context information for the consolidation job
 *
 *   @returns {Promise<Object>} Result object containing...
 *                      result.consolidation: Instance of Consolidation
 *                      result.fragIdCount:   Defines a range within fragIds:
 *                                            Only fragIds[0], ... , fragIds[result.fragIdCount-1] were consolidated.
 */
async function createConsolidationMap(model, fragIds, fragIdToNodeIdx, jobContext) {
    const fragList = model.getFragmentList();
    const polyCountLimit = jobContext.bvhOptions?.consolidation_polycount_limit ?? DEFAULT_CONSOLIDATION_POLYCOUNT_LIMIT;

    return new Promise((resolve) => {
        // reused in loop below
        var fragBox = new THREE.Box3();
        const geomList = model.getGeometryList();

        var mc = new ConsolidationBuilder();
        var i = 0;

        const addMoreGeoms = () => {
            jobContext.stopIfCanceled(CONSOLIDATION_STOP_MARKER.MAP_CREATION);

            const endTime = performance.now() + jobContext.syncTimeSlice;
            for (; i<fragIds.length; i++) {
                let fragId = fragIds[i];

                // stop if we reached our memory limit.
                if (USE_OUT_OF_CORE_TILE_MANAGER) {
                    let allInstancePolyCount = getPolyCountOfAllInstances(model, fragId, fragList, geomList, fragIdToNodeIdx, jobContext);

                    if (allInstancePolyCount > polyCountLimit) {
                        break;
                    }
                } else {
                    if (mc.costs >= jobContext.byteLimit) {
                        break;
                    }
                }

                // get world box
                fragList.getWorldBounds(fragId, fragBox);

                // add mesh to consolidation
                let materialId, geometry;
                if (useEarlyConsolidation(model)) {
                    geometry = null;
                    materialId = model.loader.svf.fragments.materials[fragId];
                    if (model.loader.svf.extraFragInfo.isTransparent(fragId)) {
                        break;
                    }
                } else {
                    geometry = fragList.getGeometry(fragId);
                    const material = fragList.getMaterial(fragId);
                    if (material.transparent) {
                        break;
                    }
                    materialId = material.id;
                }

                mc.addGeom(geometry, materialId, fragBox, fragId, fragIdToNodeIdx);

                // check if we need to yield because we are running out of time
                if (i % FRAGMENTS_PER_TIME_CHECK === 0) {
                    const diffToInstancing = CONSOLIDATION_STOP_MARKER.INSTANCING.progress - CONSOLIDATION_STOP_MARKER.MAP_CREATION.progress;
                    jobContext.signalProgress(CONSOLIDATION_STOP_MARKER.MAP_CREATION.progress + diffToInstancing * Math.max(i / fragIds.length, mc.costs / jobContext.byteLimit));

                    if (performance.now() > endTime) {
                        ++i;
                        setTimeout(addMoreGeoms, 0);
                        return;
                    }
                }
            }

            // create ConsolidationMap
            resolve(mc.createConsolidationMap(fragIds, i, fragIdToNodeIdx));
        };

        addMoreGeoms();
    });
}

export var applyInstancingToRange = (function (){

    var _tempMatrix = null;

    /**
     * Combines a sequence of fragments with shared geometry and material into an instanced mesh.
     * This instanced mesh is added to 'result'.
     *
     * For fragments that cannot be instanced, we add an individual mesh instead that shares
     * original geometry and material. This happens if:
     *
     *  a) The is just a single instance (range length 1)
     *  b) The instance has a matrix that cannot be decomposed into pos/scale/rotation.
     *
     *  @param {RenderModel}     model      - The model to which the instancing is applied
     *  @param {MaterialManager} materials  - needed to create new materials for instanced shapes
     *  @param {Int32Array}      fragIds    - Array of fragment ids to consolidate
     *  @param {number}          rangeStart - defines a range within the fragIds array
     *  @param {number}          rangeEnd   - end of the range within the fragIds array
     *  @param {Consolidation}   result     - collects the resulting mesh.
     */
    return function(model, materials, fragIds, rangeStart, rangeEnd, result) {

        var fragList = model.getFragmentList();

        // init temp matrix
        if (!_tempMatrix) { _tempMatrix = new THREE.Matrix4(); }

        var firstFrag = fragIds[rangeStart];

        const isOTG = model.loader instanceof OtgLoader;

        // get geometry and material (must be the same for all frags in the range)
        var geom  = fragList.getGeometry(firstFrag);

        let mat;
        let materialHash;
        if (isOTG) {
            let materialID = model.loader.svf.fragments.materials[firstFrag];
            materialHash = model.loader.svf.materialHashes.hashes[materialID];
            mat = materials.findMaterial(model, materialHash);
        } else {
            mat = fragList.getMaterial(firstFrag);
        }

        // just a single instance? => add it directly
        var rangeLength = rangeEnd - rangeStart;
        var lastIndex = rangeEnd - 1;
        const material = fragList.getMaterial(fragIds[rangeStart]);
        if (rangeLength <= MINIMAL_INSTANCES_FOR_INSTANCED_RENDERING || (material && material.transparent)) {
            for (let i=rangeStart; i<=lastIndex; i++)
                result.addSingleFragment(fragIds[i], fragList);
            return;
        }

        // create instanced geometry from geom and all transforms
        var builder = new InstanceBufferBuilder(geom, rangeLength);
        for (let i=rangeStart; i<=lastIndex; i++) {

            var fragId = fragIds[i];

            // world matrix and dbId
            fragList.getOriginalWorldMatrix(fragId, _tempMatrix);
            var dbId = fragList.fragments.fragId2dbId[fragId];

            // try to process as instanced mesh
            var valid = builder.addInstance(_tempMatrix, dbId, fragList.getThemingColor(fragId), fragList.vizflags[fragId]);

            // If adding this instance failed, its matrix did not allow to
            // be represented as pos/rotation/scale. In this case, add
            // the mesh individually.
            if (!valid) {
                // Swap last and current. This keeps all of the fragments
                // in the instanced buffer together.
                var tmp = fragIds[lastIndex];
                fragIds[lastIndex] = fragId;
                fragIds[i] = tmp;
                --i;
                --lastIndex;
            }
        }

        var instGeom = builder.finish();

        // instGeom might be null if all instances had matrices that could not be decomposed.
        // In this case, all frags have been skipped and will be added individually below
        if (instGeom) {

            // create instancing material
            var instMat  = materials.getMaterialVariant(mat, MATERIAL_VARIANT.INSTANCED, model);
            if (USE_MULTI_MATERIAL_RENDER_CALLS) {
                instMat.evalThemingAndVizflagsInShader = true;
            }

            // add instanced mesh
            result.addContainerMesh(instGeom, instMat, fragIds, rangeStart, rangeLength);
            // Set start of fragment id range.
            result.meshes[result.meshes.length - 1].rangeStart = rangeStart;
        }

        // if we had to skip any fragment, add it separately. Note that this must be done after
        // adding the container, so that fragId2MeshIndex finally refers to the individual geometry.
        for (let i=lastIndex+1; i<rangeEnd; i++) {
            fragId = fragIds[i];
            result.addSingleFragment(fragId, fragList);
        }
    };
}());

/**
 * Returns the polygon count of all instances of the geometry the given fragment belongs to.
 *
 * @param {RenderMode} model                   - The model
 * @param {number} fragId                      - Id of the fragment
 * @param {FragmentList} fragList              - The fragment list
 * @param {GeometryList} geomList              - The geometry list
 * @param {Int32Array} fragIdToNodeIdx         - Optional: If available, the bvh node index for each fragment
 * @param {ConsolidationJobContext} jobContext - Context information for the consolidation job
 * @returns
 */
function getPolyCountOfAllInstances(model, fragId, fragList, geomList, fragIdToNodeIdx, jobContext) {
    if (PER_TILE_INSTANCING) {
        // This is the right way to do it, but it is expensive with the current implementation
        // We will switch to this once we have refactored other parts of the code to only operate on a subset of fragments
        return getMergeablePolyCount(model, fragId, fragList, geomList, fragIdToNodeIdx, jobContext);
    }

    let polyCount, instanceCount;
    if (useEarlyConsolidation(model)) {
        polyCount = model.loader.svf.extraFragInfo.getPolygonCount(fragId);
        instanceCount = model.loader.svf.getFragInstanceCount(fragId);
    } else {
        const geomId = fragList.getGeometryId(fragId);
        instanceCount = geomList.getInstanceCount(geomId);
        polyCount = geomList.getGeometry(geomId).polyCount;
    }
    return polyCount * instanceCount;
}

/**
 * Returns the polygon count of all instances sharing the same material, geometry and potentially the same BVH node
 * and therefore are candidates for merging into consolidated or instanced meshes.
 *
 * @param {RenderMode} model                   - The model
 * @param {number} fragId                      - Id of the fragment
 * @param {FragmentList} fragList              - The fragment list
 * @param {GeometryList} geomList              - The geometry list
 * @param {Int32Array} fragIdToNodeIdx         - Optional: If available, the bvh node index for each fragment
 * @param {ConsolidationJobContext} jobContext - Context information for the consolidation job
 * @returns
 */
function getMergeablePolyCount(model, fragId, fragList, geomList, fragIdToNodeIdx, jobContext) {
    const isOTG = model.loader instanceof OtgLoader;
    let geomDataIndexes, materials, polyCount;

    if (isOTG) {
        model.loader.svf.getFragInstanceCount(fragId);
        geomDataIndexes = model.loader.svf.fragments.geomDataIndexes;
        materials = model.loader.svf.fragments.materials;
    }

    if (useEarlyConsolidation(model)) {
        polyCount = model.loader.svf.extraFragInfo.getPolygonCount(fragId);
    } else {
        const geomId = fragList.getGeometryId(fragId);
        polyCount = geomList.getGeometry(geomId).polyCount;
    }

    let getKey;
    if (isOTG) {
        getKey = (fragId) => {
            let geomId, nodeId;
            geomId = geomDataIndexes[fragId];
            nodeId = PER_TILE_INSTANCING && fragIdToNodeIdx ? fragIdToNodeIdx[fragId] : 0;
            return `${geomId}_${nodeId}`;
        };
    } else {
        getKey = (fragId) => fragList.getGeometryId(fragId);
    }

    const getMaterialId = isOTG ? (fragId) => materials[fragId] : (fragId) => fragList.getMaterialId(fragId);
    if (!jobContext.geometryInstanceCounts || (jobContext.nodeIdsAvailable !== (fragIdToNodeIdx !== undefined))) {
        // Compute lookup table for geometry instance counts
        const fragCount = fragList.fragments.length;

        jobContext.geometryInstanceCounts = new Map();
        jobContext.nodeIdsAvailable = fragIdToNodeIdx !== undefined;
		for (let i = 0; i < fragCount; i++) {
            const key = getKey(i);
            const matId = getMaterialId(i);

            let keyMap = jobContext.geometryInstanceCounts.get(matId);
            if (!jobContext.geometryInstanceCounts.has(matId)) {
                keyMap = new Map();
                jobContext.geometryInstanceCounts.set(matId, keyMap);
            }

            const instanceCount = keyMap.get(key) ?? 0;
            keyMap.set(key, instanceCount + 1);
		}
	}

	const key = getKey(fragId);
    const keyMap = jobContext.geometryInstanceCounts.get(getMaterialId(fragId));
    return polyCount * (keyMap?.get(key) ?? 0);
}

/**
 * Combines fragments with shared geometries into instanced meshes. Note that creating instanced meshes
 * only makes sense for fragments that share geometry and material. All other fragments will also be
 * added to the result, but the meshes will share original geometry and material.
 *
 * Requirement: fragIds must already be sorted in a way that meshes with identical geometry and material form
 *              a contiguous range.
 *
 * @param {RenderModel}             model      - Model to be consolidated
 * @param {MaterialManager}         materials  - Needed to create new materials for instanced shapes
 * @param {Consolidation} consolidation        - Consolidation object to which the instancing is applied
 * @param {ConsolidationJobContext} jobContext - Context information for the consolidation job
 * @param {Promise<Consolidation>} result - collects all output meshes
 */
async function applyInstancing(model, materials, consolidation, jobContext) {

    var fragList = model.getFragmentList();

    // the first n=numConsolidated fragments in fragIds are consolidated already.
    // The remaining fragIds are now processed using hardware instancing.
    const fragIds = jobContext.consolidationMap.fragOrder;
    const startIndex = jobContext.consolidationMap.numConsolidated;
    const bvhNodeToInstancingRanges = jobContext.consolidationMap.bvhNodeToInstancingRanges = new Array();

    if (startIndex >= fragIds.length) {
        // range empty
        // This may happen if we could consolidate all fragments per mesh merging already, so
        // that instancing is not needed anymore.
        return;
    }

    // track ranges of equal geometry and material
    var rangeStart = startIndex;
    var lastGeomId = -1;
    var lastMatId  = -1;
    let lastNodeIdx = -1;
    let indexCount = 0;
    const fragIdToNodeIdx = jobContext.consolidationMap.fragIdToNodeIdx;

    let { geomIds, materialIds } = getGeomAndMaterialIDs(model);


    consolidation.fragId2InstancingRange = new Array();
    const process = (rangeStart, rangeEnd) => {
        if (useEarlyConsolidation(model)) {
            // this is used later in the OutOfCoreTileManager to know which instancing ranges to process for a given node.
            const nodeIndices = new Set();
            for (let i = rangeStart; i < rangeEnd; i++) {
                nodeIndices.add(fragIdToNodeIdx[fragIds[i]]);
            }
            for (const nodeIdx of nodeIndices) {
                if (bvhNodeToInstancingRanges[nodeIdx] === undefined) {
                    bvhNodeToInstancingRanges[nodeIdx] = [];
                }
                bvhNodeToInstancingRanges[nodeIdx].push([rangeStart, rangeEnd]);
            }
        } else {
            applyInstancingToRange(model, materials, fragIds, rangeStart, rangeEnd, consolidation);
        }
    };

    let endTime = performance.now() + jobContext.syncTimeSlice;
    for (let i=startIndex; i < fragIds.length; i++) {
        var fragId = fragIds[i];
        var geomId = geomIds[fragId];
        var matId  = materialIds[fragId];
        var nodeIdx = fragIdToNodeIdx ? fragIdToNodeIdx[fragId] : -1;

        // check if a new range starts here
        // If case of per tile consolidation, we allow pulling in more fragments from other nodes
        // to a certain degree to increase render batch sizes.
        if (geomId != lastGeomId || matId != lastMatId ||
            (nodeIdx != lastNodeIdx && indexCount > MAX_INDICES_PER_RANGE)) {

            // a new range starts at index i
            // => process previous range [rangeStart, ..., i-1]
            if (i!=startIndex) {
                process(rangeStart, i);
            }

            // start new range
            rangeStart = i;
            lastGeomId = geomId;
            lastMatId = matId;
            lastNodeIdx = nodeIdx;
            indexCount = 0;
        }

        if (useEarlyConsolidation(model)) {
            if (model.loader.svf.cachedPolygonCounts) {
                indexCount += 3 * model.loader.svf.cachedPolygonCounts[fragId];
            }
            else {
                const polyCount = model.loader.svf.extraFragInfo?.getPolygonCount(fragId);
                indexCount += 3 * polyCount;
            }
        } else {
            const geom  = fragList.getGeometry(fragId);
            indexCount += geom.ib ? geom.ib.length : 0;
        }

        if (i % FRAGMENTS_PER_TIME_CHECK === 0) {
            const diffToFinalProcessing = CONSOLIDATION_STOP_MARKER.FINAL_PROCESSING.progress - CONSOLIDATION_STOP_MARKER.INSTANCING.progress;
            jobContext.signalProgress(CONSOLIDATION_STOP_MARKER.INSTANCING.progress + diffToFinalProcessing * i / fragIds.length);

            if (performance.now() > endTime) {
                await new Promise(resolve => setTimeout(resolve, 0));
                endTime = performance.now() + jobContext.syncTimeSlice;
                jobContext.stopIfCanceled(CONSOLIDATION_STOP_MARKER.INSTANCING);
            }
        }
    }

    // process final range
    process(rangeStart, fragIds.length);
}

/**
 * Returns an array that enumerates all fragIds in a way that...
 *
 *  1. They are ordered by increasing memory costs that it takes to consolidate them.
 *  2. FragIds with equal geometry and material form a contiguous range.
 *  3. If available by bvh node index, so that fragments in the same node are grouped together.
 *
 *  @param {RenderModel} model                  - The model to consolidate
 *  @param {Int32Array}  [sortedFragIds]        - Optional: If available, the array will be reused to store the result
 *  @param {Uint32Array} [fragIdToNodeIdx]      - Optional: If available, sorting will take into account the bvh node index
 *  @param {ConsolidationJobContext} jobContext - Context information for the consolidation job
 *  @returns {Promise<Int32Array>} ordered list of fragment ids, wrapped in a promise
 */
async function sortByConsolidationCosts(model, sortedFragIds, fragIdToNodeIdx, jobContext) {
    const fragList = model.getFragmentList();
    const geomList = model.getGeometryList();

    // a single missing geometry shouldn't make the whole consolidation fail.
    // therefore, we exclude any null-geometry fragemnts.
    var validFrags = 0;

    // create fragId array [0,1,2,...]
    var fragCount = fragList.getCount();
    var fragIds = sortedFragIds && sortedFragIds.length === fragCount ? sortedFragIds : new Int32Array(fragCount);
    const memCosts = new Uint32Array(fragCount);
    const maxUint32 = Math.pow(2, 32) - 1;
    for (var i=0; i<fragCount; i++) {

        if (useEarlyConsolidation(model)) {
            // exclude fragments without valid geometry
            if (model.loader.svf.fragments.geomDataIndexes[i] === 0) {
                continue;
            }

            let allInstancePolyCount = getPolyCountOfAllInstances(model, i, fragList, geomList, fragIdToNodeIdx, jobContext);
            fragIds[validFrags] = i;

            // Transparent fragments should never be consolidated and should be sorted to the end after all opaque fragments, so we set their cost to the maximum
            memCosts[i] = model.loader.svf.extraFragInfo.isTransparent(i) ? maxUint32 : allInstancePolyCount;

        } else {
            // exclude fragments without valid geometry
            if (!fragList.isFragmentActive(i)) {
                continue;
            }

            fragIds[validFrags] = i;
            const geom = fragList.getGeometry(i);
            const geomId = fragList.getGeometryId(i);
            const instCount = geomList.getInstanceCount(geomId);

            // Transparent fragments should never be consolidated and should be sorted to the end after all opaque fragments, so we set their cost to the maximum
            memCosts[i] = fragList.getMaterial(i).transparent ? maxUint32 : instCount * geom.byteSize;
        }
        validFrags++;
    }

    // resize array if we had to skip fragments
    if (validFrags < fragCount) {
        fragIds = new Int32Array(fragIds.buffer, fragIds.byteOffset, validFrags);
    }

    // We do the actual sorting in a worker to avoid blocking the main thread
    let returnResult;

    let { geomIds, materialIds } = getGeomAndMaterialIDs(model);

    return new Promise((resolve) => {
        const jobId = workerJobId++;

        // Worker Callback
        returnResult = (result) => {
            // We might get calls for previous jobs, so we need to check if the result is for the current job
            if (result.data.jobId === jobId) {
                resolve(result.data.fragIds);
            }
        };
        jobContext.worker.addEventListener('message', returnResult);

        const context = {
            operation: "SORT_FRAGMENTS",
            fragIds,
            geomIds,
            memCosts,
            materialIds,
            fragIdToNodeIdx,
            jobId
        };

        jobContext.worker.doOperation(context);
    }).finally(() => {
        jobContext.worker?.removeEventListener('message', returnResult);
    });
}

/**
 * For tile based consolidation a BVH is needed. So we compute a one suited for consolidation
 * @param {RenderModel} model                  - The model to consolidate
 * @param {Uint32Array} fragIdToNodeIdx        - maps fragment id to bvh node index
 * @param {Int32Array} sortedFragIds           - will be filled with sorted fragment ids by consolidation costs
 * @param {ConsolidationJobContext} jobContext - Context information for the consolidation job
 */
async function computeConsolidationBVH(model, fragIdToNodeIdx, sortedFragIds, jobContext) {

    const fl = model.getFragmentList();

    // We are copying the data from the fragment list into a format that can be passed to a worker
    // This is necessary because the fragment list is not available in the worker
    // This creates some temporary memory overhead, but if we don't have enough memory for this we shouldn't
    // do consolidation anyway
    let { geomIds, materialIds } = getGeomAndMaterialIDs(model);

    const fragments = {
        boxes: fl.fragments.boxes,
        polygonCounts: new Uint32Array(geomIds.length),
        flags: new Uint8Array(geomIds.length),
        materials: materialIds,
        length: geomIds.length,
        geomids: geomIds,
        wantSort: true
    };

    // Compute which fragments can be consolidated and which are transparent
    sortedFragIds = await sortByConsolidationCosts(model, sortedFragIds, undefined, jobContext);
    jobContext.stopIfCanceled(CONSOLIDATION_STOP_MARKER.BVH_SORTING);
    jobContext.signalProgress(CONSOLIDATION_STOP_MARKER.BVH_SORTING.progress);

    const polyCountLimit = jobContext.bvhOptions?.consolidation_polycount_limit ?? DEFAULT_CONSOLIDATION_POLYCOUNT_LIMIT;
    const materialIdMap = model.getFragmentList().materialIdMap;
    let consolidationCosts = 0;
    for (let i = 0; i < sortedFragIds.length; ++i) {
        const fragId = sortedFragIds[i];
        if (useEarlyConsolidation(model)) {
            let geomId = model.loader.svf.fragments.geomDataIndexes[fragId];
            const extraFragInfo = model.loader.svf.extraFragInfo;

            fragments.polygonCounts[fragId] = geomId !== 0 ?
                extraFragInfo.getPolygonCount(fragId) :
                0;

            if (fragments.polygonCounts[fragId] < polyCountLimit) {
                fragments.flags[fragId] = 1; // can be consolidated
            }

            fragments.flags[fragId] |= extraFragInfo.isTransparent(fragId) ? 2 : 0;
        } else {
            const geom = fl.getGeometry(fragId);
            fragments.polygonCounts[fragId] = geom ? geom.polyCount : 0;
            consolidationCosts += geom.byteSize;

            if (USE_OUT_OF_CORE_TILE_MANAGER) {
                if (fragments.polygonCounts[fragId] < polyCountLimit) {
                    fragments.flags[fragId] = 1; // can be consolidated
                }
            } else {
                if (consolidationCosts <= jobContext.byteLimit) {
                   fragments.flags[fragId] = 1; // can be consolidated
                }
            }

            const materialDef = materialIdMap && materialIdMap[fragments.materials[fragId]];
            fragments.flags[fragId] |= materialDef && materialDef.transparent ? 2 : 0;
        }
    }

    // offload the bvh computation to a worker
    let returnResult;
    const bvh = await new Promise((resolve) => {
        const jobId = workerJobId++;

        // Worker Callback
        returnResult = (result) => {
            // We might get calls for previous jobs, so we need to check if the result is for the current job
            if (result.data.jobId === jobId) {
                const bvh = result.data.bvh;
                resolve(new BVH(bvh.nodes, bvh.useLeanNodes, bvh.primitives, jobContext.bvhOptions));
            }
        };
        jobContext.worker.addEventListener('message', returnResult);

        jobContext.worker.doOperation({
            operation: "COMPUTE_BVH",
            fragments,
            modelId: model.id,
            bvhOptions: jobContext.bvhOptions,
            jobId
        }, [fragments.polygonCounts.buffer, fragments.flags.buffer]);
    }).finally(() => {
        jobContext.worker?.removeEventListener('message', returnResult);
    });

    jobContext.consolidationBVH = bvh;

    jobContext.stopIfCanceled(CONSOLIDATION_STOP_MARKER.BVH);
    jobContext.signalProgress(CONSOLIDATION_STOP_MARKER.BVH.progress);

    computeFragIdToNodeIdx(bvh, fragIdToNodeIdx);
}

/**
 * Get the geom and material IDs for the model.
 * @param {RenderModel} model - The model
 * @returns { {geomIds: Uint32Array, materialIds: Uint32Array} } - The geom and material IDs
 */
function getGeomAndMaterialIDs(model) {
    const fl = model.getFragmentList();
    let geomIds, materialIds;

    if (useEarlyConsolidation(model)) {
        geomIds = model.loader.svf.fragments.geomDataIndexes;
        materialIds = model.loader.svf.fragments.materials;
    } else {
        geomIds = fl.geomids;
        materialIds = fl.materialids;
    }
    return { geomIds, materialIds };
}

/**
 * Get the current consolidation map or create a new one if it does not exist yet or if parameters have changed
 * requirung a recomputation.
 * @param {RenderModel}             model       - The model to consolidate
 * @param {ConsolidationJobContext} jobContext  - Context information for the consolidation job
 * @returns {Promise<ConsolidationMap>}
 */
async function getOrCreateConsolidationMap(model, jobContext) {
    if (!jobContext.consolidationMap || jobContext.forceConsolidationMapRecomputation) {
        const fragList = model.getFragmentList();
        let fragIdToNodeIdx = null; // maps fragment id to bvh node index (only needed for per-tile consolidation)
        let sortedFragIds = new Int32Array(fragList.getCount());

        if (jobContext.bvhOptions?.per_tile_consolidation) {
            fragIdToNodeIdx = new Uint32Array(fragList.getCount());
            await computeConsolidationBVH(model, fragIdToNodeIdx, sortedFragIds, jobContext);
        }

        // create consolidation map
        sortedFragIds = await sortByConsolidationCosts(model, sortedFragIds, fragIdToNodeIdx, jobContext);
        jobContext.stopIfCanceled(CONSOLIDATION_STOP_MARKER.SORTING);
        jobContext.signalProgress(CONSOLIDATION_STOP_MARKER.SORTING.progress);

        jobContext.consolidationMap = await createConsolidationMap(model, sortedFragIds, fragIdToNodeIdx, jobContext);
    }

    // this is used later in the OutOfCoreTileManager to know which meshes to build for a given node.
    const bvhNodeToRanges = jobContext.consolidationMap.bvhNodeToRanges = new Array();
    if (useEarlyConsolidation(model)) {
        jobContext.consolidationMap.bvhNodeIndices.forEach((bvhNodeId, rangeIndex) => {
            if (!bvhNodeToRanges[bvhNodeId]) {
                bvhNodeToRanges[bvhNodeId] = new Array();
            }
            bvhNodeToRanges[bvhNodeId].push(rangeIndex);
        });
    }

    return jobContext.consolidationMap;
}

/**
 * Writes mapping from frag ID to BVH node index into the given array.
 * @param {BVH} bvh
 * @param {Uint32Array} fragIdToNodeIdx
 */
export function computeFragIdToNodeIdx(bvh, fragIdToNodeIdx) {
    bvh.traverseBreadthFirst((nodeIdx) => {
        const start = bvh.nodes.getPrimStart(nodeIdx);
        const primCount = bvh.nodes.getPrimCount(nodeIdx);

        const end = start + primCount;
        for (let i = start; i < end; ++i) {
            fragIdToNodeIdx[bvh.primitives[i]] = nodeIdx;
        }
    });
}

/**
 *  Creates a consolidated representation of a fragments. For each fragment f, there will be a mesh in the result that
 *  contains it - or shares its geometry if was not mergeable with any other fragment.
 *
 *   @param {RenderModel}     model               - The model to consolidate
 *   @param {MaterialManager} materials           - needed to create new material variants for consolidated/instanced meshes
 *   @param {ConsolidationJobContext} jobContext  - Context information for the consolidation job
 *   @returns {Promise<Consolidation>}            - Returns a promise that resolves to a Consolidation object
 */
export async function consolidateFragmentList(model, materials, jobContext) {
    const fragList = model.getFragmentList();

    // If not available yet, create ConsolidationMap that describes the mapping from src fragments
    // into consolidated meshes.
    const consMap = jobContext.consolidationMap = await getOrCreateConsolidationMap(model, jobContext);

    // Create Consolidation
    const consolidation = consMap.buildConsolidation(fragList, materials, model, jobContext.useDeferredConsolidation);

    // Apply instancing to all remaining fragments that were not consolidated yet
    await applyInstancing(model, materials, consolidation, jobContext);

    return consolidation;
}
