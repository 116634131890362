import { CallbackTask } from "../CallbackTask";
import { ConsolidationMapTask } from "./ConsolidationMapTask";
import { applyInstancingToRange } from "../../../consolidation/FragmentListConsolidation";
import { ModelIteratorBVH } from '../../../ModelIteratorBVH';
import { INCREMENTAL_CONSOLIDATION } from "../../../../globals";

/** @import { BvhNode } from "../../BvhNode" */

/**
 * Compute the consolidation for the node
 * @param {BvhNode} node
 * @returns
 */
function computeNodeConsolidation(node) {
    if (node.initialized) {
        console.error("Node already initialized");
        return;
    }

    const bvhModelIterator = node.model.getIterator();
    const consolidation = node.model.getConsolidation();
    const fragList = node.model.getFragmentList();

    if (!(bvhModelIterator instanceof ModelIteratorBVH)) {
        console.error("ModelIterator is not a BVH iterator");
        return;
    }

    // these are only set when early consolidation is used
    consolidation.consolidationMap.bvhNodeToRanges[node.nodeId]?.forEach(rangeIndex => {
        // There's this.#getViewer(), but it returns undefined if a model is loaded with `loadAsHidden: true` because no MODEL_ADDED_EVENT is fired.
        const matman = node.model.loader.viewer3DImpl.matman();
        consolidation.consolidationMap._buildConsolidationMesh(rangeIndex, fragList, matman, node.model, true, false, consolidation);
    });
    consolidation.consolidationMap.bvhNodeToInstancingRanges[node.nodeId]?.forEach(([rangeBegin, rangeEnd]) => {
        // don't call applyInstancingToRange multiple times if the range spans multiple nodes
        if (consolidation.fragId2MeshIndex[consolidation.consolidationMap.fragOrder[rangeBegin]] !== -1) {
            return;
        }
        const matman = node.model.loader.viewer3DImpl.matman();
        applyInstancingToRange(node.model, matman, consolidation.consolidationMap.fragOrder, rangeBegin, rangeEnd, consolidation);
    });

    // Mark the consolidation computation succeeded on the node
    node.consolidationComputed = true;
}

/**
 * Creates a task to compute the consolidation map
 *
 * @param {BvhNode} node - The ID of the BVH node
 * @param {Stage} stage - The stage of the task
 */
export function initializeConsolidationComputedTasks(node, stage) {
    if (!node.transparent) {
        if (INCREMENTAL_CONSOLIDATION)
            node.addTask(new ConsolidationMapTask(node.outOfCoreTileManager, node.nodeId), stage);
        else {
            node.addTask(new CallbackTask(node.outOfCoreTileManager, async () => {
                // Wait until consolidation is available
                while (!node.model.getConsolidation(node.nodeId)) {
                    await new Promise(resolve => setTimeout(resolve, 100));
                }

                // Compute the consolidation for the node
                computeNodeConsolidation(node);
            }), stage);
        }
    }
}
