import {UniformBuffer} from "../UniformBuffer";

const Offsets = {
    Projection: 0,
    ProjScale: 4,
    Near: 5,
    Far: 6,
    Radius: 8,
    Bias: 9,
    Intensity: 10,
    IsOrtho: 11,
    // Update size when adding values:
    SIZE_IN_FLOATS: 12,
};

export class SAOUniforms extends UniformBuffer {

    constructor(device) {
        super(device, Offsets.SIZE_IN_FLOATS, true, false);
    }


    setCamera(camera, w, h) {

        let near = camera.near;
        let far = camera.far;

        this.setFloat(Offsets.Near, near);
        this.setFloat(Offsets.Far, far);
        //_saoMipFirstPass.uniforms['cameraNear'].value = near;
        //_saoMipFirstPass.uniforms['cameraInvNearFar'].value = 1.0 / (near - far);

        let P = camera.projectionMatrix.elements;

        //Note that we flip Y here to take into account that sampling
        //from a render target on WebGPU requires the flipped Y, compared
        //to the same shader in WebGL.
        if (camera.isPerspective) {
            // vec4(-2.0f / (width*P[0][0]),
            //-2.0f / (height*P[1][1]),
            //( 1.0f - P[0][2]) / P[0][0],
            //( 1.0f + P[1][2]) / P[1][1])
            this.setFloat4(Offsets.Projection,
                -2.0 / (w * P[0]),
                2.0 / (h * P[5]),
                (1.0 - P[8]) / P[0],
                -(1.0 + P[9]) / P[5]);
        } else {
            this.setFloat4(Offsets.Projection,
                -2.0 / (w * P[0]),
                2.0 / (h * P[5]),
                (1.0 - P[12]) / P[0],
                -(1.0 - P[13]) / P[5]);
        }

        let isOrtho = (camera.isPerspective ? 0 : 1);
        this.setInt(Offsets.IsOrtho, isOrtho);

        //Scaling factor needed to increase contrast of our SSAO.
        let hack_scale = 0.25;
        let projScale = hack_scale * 0.5 * (h * P[5]);
        this.setFloat(Offsets.ProjScale, projScale);
    }

    setAOOptions(radius, bias, intensity) {
        if (radius !== undefined) {
            this.setFloat(Offsets.Radius, radius);
        }

        if (bias !== undefined) {
            this.setFloat(Offsets.Bias, bias);
        }

        if (intensity !== undefined) {
            this.setFloat(Offsets.Intensity, intensity);
        }
    }

    /**
     * @typedef {Object} AoOptions
     * @property {number} bias
     * @property {number} radius
     * @property {number} intensity
     */
    /**
     * @returns {AoOptions}
     */
    getAOOptions() {
        return {
            bias: this.getFloat(Offsets.Bias),
            radius: this.getFloat(Offsets.Radius),
            intensity: this.getFloat(Offsets.Intensity),
        };
    }

}
