import { CommonMaterialUniforms } from './CommonMaterialUniforms.js';
import { MaterialUniformBuffer } from './MaterialUniformBuffer.js';
import { ObjectUniformBuffer, MAX_BATCH } from './ObjectUniformBuffer.js';
import { ModelUniformUpdater } from './ModelUniformUpdater.js';
import { OBJECT_STRIDE_32, OBJECT_STRIDE } from './ObjectUniforms.js';
import { USE_DYNAMIC_UNIFORM_ALLOCATION, USE_OUT_OF_CORE_TILE_MANAGER } from "../../../globals";

// find modelId of a RenderBatch
const getModelId = (renderBatch) => {
    const fragList = renderBatch.frags;
    return fragList?.modelId ?? 0;
}

/**
 * Manages bindGroups and batched uniform buffers for each RenderBatch.
 *
 * There are 3 types of UniformBuffers:
 *
 *  1. ObjectUniformBuffer:    Uniforms that vary per object (e.g. model matrix a themingColor etc.)
 *  2. MaterialUniformBuffer:  Uniforms that vary per material (e.g. color, texture, etc.). Entries are referenced by object uniforms.
 *  3. CommonMaterialUniforms: Some values that are just set once for each scene.
 *
 * For 1., there are two variants:
 *
 *  - Pre-Batched: The uniforms for a whole RenderBatch are uploaded just once in advance and updated on changes.
 *                 We do this for all model geometry. A RenderBatch with pre-batched uniforms has a range
 *                 in the GPU-side uniform buffer, stored in ObjectUniformBuffer
 *                 (see ObjectUniformBufferRange type in ObjectUniformBuffer.js).
 *
 *  - Dynamic:     The uniforms are uploaded per frame. We have a small shared object uniform buffer, enough for MAX_BATCH objects.
 *                 This buffer is used when rendering anything but actual models, e.g. scene, sceneAfter, overlays, ...
 *                 If this buffer is used, uniforms need to be set for every rendered object, via the setOne... methods.
 *                 It's a shared buffer that is constantly rewritten.
 *                 There's a CPU-side buffer of the same size that is used to aggregate object data and push it into the GPU buffer.
 *
 *  A render pass needs to determine if it's currently rendering a model scene (i.e. RenderBatch) or one of the other scenes.
 *
 *  1) Rendering a non-model scene: (using dynamic uniforms)
 *      - The bind group needs to be obtained via getBindGroup() (optionally provide -1 as the only parameter)
 *      - Object and material uniforms need to be set via the setOne... methods for every object
 *      - writeToQueue needs to be called prior to submitting the render command buffer
 *      - Each render command buffer may only contain draw calls for up to MAX_BATCH objects
 *
 *  2) Rendering a model scene: (using pre-batched uniforms)
 *      - The bind group needs to be obtained via getBindGroup(renderBatch)
 *      - Renderbatches may be flagged as dirty, requiring batched uniform updates;
 *        updateBatch needs to be called in this case
 *      - Materials need to be set up (once) via initMaterialUpdateHook; object uniforms do not need to be set
 *      - A material's needsUpdate flag needs to be reset after the pipeline's draw function has been called
 *      - writeToQueue does not need to be called
 *      - Render command buffers may contain draw calls for an unlimited number of objects
 */
export class RenderBatchUniforms {

    /** @type GPUDevice */
    #device;

    // Manages buffers for all pre-uploaded uniform batches.
    /** @type {ObjectUniformBuffer} */
    #batchedUniforms;

    // Indexed by modelId. Non-model geometry can use index 0.
    /** @type Map<modelId, ObjectUniformBuffer> */
    modelUniforms = new Map();

    // A separate UniformStorage with a single fixed-size buffer. This is used for the cases where we have to
    // upload the object-uniforms per-frame. This is done whenever we don't have RenderBatches or THREE.Scenes
    // that don't have pre-uploaded uniform batches. (currently for overlays and 2D geometry).
    /** @type {ObjectUniformBuffer} */
    #dynamicUniforms;
    /** @type {GPUBindGroup} */
    #dynamicUniformsBindGroup;

    // Common uniforms that are shared across all objects of a scene
    /* @type {CommonMaterialUniforms} */
    #commonMaterialUniforms;

    // Per-material uniforms that can be shared across multiple objects and models.
    /* @type {MaterialUniformBuffer} */
    #materialUniforms;

    // Same for all bindGroups
    /** @type {GPUBindGroupLayout} */
    #bindGroupLayout;

    // Each ObjectUniformBuffer instance can manage one or more buffers.
    // For each of these buffers, we need a separate bindGroup.
    // #bindGroup[modelId][bufferIndex] provides the bingGroup for the given modelId/bufferIndex cominbation.
    /** @type {GPUBindGroup[][]} */
    #bindGroups = [];

    // For updating individual fragId uniforms, we need to find where they are stored in the GPU-side UniformBuffers.
    // This is managed by the modelUniformUpdaters.
    #modelUniformUpdaters = new Map(); // model id -> uniform updater instance

    // Needed by MainPass to know how many shapes can be rendered in one-go without intermediate uniform-buffer uploads.
    // Only relevant for per-frame-uploaded uniforms (overlays, THREE.Scene, and 2D geometry).
    MAX_BATCH = MAX_BATCH();

    // When rendering postMaterial overlays, there is some code in MainPass that addresses a materialReference
    // using a float offset. (see setMaterialReference)
    // Note: This is just for legacy compatibility with old ObjectUniforms and should finally use an object index directly.
    OBJECT_STRIDE_32 = OBJECT_STRIDE_32;

    // Only needed by ModelUpdaters to invalidate RenderBundles.
    /** @type {Renderer} */
    #renderer;

    // TODO: The props below describe a "current state" that is silently set by "getBindGroup". They will probably go away
    //       if we remove the statefulness and make getRenderIndex() just take the RenderBatch directly.

    // Note: This is just needed temporarily to keep the getObjectIndex remapping work for ConsolidatedRenderBatches.
    //        Hopefully, we can remove it when we get rid of ConsolidationIterator. In general, ObjectUniforms shouldn't need to care about a current model.
    #currentModelId;

    // Used during rendering a previously uploaded RenderBatch
    //  - Before starting the batch, it is set to the offset in the GPU-side uniform buffer where the batch uniforms start.
    //  - During batch rendering, it used to convert between "objectIndex" (= index within the batch) and renderIndex (= index in the GPU-side buffer where the uniform data is stored)
    #currentRangeStartGPU = 0;

    // During rendering a pre-uploaded batch, this index is used to convert between objectIndex and renderIndex.
    // objectIndex is the loopIndex within the batch, i.e., starting at batch.start.
    #currentBatchStartIndex = 0;

    /**
     * @param {Renderer} renderer
     */
    constructor(renderer) {

        // Note that the renderer is only needed to invalidate RenderBundles when a material changes. All other code here
        // actually just needs the WebGPU device.
        this.#renderer = renderer;
        this.#device = renderer.getDevice();

        this.#commonMaterialUniforms = new CommonMaterialUniforms(this.#device);
        this.#materialUniforms = new MaterialUniformBuffer(this.#device);

        this.#initBindGroupLayout();

        // Initiale dynamic uniform storage and corresponding bind group
        this.#dynamicUniforms = new ObjectUniformBuffer(this.#device, this.#materialUniforms, this.MAX_BATCH * OBJECT_STRIDE, true);
        const gpuBuffer = this.#dynamicUniforms.getGPUBuffer(0);
        this.#dynamicUniformsBindGroup = this.#createBindGroup(gpuBuffer);

        if (USE_DYNAMIC_UNIFORM_ALLOCATION) {
            this.#batchedUniforms = new ObjectUniformBuffer(this.#device, this.#materialUniforms);
        }
    }

    /**
     * Upload uniform data for the given RenderBatch.
     * The allocated GPUBufferRange is attached to a range in an ObjectUniformBuffer.
     * For later uploads, the range is reused if possible.
     * Note:
     *    For some fragments, materials may be undefined. This may happen if the fragment is not fully loaded yet.
     *    This is not a problem, but these specific fragments will not be rendered until their material is set via setMaterial() later.
     * @param {RenderBatch} renderBatch
     */
    updateBatch(renderBatch) {

        // First, we need to make sure that a range of appropriate size is allocated in the GPU-side UniformBuffer.
        let range;

        let uniformStorage;
        if (USE_DYNAMIC_UNIFORM_ALLOCATION) {
            range = this.#batchedUniforms.getRange(renderBatch);
            // If there is an allocated range, but it has wrong size, discard it first
            if (range && range.rangeLength !== renderBatch.count) {
                this.freeUniforms(renderBatch);
                range = null;
            }

            // New code - use a single dyamic storage for all models
            uniformStorage = this.#batchedUniforms;
        } else {
            const modelId = getModelId(renderBatch);
            uniformStorage = this.modelUniforms.get(modelId);
            range = uniformStorage.getRange(renderBatch);
        }

        // Make sure a range of proper size is allocated
        if (!range) {
            // Allocate a new GPU range
            range = uniformStorage.allocUniformBufferRange(renderBatch);

            // Register the RenderBatch at the modelUniformUpdater, so that we can find the uploaded
            // uniforms for each fragment in this RenderBatch.
            this.#registerRenderBatch(renderBatch);
        }

        // Note:
        //  - In most cases, updateCount is identical with renderBatch.count. However, it may be smaller
        //    for incomplete RenderBatches during loading.
        //  - Despite of the name, lastItem itself is NOT part of the range, so the we don't have to add 1.
        const updateCount = renderBatch.lastItem - renderBatch.start;

        uniformStorage.updateRange(
            renderBatch.frags,
            renderBatch.getIndices(),
            renderBatch.start,
            updateCount,
            range.startIndex,
            range.bufferIndex
        );
    }

    freeUniforms(renderBatch) {
        if (!this.#batchedUniforms.getRange(renderBatch)) {
            return;
        }

        this.#batchedUniforms.freeUniformBufferRange(renderBatch);
        // Tell modelUniformUpdater that we don't need any GPU-side updates anymore
        // if fragments in this batch change.
        this.#unregisterRenderBatch(renderBatch);
    }

    /**
     * Prepares rendering of the given batch. It is required/assumed that the RenderBatch was uploaded before
     * using uploadBatchUniforms().
     * @param {RenderBatch} [renderBatch] - If not provided, we return the bindGroup for dynamic uniforms.
     * @returns {GPUBindGroup} - The bindGroup that needs to be set for rendering this batch
     */
    getBindGroup(renderBatch) {

        const modelId = renderBatch ? getModelId(renderBatch) : 0;

        let range;
        if (USE_DYNAMIC_UNIFORM_ALLOCATION) {
            range = this.#batchedUniforms.getRange(renderBatch);
        } else {
            const uniforms = this.modelUniforms.get(modelId);
            if (uniforms) {
                range = uniforms.getRange(renderBatch);
            }
        }

        // If we don't have pre-batched uniform, use the bindGroup for dynamic uniforms.
        if (!range) {
            // If we call without a RenderBatch or the RenderBatch doesn't provide pre-batched
            // uniforms, we  use bindGroup for dynamic uniforms. The state variables
            // are not needed and are just reset in this case.
            this.#currentRangeStartGPU = 0;
            this.#currentBatchStartIndex = 0;
            this.#currentModelId = 0;
            return this.#dynamicUniformsBindGroup;
        }

        // The Uniforms for the renderBatch are stored in a range within the GPU-side uniform buffer
        // Set the current rangeStart to the start of that range.
        this.#currentRangeStartGPU = range.startIndex;

        // Store the range startIndex. This is the objectIndex of the first object in the batch.
        this.#currentBatchStartIndex = renderBatch.start | 0;

        this.#currentModelId = modelId;

        // get bind group pointing to the bufffer that contains the uniforms for this batch
        const bufferIndex = range.bufferIndex;
        const bindGroup = this.#initBindGroup(modelId, bufferIndex);
        return bindGroup;
    }

    /**
     * Converts the objectIndex within a batch to the corresponding renderIndex in the GPU-side uniform buffer.
     * @param {number} objectIndex       - The loopIndex provided while rendering an object. While rendering a batch, the passed objectIndex values [batch.start, ..., batch.start.count]
     * @returns {number} The renderIndex - pointing to the corresponding entry in the GPU uniform buffer
     */
    getRenderIndex(objectIndex) {
        return objectIndex - this.#currentBatchStartIndex + this.#currentRangeStartGPU;
    }

    // @returns {GPUBindGroupLayout}
    getLayout() {
        return this.#bindGroupLayout;
    }

    // Adding a model before rendering ensures that...
    //  - ObjectUniformBuffers are created to store the object uniforms for this model in batched buffers.
    //  - A ModelUniformUpdater is created to make sure that changes on fragments (e.g. material)
    //    trigger updates of the corresponding GPU-side uniforms.
    addModel(model) {

        // For 2D models, we currently upload uniforms per-frame. No need to create uniform storage.
        if (model.is2d()) {
            return;
        }

        // Usually, the same model shoudn't be added twice.
        if (this.modelUniforms.get(model.id)) {
            console.warn("Adding the same model twice is unexpected.");
            return;
        }

        // The fragCount is currently required to pre-allocate batches. If size is 0, we skip this model.
        const fragCount = model.getFragmentList()?.fragments?.length | 0;
        if (fragCount === 0) {
            return;
        }

        // Per-model uniform storages and preallocation will not be needed anymore if we use dynamic uniform buffer management
        let uniformBuffer;
        if (USE_DYNAMIC_UNIFORM_ALLOCATION) {
            uniformBuffer = this.#batchedUniforms;
        } else {
            // Legacy path: Create per-model ObjectUniformBuffer with preallocated buffers

            // Create uniform storage
            uniformBuffer = new ObjectUniformBuffer(this.#device, this.#materialUniforms);
            this.modelUniforms.set(model.id, uniformBuffer);

            // Pre-allocate GPU buffers for all fragment uniforms
            uniformBuffer.preallocateModelBuffers(fragCount);
        }

        // Create UniformUpdater to handle fragment changes
        this.#modelUniformUpdaters.set(model.id, new ModelUniformUpdater(model, uniformBuffer, this.#renderer));

        // Create array to store bindGroups for this model
        this.#bindGroups[model.id] = [];
    }

    /**
     * Clean up object uniform data for a model.
     * @param {RenderModel} model The model to clean up resources for.
     */
    removeModel(model) {

        const modelKnown = this.#modelUniformUpdaters.has(model.id);
        if (!modelKnown) {
            // If we can't find any storage, this model was never
            // added and we can stop here.
            return;
        }

        // Disconnect any model-related callbacks
        const updater = this.#modelUniformUpdaters.get(model.id);

        // Note: When using OutOfCoreTileManager, we don't free anything here, because...
        //  a) This is responsibility of OutOfCoreTileManager
        //  b) Freeing it directly here would actually bypass the memory tracking of OutOfCoreTileManager
        if (USE_DYNAMIC_UNIFORM_ALLOCATION && !USE_OUT_OF_CORE_TILE_MANAGER) {
            // Use ModelUpdater to free all uniforms
            updater.freeAllUniforms();
        }

        // Old code path
        if (!USE_DYNAMIC_UNIFORM_ALLOCATION) {
            // Free GPU buffers
            const storage = this.modelUniforms.get(model.id);
            storage.dtor();
            this.modelUniforms.delete(model.id);
        }

        updater.dtor();

        // Remove map entries for this model
        this.#modelUniformUpdaters.delete(model.id);
    }

    /**
     * @returns {CommonMaterialUniforms} - Access to uniforms that are shared across all objects of a scene.
     */
    getCommonMaterialUniforms() {
        return this.#commonMaterialUniforms;
    }

    /**
     * @returns {ObjectUniformBuffer} - Access to the storage used for uniforms that are uploaded per frame.
     */
    getDynamicUniforms() {
        return this.#dynamicUniforms;
    }

    //
    // Legacy wrapper for compatibility with old ObjectUniforms.
    // Most of them just forward to the underlying handlers, so we can get rid of them after removing the old ObjectUniforms.
    //

    // Todo: This is just a legacy wrapper for compabibility with the old ObjectUniforms
    //       Actually, main pass could use dynamicUniforms directly.
    setOneObjectData(mesh, objectIndex) {
        this.#dynamicUniforms.setOneObjectCPU(mesh, objectIndex);
    }

    // Update material for a single object (if changed). Only used for scenes that don't have pre-uploaded uniform batches.
    setOneMaterialData(material, materialTextureMask) {
        this.#materialUniforms.updateMaterial(material, materialTextureMask);
    }

    setOneMaterialData2D(material) {
        this.#materialUniforms.updateMaterial2D(material);
    }

    // Flush dynamic uniforms to GPU after setting items [0,...itemsAdded-1] via setOneObjectData.
    writeToQueue(itemsAdded) {
        // BufferIndex is always 0, because dynamic uniforms don't need multiple buffers.
        const bufferIndex = 0;
        this.#dynamicUniforms.writeToQueue(itemsAdded, bufferIndex);
    }


    resetUpdateHeuristic(modelId) {
        this.#modelUniformUpdaters.get(modelId)?.resetUpdateHeuristic();
    }

    setEdgeColorInt(color) {
        this.#commonMaterialUniforms.setEdgeColorInt(color);
    }

    setDoNotCutOverride(value) {
        this.#commonMaterialUniforms.setDoNotCutOverride(value);
    }

    initMaterialUpdateHook(material, materialTextureMask) {
        this.#materialUniforms.initMaterialUpdateHook(material, materialTextureMask);
    }

    // Direct override of a materialReference for a single object.
    // Used when rendering overlays with override materials.
    // Note that this only sets the value CPU side but doesn't upload yet
    setMaterialReference(objectIndex, material) {
        const materialRef = this.#materialUniforms.acquireMaterial(material);
        const writer = this.#dynamicUniforms.getUniformBuilder();
        writer.setMaterialReference(objectIndex, materialRef);
    }

    /**
     * @returns {number} Total byteSize on GPU. Note that we track actually used memory, not including extra memory due to paging.
     */
    byteSize() {
        const batchUniformBytes = USE_DYNAMIC_UNIFORM_ALLOCATION ? this.#batchedUniforms.byteSize() : this.modelUniforms.reduce((acc, buffer) => acc + buffer.byteSize(), 0);
        const dynamicUniformsBytes = this.#dynamicUniforms.byteSize();
        const materialUniformBytes = this.#materialUniforms.byteSize();
        const commonMaterialUniforms = this.#commonMaterialUniforms.byteSize();
        return batchUniformBytes + dynamicUniformsBytes + materialUniformBytes + commonMaterialUniforms;
    }

    /**
     * Set the object data for a range of objects by getting the data from an instanced mesh.
     *  @param {THREE.Mesh} instancedMesh
     *  @param {number}     itemOffset
     */
    setObjectDataFromInstanceBuffer(instancedMesh, itemOffset) {
        this.#dynamicUniforms.setObjectDataFromInstanceBuffer(instancedMesh, itemOffset);
    }

    /**
     * Todo: This is only for backward compatibility to keep the index remapping for ConsolidatedRenderBatch working.
     *       We should remove this when we get rid of ConsolidationIterator.
     *
     * Given a fragmentId, this method finds the index needed to find the uniforms of this fragment in the
     * batched object uniform buffer. Note that this lookup is not needed if the objectIndex matches
     * with the order of the fragments in the current RenderBatch.
     * Using the function requires that ObjectUniforms has already been initialized for the currently used model iterator.
     *
     * @param {number} fragId
     * @returns {number} The index needed to find the uniforms for this fragId in the batched object uniform buffer.
     */
    getObjectIndex(fragId) {
        const updater = this.#modelUniformUpdaters.get(this.#currentModelId);
        const gpuIndex = updater.getGPUArrayIndex(fragId);
        const objectIndex = gpuIndex - this.#currentRangeStartGPU + this.#currentBatchStartIndex;
        return objectIndex;
    }

    //
    // Private methods
    //

    #initBindGroupLayout() {
        this.#bindGroupLayout = this.#device.createBindGroupLayout({
            entries: [
                {
                    binding: 0,
                    visibility: GPUShaderStage.VERTEX | GPUShaderStage.FRAGMENT,
                    buffer: {
                        type: "read-only-storage"
                    }
                },
                {
                    binding: 1,
                    visibility: GPUShaderStage.VERTEX | GPUShaderStage.FRAGMENT,
                    buffer: {
                        type: "read-only-storage"
                    }
                },
                {
                    binding: 2,
                    visibility: GPUShaderStage.FRAGMENT,
                    buffer: {
                        type: "uniform"
                    }
                },
            ]
        });
    }

    #createBindGroup(
        objectUniformsBuffer // GPUBuffer
    ) {
        return this.#device.createBindGroup({
            layout: this.#bindGroupLayout,
            entries: [
                {
                    binding: 0,
                    resource: {
                        buffer: objectUniformsBuffer
                    },
                },
                {
                    binding: 1,
                    resource: {
                        buffer: this.#materialUniforms.getGPUBuffer()
                    },
                },
                {
                    binding: 2,
                    resource: {
                        buffer: this.#commonMaterialUniforms.getGPUBuffer()
                    },
                }
            ],
        });
    }

    // After upload, a RenderBatch must be registered at the modelUniformUpdater. This is needed to
    // track for each fragment where to find its uniform in the UniformBuffer.
    #registerRenderBatch(renderBatch) {
        const modelId = getModelId(renderBatch);
        const updater = this.#modelUniformUpdaters.get(modelId);
        updater.registerRenderBatch(renderBatch);
    }

    /**
     * After a RenderBatch is no longer needed, it must be unregistered at the modelUniformUpdater.
     * @param {RenderBatch} renderBatch
     */
    #unregisterRenderBatch(renderBatch) {
        const modelId = getModelId(renderBatch);
        const updater = this.#modelUniformUpdaters.get(modelId);
        updater.unregisterRenderBatch(renderBatch);
    }

    /**
     * Returns the bindGroup for the given modelId/bufferIndex combination.
     * If the Buffer exists, the bindGroup is created on-demand.
     *
     * @returns {GPUBindGroup|null} - The bind group providing using the specified ObjectUniform buffer.
     */
    #initBindGroup(modelId, bufferIndex) {

        const buffer = this.#getGPUBuffer(modelId, bufferIndex);
        if (!buffer) {
            console.error("TODO: Specified UniformBuffer does not exist.");
            return null;
        }

        // Make sure that a bindGroup exist for this UniformBuffer
        let bindGroup = this.#bindGroups[modelId][bufferIndex];
        if (!bindGroup) {
            bindGroup = this.#createBindGroup(buffer);
            this.#bindGroups[modelId][bufferIndex] = bindGroup;
        }

        return bindGroup;
    }

    #getGPUBuffer(modelId, bufferIndex) {
        if (USE_DYNAMIC_UNIFORM_ALLOCATION) {
            return this.#batchedUniforms.getGPUBuffer(bufferIndex);
        }
        return this.modelUniforms.get(modelId).getGPUBuffer(bufferIndex);
    }
}
