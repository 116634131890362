import * as EventTypes from "../../../application/EventTypes";
import { MODEL_CONSOLIDATION_ENABLED } from '../../../application/PrivateEventTypes';
import { BvhNode } from './BvhNode';
import { GPU_MEMORY_LIMIT, USE_WEBGPU, INCREMENTAL_CONSOLIDATION } from "../../globals";
import { analytics } from "../../../analytics/index";
import { getDefaultStageConfiguration, StageNames, ResourceType } from "./tasks/defaultStageConfiguration";

// Binary search for insertion function that uses a sort compatible compare function
const sortedInsertPosition = (array, value, compare) => {
    let low = 0;
    let high = array.length;

    while (low < high) {
        let mid = (low + high) >>> 1;
        if (compare(array[mid], value) < 0) {
            low = mid + 1;
        } else {
            high = mid;
        }
    }

    return low;
};

export class Stage {
    /** @type {OutOfCoreTileManagerStageConfiguration} */
    config;
    /** @type {BvhNode[]} */
    nodes = [];
    /** @type {BvhNode[]} */
    inAsyncPromotion = [];
    /** @type {Stage} */
    nextStage;
    /** @type {Stage} */
    previousStage;
    /** @type {number} Index in the stages array */
    index;

    constructor(config) {
        this.config = config;
    }
}

/** @import { RenderModel } from "../RenderModel" */
/** @import { Viewer3DImpl } from "../../../application/Viewer3DImpl" */
/** @import { ModelIteratorBVH } from "../ModelIteratorBVH" */
/** @import { WebGLRenderer } from "../../render/WebGLRenderer" */

/**
 * @typedef {Object} OutOfCoreTileManagerStageConfiguration
 *
 * @property {StageNames} stage                         - The name of the stage
 * @property {number}     screenSpaceErrorScalingFactor - Scaling factor for the screenspace errors.
 *                                                        Note: these MUST be monotonically decreasing, as otherwise
 *                                                              nodes might get stuck in upper stages.
 * @property {Function[]} taskInitializationFunctions   - Functions to create new tasks when the stage has been reached
 * @property {ResourceType} resource                    - The resource that is used by a node when it gets promoted into this stage
 */

/**
 * @typedef {Object} ActivePromotion
 *
 * @property {BvhNode} node
 * @property {Promise[]} taskCompletionPromises - Promises to be waited for until the node has completed the promotion
*/

/**
 * @typedef {Function} OneShotTaskFunction
 * A function that is executed once during task processing
 *
 * @returns {boolean} - Whether the task has been completed
 */

/**
 * Class responsible for managing the resources for the BVH tiles
 *
 * @alias Autodesk.Viewing.Private.OutOfCoreTileManager
 * @private
 */
export class OutOfCoreTileManager {
    // Global data structures that are shared across all viewer instances and models

    /** @type {Map<ResourceType, { consumed: number, limit: number }} */
    static #resources = new Map();

    static #stats = {
        promotedThisFrame: 0,
        demotedThisFrame: 0,
        promotedLastSecond: 0,
        demotedLastSecond: 0,
        promotedThisSecond: 0,
        demotedThisSecond: 0
    };

    static #statsUpdateTimer;

    /** @type {Set<OutOfCoreTileManager>} Out of core tile managers that have not yet received a viewer.addModel event */
    static notYetAddedManagers = new Set();

    /** @type {Array<BvhNode>} BVHNodes that are still waiting for geometries from the server */
    static pendingNodes = [];

    /** @type {Map<LeanBufferGeometry, number>} */
    static geomRefCounts = new Map();

    /** @type {Map<Model|string, OutOfCoreTileManager} */
    static modelManagerRegistry = new Map();

    /** @type {{priority: (Number|BvhNode), task: OneShotTaskFunction}[]} */
    static oneShotTasks = [];

    /** @type {Function[]} */
    static recurringTasks = [];

    static onlyOneShotTasksExecuted = false;

    // Instance data structures that are specific to a model
    #frameCounts = [];

    /** @type {RenderModel} */
    model;

    /** @type {Array<BvhNode>} can be sparse! */
    bvhNodes = [];

    /** @type {Map<number, Set<number>>} */
    iteratorLockedTiles = new Map();

    /** @type {Viewer3DImpl[]} */
    #viewers = [];

    /** @type {ModelIteratorBVH[]} */
    iteratorIdRegistry = [];

    /** @type {Stage[]} - these are shared between OOCTMs with the same config */
    stages;

    /** @type {Map<string, Stage>} - Maps from stage name to stage object */
    stagesByName = new Map();

    /** @type {Number} - start time stamp when model was created */
    static #t0_ModelCreation = undefined;

    /** @type {Number} - end time stamp for GPU upload reaching the memory limit if so */
    static #t1_GPUUpload = undefined;

    /** @type {Map<OutCoreTileManagerStageConfiguration, Stage[]>} */
    static _stagesByConfig = new Map();

    /** @type {Stage[]} - flat _stagesByConfig for easier iterating */
    static _allStages = [];

    /** @type {Map<ResourceType, Stage[]>} */
    static _stagesByResourceForDemotion = new Map();

    /** @type {ActivePromotion} The currently active promotion */
    static currentPromotion = undefined;

    /** @type {number} timeout to call executeTasks again after addOneShotTask was called */
    static resumeExecutingTasksTimeout = undefined;

    /** @type {number} The most recently used deadline. Used by resumeExecutingTasksTimeout */
    static lastDeadline = undefined;

    /**
     * Creates a new OutOfCoreTileManager
     * @param {RenderModel} model - The model for which this tile manager is responsible
     * @param {OutOfCoreTileManagerStageConfiguration[]} [stageConfiguration] - The configuration for the stages
     */
    constructor(model, stageConfiguration = getDefaultStageConfiguration()) {
        this.model = model;

        this.stages = OutOfCoreTileManager.#registerStageConfig(stageConfiguration);

        this.stages.forEach(stage => this.stagesByName.set(stage.config.stage, stage));

        OutOfCoreTileManager.setResourceLimit(ResourceType.GPU_MEMORY, 0.9 * GPU_MEMORY_LIMIT);
    }

    /**
     * Returns the BVH node for the given node index.
     *
     * @param {number} nodeIdx
     * @returns {BvhNode}
     */
    getBvhNode(nodeIdx) {
        return this.bvhNodes[nodeIdx];
    }

    /**
     * Initialized the BVH node for the given node index (it it doesn't already exist).
     * If a new node ist created, it will be assigned to the first stage.
     *
     * @param {number} nodeIdx - Index of the node
     * @param {boolean} transparent - is this node transparent?
     * @returns {BvhNode}
     */
    initializeBvhNode(nodeIdx, transparent) {
        let node = this.bvhNodes[nodeIdx];
        if (!node) {
            node = new BvhNode(nodeIdx, this.model, this, this.stages[0], transparent);
            this.bvhNodes[nodeIdx] = node;

            // Add the node at the correct position in the new stage
            let nodesInNewStage = node.currentStage.nodes;
            nodesInNewStage.push(node);
        }

        return node;
    }

    /**
     * Checks, whether the given BVH node has already been fully initialized
     * @param {number} bvhNodeId
     * @returns {boolean}
     */
    bvhNodeConsolidationComputed(bvhNodeId) {
        return this.bvhNodes[bvhNodeId]?.consolidationComputed;
    }

    /**
     * Adds the geometry for this scene to pendingNodes array to be requested via the loader.
     *
     * @param {number} bvhNodeId - The ID of the node
     */
    requestGeometryForNode(bvhNodeId) {
        const node = this.getBvhNode(bvhNodeId);
        OutOfCoreTileManager.pendingNodes.push(node);
    }

    /**
     * Releases an iterator, if it is no longer used (when the consolidation iterator that
     * references it is being destroyed or the model is removed from the viewer)
     * @param {number} iteratorId
     */
    releaseIterator(iteratorId) {
        this.resetScreenSpaceErrors(iteratorId);
        this.resetLockedTiles(iteratorId);
        this.iteratorIdRegistry[iteratorId] = undefined;

        if (this.iteratorIdRegistry.every(id => id === undefined)) {
            this.freeAllNodes();
        }
    }

    /**
     * Reactivate the iterator at the given iterator ID (when a consolidation iterator is being recreated)
     * @param {number} iteratorId
     * @param {ModelIteratorBVH} iterator
     */
    activateIterator(iteratorId, iterator) {
        this.iteratorIdRegistry[iteratorId] = iterator;
    }

    /**
     * Frees the all resources for all nodes in the OutOfCoreTileManager
     * @param {number} iteratorId - The ID of the iterator
     */
    freeAllNodes() {
        this.bvhNodes.forEach(node => { // forEach skips empty slots
            // Free resources used by the node
            let freedResources = node.freeAllResources();
            for (let [resourceType, freedAmount] of Object.entries(freedResources)) {
                OutOfCoreTileManager.#resources.get(resourceType).consumed -= freedAmount;
            }

            // Delete all existing tasks
            node.stageTasks = new Map();

            // Reset the node to the first stage
            while (node.currentStage && node.currentStage.previousStage) {
                node.currentStage = node.currentStage.previousStage;
            }


            // Reset the initialized flag
            node.consolidationComputed = false;
        });

        // Remove the nodes from global node lists
        for (let stage of this.stages) {
            stage.nodes = stage.nodes.filter(node => node.outOfCoreTileManager !== this);
            stage.inAsyncPromotion = stage.inAsyncPromotion.filter(node => node.outOfCoreTileManager !== this);
        }
        OutOfCoreTileManager.pendingNodes = OutOfCoreTileManager.pendingNodes.filter(node => node.outOfCoreTileManager !== this);

        if (OutOfCoreTileManager.currentPromotion?.node?.outOfCoreTileManager === this) {
            OutOfCoreTileManager.currentPromotion = undefined;
        }
    }

    /**
     * Updates the cost and transparency flag of a BVH node.
     *
     * @param {number} iteratorId - The ID of the iterator.
     * @param {number} bvhNodeId - The ID of the BVH node.
     * @param {number} cost - The cost to update the BVH node with.
     */
    updateBvhNodeScreenSpaceError(iteratorId, bvhNodeId, cost) {
        let node = this.getBvhNode(bvhNodeId);

        node.updateScreenSpaceError(iteratorId, cost, this.getFrameCount(iteratorId));
    }

    /**
     * Removes the locked flag from all tiles. This should only be called,
     * once all candidate renderbatches have been cleared and therefore
     * no old consolidated mesh is any longer referenced.
     *
     * @param {number} iteratorId -- The id of the iterator
     */
    resetLockedTiles(iteratorId) {
        let lockedTiles = this.iteratorLockedTiles.get(iteratorId);
        if (lockedTiles) {
            for (let tileId of lockedTiles) {
                this.unlockTile(iteratorId, tileId);
            }
        }
    }

    /**
     * Unlocks a tile corresponding to the given nodeId
     * @param {number} iteratorId -- The id of the iterator
     * @param {number} bvhNodeId -- The id of the node in the BVH
     */
    unlockTile(iteratorId, bvhNodeId) {
        let node = this.getBvhNode(bvhNodeId);

        let lockedTiles = this.iteratorLockedTiles.get(iteratorId);
        if (lockedTiles.delete(bvhNodeId)) {
            node.lockedCounter--;
        }
        console.assert(lockedTiles && node.lockedCounter >= 0);
    }

    /**
     * Locks a tile corresponding to the given nodeId. While tiles are locked
     * they cannot be demoted.
     *
     * The reason this is needed is, that the RenderScene keeps render batches across frames
     * while doing progressive rendering and we must not free the resources hold by one of these
     * cached batches. Only after the batch has been rendered or the render scene has been
     * reset can we free the resources.
     *
     * @param {number} iteratorId -- The id of the iterator
     * @param {number} bvhNodeId -- The id of the node in the BVH
     */
    lockTile(iteratorId, bvhNodeId) {
        let node = this.getBvhNode(bvhNodeId);
        node.lockedCounter++;

        let lockedTiles = this.iteratorLockedTiles.get(iteratorId);
        if (!lockedTiles) {
            lockedTiles = new Set();
            this.iteratorLockedTiles.set(iteratorId, lockedTiles);
        }
        lockedTiles.add(bvhNodeId);
    }

    /**
     * Increment the frame count
     */
    incrementFrameCount(iteratorId) {
        this.#frameCounts[iteratorId] = (this.#frameCounts[iteratorId] ?? 0) + 1;
    }

    /**
     * Resets the screen space errors in all nodes for the given iterator
     * @param {number} iteratorId - The ID of the iterator
     */
    resetScreenSpaceErrors(iteratorId) {
        // Reset the screen space errors for all nodes
        this.bvhNodes.forEach(node => { // forEach skips empty slots
            node.updateScreenSpaceError(iteratorId, -Infinity, 0);
            node.updateLastRendered(iteratorId, 0);
        });
    }

    /**
     * Obtains the ID for the given model iterator
     * @param {ModelIteratorBVH} iterator
     * @param {boolean} doNotCreate - Whether to create a new ID if it does not exist
     * @returns
     */
    getIteratorId(iterator, doNotCreate = false) {
        let ids = this.iteratorIdRegistry;

        // Do we already have an entry for the model?
        let index = ids.indexOf(iterator);
        if (index !== -1) {
            return index;
        }

        if (doNotCreate) {
            return undefined;
        }

        // If not, insert the model in the next free slot
        for (let i = 0; true; i++) {
                if (ids[i] === undefined) {
                        ids[i] = iterator;
                        return i;
                }
        }
    }

    /**
     * Assign a viewer to the OutOfCoreTileManager
     * @param {Viewer3DImpl} viewer - The viewer instance to use for this OutOfCoreTileManager
     */
    #addViewer(viewer) {
        this.#viewers.push(viewer);
    }

    /**
     * Removes a viewer from the OutOfCoreTileManager
     * @param {Viewer3DImpl} viewer - The viewer instance to use for this OutOfCoreTileManager
     * @param {RenderModel} model - The model which is being removed
     */
    #removeViewer(viewer, model) {
        const bvhIterator = model.getIterator();
        const iteratorId = this.iteratorIdRegistry.indexOf(bvhIterator);
        if (iteratorId !== -1) {
            this.releaseIterator(iteratorId);
        }

        // The viewer must be removed last, as releasing the iterator might cause nodes to be freed.
        // In case a UniformUploadTask needs to be freed it still needs the viewer to do that.
        let viewerIndex = this.#viewers.indexOf(viewer);
        if (viewerIndex !== -1) {
            this.#viewers.splice(viewerIndex, 1);
        }
    }

    /**
     * Returns a viewer instance for this OutOfCoreTileManager. There might be multiple viewers
     * associated to this OutOfCoreTileManager if those instances share resources. In such a case,
     * we return the first viewer instance.
     *
     * @returns {Viewer3DImpl} - The viewer instance
     */
    getViewer() {
        return this.#viewers[0];
    }

    /**
     * Get the Renderer associated with this OutOfCoreTileManager
     * @returns {WebGLRenderer} - The renderer instance
     */
    getRenderer() {
        return this.#viewers[0].glrenderer();
    }

    /**
     * Returns the next batch of geometries to be requested from the server.
     * @returns {{hashes: Set<String>}|null, callback: Function} - Hashes to be requested and callback for finished requests or null if none needed
     */
    static getNextBVHNodeToPrefetchGeometryFor(hash2Requests) {
        OutOfCoreTileManager.pendingNodes.sort(
            (a, b) => {
                // sort nodes of unconsolidated models to the back
                if (!a.model.isConsolidated()) return 1;
                if (!b.model.isConsolidated()) return -1;
                return a.compare(b);
            }
        );

        while (OutOfCoreTileManager.pendingNodes.length > 0) {

            const node = OutOfCoreTileManager.pendingNodes.shift();

            const model = node.outOfCoreTileManager.model;

            // For the moment we still wait here for the consolidation to be available. This is not strictly
            // needed here, but this will make sure we initially only load the fraglists and thus make sure
            // that the consolidation is computed as fast as possible. TODO: we should remove this as soon
            // as incremental consolidation is available.
            if (!INCREMENTAL_CONSOLIDATION) {
                const consolidation = model.getConsolidation();
                if (!consolidation && !USE_WEBGPU) { // not yet consolidated (we don't care for WebGPU)
                    OutOfCoreTileManager.pendingNodes.push(node);
                    return null;
                }
            }

            const fragList = model.getFragmentList();
            const bvhModelIterator = model.getIterator();
            const renderBatch = bvhModelIterator.getGeomScenes()[node.nodeId];
            const renderBatchIndices = renderBatch.getIndices();

            const missingHashes = new Set();
            let cannotConsolidate = false;

            for (let i = renderBatch.start; i < renderBatch.lastItem; i++) {

                const fragId = renderBatchIndices[i];

                // TODO If a fragment is set to MESH_NOTLOADED, its geometry will never be loaded.
                // So we shouldn't request this from the OtgResourceCache, and there is nothing to wait for,
                // but at the moment this also means we cannot consolidate the whole node.
                if (fragList.isNotLoaded(fragId)) {
                    cannotConsolidate = true;
                    continue;
                }

                const geomId = fragList.getGeometryId(fragId);
                if (geomId !== 0 && !fragList.geoms.hasGeometry(geomId)) {
                    let hash = model.loader.svf.getGeometryHash(geomId);

                    // It can happen, that the BVH is already initialized and ready to provide batches
                    // to the loader, before the request objects in the loader have been initialized.
                    // Specifically, if there is a selective loading controller active, it can delay the
                    // initialization of fragments and then the requests will not be created. In such a case,
                    // we delay the requests via the batch loading mechanism until the requests for the hashes
                    // have been created.
                    if (!hash2Requests.has(hash)) {
                        OutOfCoreTileManager.pendingNodes.push(node);
                        return null;
                    }
                    missingHashes.add(hash);
                }
                // materials are always requested immediately when loading the fraglist, but we still need to wait for them.
                if (!fragList.hasMaterial(fragId)) {
                    // The loader might already have the material, but it's not set on the fragment until it's activated.
                    // So we have to check pendingMaterials.
                    const materialId = model.loader.svf.fragments.materials[fragId];
                    const hash = model.loader.svf.getMaterialHash(materialId);
                    if (model.loader.pendingMaterials.has(hash)) {
                        missingHashes.add(hash);
                    }
                }
            }

            if (missingHashes.size === 0 && !cannotConsolidate) {
                node.onNodeGeometryLoaded();
                continue;
            }

            let numMissingHashes = missingHashes.size;
            return {
                hashes: missingHashes,
                callback: (result) => {
                    if (!result) {
                        // A required geom or material failed to load. Don't try consolidating,
                        // because the tile processing code doesn't expect missing geom or materials.
                        cannotConsolidate = true;
                    } else if (--numMissingHashes === 0 && !cannotConsolidate) {
                        node.onNodeGeometryLoaded();
                    }
                }
            };
        }
        return null;
    }

    /**
     * Adds a one shot function to execute during the task processing
     *
     * @param {OneShotTaskFunction} task - The function to execute for the global task
     * @param {number|BvhNode} [priority=Infinity] - The priority of the task. If a BvhNode is given, the screenspace error of that node is used as priority.
     * @param {boolean} [resumeExecutingTasks=true] - Whether to call executeTasks after the task has been queued (if there's still time budget available).
     *                                                This should be used if calling addOneShotTask outside of the task processing loop, so that the task
     *                                                doesn't have to wait until the next tick.
     */
    static addOneShotTask(task, priority = Infinity, resumeExecutingTasks = true) {
        OutOfCoreTileManager.oneShotTasks.push({ priority, task });

        if (!resumeExecutingTasks || OutOfCoreTileManager.resumeExecutingTasksTimeout) {
            return;
        }
        OutOfCoreTileManager.resumeExecutingTasksTimeout = setTimeout(() => {
            OutOfCoreTileManager.resumeExecutingTasksTimeout = undefined;
            if (performance.now() < OutOfCoreTileManager.lastDeadline) {
                OutOfCoreTileManager.executeTasks(this.lastDeadline);
            }
        }, 0);
    }

    /**
     * Adds a task that is run every tick and that is not cleared. Use for fast tasks only.
     * @param {Function} task
     */
    static addRecurringTask(task) {
        OutOfCoreTileManager.recurringTasks.push(task);
    }

    /**
     * Removes a recurring task.
     * @param {Function} task
     */
    static removeRecurringTask(task) {
        const index = OutOfCoreTileManager.recurringTasks.indexOf(task);
        if (index !== -1) {
            OutOfCoreTileManager.recurringTasks.splice(index, 1);
        }
    }

    static _executeOneShotTasks(deadline) {
        OutOfCoreTileManager.oneShotTasks.sort((a, b) => {
            let priorityA = a.priority instanceof BvhNode ? a.priority.getCurrentScreenSpaceError() : a.priority;
            let priorityB = b.priority instanceof BvhNode ? b.priority.getCurrentScreenSpaceError() : b.priority;
            return priorityB - priorityA;
        });
        while (OutOfCoreTileManager.oneShotTasks.length > 0) {
            let { task } = OutOfCoreTileManager.oneShotTasks[0];
            let completed = task(deadline);

            if (completed) {
                OutOfCoreTileManager.oneShotTasks.shift();
            }

            if (performance.now() >= deadline) {
                OutOfCoreTileManager.onlyOneShotTasksExecuted = true;
                return true;
            }
        }
        return false;
    }


    /**
     * Performs a promotion of a node to the next stage
     *
     * @param {ActivePromotion} promotion - The promotion to perform.
     * @param {number} deadline - The deadline for the current task processing
     * @returns {boolean} - Whether more tasks can be processed
     */
    static processPromotion(promotion, deadline) {
        const { node, taskCompletionPromises } = promotion;

        while (performance.now() < deadline) {
            // Check whether the next task can be executed this tick
            let nextTask = node.getNextTask();
            if (nextTask && !nextTask.canBeExecutedThisTick()) {
                return false;
            }

            let [resourceCost, moreTasks, taskCompletionPromise] = node.processNextTask(deadline);
            OutOfCoreTileManager.#resources.get(node.getResourceType(true)).consumed += resourceCost;
            const nodeCompleted = !moreTasks;

            if (taskCompletionPromise) {
                taskCompletionPromises.push(taskCompletionPromise);
            }

            if (nodeCompleted) {
                OutOfCoreTileManager.#moveNodeToNewStage(
                    node,
                    true,
                    taskCompletionPromises,
                );
                return true;
            }
        }

        return false;
    }

    static getPromotionCandidateForStage(alreadyProcessedResources, stage) {
        let candidate = stage.nodes[stage.nodes.length - 1];
        if (!candidate) {
            return;
        }

        // No more promotions for this node possible
        if (!candidate.getNextStage()) {
            return;
        }

        // We no longer want any candidates for this resource. We already tried freeing it and failed
        if (alreadyProcessedResources.has(candidate.getResourceType(true))) {
            return;
        }


        // Are there already too many nodes currently waiting for the promotion to the next stage?
        if (candidate.currentStage.inAsyncPromotion.length >= (INCREMENTAL_CONSOLIDATION ? 10 : 50000)) {
            return;
        }

        // Make sure, all tasks on the candidate node are initialized
        candidate.initializeTasksForPromotion();

        return candidate;
    }

    /**
    * Run tasks until the deadline.
    * @param {Number} deadline - when to stop processing tasks.
    */
    static executeTasks(deadline) {

        for (const task of OutOfCoreTileManager.recurringTasks) {
            task(deadline);
        }

        // I don't really know how to best prioritize global tasks
        // compared to upload tasks yet. So for the moment, I just
        // have a heuristic, that if in the last frame we didn't
        // have any time to process upload tasks, we will skip
        // global tasks for this frame.
        if (!OutOfCoreTileManager.onlyOneShotTasksExecuted) {
            let outOfTime = OutOfCoreTileManager._executeOneShotTasks(deadline);
            if (outOfTime) {
                return;
            }
        }
        OutOfCoreTileManager.onlyOneShotTasksExecuted = false;

        // Don't start processing events if models have been loaded, but not yet added to the viewer
        // The OutOfCoreTileManager for these models have not yet been fully initialized
        if (OutOfCoreTileManager.notYetAddedManagers.size > 0) {
            return;
        }

        OutOfCoreTileManager.#stats.promotedThisFrame = 0;
        OutOfCoreTileManager.#stats.demotedThisFrame = 0;

        // Make sure the nodes in all stages are sorted
        for (let stage of OutOfCoreTileManager._allStages) {
            stage.nodes.sort(
                (a, b) => {
                    return -a.compare(b);
                }
            );
        }

        // The amount of resources left can become negative, if the resource limit is reduced and
        // not all resources could be freed instantly, because of locked nodes. In that case, we
        // try to free the resources here
        for (let resourceType of OutOfCoreTileManager.#resources.keys()) {
            OutOfCoreTileManager.freeResourcesBelowLimit(resourceType, deadline);
        }

        let alreadyProcessedResources = new Set();
        while (true) {
            // Continue an ongoing promotion
            if (OutOfCoreTileManager.currentPromotion) {
                let done = OutOfCoreTileManager.processPromotion(OutOfCoreTileManager.currentPromotion, deadline);

                if (!done) {
                    break; // this will also break in the case of a timeout
                }

                // Promotion has completed
                OutOfCoreTileManager.currentPromotion = undefined;
            }

            // If we still have time left, start the next promotion
            if (performance.now() > deadline) {
                // out of time
                break;
            }

            // Find the next node to promote
            let bestCandidate = null;
            for (let stage of OutOfCoreTileManager._allStages) {
                const candidate = OutOfCoreTileManager.getPromotionCandidateForStage(alreadyProcessedResources, stage);
                if (candidate) {
                    if (!bestCandidate || candidate.compare(bestCandidate, candidate.getNextStage(), bestCandidate.getNextStage()) < 0) {
                        bestCandidate = candidate;
                    }
                }
            }

            // Stop if no more work needs to be done
            if (bestCandidate === null) {
                break;
            }

            // Attempt to free resource if necessary
            let resourceAvailable = OutOfCoreTileManager.freeResourcesBelowLimit(bestCandidate, deadline);
            if (!resourceAvailable) {
                // Try again with nodes that require other resource types
                alreadyProcessedResources.add(bestCandidate.getResourceType(true));
                continue;
            }

            OutOfCoreTileManager.currentPromotion = {
                node: bestCandidate,
                taskCompletionPromises: []
            };
        }

        // If we don't have any other work and still time left, check whether there are still global tasks we skipped
        if (performance.now() < deadline) {
            OutOfCoreTileManager._executeOneShotTasks(deadline);
        }
        this.lastDeadline = deadline;
    }

    /**
     * Tries to free enough resources to satisfy the resource limits. If a comparison node is provided, it will try to free
     * enough memory to enable promoting that node to the next stage. If not enough of the associated resource is available
     * we will free other nodes with a lower weighted screen space error that consume the same resource than the comparison node.
     *
     * @param {BvhNode|ResourceType} comparisonNodeOrResourceType - This can either be a resource type or a node
     *                                        * If it is  a a node, we will try to free sufficient resources
     *                                          to enable promoting this node to the next stage. We will only
     *                                          free other resources, if the screen space error of those nodes
     *                                          is smaller than the error for the comparison node.
     *                                        * If it is a resource type, we will free resources until the
     *                                          limit for that resource is satisfied, not checking for any
     *                                          screen space error.
     * @param {number} deadline             - The deadline for the current task processing
     *
     * @returns {boolean} - Whether the required resources are available
     */
    static freeResourcesBelowLimit(comparisonNodeOrResourceType, deadline) {
        let comparisonNode = null;
        let resourceTypeToFree;
        let resourceTarget;

        if (comparisonNodeOrResourceType instanceof BvhNode) {
            comparisonNode = comparisonNodeOrResourceType;
            resourceTypeToFree = comparisonNode.getResourceType(true);
            resourceTarget = OutOfCoreTileManager.#resources.get(resourceTypeToFree).limit - comparisonNode.getRemainingResourceCost();
        } else {
            resourceTypeToFree = comparisonNodeOrResourceType;
            resourceTarget = OutOfCoreTileManager.#resources.get(resourceTypeToFree).limit;
        }

        if (OutOfCoreTileManager.#resources.get(resourceTypeToFree).consumed <= resourceTarget) {
            return true;
        }

        // Report the first time we reach the GPU memory limit
        if (resourceTypeToFree === ResourceType.GPU_MEMORY) {
            OutOfCoreTileManager.#trackGPUOutOfCore(comparisonNode?.model);
        }

        // Try to find enough nodes that can be demoted to free the resources
        // needed for the comparison node
        let stagesForDemotion = OutOfCoreTileManager._stagesByResourceForDemotion.get(resourceTypeToFree);
        while (OutOfCoreTileManager.#resources.get(resourceTypeToFree).consumed > resourceTarget && performance.now() < deadline) {

            // Insert candidates from all stages
            let bestCandidate = undefined;
            for (let stage of stagesForDemotion) {
                let candidateNode = stage.nodes[0];

                if (!candidateNode) continue;

            // Do we have a valid previous stage?
                if (!candidateNode.getPreviousStage()) continue;

                // If candidate and comparisonNode are the same, we don't need to compare them
                // because freeing and again uploading the same node wouldn't make any sense
                if (candidateNode === comparisonNode) continue;

                // Ignore locked nodes
                if (candidateNode.lockedCounter !== 0) continue;

                let stageWithSameResource = candidateNode.currentStage;
                while (stageWithSameResource.config.resource !== resourceTypeToFree) {
                    stageWithSameResource = stageWithSameResource.previousStage;
                }

                // If a comparison node is given, does the node have a higher screen space error?
                if (comparisonNode === null ||
                    candidateNode.compare(comparisonNode, stageWithSameResource, comparisonNode.getNextStage()) > 0) {

                    // Check whether this is best candidate available
                    if (bestCandidate === undefined ||
                        (bestCandidate.compare(candidateNode) < 0)) {
                        bestCandidate = candidateNode;
                    }
                }
            }

            if (!bestCandidate) {
                // No more candidates available
                break;
            }

            // Free the resources for the candidate node
            OutOfCoreTileManager.#moveNodeToNewStage(bestCandidate, false);
        }

        return OutOfCoreTileManager.#resources.get(resourceTypeToFree).consumed <= resourceTarget;
    }

    /**
     * Demote the node to the target stage or don't do anything if the node is already in the target or earlier stage
     * @param {BvhNode} node
     * @param {Stage} targetStage
     */
    demoteToStage(node, targetStage) {
        while (node.currentStage.index > targetStage.index) {
            OutOfCoreTileManager.#moveNodeToNewStage(node, false);
        }
    }

    /**
     * Sets the limit for a resource type.
     *
     * If necessary, it will free the requested amount of the given resource type (however, this might fail, if nodes are locked)
     *
     * @param {ResourceType} resourceType - The resource type to set the limit for
     * @param {number} value - The new resource limit value.
     */
    static setResourceLimit(resourceType, value) {
        let currentResources = this.#resources.get(resourceType) ?? { consumed: 0, limit: 0 };
        currentResources.limit = value;
        this.#resources.set(resourceType, currentResources);

        OutOfCoreTileManager.freeResourcesBelowLimit(resourceType, Infinity);
    }

    /**
     * Returns the frame count for the specified iterator ID.
     * @param {string} iteratorId - The ID of the iterator.
     * @returns {number} The frame count for the specified iterator ID.
     */
    getFrameCount(iteratorId) {
        return this.#frameCounts[iteratorId] ?? 0;
    }

    /**
     * Get the statistics of the OutOfCoreTileManager.
     * @returns {Object} statistics of the OutOfCoreTileManager
     */
    static getStats() {
        if (!OutOfCoreTileManager.#statsUpdateTimer) {
            OutOfCoreTileManager.#statsUpdateTimer = setInterval(OutOfCoreTileManager.updateStats, 1000);
        }

        // might be null in tests
        const defaultStages = OutOfCoreTileManager._stagesByConfig.get(getDefaultStageConfiguration());

        return {
            tilesInQueue: defaultStages?.find(stage => stage.config.stage === StageNames.CONSOLIDATION_COMPUTED)?.nodes?.length ?? 0,
            tilesOnGpu: defaultStages?.find(stage => stage.config.stage === StageNames.OPTIMIZED_ON_GPU)?.nodes?.length ?? 0,
            promotedLastSecond: OutOfCoreTileManager.#stats.promotedLastSecond,
            demotedLastSecond: OutOfCoreTileManager.#stats.demotedLastSecond,
            promotedThisFrame: OutOfCoreTileManager.#stats.promotedThisFrame,
            demotedThisFrame: OutOfCoreTileManager.#stats.demotedThisFrame,
            resources: OutOfCoreTileManager.#resources,
        };
    }

    static updateStats() {
        OutOfCoreTileManager.#stats.promotedLastSecond = OutOfCoreTileManager.#stats.promotedThisSecond;
        OutOfCoreTileManager.#stats.demotedLastSecond = OutOfCoreTileManager.#stats.demotedThisSecond;
        OutOfCoreTileManager.#stats.promotedThisSecond = 0;
        OutOfCoreTileManager.#stats.demotedThisSecond = 0;
    }


    /**
     * Adds a new config to the OutOfCoreTileManager
     * @param {OutOfCoreTileManagerStageConfiguration[]} config
     */
    static #registerStageConfig(config) {
        let stages = OutOfCoreTileManager._stagesByConfig.get(config);
        if (!stages) {
            stages = config.map(stageConfig => new Stage(stageConfig));
            OutOfCoreTileManager._stagesByConfig.set(config, stages);

            for (let i = 0; i < config.length; i++) {
                const stage = stages[i];

                stage.index = i;
                stage.previousStage = i !== 0 ? stages[i - 1] : undefined;
                stage.nextStage = i !== config.length - 1 ? stages[i + 1] : undefined;

                const demotionStageList = stages.slice(i);
                OutOfCoreTileManager._allStages.push(stage);

                const resource = stage.config.resource;
                if (!OutOfCoreTileManager.#resources.get(resource)) {
                    OutOfCoreTileManager.#resources.set(resource, { consumed: 0, limit: 0 });
                }

                // If there is a previous stage, we can demote nodes in this stage and therefore
                // register this node for its resource type as demotable
                let globalDemotionStageList = OutOfCoreTileManager._stagesByResourceForDemotion.get(resource);
                if (!globalDemotionStageList) {
                    globalDemotionStageList = [];
                    OutOfCoreTileManager._stagesByResourceForDemotion.set(resource, globalDemotionStageList);
                }
                for (let stage of demotionStageList) {
                    if (!globalDemotionStageList.includes(stage)) {
                        globalDemotionStageList.push(stage);
                    }
                }
            }
        }
        return stages;
    }

    /**
     * Register a new viewer instance with the OutOfCoreTileManager
     * @param {Viewer3DImpl} viewer
     */
    static registerViewer(viewer) {
        viewer.api.addEventListener(EventTypes.MODEL_ADDED_EVENT, OutOfCoreTileManager.#onModelAdded.bind(undefined, viewer));
        viewer.api.addEventListener(EventTypes.MODEL_REMOVED_EVENT, OutOfCoreTileManager.#onModelRemoved.bind(undefined, viewer));
        viewer.api.addEventListener(EventTypes.VIEWER_VISIBILITY_CHANGED, OutOfCoreTileManager.#onVisibilityChanged.bind(undefined, viewer));
        viewer.api.addEventListener(MODEL_CONSOLIDATION_ENABLED, OutOfCoreTileManager.#onModelConsolidated.bind(undefined, viewer));
    }

    /**
     * Register a new viewer instance with the OutOfCoreTileManager
     * @param {Viewer3DImpl} viewer
     */
    static unregisterViewer(viewer) {
        viewer.api.removeEventListener(EventTypes.MODEL_ADDED_EVENT, OutOfCoreTileManager.#onModelAdded.bind(undefined, viewer));
        viewer.api.removeEventListener(EventTypes.MODEL_REMOVED_EVENT, OutOfCoreTileManager.#onModelRemoved.bind(undefined, viewer));
        viewer.api.removeEventListener(EventTypes.VIEWER_VISIBILITY_CHANGED, OutOfCoreTileManager.#onVisibilityChanged.bind(undefined, viewer));
        viewer.api.removeEventListener(MODEL_CONSOLIDATION_ENABLED, OutOfCoreTileManager.#onModelConsolidated.bind(undefined, viewer));
    }

    /**
     * When a model gets consolidated, we need to retrigger otg cache processing and initialize the nodes
     * @param {Viewer3DImpl} viewer
     * @param {{visible: boolean}} event        - The event object
     */
    static #onModelConsolidated(viewer, event) {
        viewer.geomCache(false)?.startProcessing();
    }

    /**
     * The visibility for a viewer has changed
     * @param {Viewer3DImpl} viewer
     * @param {{visible: boolean}} event        - The event object
     */
    static #onVisibilityChanged(viewer, event) {
        if (!event.visible) {
            // If the viewer has been hidden, we have to reset the error for
            // all nodes, because the screen space error is not valid anymore
            for (let model of viewer.api.getAllModels()) {
                let [manager, iteratorID] = OutOfCoreTileManager.getManagerForIterator(model.getIterator(), model, true);
                if (manager && iteratorID) {
                    manager.resetScreenSpaceErrors(iteratorID);
                }
            }
        }
    }

    /**
     * A model has been added to a viewer
     * @param {Viewer3DImpl} viewer               - The viewer instance
     * @param {{model: RenderModel}} event        - The event object
     */
    static #onModelAdded(viewer, event) {
        let outOfCoreTileManager;

        // First check, whether we already have registered a model with the same leechViewerKey
        // (i.e. whether this model shares resources with a different model). In that case, we
        // we will use the already registered OutOfCoreTileManager for the referenced model
        OutOfCoreTileManager._updateLeechViewerKeysInModelRegistry();
        if (event.model.leechViewerKey !== undefined) {
            outOfCoreTileManager = OutOfCoreTileManager.modelManagerRegistry.get(event.model.leechViewerKey);
        }

        // Check, whether the model has already directly been registered with the out-of-core manager
        if (!outOfCoreTileManager) {
            outOfCoreTileManager = OutOfCoreTileManager.modelManagerRegistry.get(event.model);
        }
        OutOfCoreTileManager.notYetAddedManagers.delete(outOfCoreTileManager);

        // If we did not find any manager, we create a new one
        if (!outOfCoreTileManager) {
            outOfCoreTileManager = new OutOfCoreTileManager(event.model);

            // If this model is using a LeechViewer, we also store a reference with the leechViewerKey
            if (event.model.leechViewerKey !== undefined) {
                OutOfCoreTileManager.modelManagerRegistry.set(event.model.leechViewerKey, outOfCoreTileManager);
            }
        }
        OutOfCoreTileManager.modelManagerRegistry.set(event.model, outOfCoreTileManager);

        OutOfCoreTileManager.#t1_GPUUpload = undefined;

        // Assign the the viewer to the out-of-core tile manager
        outOfCoreTileManager.#addViewer(viewer);
    }

    /**
     * A model has been removed from a viewer
     * @param {Viewer3DImpl} viewer               - The viewer instance
     * @param {{model: RenderModel}} event        - The event object
     */
    static #onModelRemoved(viewer, event) {
        let outOfCoreTileManager = OutOfCoreTileManager.modelManagerRegistry.get(event.model);
        if (outOfCoreTileManager) {
            outOfCoreTileManager.#removeViewer(viewer, event.model);
        }

        OutOfCoreTileManager.modelManagerRegistry.delete(event.model);

        if (event.model.leechViewerKey !== undefined) {
            let usageCount = Array.from(OutOfCoreTileManager.modelManagerRegistry.keys()).filter(x => x.leechViewerKey === event.model.leechViewerKey).length;

            // If we no longer have any reference to the leechViewerKey, we can also remove that entry
            if (usageCount === 0) {
                OutOfCoreTileManager.modelManagerRegistry.delete(event.model.leechViewerKey);
            }
        }

        OutOfCoreTileManager._allStages = OutOfCoreTileManager._allStages.filter(stage => outOfCoreTileManager.stages.includes(stage));
    }

    /**
     * Gets the OutOfCoreTileManager and the iteratorId for an iterator
     * @param {ModelIteratorBVH} iterator - The BVH iterator
     * @param {RenderModel} model - The model the BVH iterator is responsible for
     * @param {boolean} doNotCreate - Whether to create a new manager if none is found
     * @return {[OutOfCoreTileManager, number]}
     */
    static getManagerForIterator(iterator, model, doNotCreate = false) {
        OutOfCoreTileManager._updateLeechViewerKeysInModelRegistry();
        let  manager = OutOfCoreTileManager.modelManagerRegistry.get(model.leechViewerKey) ??
                                        OutOfCoreTileManager.modelManagerRegistry.get(model);

        if (!manager) {
            if (doNotCreate) {
                return [undefined, 0];
            } else {
                // Create a new manager if none yet exists (this can happen if the
                // BVH is created before the model is added to the viewer)
                manager = new OutOfCoreTileManager(model);

                // If this model is using a LeechViewer, we also store a reference with the leechViewerKey
                if (model.leechViewerKey !== undefined) {
                    OutOfCoreTileManager.modelManagerRegistry.set(model.leechViewerKey, manager);
                }
                OutOfCoreTileManager.modelManagerRegistry.set(model, manager);
                OutOfCoreTileManager.notYetAddedManagers.add(manager);
            }
        }

        let id = manager.getIteratorId(iterator, doNotCreate);

        return [manager, id];
    }

    /**
     * Updates the leechViewerKeys in the model registry
     *
     * It can happen that models are added to the registry, before their leechViewerKey is set. In that case, we
     * would not have them under the correct key in the registry. This function updates the registry to ensure that
     * all models are registered under the correct key.
     */
    static _updateLeechViewerKeysInModelRegistry() {
        for (let [existingModel, outOfCoreTileManager] of OutOfCoreTileManager.modelManagerRegistry.entries()) {
            if (existingModel.leechViewerKey && !OutOfCoreTileManager.modelManagerRegistry.has(existingModel.leechViewerKey)) {
                OutOfCoreTileManager.modelManagerRegistry.set(existingModel.leechViewerKey, outOfCoreTileManager);
            }
        }
	}

    /**
     * Moves a node to a new stage (either upwards or downwards)
     *
     * @param {BvhNode} node - The node to add to the stage
     * @param {boolean} promotion - Whether the node is promoted to the next stage or has been demoted
     * @param {Promise[]} [taskCompletionPromises] - Promises that should be awaited before the node is added to the new stage
     */
    static async #moveNodeToNewStage(node, promotion, taskCompletionPromises) {

        if (!promotion) {
            // for promotions, this must be handled before the promotion is initiated.
            OutOfCoreTileManager.#resources.get(node.getResourceType(false)).consumed -= node.freeResourcesForCurrentStage();
        }

        // Remove the node from the current stage
        node.currentStage.nodes.splice(node.currentStage.nodes.indexOf(node), 1);

        if (taskCompletionPromises?.length > 0) {
            console.assert(promotion, 'We only support async transitions for promotions, not for demotions');

            node.currentStage.inAsyncPromotion.push(node);
            await Promise.all(taskCompletionPromises);

            let indexInAsyncPromotion = node.currentStage.inAsyncPromotion.indexOf(node);
            if (indexInAsyncPromotion !== -1) {
                node.currentStage.inAsyncPromotion.splice(indexInAsyncPromotion, 1);
            } else {
                // If the node is no longer in the inAsyncPromotion array, this means that the async transition
                // has been canceled during the execution of the stage transition, in that case we
                // no longer have to add the node to the new stage and instead stop here
                return;
            }
        }

        const newStage = promotion ? node.getNextStage() : node.getPreviousStage();

        // Update the current stage of the node
        node.currentStage = newStage;

        // Add the node at the correct position in the new stage
        let insertPosition = sortedInsertPosition(newStage.nodes, node, (a, b) => -a.compare(b));
        newStage.nodes.splice(insertPosition, 0, node);

        if (promotion) {
            OutOfCoreTileManager.#stats.promotedThisSecond++;
            OutOfCoreTileManager.#stats.promotedThisFrame++;
        } else {
            OutOfCoreTileManager.#stats.demotedThisSecond++;
            OutOfCoreTileManager.#stats.demotedThisFrame++;
        }
    }

    /**
      * Free all GPU Resources in the case of a context loss.
      */
    static resetAfterContextLoss() {
        for (let manager of OutOfCoreTileManager.modelManagerRegistry.values()) {
            manager.freeAllNodes();
        }
    }

    /**
     * Add analytics for GPU out-of-core, including:
     * - Time to reach the GPU memory limit the first time, if it was reached at all
     *
     * Note the OutOfCoreTileManager operates on multiple models, so there is no specific URL attached to the event.
     *
     * @param {RenderModel} [model]
     */
    static #trackGPUOutOfCore(model) {
        // only track the first time
        if (OutOfCoreTileManager.#t1_GPUUpload) {
            return;
        }

        OutOfCoreTileManager.#t1_GPUUpload = performance.now();
        const stats = OutOfCoreTileManager.getStats();
        const properties = {
            memory_limit_time: OutOfCoreTileManager.#t1_GPUUpload - OutOfCoreTileManager.#t0_ModelCreation,
            consolidation_memory_consumed: stats.gpuMemoryConsumed,
            consolidation_memory_limit: stats.gpuMemoryLimit,
            tiles_in_queue: stats.tilesInQueue,
            tiles_on_gpu: stats.tilesOnGpu,
            urn: model?.getData()?.urn ?? 'NOT_SET',
        };
        analytics.track('viewer.model.gpu_out_of_core', properties);
    }

    static setModelLoadStartedTimestamp() {
        this.#t0_ModelCreation = performance.now();
    }
}

// Ressource manager is currently not yet merged in dev
/*
OutOfCoreTileManager.seGpuMemoryLimit(ResourceManager.getHardwareLimits().GPU_MEMORY_LIMIT);

// Register listener for hardware level changes to update consolidation memory limit
ResourceManager.addEventListener(et.HARDWARE_LEVEL_CHANGED, function() {
    OutOfCoreTileManager.seGpuMemoryLimit(ResourceManager.getHardwareLimits().GPU_MEMORY_LIMIT);
});
*/